import { IAgency } from './interfaces/IAgency.interface';

export default class User {
  id: number;
  email: string;
  name: string;
  agency: IAgency;
  operadoraId: number;
  role: string;

  constructor(id: number, email: string, name: string, agency: IAgency, user: string, operadoraId: number) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.agency = agency;
    this.role = user;
    this.operadoraId = operadoraId;
  }

  isAdmin(): boolean {
    return this.role === 'ADMIN';
  }

  isGoWeasy(): boolean {
    return this.role === 'GOWEASY';
  }

  isOperator(): boolean {
    return this.role === 'OPERATOR';
  }
}
