import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { APP_KEYS } from 'constants/api/keys';
import { getToken, clearToken, clearUser } from './Login/user.service';
import { showErrorMessage } from 'utils/Toast';
import { FETCH_LOGIN_ROUTE } from './Login/fetchLogin.service';
import { LOGIN_URL } from 'routes/routes/public.routes';
import i18n from '../languages/index';

const UNAUTHORIZED = 401;

const createAxiosInstance = (baseUrl: any) => {
  const instance: AxiosInstance = axios.create({
    baseURL: baseUrl,
  });

  instance.interceptors.request.use((config: any) => {
    try {
      const isLoginRoute = config.url.includes(FETCH_LOGIN_ROUTE);
      const isTomRoute = config.url.includes('tom/');

      const token = getToken();
      if (token && !isLoginRoute && !isTomRoute) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.warn('Error on interceptors', error);
    }
    return config;
  });

  instance.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      const isLoginRoute = error.config.url.includes(FETCH_LOGIN_ROUTE);

      if (error.response.status === UNAUTHORIZED && !isLoginRoute) {
        clearToken();
        clearUser();
        showErrorMessage(i18n.t('PUBLIC.LOGIN.UNAUTHORIZED'), {
          autoClose: 1500,
          onClose: () => {
            window.location.href = LOGIN_URL;
          },
        });
      }
      throw error;
    },
  );

  return instance;
};

type AppAxiosInstance = {
  [key: string]: {
    baseUrlKey: string;
    instance: AxiosInstance;
    interceptors?: {
      request?: (config: AxiosRequestConfig) => Promise<AxiosRequestConfig>;
    };
  };
};

export const axiosInstances: AppAxiosInstance = {
  base: {
    baseUrlKey: 'base',
    instance: createAxiosInstance(APP_KEYS.API_URL),
  },
  cep: {
    baseUrlKey: 'cep',
    instance: createAxiosInstance('https://viacep.com.br/ws/'),
  },
  tom: {
    baseUrlKey: 'tom',
    instance: createAxiosInstance('https://api.dev.we.paxtecnologia.com.br'),
  },
};
