import { useContext, useEffect, useState } from 'react';

import LoadingSpinner from 'components/atoms/loading';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataContainer, HouseDetailsHR, MainPage } from './HouseDetails.style';
import Checkout from './components/Checkout.component';
import HouseDetails from './components/Details.component';
import HouseNotes from './components/HouseNotes.component';
import ListImagePreview from './components/ListImagePreview.component';
import PropertyRules from './components/PropertyRules.component';
import ReadMore from './components/ReadMore';

import styles from './styles.module.scss';
import useHouse from 'hooks/useHouse';
import Copyright from 'components/Copyright';
import COLORS from 'constants/colors';
import useBooking from 'hooks/useBooking';
import BookingContext from 'store/context/booking.context';
import PointMap from 'components/Maps/PointMap/Location.component';
import useFilters from 'hooks/useFilters';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { isLogged } from 'services/Login/user.service';
import { getOrigin } from 'utils/helpers';
import i18n from 'languages';
import { Language } from 'pages/Reservations/components/ReservationTable';

const HouseDetailsPage = () => {
  const [withError] = useState(false);
  const { t } = useTranslation();
  const initialLanguage = i18n.language as Language;
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const [locationState, setLocationState] = useState<any>(null);
  const [dateLabel, setDateLabel] = useState<{ date: string; label: string }[]>();

  const navigate = useNavigate();
  const { setBooking, setHouseBooking } = useContext(BookingContext);
  const { getFilterParameters, updateFilters, getHouseId, getFiltersAsQueryString } = useFilters();
  const location = useLocation();

  const { house, loadingHouse } = useHouse(getHouseId());
  const { booking } = useBooking();
  const [description, setDescription] = useState<any>('');

  const getPeriod = () => {
    return [booking.checkIn ?? '', booking.checkOut ?? ''];
  };

  const handleSubmit = () => {
    try {
      const urlQuery = getFilterParameters();

      if (!urlQuery.checkIn || !urlQuery.checkOut) {
        alert('Erro na query, checkin ou checkout não encontrados');
        return;
      }

      setHouseBooking(house);
      setBooking(booking);

      const query = getFiltersAsQueryString();
      const houseId = getHouseId();
      navigate({
        pathname: `/checkout/${houseId}`,
        search: `?${query}`,
      });
    } catch (err) {
      alert(`ERRO001 - Mensagem de erro: ${err}`);
    }
  };

  const handlePeriod = (value: any) => {
    const checkIn = `${value[0].getFullYear()}-${getFormattedDayWithZeroLeft(
      value[0].getMonth() + 1,
    )}-${getFormattedDayWithZeroLeft(value[0].getDate())}`;
    const checkOut = `${value[1].getFullYear()}-${getFormattedDayWithZeroLeft(
      value[1].getMonth() + 1,
    )}-${getFormattedDayWithZeroLeft(value[1].getDate())}`;

    booking.setPeriod(checkIn, checkOut);

    updateFilters({
      checkIn,
      checkOut,
    });
  };

  const handleGuests = (guests: any) => {
    updateFilters({
      ...guests,
    });
    booking.setGuests(guests.adults, guests.kids, guests.pcd, guests.elderly);
  };

  const goBack = () => {
    const origim = getOrigin();

    if (origim) {
      navigate(origim, {
        state: locationState,
      });
      return;
    }

    navigate(-1);
  };

  const getFormattedDayWithZeroLeft = (day: number) => {
    return day < 10 ? `0${day}` : `${day}`;
  };

  const formatDateLabel = (prefix: string, value: number): string => {
    if (value == 0) return '';
    return prefix + String(Math.round(value));
  };

  useEffect(() => {
    if (currentLanguage === 'pt-BR') {
      const newDateLabelValue = house?.scheduleForRent?.map((item) => ({
        date: item?.date,
        label: formatDateLabel('R$ ', item?.valueReal), // TODO: Criar uma função geral para essa conversão
      }));
      setDateLabel(newDateLabelValue);
      setDescription(house?.descriptionPT);
    } else if (currentLanguage === 'pt-PT') {
      const newDateLabelValue = house?.scheduleForRent?.map((item) => ({
        date: item?.date,
        label: formatDateLabel('R$ ', item?.valueReal),
      }));
      setDateLabel(newDateLabelValue);
      setDescription(house?.descriptionPT);
    } else if (currentLanguage === 'en') {
      const newDateLabelValue = house?.scheduleForRent?.map((item) => ({
        date: item?.date,
        label: formatDateLabel('$', item?.value),
      }));
      setDateLabel(newDateLabelValue);
      setDescription(house?.description);
    } else if (currentLanguage === 'es') {
      const newDateLabelValue = house?.scheduleForRent?.map((item) => ({
        date: item?.date,
        label: formatDateLabel('$', item?.value),
      }));
      setDateLabel(newDateLabelValue);
      setDescription(house?.descriptionES);
    }
  }, [currentLanguage, house]);

  useEffect(() => {
    if (location?.state) {
      setLocationState(location.state);
    }

    // TODO: tentar melhorar isso com um hook talvez
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  const renderContent = () => {
    if (loadingHouse && !house) return <LoadingSpinner />;

    if (house && !withError) {
      return (
        <>
          <div className={styles.content}>
            {isLogged() && (
              <div onClick={goBack} className={styles.backButton}>
                <MdOutlineChevronLeft size={32} color="#FFFFFF" />
              </div>
            )}
            <MainPage>
              <div>
                <div>{house && <ListImagePreview pictures={house.pictures} />}</div>
                <DataContainer>
                  <HouseDetails amenities={house.getSpaces()} title={t('BACKEND.TITLE')} />
                  <HouseDetailsHR />
                  <ReadMore text={description} label={t('HOUSE_DETAILS.ABOUT_HOUSE')} limit={100} textType="MARKDOWN" />

                  <HouseDetailsHR />
                  <PointMap
                    markers={[
                      {
                        idLocation: 0,
                        coordinates: {
                          lat: house.coordenates.lat,
                          long: house.coordenates.long,
                        },
                      },
                    ]}
                    title={t('HOUSE_DETAILS.EXPLORE_REGION')}
                  />
                  <HouseDetailsHR />
                  <PropertyRules
                    petsAllowed={house?.isPetFriendly}
                    checkIn={house.scheduleRules.checkIn}
                    checkOut={house.scheduleRules.checkOut}
                  />
                  <HouseDetailsHR />
                  <HouseNotes />
                </DataContainer>
              </div>
              <div>
                {/* TODO: Adicionar valores baseado na nova rota do back que está em desenvolvimento */}
                <Checkout
                  totalWithouTaxes={booking.getTotalPrice()}
                  isValidBooking={booking.isValidBooking()}
                  petsAllowed={false}
                  maxGuests={house.maxOccupancy}
                  onSubmit={handleSubmit}
                  onChangePeriod={handlePeriod}
                  onChangeGuests={handleGuests}
                  initialPeriod={getPeriod()}
                  dateSchedule={dateLabel}
                  ratesCalendar={house?.scheduleForRent}
                />
              </div>
            </MainPage>
          </div>
          <Copyright color={COLORS.neutral.white} backgroundColor={COLORS.neutral.black} />
        </>
      );
    }
    return null;
  };

  return renderContent();
};

export default HouseDetailsPage;
