/* eslint-disable max-len */

export default {
  WHO_WE_ARE: {
    TITLE: 'Quem Somos',
    SUBTITLE: 'Bem-vindos à GoWeasy, a sua plataforma exclusiva para alugueis de casas de temporada!',
    PARAGRAPH: `
      A GoWeasy nasceu para facilitar a busca da melhor experiência em hospedagem em uma casa de férias. A ideia nasceu baseada na experiência pessoal que os fundadores adquiriram ao longo de quase 30 anos trabalhando no mercado de turismo. Como donos de uma grande rede de agências de viagem e uma empresa de Property Management, sabemos os desafios que um cliente tem quando busca acertar na escolha da casa perfeita e mais ainda no atendimento recebido ao longo da estadia.
      <br/> <br/>
      Com tantas opções que o mercado oferece nem sempre é fácil atingir esses objetivos e conectar a casa perfeita com o cliente. 
      <br/> <br/>
      Aqui, estamos focados em simplificar a experiência de reserva de casas de férias para agentes de viagem.
      <br/> <br/>
      Nossa plataforma foi projetada em cada detalhe baseada para conectar perfeitamente sua empresa com uma seleção premium de casas de férias. Ao escolher a GoWeasy, você terá acesso a um sistema poderoso que simplifica sua busca, poupando tempo precioso, garantindo reservas eficientes e experiências únicas para seus clientes.
      <br/> <br/>
      Nosso objetivo é fornecer uma parceria confiável, que transforma desafios em soluções. Com uma equipe dedicada e qualificada oferecemos todo o apoio necessário para que a sua empresa se destaque no atendimento ao seu cliente, maximizando as suas vendas. 
      <br/> <br/>
      Junte-se a nós na GoWeasy e entre nesse mercado fascinante de oferecer uma experiência real em uma casa americana.
       `,
    PROPERTY: {
      TITLE: 'Property Managers',
      SUBTITLE: 'Sua Jornada com a GoWeasy: Parceria para o Sucesso',
      PARAGRAPH: `<strong>Descoberta:</strong> A GoWeasy oferece uma ferramenta inovadora de conectar suas propriedades a um novo mercado de milhares de agências de viagens (OTAS, Travel Techs, Digital Influencers) em vários paises, trazendo a oportunidade de elevar suas taxas de ocupação e aumentar a rentabilidade. A GoWeasy é a solução que você estava procurando para complementar sua estratégia de distribuição.
      <br/> <br/>
      <strong>Parceria Imediata:</strong> Envie suas informações e garanta uma rápida aprovação como parceiro GoWeasy. Você estará se juntando a uma comunidade comprometida com o sucesso mútuo e o crescimento dos negócios!
      <br/> <br/>
      <strong>Listagem Livre de Compromissos:</strong> O melhor de tudo? Não há custos nem obrigações de exclusividade ao listar suas propriedades na GoWeasy. Você mantém o controle enquanto amplia sua presença em um mercado diversificado.
      <br/> <br/>
      <strong>Maximize Sua Rentabilidade:</strong> Com nossa plataforma de distribuição eficiente, suas propriedades ganham o devido destaque e são vistas pelos maiores canais de distribuição na indústria. Aumente suas taxas de ocupação e veja sua rentabilidade decolar!
      <br/> <br/>
      <strong>Suporte de Alto Nível:</strong> Estamos aqui para você! Nossa equipe experiente oferece orientação, suporte contínuo e insights valiosos para impulsionar seus resultados.
      <br/> <br/>
      <strong>Conecte-se para Avançar:</strong> Interessado na nossa proposta? Entre em contato agora mesmo para iniciar uma parceria que vai revolucionar o modo como você gerencia suas propriedades. Chegou a hora de alavancar seu negócio! Junte-se à GoWeasy e experimente uma nova era de oportunidades para suas propriedades de aluguel de temporada.`,
    },
    AGENTS: {
      TITLE: 'Agentes de Turismo',
      SUBTITLE: 'Sua Jornada com a GoWeasy: Desbloqueando um Mundo de Oportunidades',
      PARAGRAPH: `<strong>Explore Novas Possibilidades:</strong> Como OTA ou agente de viagens, você busca constantemente diferenciais para atender às demandas dos clientes. Na GoWeasy, você descobre um novo mundo de possibilidades, especialmente criado para colocar você neste mercado de aluguel de temporada.
      <br/> <br/>
      <strong>Plataforma Flexível de Comissões:</strong> Além disso, oferecemos a você a exclusiva possibilidade de gerenciar suas comissões diretamente pela nossa plataforma. Simplifique o processo de controle financeiro ao adicionar suas comissões de forma prática e transparente, tudo em um só lugar.
      <br/> <br/>
      <strong>Plataforma Excepcional:</strong> Ao se tornar um parceiro GoWeasy, você terá acesso a uma plataforma excepcional, projetada especificamente para atender às necessidades das OTA e agentes de viagens. Uma seleção exclusiva de propriedades, escolhidas a dedo para garantir qualidade e diversidade, estará à sua disposição.
      <br/> <br/>
      <strong>Inventário Selecionado a Dedo:</strong> Oferecemos um inventário cuidadosamente selecionado para atender aos mais altos padrões de qualidade. Com propriedades escolhidas criteriosamente, você poderá apresentar aos seus clientes uma gama única de opções de aluguel de temporada.
      <br/> <br/>
      <strong>Suporte 24hrs:</strong> Nossa equipe dedicada oferece suporte a qualquer hora, auxiliando você a atender as necessidades de seu cliente no momento que ele mais precisar.
      <br/> <br/>
      <strong>Junte-se a Nós:</strong> Interessado com o que oferecemos? Entre em contato agora mesmo para explorar uma parceria única. Estamos prontos para oferecer o melhor em estadia e experiência em uma casa americana.`,
    },
  },
};
