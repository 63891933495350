import { TextField as TextFieldMUI } from '@mui/material';
import { styled } from '@mui/material/styles';
import APP_COLORS from 'constants/color';

export const TextField = styled(TextFieldMUI)(({ theme, error }) => ({
  '& .MuiFormLabel-root': {
    top: '.2rem',
    fontFamily: 'HelveticaNeue, sans-serif',
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: 4,
    position: 'relative',
    borderColor: error ? APP_COLORS.feedback.danger.default : '#E0E3E7',
    background: '#fff',
    padding: '.75rem',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#0089FF',
      borderWidth: 0.5,
    },
  },
}));
