import { useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
import { fetchFeaturedHouses } from 'services/Home/fetchFeaturedHouses.service';
import { getHousesByCondominium } from 'services/Houses/getHousesByCondominium.service';

const useHouses = (filtersParam: string[]) => {
  const [featuredHouses, setFeaturedHouses] = useState<any[]>([]);

  const { loading, call: loadHouses } = useAsync(async () => {
    const houseList = await fetchFeaturedHouses(filtersParam);
    setFeaturedHouses(houseList);
  }, [filtersParam]);

  const { loading: loadingHousesByCondominium, call: loadHousesByCondominium } = useAsync(
    async (condominium: string, checkIn: string, checkOut: string, filters: string[]) => {
      const houseList = await getHousesByCondominium(filters, checkIn, checkOut, condominium);
      setFeaturedHouses(houseList);
    },
  );

  const getSectionByName = (sectionName: string) => {
    if (!featuredHouses || featuredHouses.length === 0) return [];

    return featuredHouses.find((section) => section.title === sectionName)?.data ?? [];
  };

  const getSectionsFiltered = (filters: string[]) => {
    if (!featuredHouses || featuredHouses.length === 0) return [];

    return featuredHouses.filter((section) => !filters.includes(section.title));
  };

  return {
    featuredHouses,
    houses: featuredHouses,
    loadingHouses: loading || loadingHousesByCondominium,
    loadHouses,
    loadHousesByCondominium,
    getSectionByName,
    getSectionsFiltered,
  };
};

export default useHouses;
