import ReservationTable from './components/ReservationTable';
import { DoneDeal, HouseGreen, Revoked } from 'assets';
import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
import StatsSection from 'components/StatsSection';
import FeatureRow from 'components/BackOffice/FeatureRow';
import { IoSearchSharp } from 'react-icons/io5';
import { getUser } from 'services/Login/user.service';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Button from 'components/BackOffice/Button';
import moment from 'moment';
import styles from './styles.module.scss';
import RangeDatePicker from 'components/BackOffice/RangeDatePicker';
import getReservationAndReports from 'services/operators/getReservationReports';

// TODO: Passar o componente pra dentro da pasta backoffice

const Reservations = () => {
  const today = moment().format('YYYY-MM-DD HH:mm:ss');
  const [period, setPeriod] = useState(['01-01-2024', '12-12-2024']);
  const startDate = moment(period?.[0]).format('YYYY-MM-DD');
  const endDate = moment(period?.[1]).format('YYYY-MM-DD');
  const { t } = useTranslation();
  const location = useLocation();
  const [reservations, setReservations] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [code, setCode] = useState<any>('');
  const user = useMemo(() => getUser(), []);
  const tableReservationData = reservations?.reservations?.filter((r: any) =>
    String(r.reservaCode).toLowerCase().includes(code),
  );
  const reservationStatsNumbers = {
    confirmed: reservations?.reservationSuccess,
    pending: reservations?.reservationPending,
    cancel: reservations?.reservationCancel,
  };
  const headers = [
    { label: t('BACKOFFICE.RESERVATIONSCREEN.RESERVATIONID'), key: 'reservaID' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.DATE'), key: 'date' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.CUSTOMERNAME'), key: 'nomeCliente' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.PROPERTY'), key: 'imovel' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.AGENT'), key: 'agente' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.AGENCY'), key: 'agencia' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.DOLLARAMOUNT'), key: 'valorDolar' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.REALAMOUNT'), key: 'valorReal' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.NIGHTS'), key: 'diarias' },
    { label: t('BACKOFFICE.RESERVATIONSCREEN.STATUS'), key: 'status' },
  ];
  // TODO: change statsTest
  const statsTest = [
    {
      name: t('BACKOFFICE.RESERVATIONS'),
      status: t('BACKOFFICE.RESERVATIONSCREEN.COMPLETED'),
      reservationNumbers: reservationStatsNumbers.confirmed,
      icon: HouseGreen,
    },
    {
      name: t('BACKOFFICE.RESERVATIONS'),
      status: t('BACKOFFICE.RESERVATIONSCREEN.CONFIRMED'),
      reservationNumbers: reservationStatsNumbers.pending,
      icon: DoneDeal,
    },
    {
      name: t('BACKOFFICE.RESERVATIONS'),
      status: t('BACKOFFICE.RESERVATIONSCREEN.CANCELED'),
      reservationNumbers: reservationStatsNumbers.cancel,
      icon: Revoked,
    },
  ];

  const handleCodeChange = (event: any) => {
    setCode(event.target.value);
  };

  const handlePeriod = (date: any) => {
    setPeriod(date);
  };

  const getReportsData = (responseData: any) => {
    const newData = responseData?.reservations?.map((item: any) => ({
      reservaID: item.reservaCode,
      date: moment(item.date).format('YYYY-MM-DD HH:mm:ss'),
      nomeCliente: item.nameClient,
      imovel: item.houseId,
      agente: item.agente,
      agencia: item.agency,
      valorDolar: item.valueDollar,
      valorReal: item.valueReal,
      diarias: item.days,
      status: item.status,
    }));
    return newData;
  };

  const { call: getReservation } = useAsync(async (sDate: any, eDate: any) => {
    if (user.role === 'ADMIN') {
      const reservationList = await getReservationAndReports(user.agency.id, sDate, eDate);
      setReservations(reservationList);
      const reports = getReportsData(reservationList);
      setCsvData(reports);
    } else if (user.role === 'GOWEASY') {
      const reservationList = await getReservationAndReports(location?.state?.id, sDate, eDate);
      setReservations(reservationList);
      const reports = getReportsData(reservationList);
      setCsvData(reports);
    }
  }, []);

  useEffect(() => {
    getReservation(startDate, endDate);
  }, [period]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('BACKOFFICE.RESERVATIONS')}>
        <div style={{ display: 'flex' }}>
          {statsTest.map((stat) => {
            return (
              <StatsSection
                name={stat.name}
                status={stat.status}
                reservationNumbers={stat.reservationNumbers}
                icon={stat.icon}
              />
            );
          })}
        </div>
      </FeatureRow>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <TextField
          label={t('BACKOFFICE.RESERVATIONSCREEN.SEARCH_BY_RESERVATION_ID')}
          variant="outlined"
          value={code}
          onChange={handleCodeChange}
          style={{ width: '30%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoSearchSharp size={24} />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: 'flex', gap: '25px', flexDirection: 'row', alignItems: 'center' }}>
          <Button className={styles.buttonReports}>
            <CSVLink
              className={styles.buttonDownload}
              data={csvData}
              headers={headers}
              filename={`reservations - ${today}.csv`}
            >
              {t('BACKOFFICE.RESERVATIONSCREEN.DOWNLOAD')}
            </CSVLink>
          </Button>
          {<RangeDatePicker cleanOff minDate={new Date('01-01-2020')} value={period} onChangeValue={handlePeriod} />}
        </div>
      </div>
      <ReservationTable reservations={tableReservationData} />
    </div>
  );
};

export default Reservations;
