import { axiosInstances } from 'services/api';
import AgencyRequest from 'models/contracts/request/agency.request';
import AgencyResponse from 'models/contracts/response/Agency.response';
import { IApi } from 'models/application/interfaces/Api.interface';

const createAgency = async (agency: AgencyRequest): Promise<number> => {
  const { data }: IApi<AgencyResponse> = await axiosInstances.base.instance.post('/service/agencies', {
    ...agency,
  });

  return data.id;
};

export default createAgency;
