class LatinAmericanAgency {
  name: string;
  country: string;
  city: string;
  street: string;
  number: string;
  email: string;
  cellphone: string;
  corporateName: string;
  dossier: string;

  constructor(agency: LatinAmericanAgency) {
    this.name = agency.name;
    this.country = agency.country;
    this.city = agency.city;
    this.street = agency.street;
    this.number = agency.number;
    this.email = agency.email;
    this.cellphone = agency.cellphone;
    this.corporateName = agency.corporateName;
    this.dossier = agency.dossier;
  }
}

export default LatinAmericanAgency;
