import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

const Checkin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-left: 2px;
  padding-right: 16px;
  flex-grow: 1;
`;

const Checkout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-left: 2px;
  padding-left: 16px;
  flex-grow: 1;
`;

const Divider = styled.div`
  height: 20px;
  background-color: #cccccc;
  width: 0.5px;
  margin-right: 16px;
  margin-left: 16px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
`;

export { Checkin, Checkout, Container, Divider, LabelContainer };
