import Yup from 'utils/yup';

export const validateAgent = Yup.object({
  name: Yup.string().required(),
  cpf: Yup.string().required(),
  //.test('is-valid-cpf', 'CPF Inválido', (value) => isValidCPF(value)),
  // position: Yup.string().required(),
  email: Yup.string().email().required(),
  cep: Yup.string().required(),
  number: Yup.number().required(),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});
