export const setPath = (path: string) => {
  sessionStorage.setItem('path', path);
};

export const clearPath = () => {
  sessionStorage.removeItem('path');
};

export const getPath = () => {
  return sessionStorage.getItem('path');
};
