import { axiosInstances } from 'services/api';

// TODO: Criar tipo
const newCondominium = async (formData: any): Promise<any> => {
  const { data } = await axiosInstances.base.instance.post(`/service/v1/condominiums`, {
    ...formData,
  });

  return data;
};

export default newCondominium;
