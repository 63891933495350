import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BasicTable from 'components/BasicTable';
import { useEffect, useMemo, useState } from 'react';
import listAgentByAgency from 'services/Agent/listAgentByAgency.service';
import useAsync from 'hooks/useAsync.hook';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { maskPhoneBR } from 'utils/masks';
//import { PRIMARY_BLUE_COBALT } from 'styles/colors';
//import MoonLoader from 'react-spinners/MoonLoader';
//import useListAgency from 'hooks/useListAgency.hook';
//import ListAgencyAdm from 'models/application/ListAgency/ListAgency.model';
//import Button from 'components/BackOffice/Button';
//import ConfirmAgentModal from 'pages/BackOffice/Administrative/ListAgency/Components/ListAgencyTable/ConfirmAgentModal';

type AgentTableProps = {
  filter?: string;
};

const LinkedAgentsTable: React.FC<AgentTableProps> = ({ filter }) => {
  const { t } = useTranslation();
  //const navigate = useNavigate();
  const params = useParams();
  const agencyId = params.id;

  const [listAgent, setListAgent] = useState<any>([]);
  //const [confirmAccessModal, setConfirmAccessModal] = useState(false);
  //const [userSelected, setUserSelected] = useState<ListAgencyAdm>();
  //const [comissionSelected, setComissionSelected] = useState<any>('');

  const collumnsNames = [
    t('BACKOFFICE.TABLE.CODE'),
    t('BACKOFFICE.TABLE.TYPE'),
    t('BACKOFFICE.TABLE.NAME'),
    t('BACKOFFICE.TABLE.CONTACT'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_SALES'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_RESERVATIONS'),
    t('BACKOFFICE.TABLE.STATUS'),
    ' ',
  ];

  const { call: callGetAgents } = useAsync(async () => {
    try {
      const response = await listAgentByAgency(Number(agencyId));
      setListAgent(response);
    } catch (err: any) {
      throw new Error(err);
    }
  }, []);

  useEffect(() => {
    callGetAgents();
  }, []);

  const filterList = (agentsToFilter: any[], searchTerm: string) => {
    return agentsToFilter?.filter(
      (agencyList: any) =>
        agencyList?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agencyList?.id?.toString().includes(searchTerm),
    );
  };

  const agentsFiltered = useMemo(() => (filter ? filterList(listAgent, filter) : listAgent), [listAgent, filter]);

  /*const unlockAgent = (user: ListAgencyAdm) => {
    if (!user) return;
    setUserSelected(user);
    setConfirmAccessModal(true);
  };*/

  /*const confirmAllowAccess = async () => {
    if (!userSelected) return;
    await allowAgency(userSelected.id, comissionSelected);
    unselectUser();
  };*/

  /*const unselectUser = () => {
    setConfirmAccessModal(false);
    setUserSelected(undefined);
  };*/

  /*const handleSelect = (newSelectedValue: any) => {
    setComissionSelected(newSelectedValue);
  };*/

  const getStatus = (ativo?: boolean) => {
    if (ativo) {
      return <div className={styles.active}>{t('BACKOFFICE.TABLE.ACTIVE')}</div>;
    }

    return <div className={styles.waiting}>{t('BACKOFFICE.TABLE.WAITING_ACCESS')}</div>;
  };

  const getRole = (role?: string) => {
    if (role === 'ADMIN') {
      return <div className={styles.admin}>{t('BACKOFFICE.TABLE.ADMIN')}</div>;
    }

    if (role === 'USER') {
      return <div className={styles.user}>{t('BACKOFFICE.TABLE.USER')}</div>;
    }

    return null;
  };

  /*const goToAgency = (id: number) => {
    navigate(`/admin/agency-details/${id}`);
  };*/

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {agentsFiltered.map((currentAgents: any) => (
        <TableRow key={currentAgents.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell style={{ color: '#0049a1', fontSize: '14px' }}>#{currentAgents.id}</TableCell>
          <TableCell>{getRole(currentAgents.role)}</TableCell>
          <TableCell>{currentAgents.name}</TableCell>
          <TableCell>
            <div className={styles.contact}>
              <div>{maskPhoneBR(currentAgents?.cellphone)}</div>
              <div className={styles.email}>{currentAgents.email}</div>
            </div>
          </TableCell>
          <TableCell>
            <span className={styles.monthSalesAgent}>{'$' + ' ' + currentAgents?.sales}</span>
          </TableCell>
          <TableCell>
            <span className={styles.monthReservationsAgent}>
              {currentAgents?.countReservations +
                ' ' +
                (Number(currentAgents?.countReservations) > 1
                  ? t('BACKOFFICE.TABLE.RESERVATION') + 'S'
                  : t('BACKOFFICE.TABLE.RESERVATION'))}
            </span>
          </TableCell>
          <TableCell>{getStatus(currentAgents?.ativo)}</TableCell>
        </TableRow>
      ))}
      {/*userSelected && (
        <ConfirmAgentModal
          isOpen={confirmAccessModal}
          user={userSelected}
          onConfirm={confirmAllowAccess}
          onSelect={handleSelect}
          selectedValue={comissionSelected}
          onClose={unselectUser}
          loading={loadingAllowAgency}
        />
      )*/}
    </BasicTable>
  );
};

export default LinkedAgentsTable;
