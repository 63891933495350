import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import useAsync from 'hooks/useAsync.hook';
import reservationDetails from 'services/Management/getReservartionDetail.service';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { Languages } from 'languages/helpers.languages';
import i18n from 'languages';
import { Language } from 'pages/Reservations/components/ReservationTable';
import PointMap from 'components/Maps/PointMap/Location.component';
import { Buttons } from 'components/molecules/buttons';
import COLORS from 'constants/colors';
import TitleBack from 'components/TitleBack';
import MoonLoader from 'react-spinners/MoonLoader';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import { useTranslation } from 'react-i18next';

const ReservationResume = () => {
  const { t } = useTranslation();
  const initialLanguage = i18n.language as Language;
  const urlParams = useParams();
  const [reservationResume, setReservationResume] = useState<any>();
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);

  const { call: getReservationResume, loading } = useAsync(async () => {
    const response = await reservationDetails(urlParams.reservationId);
    setReservationResume(response);
  }, []);

  useEffect(() => {
    getReservationResume();

    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  const getLocale = () => {
    switch (i18next.language) {
      case Languages.ES:
        return 'es';
      case Languages.PT_BR:
        return 'pt-BR';
      default:
        return 'en-US';
    }
  };

  const calculateTotal = (language: string) => {
    const totalDolar =
      Number(reservationResume?.reservation?.payment?.payTaxValue) +
      Number(reservationResume?.reservation?.payment?.totalRentWithoutTax) +
      Number(reservationResume?.reservation?.payment?.totalRentServices) +
      Number(reservationResume?.reservation?.payment?.totalService) +
      Number(reservationResume?.reservation?.payment?.totalTax) +
      Number(reservationResume?.reservation?.payment?.agencyTaxValue);

    const totalReal =
      Number(reservationResume?.reservation?.payment?.payTaxValueConverted) +
      Number(reservationResume?.reservation?.payment?.totalRentWithoutTaxConverted) +
      Number(reservationResume?.reservation?.payment?.totalRentServicesConverted) +
      Number(reservationResume?.reservation?.payment?.totalServiceConverted) +
      Number(reservationResume?.reservation?.payment?.totalTaxConverted) +
      Number(reservationResume?.reservation?.payment?.agencyTaxValueConverted);

    if (language === Languages.PT_BR) {
      return `R$ ${totalReal.toFixed(3).slice(0, -1)}`;
    } else {
      return `$ ${totalDolar.toFixed(3).slice(0, -1)}`;
    }
  };

  const getDateName = (checkin: string, checkout: string) => {
    const firstDay = {
      month: new Date(checkin),
      day: new Date(checkin)?.getDate(),
    };

    const secondDay = {
      month: new Date(checkout),
      day: new Date(checkout)?.getDate(),
    };

    const isSameMonth = firstDay.month.getMonth() === secondDay.month.getMonth();

    if (isSameMonth) {
      return `${firstDay.day} - ${secondDay.day} ${firstDay.month?.toLocaleString(getLocale(), { month: 'long' })}`;
    }

    return `${firstDay.day} ${firstDay.month.toLocaleString(getLocale(), { month: 'short' })} - ${
      secondDay.day
    } ${secondDay.month.toLocaleString(getLocale(), { month: 'short' })}`;
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loading}>
          <MoonLoader color={PRIMARY_BLUE_COBALT} />
        </div>
      ) : (
        <div className={styles.sides}>
          <div className={styles.info}>
            <div className={styles.title}>
              <TitleBack title={`${t('RESERVATION.YOUR_RESERVE')} - ${reservationResume?.reservation?.id}`} />
            </div>
            <div className={styles.subTitle}>{t('RESERVATION.INFO')}</div>
            <div className={styles.miniSection}>
              <div className={styles.miniTitle}>{t('RESERVATION.DATES')}</div>
              <div>
                {getDateName(reservationResume?.reservation?.checkin, reservationResume?.reservation?.checkout)}
              </div>
            </div>
            <div className={styles.miniSection}>
              <div className={styles.miniTitle}>{t('RESERVATION.GUESTS')}</div>
              <div>
                {reservationResume?.house?.maxOccupancy} {t('RESERVATION.GUESTS')}
              </div>
            </div>
            <div className={styles.miniSection}>
              <div className={styles.miniTitle}>{t('RESERVATION.ADDRESS')}</div>
              <div>{reservationResume?.house?.adress}</div>
            </div>
            <div className={styles.miniSection}>
              <div className={styles.miniTitle}>{t('RESERVATION.CODE')}</div>
              <div>{reservationResume?.reservation?.payment?.paymentId}</div>
            </div>
            <div className={styles.miniSection}>
              <div className={styles.subTitle}>{t('RESERVATION.RULES')}</div>
              <div>{t('RESERVATION.RULES_TEXT')}</div>
            </div>
            <div className={styles.miniSection}>
              <div className={styles.subTitle}>{t('RESERVATION.NEED_INFO')}</div>
              <div>
                {t('RESERVATION.NEED_INFO_1')}

                <br />
                <br />
                {t('RESERVATION.NEED_INFO_2')}

                <br />
                <br />
                {t('RESERVATION.NEED_INFO_3')}
              </div>
            </div>
            <div className={styles.miniSection}>
              <div className={styles.subTitle}>Total</div>
              <div>{calculateTotal(currentLanguage)}</div>
            </div>
            <br />
            <br />
            <br />
            <Buttons.Default
              className={styles.button}
              backgroundColor={COLORS.primary.bleu}
              variant="contained"
              onClick={() => window.print()}
            >
              {t('RESERVATION.PRINT')}
            </Buttons.Default>
          </div>
          <div>
            {reservationResume?.house?.latitude && reservationResume?.house?.longitude ? (
              <div className={styles.borders}>
                <PointMap
                  mapHeight="500px"
                  markers={[
                    {
                      idLocation: 0,
                      coordinates: {
                        lat: reservationResume?.house?.latitude,
                        long: reservationResume?.house?.longitude,
                      },
                    },
                  ]}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservationResume;
