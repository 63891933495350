import { useContext, useEffect, useRef, useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
import { getBookingPrice } from 'services/HouseDetails/getBookingPrice.service';
import Booking from 'models/application/Booking.model';
import UserContext from 'store/context/user.context';
import useFilters from './useFilters';

const useBooking = () => {
  const { getFilterParameters, getHouseId } = useFilters();
  const controllerRef = useRef<AbortController>();

  const [user] = useContext(UserContext);
  const [booking, setBooking] = useState<Booking>(
    new Booking({
      ...getFilterParameters(),
      houseId: getHouseId(),
    }),
  );

  useEffect(() => {
    if (!booking.checkIn && !booking.checkOut) return;
    if (booking.getTotalGuests() > 0) {
      getPriceBooking();
    }
  }, [booking.checkIn, booking.checkOut, booking.kids + booking.adults + booking.pcd + booking.elderly]);

  const { loading, call: getPriceBooking } = useAsync(async () => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const bookingWithPrice = await getBookingPrice(booking, user.id, controllerRef.current.signal);
      setBooking(bookingWithPrice);
    } catch (error: any) {
      if (error.message != 'generic_error') {
        booking.setPrice({
          dollar: 0,
          real: 0,
        });
      }
    }
  }, []);

  return {
    booking,
    loadingPrice: loading,
    getPriceBooking,
  };
};

export default useBooking;
