import { axiosInstances } from 'services/api';
import Agency from 'models/application/Agency/Agency.model';

const editAgency = async (agency: Agency) => {
  await axiosInstances.base.instance.put('/service/agencies', {
    cnpj: agency.document,
    ...agency,
  });
};

export default editAgency;
