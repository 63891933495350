import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import FiltersEnum from 'utils/enums/filters.enum';
import { useTranslation } from 'react-i18next';
import FilterItem from '../FilterItem';
import TownhomesSVG from 'components/svgs/townhomes';
import SingleFamilySVG from 'components/svgs/singleFamily';
import ApartmentSVG from 'components/svgs/apartment';
import CondoSVG from 'components/svgs/condo';
import VillaSVG from 'components/svgs/villa';
import LuxuryHousesSVG from 'components/svgs/luxuryHouses';
import ManorSVG from 'components/svgs/manor';
import FirstTimeOrlandoSVG from 'components/svgs/firstTimeOrlando';
import ThematicSVG from 'components/svgs/thematic';
import PetFriendlySVG from 'components/svgs/petFriendly';
import ForSeniorsSVG from 'components/svgs/forSeniors';
import IntegratedOfficeSVG from 'components/svgs/integratedOffice';
import LargeGroupsSVG from 'components/svgs/largeGroups';
import CinemaSVG from 'components/svgs/cinema';
import NearDisneySVG from 'components/svgs/nearDisney';
import NearUniversalSVG from 'components/svgs/nearUniversal';
import { saveFilters } from 'utils/helpers';
import GolfNewSVG from 'components/svgs/golfNew';
import InternationalDriveSVG from 'components/svgs/internationalDrive';

type FilterHomeProps = {
  onChangeFilters: (filters: string[]) => void;
  defaultFilters?: string[];
};

const FilterHome: React.FC<FilterHomeProps> = ({ onChangeFilters, defaultFilters }) => {
  const { t } = useTranslation();

  const [selectedFilters, setSelectedFilters] = useState<string[]>(defaultFilters ?? []);

  const getColor = (enumValue: string) => {
    if (selectedFilters.includes(enumValue)) {
      return '#ffc99b';
    }
    return '#FFF';
  };

  const firstRow = [
    {
      title: t('FILTERS.TOWNHOMES'),
      iconPath: <TownhomesSVG color={getColor(FiltersEnum.TOWNHOMES)} />,
      enum: FiltersEnum.TOWNHOMES,
      alt: 'Townhome icon',
      hoverText: t('FILTERS.HOVER_TEXT.TOWNHOMES'),
    },
    {
      title: t('FILTERS.SINGLE_FAMILY'),
      iconPath: <SingleFamilySVG color={getColor(FiltersEnum.SINGLE_FAMILY)} />,
      enum: FiltersEnum.SINGLE_FAMILY,
      alt: 'Single Family Icon',
      hoverText: t('FILTERS.HOVER_TEXT.SINGLE_FAMILY'),
    },
    {
      title: t('FILTERS.APARTMENT'),
      iconPath: <ApartmentSVG color={getColor(FiltersEnum.APARTMENT)} />,
      enum: FiltersEnum.APARTMENT,
      alt: 'Apartment icon',
      hoverText: t('FILTERS.HOVER_TEXT.APARTMENT'),
    },
    {
      title: t('FILTERS.CONDOMINIUM'),
      iconPath: <CondoSVG color={getColor(FiltersEnum.CONDOMINIUM)} />,
      enum: FiltersEnum.CONDOMINIUM,
      alt: 'Condominium icon',
      hoverText: t('FILTERS.HOVER_TEXT.CONDOMINIUM'),
    },
    {
      title: t('FILTERS.VILLA'),
      iconPath: <VillaSVG color={getColor(FiltersEnum.VILLA)} />,
      enum: FiltersEnum.VILLA,
      alt: 'Villa icon',
      hoverText: t('FILTERS.HOVER_TEXT.VILLA'),
    },
    {
      title: t('FILTERS.LUXURY_HOUSES'),
      iconPath: <LuxuryHousesSVG color={getColor(FiltersEnum.LUXURY_HOUSE)} />,
      enum: FiltersEnum.LUXURY_HOUSE,
      alt: 'Luxury Houses icon',
      hoverText: t('FILTERS.HOVER_TEXT.LUXURY_HOUSES'),
    },
    {
      title: t('FILTERS.MANOR'),
      iconPath: <ManorSVG color={getColor(FiltersEnum.MANOR)} />,
      enum: FiltersEnum.MANOR,
      alt: 'Manor icon',
      hoverText: t('FILTERS.HOVER_TEXT.MANOR'),
    },
    {
      title: t('FILTERS.THEMATIC'),
      iconPath: <ThematicSVG color={getColor(FiltersEnum.THEMATIC)} />,
      enum: FiltersEnum.THEMATIC,
      alt: 'Single Family Icon',
      hoverText: t('FILTERS.HOVER_TEXT.THEMATIC'),
    },
  ];

  const secondRow = [
    {
      title: t('FILTERS.FIRST_TIME_ORLANDO'),
      iconPath: <FirstTimeOrlandoSVG color={getColor(FiltersEnum.FIRST_TIME_ORLANDO)} />,
      enum: FiltersEnum.FIRST_TIME_ORLANDO,
      alt: 'First time Orlando icon',
      hoverText: t('FILTERS.HOVER_TEXT.FIRST_TIME_ORLANDO'),
    },
    {
      title: t('FILTERS.PET_FRIENDLY'),
      iconPath: <PetFriendlySVG color={getColor(FiltersEnum.PET_FRIENDLY)} />,
      enum: FiltersEnum.PET_FRIENDLY,
      alt: 'Pet Friendly icon',
      hoverText: t('FILTERS.HOVER_TEXT.PET_FRIENDLY'),
    },
    {
      title: t('FILTERS.FOR_SENIORS'),
      iconPath: <ForSeniorsSVG color={getColor(FiltersEnum.FOR_SENIORS)} />,
      enum: FiltersEnum.FOR_SENIORS,
      alt: 'For Seniors icon',
      hoverText: t('FILTERS.HOVER_TEXT.FOR_SENIORS'),
    },
    {
      title: t('FILTERS.INTEGRATED_OFFICE'),
      iconPath: <IntegratedOfficeSVG color={getColor(FiltersEnum.INTEGRATED_OFFICE)} />,
      enum: FiltersEnum.INTEGRATED_OFFICE,
      alt: 'Integrated Office icon',
      hoverText: t('FILTERS.HOVER_TEXT.INTEGRATED_OFFICE'),
    },
    {
      title: t('FILTERS.LARGE_GROUPS'),
      iconPath: <LargeGroupsSVG color={getColor(FiltersEnum.LARGE_GROUPS)} />,
      enum: FiltersEnum.LARGE_GROUPS,
      alt: 'Group icon',
      hoverText: t('FILTERS.HOVER_TEXT.LARGE_GROUPS'),
    },
    {
      title: t('FILTERS.GOLF_COURSE'),
      iconPath: <GolfNewSVG color={getColor(FiltersEnum.GOLF_COURSE)} />,
      enum: FiltersEnum.GOLF_COURSE,
      alt: 'Golf Course icon',
      hoverText: t('FILTERS.HOVER_TEXT.GOLF_COURSE'),
    },
    {
      title: t('FILTERS.CINEMA'),
      iconPath: <CinemaSVG color={getColor(FiltersEnum.CINEMA)} />,
      enum: FiltersEnum.CINEMA,
      alt: 'Cinema icon',
      hoverText: t('FILTERS.HOVER_TEXT.CINEMA'),
    },
    {
      title: t('SECTIONS.NEAR_DISNEY'),
      iconPath: <NearDisneySVG color={getColor(FiltersEnum.NEAR_DISNEY)} />,
      enum: FiltersEnum.NEAR_DISNEY,
      alt: 'Disney icon',
      hoverText: t('FILTERS.HOVER_TEXT.NEAR_DISNEY'),
    },
    {
      title: t('SECTIONS.NEAR_UNIVERSAL'),
      iconPath: <NearUniversalSVG color={getColor(FiltersEnum.NEAR_UNIVERSAL)} />,
      enum: FiltersEnum.NEAR_UNIVERSAL,
      alt: 'Universal icon',
      hoverText: t('FILTERS.HOVER_TEXT.NEAR_UNIVERSAL'),
    },
    {
      title: t('SECTIONS.NEAR_INTERNATIONAL'),
      iconPath: <InternationalDriveSVG color={getColor(FiltersEnum.INTERNATIONAL_DRIVE)} />,
      enum: FiltersEnum.INTERNATIONAL_DRIVE,
      alt: 'International Drive icon',
      hoverText: t('SECTIONS.NEAR_INTERNATIONAL'),
    },
  ];

  const handleFilterSelected = (enumValue: string) => {
    if (selectedFilters.includes(enumValue)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== enumValue));
      return;
    }
    setSelectedFilters([...selectedFilters, enumValue]);
  };

  useEffect(() => {
    onChangeFilters(selectedFilters);
    saveFilters(selectedFilters);
  }, [selectedFilters]);

  return (
    <div className={styles.main}>
      <div className={styles.desktopContainer}>
        <div className={styles.firstRow}>
          {firstRow.map((filter, index) => (
            <FilterItem
              key={index}
              icon={filter.iconPath}
              title={filter.title}
              enumValue={filter.enum}
              alt={filter.alt}
              onSelected={handleFilterSelected}
              hoverText={filter.hoverText}
            />
          ))}
        </div>
        <div className={styles.divider}></div>
        <div className={styles.secondRow}>
          {secondRow.map((filter, index) => (
            <FilterItem
              key={index}
              icon={filter.iconPath}
              title={filter.title}
              enumValue={filter.enum}
              alt={filter.alt}
              onSelected={handleFilterSelected}
              hoverText={filter.hoverText}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterHome;
