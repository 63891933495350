import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Day from './Day';

export interface DateValue {
  date: string;
  value: number | string;
}

type CalendarProps = {
  availableDates?: DateValue[];
  selectedDate: any;
};

const Calendar: React.FC<CalendarProps> = ({ availableDates, selectedDate }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState<any>([]);

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const handlePreviousMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1));
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1));
  };

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const days = [];
    let day = 1;

    for (let i = 0; i < firstDayOfMonth.getDay(); i++) {
      days.push(null);
    }

    while (day <= lastDayOfMonth.getDate()) {
      const dateKey = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const dateValue = availableDates?.find((date) => date.date === dateKey);

      days.push({
        day,
        value: dateValue?.value ?? undefined,
        isAvailable: dateValue?.value && dateValue?.value !== 0 && dateValue?.value !== '0',
      });

      day = day + 1;
    }

    const lastDayOfWeek = lastDayOfMonth.getDay();
    for (let i = lastDayOfWeek + 1; i <= 6; i++) {
      days.push(null);
    }

    setDaysInMonth(days);
  }, [currentDate, availableDates]);

  useEffect(() => {
    if (selectedDate.length > 1) {
      setCurrentDate(selectedDate[0]);
    }
  }, [selectedDate]);

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <FaChevronLeft className={styles.left} onClick={handlePreviousMonth} color="#6C757D" size={18} />
        <FaChevronRight className={styles.right} onClick={handleNextMonth} color="#6C757D" size={18} />
        <div className={styles.month}>
          {months[currentDate.getMonth()]} {currentDate.getFullYear()}
        </div>
      </div>
      <div className={styles.daysOfWeek}>
        {daysOfWeek.map((day, index) => (
          <div className={styles.day} key={index}>
            {day}
          </div>
        ))}
      </div>
      <div className={styles.datesContainer}>
        {daysInMonth.map((day: any, index: number) => (
          <Day key={index} day={day?.day} value={day?.value?.toString()} isAvailable={day?.isAvailable} />
        ))}
      </div>
    </div>
  );
};

export default Calendar;
