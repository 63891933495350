import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ListHousesSection from 'components/ListHousesSection';
import ListHousesLoading from 'components/ListHousesSection/loading';
import useTopTenHouses from 'hooks/useTopTenHouses';
import { useSearchData } from 'layouts/Home';
import useHouses from 'hooks/useHouses';

const Home: React.FC = () => {
  const { filters = [] } = useSearchData();
  const { loadingHouses, loadHouses, getSectionsFiltered } = useHouses(filters);

  const sections = getSectionsFiltered([]);

  const { t } = useTranslation();
  const { topTenHouses, getTopTenHouses } = useTopTenHouses();

  useEffect(() => {
    loadHouses();
  }, [filters]);

  useEffect(() => {
    getTopTenHouses();
  }, []);

  return (
    <div>
      {loadingHouses ? (
        <>
          <ListHousesLoading />
          <br />
          <br />
          <ListHousesLoading />
        </>
      ) : (
        <>
          {sections?.slice(0, 3).map((house, index) => (
            <ListHousesSection key={index} title={t(`SECTIONS.${house.title}`)} data={house.data} />
          ))}
          {topTenHouses?.length > 0 && (
            <ListHousesSection title={t('GO_WEASY_CHOICES')} data={topTenHouses?.slice(0, 10)} withNumbers />
          )}
          {sections?.slice(3, sections.length).map((house, index) => (
            <ListHousesSection key={index} title={t(`SECTIONS.${house.title}`)} data={house.data} />
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
