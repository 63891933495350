import { axiosInstances } from 'services/api';
import { saveToken, saveUser } from './user.service';
import { IApi } from 'models/application/interfaces/Api.interface';
import { ILoginResonse } from 'models/contracts/response/Login.response';
import User from 'models/application/User.model';

export const FETCH_LOGIN_ROUTE = '/service/auth/login';

export const fetchLogin = async (username: string, password: string): Promise<User> => {
  const { data }: IApi<ILoginResonse> = await axiosInstances.base.instance.post(FETCH_LOGIN_ROUTE, {
    username,
    password,
  });

  const user = new User(
    data.id,
    data.email,
    data.name,
    { id: data.agencyId, name: data.agencyName },
    data.role,
    data.operadoraId,
  );

  saveToken(data.token);
  saveUser(user);

  return user;
};
