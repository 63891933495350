export default {
  RESERVATION: {
    YOUR_RESERVE: 'Su Reserva',
    INFO: 'Información de la Reserva',
    DATES: 'Fechas',
    GUESTS: 'Huéspedes',
    ADDRESS: 'Dirección del Alojamiento',
    CODE: 'Código de Confirmación',
    RULES: 'Reglas de Cancelación',
    RULES_TEXT:
      'Si cancela antes del 27 de septiembre, se le otorgará un reembolso parcial. Después de esta fecha, la reserva no será reembolsable.',
    NEED_INFO: 'Lo que Necesita Saber',
    PRINT: 'Imprimir Reserva',
    NEED_INFO_1: 'Las personas adicionales pueden incurrir en cargos adicionales según la política de la propiedad.',
    NEED_INFO_2:
      'Se puede requerir una identificación oficial con foto y una tarjeta de crédito, tarjeta de débito o depósito en efectivo al momento del check-in para cubrir gastos adicionales.',
    NEED_INFO_3:
      'Las solicitudes especiales están sujetas a disponibilidad al momento del check-in y pueden incurrir en cargos adicionales. Estas solicitudes no están garantizadas. Las fiestas o eventos grupales en el lugar están estrictamente prohibidos. Las características de seguridad disponibles en la propiedad incluyen un extintor de incendios y un detector de humo.',
  },
};
