import React from 'react';

import styles from './styles.module.scss';

type WECircleFlagProps = {
  children: React.ReactNode;
};

const WECircleFlag: React.FC<WECircleFlagProps> = ({ children }) => {
  return (
    <div className={styles.circle}>
      <div>{children}</div>
    </div>
  );
};

export default WECircleFlag;
