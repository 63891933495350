import FeatureRow from 'components/BackOffice/FeatureRow';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import LinkedAgenciesTable from '../LinkedAgencies/Components/LinkedAgenciesTable';
import { useParams } from 'react-router-dom';
import getLinkedAgenciesList from 'services/operators/getLinkedAgenciesList';
import useAsync from 'hooks/useAsync.hook';
import getAgency from 'services/Agency/getAgency.service';

const OperatorDetails = () => {
  const { t } = useTranslation();
  const [operator, setOperator] = useState<any>();
  const noSearch = '';
  const urlParams = useParams();
  const operatorId = urlParams.id; // id da operadora

  const { call: getAgenciesList } = useAsync(async () => {
    const response = await getLinkedAgenciesList(operatorId);
    const agencyId = response?.[0]?.id;
    getOperatorDetail(agencyId);
  });

  const { call: getOperatorDetail } = useAsync(async (id: any) => {
    if (id) {
      const agencyDetails = await getAgency(id);
      setOperator(agencyDetails.operadora);
    }
  });

  useEffect(() => {
    getAgenciesList();
  }, []);

  return (
    <div className={styles.screen}>
      <>
        <div className={styles.info}>
          <FeatureRow title={t('MENU.ADMINISTRATIVE')}>
            <div></div>
          </FeatureRow>
        </div>
        <div className={styles.content}>
          {operator && (
            <Card className={styles.card}>
              <div className={styles.left}>
                <div>
                  <h5>ID</h5>
                  {operator?.id}
                </div>
                <div>
                  <h5>{t('ADMINISTRATIVE.MAIN.NAME')}</h5>
                  {operator?.name}
                </div>
                <div>
                  <h5>CNPJ/Number State</h5>
                  {operator?.cnpj || operator?.numberState}
                </div>
                <div>
                  <h5>{t('ADMINISTRATIVE.MAIN.CONTACT')}</h5>
                  {operator?.primaryContact}
                </div>
              </div>
              <div className={styles.right}>
                <div>
                  <h5>E-mail</h5>
                  {operator?.email ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                </div>
                <div>
                  <h5>{t('BACKOFFICE.AGENT.CITY')}</h5>
                  {operator?.city ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                </div>
                <div>
                  <h5>{t('BACKOFFICE.AGENT.COUNTRY')}</h5>
                  {operator?.country ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                </div>
                <div>
                  <h5>{t('BACKOFFICE.AGENT.ADDRESS')}</h5>
                  {operator?.address ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                </div>
              </div>
              <div className={styles.right}>
                <div>
                  <h5>{t('ADMINISTRATIVE.MAIN.ACTIVE')}</h5>
                  {operator?.active ? t('ADMINISTRATIVE.MAIN.YES') : t('ADMINISTRATIVE.MAIN.NO')}
                </div>
                <div>
                  <h5>Cadastur</h5>
                  {operator?.cadastur ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                </div>
                <div>
                  <h5>{t('ADMINISTRATIVE.MAIN.SITE')}</h5>
                  {operator?.urlSite?.length < 2 ? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION') : operator?.urlSite}
                </div>
              </div>
            </Card>
          )}
        </div>
        <LinkedAgenciesTable id={operatorId} filter={noSearch} />
      </>
    </div>
  );
};

export default OperatorDetails;
