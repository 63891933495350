import { Buttons } from 'components/molecules/buttons';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pool } from 'assets';
import i18next from 'i18next';
import TranslationButton from 'components/TranslationButton';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { useEffect, useState } from 'react';

const ConfirmedCheckout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [reservation, setReservation] = useState<any>();

  const navigateToHome = () => {
    navigate('/');
  };

  const formatDate = (dateString: string): string => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString(i18next.language, {
      timeZone: 'UTC',
    });
  };

  const fillReservation = () => {
    if (location.state?.reservationId) {
      return setReservation({
        reservationId: location.state.reservationId,
        checkIn: location.state.checkIn,
        checkOut: location.state.checkOut,
      });
    }

    const reservationFromStorage = localStorage.getItem('reservation') ?? '{}';
    const newReservation = JSON.parse(reservationFromStorage);

    setReservation({
      reservationId: newReservation?.reservationId,
      checkIn: newReservation?.checkIn,
      checkOut: newReservation?.checkOut,
    });
  };

  useEffect(() => {
    fillReservation();
  }, []);

  return (
    <div>
      <div className={styles.header}>
        <Logo onClick={navigateToHome} className={styles.logo} />
        <div className={styles.icon}>
          <TranslationButton />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <img src={Pool} alt="" />
            <h4>{t('CONFIRMED_CHECKOUT.RESERVATION_CONFIRMED')}</h4>
            <div className={styles.text}>{t('CONFIRMED_CHECKOUT.CONGRATULATIONS')}</div>
            <div className={styles.text}>{t('CONFIRMED_CHECKOUT.UNFORGETTABLE_STAY')}</div>
            <div className={styles.info}>
              <div>
                {t('CONFIRMED_CHECKOUT.RESERVATION_IDENTIFIER')}{' '}
                <span className={styles.value}>{reservation?.reservationId}</span>
              </div>
              <div>
                {t('CONFIRMED_CHECKOUT.CHECKIN_DATE')}{' '}
                <span className={styles.value}>{formatDate(reservation?.checkIn)}</span>
              </div>
              <div>
                {t('CONFIRMED_CHECKOUT.CHECKOUT_DATE')}{' '}
                <span className={styles.value}>{formatDate(reservation?.checkOut)}</span>
              </div>
            </div>
            <Buttons.Default
              className={styles.button}
              backgroundColor="#0049A1"
              variant="contained"
              onClick={navigateToHome}
            >
              {t('CONFIRMED_CHECKOUT.BACK_TO_HOME')}
            </Buttons.Default>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmedCheckout;
