import HouseControlVideos from './components/HouseControlVideos';
import styles from './styles.module.scss';
//import WhatToDoVideos from './components/WhatToDoVideos';

const AcademyPage = () => {
  return (
    <div className={styles.gridContainer}>
      <div className={styles.fixedColumn}>
        <h1 className={styles.GoweasyTitle}>GoWeasy Academy</h1>
        <p
          style={{
            fontSize: '18px',
            color: '#CCCCCC',
            margin: 0,
            marginBottom: 12,
            textAlign: 'left',
            lineHeight: 1.5,
          }}
        >
          Neste espaço você encontrará uma sequência de 14 videos que a GoWeasy preparou para te ajudar a oferecer a seu
          cliente a melhor experiência de hospedagem em uma casa americana. Colocamos tambem dicas importantes para que
          seu cliente aproveite ao maximo a estadia.
        </p>
        {/*<label style={{ color: '#CCCCCC' }}></label>*/}
      </div>
      <div className={styles.scrollableColumn}>
        <HouseControlVideos />
      </div>
    </div>
  );
};

export default AcademyPage;
