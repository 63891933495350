import { showErrorMessage } from 'utils/Toast';
import useAsync from './useAsync.hook';
import { useState } from 'react';
import ListAgencyAdm from 'models/application/ListAgency/ListAgency.model';
import getAgencyAdministrative from 'services/AgencyList/getAgencyList.service';
import grantAccessToAgency from 'services/AgencyList/allowAgency.service';

const useListAgency = () => {
  const [agency, setAgency] = useState<ListAgencyAdm[]>([]);

  const { loading: loadingGetAgencyList, call: getAgencyList } = useAsync(async () => {
    try {
      const agencyList = await getAgencyAdministrative();
      setAgency(agencyList);
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  const { loading: loadingAllowAgency, call: allowAgency } = useAsync(async (id: any, comissionValue: any) => {
    try {
      await grantAccessToAgency(id, comissionValue);
      getAgencyList();
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  return {
    loadingGetAgencyList,
    getAgencyList,
    agency,
    loadingAllowAgency,
    allowAgency,
  };
};

export default useListAgency;
