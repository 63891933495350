import { isValidPhoneNumber } from 'utils/validator';
import Yup from 'utils/yup';

export const validateAgency = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  street: Yup.string().required(),
  number: Yup.number().required(),
  corporateName: Yup.string().required(),
  dossier: Yup.string().required(),
  cellphone: Yup.number()
    .required()
    .test('is-valid-phone', 'Celular inválido', (value) => isValidPhoneNumber(value)),
});
