import { ToastOptions, toast } from 'react-toastify';

const TOAST_DEFAULT_OPTIONS: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export const showErrorMessage = (message: string, customOptions?: any) => {
  toast.error(message, {
    ...TOAST_DEFAULT_OPTIONS,
    ...customOptions,
  });
};

export const showSuccessMessage = (message: string) => {
  toast.success(message, TOAST_DEFAULT_OPTIONS);
};

export const showInfoMessage = (message: string) => {
  toast.info(message, TOAST_DEFAULT_OPTIONS);
};
