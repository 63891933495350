import { Outlet, useLocation } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import styles from './styles.module.scss';

import { ReactComponent as Logo } from '../../assets/svg/wordmark.svg';
import { ReactComponent as LogoWhite } from '../../assets/svg/wordmark-white.svg';
import { useMemo } from 'react';
import { FORGOT_PASSWORD_URL, LOGIN_URL, REGISTER_URL } from 'routes/routes/public.routes';
import TranslationButton from 'components/TranslationButton';
import Footer from 'components/Footer';
import usePublicNavigation from 'routes/navigation/public.navigation';

const AccessLayout = () => {
  const location = useLocation();
  const { navigateToLogin } = usePublicNavigation();

  const currentUrl = location.pathname;

  const getImage = useMemo(() => {
    if (currentUrl.includes(LOGIN_URL)) {
      return require('../../assets/images/login-image-pool.png');
    }

    if (currentUrl.includes(REGISTER_URL)) {
      return require('../../assets/images/login-image-pool.png');
    }

    if (currentUrl.includes(FORGOT_PASSWORD_URL)) {
      return require('../../assets/images/login-image-pool.png');
    }

    return require('../../assets/images/login-image-pool.png');
  }, [currentUrl]);

  return (
    <div>
      <div className={styles.layout}>
        <div className={styles.logoContainer}>
          <div className={styles.logo} onClick={navigateToLogin}>
            {isDesktop ? <LogoWhite width={180} /> : <Logo width={180} />}
          </div>
          <TranslationButton className={styles.translation} />
        </div>
        <div className={styles.leftSide}>
          <div className={styles.layer}></div>
          <img className={styles.backgroundImage} src={getImage} alt="" />
        </div>
        <div className={styles.rightSide}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccessLayout;
