import React from 'react';
import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import LatinAmericanAgency from 'models/application/Agency/LatinAmericanAgency.model';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { validateAgency } from './validateAgency';
import { yupResolver } from '@hookform/resolvers/yup';

type AgencyFormESProps = {
  onSubmit: (data: LatinAmericanAgency) => void;
  agency?: LatinAmericanAgency;
};

const AgencyFormES: React.FC<AgencyFormESProps> = ({ onSubmit, agency }) => {
  const form = useForm<LatinAmericanAgency>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agency,
    resolver: yupResolver(validateAgency),
  });

  const beforeSubmit = (data: LatinAmericanAgency) => {
    onSubmit(new LatinAmericanAgency(data));
  };

  return (
    <div className={styles.containerForm}>
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="Agencia:" name="name" />
          <Inputs.Text form={form} required label="País:" name="country" />
          <Inputs.Text form={form} required label="Ciudad" name="city" />
          <Inputs.Text form={form} required label="Calle:" name="street" />
          <Inputs.Text form={form} label="Número:" name="number" />
          <div className={styles.gridTwoColumns}>
            <Inputs.Text form={form} required label="Razón Social:" name="corporateName" />
            <Inputs.Text form={form} required label="Legajo:" name="dossier" />
          </div>
          <Inputs.Text form={form} required label="Teléfono" name="cellphone" />
          <Inputs.Text form={form} required label="E-mail:" name="email" />
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Continuar
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AgencyFormES;
