import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

import styles from './styles.module.scss';
import COLORS from 'constants/colors';

const CustomArrows = {
  Right: ({ onClick }: any) => {
    return (
      <button className={styles.rightArrowButton} onClick={() => onClick()}>
        <LuChevronRight size={48} color={COLORS.primary.maya} />
      </button>
    );
  },
  Left: ({ onClick }: any) => {
    return (
      <button className={styles.leftArrowButton} onClick={() => onClick()}>
        <LuChevronLeft size={48} color={COLORS.primary.maya} />
      </button>
    );
  },
};

export default CustomArrows;
