import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { IInputProps } from '../defaultSettings';
import { TextField } from './styles';

export interface ITextProps<T extends FieldValues = FieldValues> extends IInputProps<T> {
  defaultValue?: string | number | readonly string[];
  type?: React.HTMLInputTypeAttribute;
  className?: string;
  autoComplete?: string;
}

const Text = <T extends FieldValues>(props: ITextProps<T>): React.ReactElement<T> => {
  const { name, form, defaultValue, ...rest } = props;
  const { formState, register } = form;

  const { error } = useMemo(() => {
    return form.getFieldState(name);
  }, [formState.errors]);

  return (
    <TextField
      {...rest}
      {...register(name)}
      defaultValue={defaultValue}
      fullWidth
      variant="outlined"
      error={!!error?.message}
      helperText={error?.message as string}
      size="small"
      className={props.className}
      inputProps={props.inputProps}
      InputLabelProps={props.inputLabelProps}
      onBlur={props.onBlur}
    />
  );
};

export default Text;
