import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import styles from './DatePicker.module.scss';
import { Container, Label, LabelContainer } from './styles';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { useQueryString } from 'hooks/useQueryString';
import i18next from 'i18next';
import moment from 'moment';
import { CheckoutPageParams } from 'pages/Checkout';
import { useMemo, useState } from 'react';
import { TileArgs } from 'react-calendar/dist/cjs/shared/types';
import { useTranslation } from 'react-i18next';

type TRangeValue = Date | null;

type TValuePiece = TRangeValue | Array<TRangeValue>;

type DatePickerProps = {
  minDate?: Date;
  ratesCalendar?: string[];
};

const DatePicker: React.FC<DatePickerProps> = ({ minDate, ratesCalendar = [] }) => {
  const { t } = useTranslation();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [qsData, setQsParam] = useQueryString<Partial<CheckoutPageParams>>();

  const disableDates = useMemo(
    () =>
      Object.entries(ratesCalendar)
        .filter(([_, keyValue]) => keyValue === '0')
        .map(([key]) => key),
    [ratesCalendar],
  );
  const currentLanguage = i18next.language;
  const isBR = currentLanguage === 'pt-BR';
  const dateFormat = isBR ? 'dd/MM/y' : 'MM/dd/y';

  const handleChangeValue = (dateValue: TValuePiece) => {
    if (dateValue && Array.isArray(dateValue)) {
      const allDatesSelected = getDatesBetween(dateValue[0], dateValue[1]);
      const isInvalidDateRange = disableDates.some((element) => allDatesSelected.includes(element));

      if (isInvalidDateRange) {
        return;
      }

      setQsParam({
        checkIn: moment(dateValue[0]).utc().format(),
        checkOut: moment(dateValue[1]).utc().format(),
      });
    }
  };

  const handleDisableDates = ({ date }: TileArgs) => {
    const formatedDate = moment(date).format('YYYY-MM-DD');
    const isDisableDate = disableDates.find((disableDate) => disableDate === `${formatedDate}`);

    if (isDisableDate) return true;

    return false;
  };

  const getDatesBetween = (startDate: TRangeValue, endDate: TRangeValue) => {
    const dates = [];
    const currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.toDate());
      currentDate.add(1, 'days');
    }

    return dates.map((date) => moment(date).format('YYYY-MM-DD'));
  };

  return (
    <Container>
      <LabelContainer onClick={() => setOpenCalendar((oldValue) => !oldValue)}>
        <Label>{t('CHECKOUT.FORM.TO_EDIT')}</Label>
      </LabelContainer>
      <DateRangePicker
        isOpen={openCalendar}
        calendarIcon={null}
        className={styles.dateInput}
        calendarClassName={styles.calendar}
        tileClassName={styles.tile}
        minDate={minDate}
        onChange={handleChangeValue}
        clearIcon={null}
        next2Label={null}
        locale="pt-br"
        prev2Label={null}
        value={[moment(qsData.checkIn).utc().format(), moment(qsData.checkOut).utc().format()]}
        tileDisabled={handleDisableDates}
        format={dateFormat}
      />
    </Container>
  );
};

export default DatePicker;
