import { axiosInstances } from 'services/api';
import Agent from 'models/application/Agent/Agent.model';

const updateAgent = async (agent: Agent): Promise<void> => {
  await axiosInstances.base.instance.put('/service/users/update', {
    phone: agent.cellphone,
    cpf: agent.nationalRegistration,
    document: agent.nationalRegistration,
    ...agent,
  });
};

export default updateAgent;
