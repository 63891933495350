export default {
  CLERMONT:
    'La ciudad es una de las que más crecen rápidamente en el Centro de Florida y está estratégicamente ubicada al oeste de Orlando y al norte de Disney World. <br/><br/> Conocida por sus pintorescas colinas y lagos prístinos. La calidad de vida en y alrededor de Clermont la convierte en un destino principal para aquellos que buscan un lugar familiar. Las tiendas locales ofrecen una variedad de opciones de compras y restaurantes, amplias instalaciones deportivas y actividades acuáticas.',
  KISSIMMEE:
    'Kissimmee es la ciudad más grande del Condado de Osceola, a pocos minutos de los Parques Disney, Universal Resorts y una increíble variedad de hoteles, resorts y alquileres vacacionales en la Capital Mundial de Casas de Vacaciones®. <br/><br/> Hay más que parques, ríos sinuosos y lagos relucientes junto con experiencias al aire libre. Kissimmee ofrece una increíble variedad de hoteles, resorts y alquileres vacacionales en la Capital Mundial de Casas de Vacaciones®.',
  DAVENPORT:
    'Situada a minutos de los Parques Disney y de Orlando, Davenport es una ciudad hermosa que es la estancia perfecta para vacacionar. Ubicada en el Centro de Florida, justo al lado de la I-4 y la Carretera 27, la ciudad tiene fácil acceso al entretenimiento, actividades y restaurantes cercanos, en el área de Orlando. <br/> También a las playas de la Costa del Golfo y la Costa Atlántica.',
  ORLANDO:
    'Orlando, Florida, es conocida por sus famosos parques temáticos, como Walt Disney World, Universal Studios y SeaWorld. Además de estas atracciones únicas, la ciudad, con sus cielos siempre azules y su atmósfera acogedora, ofrece una gran variedad de experiencias. Explora los encantadores jardines en Leu Gardens Park, disfruta de la alta gastronomía y las compras en Winter Park, y sumérgete en la vibrante vida nocturna del Church Street District en el centro de la ciudad. Orlando también cuenta con una excelente infraestructura turística y una rica escena cultural, con museos, teatros y muchas aventuras al aire libre.',
  OPEN_MAP: 'Ver en el mapa',
  CONDOMINIUMS: 'Condominios',
  TOOLTIP: {
    ORLANDO:
      'Orlando, Florida, es conocida por sus famosos parques temáticos, como Walt Disney World, Universal Studios y SeaWorld.',
    CLERMONT:
      '¡Colinas y lagos tranquilos cerca de Disney! Encantadora comunidad con tiendas, restaurantes, deportes y diversión frente al mar.',
    KISSIMMEE:
      '¡Minutos para Disney y Universal! ¡La capital del alquiler vacacional cerca de Orlando con parques, naturaleza y aventura!',
    DAVENPORT:
      'Ubicado a minutos de los Parques Disney y Orlando, un hermoso ciudad que es la estancia perfecta para vacaciones.',
  },
};
