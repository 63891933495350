export default {
  RESERVATION: {
    YOUR_RESERVE: 'Your Reservation',
    INFO: 'Reservation Information',
    DATES: 'Dates',
    GUESTS: 'Guests',
    ADDRESS: 'Accommodation Address',
    CODE: 'Confirmation Code',
    RULES: 'Cancellation Rules',
    RULES_TEXT:
      'If you cancel by September 27, a partial refund will be granted. After this date, the reservation becomes non-refundable.',
    NEED_INFO: 'What You Need to Know',
    PRINT: 'Print Reservation',
    NEED_INFO_1: 'Additional people may incur additional charges depending on the property’s policy.',
    NEED_INFO_2:
      'A government-issued photo ID and a credit card, debit card, or cash deposit may be required at check-in for incidental charges.',
    NEED_INFO_3:
      'Special requests are subject to availability at check-in and may incur additional charges. These requests are not guaranteed. Parties or group events on-site are strictly prohibited. Safety features available at the property include a fire extinguisher and a smoke detector.',
  },
};
