export default {
  AGENT: {
    CONFIRM: {
      AGENCY: 'Agencia',
      NAME: 'nombre',
      NATIONAL_REGISTRATION: '',
      EMAIL: 'Email',
      CONTACT: 'Contacto',
      BUTTON: 'Permitir el acceso',
      TITLE: 'Confirmar los datos del agente:',
      CANCEL: 'Cancelar',
    },
  },
  LANGUAGE: {
    CHOOSE: {
      TITLE: 'Elija el idioma deseado para continuar con el registro y la categoría:',
      CONFIRM: 'Confirmar',
      CATEGORY_INPUT: {
        LABEL: 'Categoría',
        PLACEHOLDER: 'Seleccione una categoría',
      },
      CATEGORY: {
        AGENT: 'Agente de Viajes',
        TRAVEL_AGENCY: 'Agencia de Viajes',
        PROPERTY_MANAGER: 'Administrador de Propiedades',
        FREELANCER: 'Trabajador autónomo',
        GOWEASY: 'GoWeasy',
        TRAVEL_OPERATOR: 'Operador de Viajes',
        AGENCY_OPERATOR: 'Operadora de Agencias',
      },
    },
  },
};
