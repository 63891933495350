import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BasicTable from 'components/BasicTable';
import i18n from 'languages';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// TODO: retirar daqui. Usado por outros arquivos, cuidado.
export type Language = 'pt-BR' | 'en' | 'es' | 'pt-PT';

const Status: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div>
      {status === 'PENDENTE' && (
        <label className={styles.statusCreated}>{t('BACKOFFICE.COMMISSIONS_TABLE.PENDING')}</label>
      )}
      {status === 'PAGA' && <label className={styles.statuSucceeded}>{t('BACKOFFICE.COMMISSIONS_TABLE.PAID')}</label>}
      {status === 'CANCELADA' && (
        <label className={styles.statusFailed}>{t('BACKOFFICE.COMMISSIONS_TABLE.CANCELED')}</label>
      )}
    </div>
  );
};

const ReportsTable: React.FC<any> = ({ data }) => {
  const initialLanguage = i18n.language as Language;
  const { t } = useTranslation();
  const [reportsValues, setReportsValues] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const collumnsNames = [
    t('ADMINISTRATIVE.REPORTS.RESERVATIONS'),
    t('ADMINISTRATIVE.REPORTS.PROPERTY'),
    t('ADMINISTRATIVE.REPORTS.PM'),
    t('ADMINISTRATIVE.REPORTS.DATE'),
    t('ADMINISTRATIVE.REPORTS.OPERATOR'),
    t('ADMINISTRATIVE.REPORTS.AGENCY'),
    t('ADMINISTRATIVE.REPORTS.AGENT'),
    t('ADMINISTRATIVE.REPORTS.SALE'),
    t('ADMINISTRATIVE.REPORTS.DOLLAR_EXCHANGE_RATE'),
    t('ADMINISTRATIVE.REPORTS.DAILY'),
    t('ADMINISTRATIVE.REPORTS.CLEANING'),
    t('ADMINISTRATIVE.REPORTS.COMMISSION_LP'),
    t('ADMINISTRATIVE.REPORTS.COMMISSION_AG'),
    t('ADMINISTRATIVE.REPORTS.COMMISSION_GW'),
    t('ADMINISTRATIVE.REPORTS.PAGTOUR_FEE'),
    t('ADMINISTRATIVE.REPORTS.TAXES'),
    t('ADMINISTRATIVE.REPORTS.DISCOUNTS'),
    t('ADMINISTRATIVE.REPORTS.GW_FEE'),
    t('ADMINISTRATIVE.REPORTS.TOTAL_GW'),
    '%',
    'STATUS',
  ];

  const convertStatus: any = (status: any) => {
    switch (status) {
      case 'PENDING':
        return 'PENDENTE';
      case 'APPROVED':
        return 'PAGA';
      case 'CANCELED':
        return 'CANCELADA';
      default:
        return '';
    }
  };

  const getLineStyle = (status: any) => {
    switch (status) {
      case 'PENDENTE':
        return styles.created;
      case 'PAGA':
        return styles.success;
      case 'CANCELADA':
        return styles.fail;
      default:
        return '';
    }
  };

  const formatDate = (dateString: string, language: Language): string => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      moment.locale('pt-br');
      return moment(dateString).format('DD MMM YYYY HH:mm');
    } else {
      moment.locale('en');
      return moment(dateString).format('MMM DD, YYYY HH:mm');
    }
  };

  useEffect(() => {
    // TODO: tentar melhorar isso com um hook talvez
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  useEffect(() => {
    const rows = data?.map((value: any) => {
      return {
        id: value?.id,
        imovel: value?.imovel,
        pmName: value?.pmName,
        date: value?.date,
        operador: value?.operador,
        agencia: value?.agencia,
        agente: value?.agente,
        venda: Number(value?.venda).toFixed(3).slice(0, -1),
        cotacaoDolar: value?.cotacaoDolar,
        diarias: value?.diarias,
        limpeza: Number(value?.limpeza).toFixed(3).slice(0, -1),
        limpezaComissao: Number(value?.limpezaComissao).toFixed(3).slice(0, -1),
        comissaoAgencia: Number(value?.comissaoAgencia).toFixed(3).slice(0, -1),
        comissaoGoweasy: Number(value?.comissaoGoweasy).toFixed(3).slice(0, -1),
        taxaPagtour: Number(value?.taxaPagtour).toFixed(3).slice(0, -1),
        impostos: value?.impostos,
        descontos: value?.descontos,
        taxaGoWeasyPlataforma: value?.taxaGoWeasyPlataforma,
        ganhosTotalGoWeasy: Number(value?.ganhosTotalGoWeasy).toFixed(3).slice(0, -1),
        porcentagem: Number(value?.porcentagem).toFixed(3).slice(0, -1),
        status: convertStatus(value?.status),
      };
    });
    setReportsValues(rows);
  }, [data, currentLanguage]);

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {reportsValues?.map((row: any) => (
        <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <div className={styles.reservationCode}>#{row?.id}</div>
          </TableCell>
          <TableCell>
            <div className={styles.reservationCode}>#{row?.imovel}</div>
          </TableCell>
          <TableCell>
            <div>{row?.pmName}</div>
          </TableCell>
          <TableCell>
            <div className={styles.reservationCode}>{formatDate(row?.date, currentLanguage)}</div>
          </TableCell>
          <TableCell>
            <div>{row?.operador}</div>
          </TableCell>
          <TableCell>{row?.agencia}</TableCell>
          <TableCell>{row?.agente}</TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.venda}</div>
          </TableCell>
          <TableCell>
            <div>{row?.cotacaoDolar}</div>
          </TableCell>
          <TableCell>{row?.diarias}</TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.limpeza}</div>
          </TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.limpezaComissao}</div>
          </TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.comissaoAgencia}</div>
          </TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.comissaoGoweasy}</div>
          </TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.taxaPagtour}</div>
          </TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.impostos}</div>
          </TableCell>
          <TableCell>{row?.descontos + '%'}</TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.taxaGoWeasyPlataforma}</div>
          </TableCell>
          <TableCell>
            <div className={getLineStyle(row?.status)}>{'$' + row?.ganhosTotalGoWeasy}</div>
          </TableCell>
          <TableCell>{row?.porcentagem + '%'}</TableCell>
          <TableCell>
            <Status status={row?.status} />
          </TableCell>
        </TableRow>
      ))}
    </BasicTable>
  );
};

export default ReportsTable;
