import Box from '@mui/material/Box';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';
import { Dialog } from '@mui/material';
import Button from 'components/BackOffice/Button';

type ConfirmAccessProps = {
  user: any;
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
};

const ConfirmAccess: React.FC<ConfirmAccessProps> = ({ onConfirm, onClose, isOpen, user, loading }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box className={styles.content}>
        <div className={styles.title}>{t('AGENT.CONFIRM.TITLE')}</div>
        <div className={styles.data}>
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.AGENCY')}:</b>
            <div className={styles.info}>{user.id}</div>
          </div>
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.NAME')}:</b>
            <div className={styles.info}>{user.name}</div>
          </div>
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.NATIONAL_REGISTRATION')}:</b>
            <div className={styles.info}>{user.cnpj}</div>
          </div>
          {/*<div className={styles.row}>
            <b>{t('AGENT.CONFIRM.EMAIL')}:</b>
            <div className={styles.info}>{user.email}</div>
          </div>*/}
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.CONTACT')}:</b>
            <div className={styles.info}>{user.primaryContact}</div>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>{t('AGENT.CONFIRM.CANCEL')}</Button>
          <Buttons.Orange
            className={styles.orangeButton}
            fullWidth
            variant="contained"
            onClick={onConfirm}
            loading={loading}
          >
            {t('AGENT.CONFIRM.BUTTON')}
          </Buttons.Orange>
        </div>
      </Box>
    </Dialog>
  );
};

export default ConfirmAccess;
