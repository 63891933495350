import HousesListAdmTable from './HouseListAdmTable';
import { CircleBlue, CircleGray, CircleGreen, CircleOrange, CircleRed } from 'assets';
import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
import FeatureRow from 'components/BackOffice/FeatureRow';
import { IoSearchSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import getHouseListByPm from 'services/HouseListAdm/getHouseListByPm';
import StatsCommissions from 'pages/BackOffice/Commission/components/StatsCommissions';
import getHouseTotalsByPm from 'services/HouseListAdm/getHouseTotalsByPm';
import { useNavigate, useParams } from 'react-router-dom';
import { Buttons } from 'components/molecules/buttons';
import { CONDOMINIUM_LIST_ADM } from 'routes/routes/backOffice.routes';

const HousesListAdm: React.FC<any> = () => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const pmId = urlParams.id;

  const [totalHouses, setTotalHouses] = useState<any>([]);
  const [houseList, setHouseList] = useState<any[]>([]);
  const [search, setSearch] = useState<any>('');
  const houseListFilteredData = houseList?.filter(
    (r: any) =>
      String(r.id).toLowerCase().includes(search) ||
      String(r.condominiumName).toLowerCase().includes(search?.toLowerCase()),
  );

  const reservationStatsNumbers = {
    reviewed: totalHouses?.saved,
    published: totalHouses?.active,
    pending: totalHouses?.underReview,
    inactive: totalHouses?.inactive,
    total: totalHouses?.totalHouse,
  };

  const statsTest = [
    {
      name: t('ADMINISTRATIVE.REVIEWED_HOUSES'),
      numbers: reservationStatsNumbers?.reviewed,
      icon: CircleGreen,
    },
    {
      name: t('ADMINISTRATIVE.PUBLISHED_HOUSES'),
      numbers: reservationStatsNumbers?.published,
      icon: CircleBlue,
    },
    {
      name: t('ADMINISTRATIVE.PENDING_HOUSES'),
      numbers: reservationStatsNumbers?.pending,
      icon: CircleOrange,
    },
    {
      name: t('ADMINISTRATIVE.INACTIVES_HOUSES'),
      numbers: reservationStatsNumbers?.inactive,
      icon: CircleGray,
    },
    {
      name: t('ADMINISTRATIVE.TOTAL_HOUSES'),
      numbers: reservationStatsNumbers?.total,
      icon: CircleRed,
    },
  ];

  const handleCodeChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleButtonClick = () => {
    navigate(CONDOMINIUM_LIST_ADM);
  };

  const { call: getHouses } = useAsync(async (id: any) => {
    const response = await getHouseListByPm(id);
    setHouseList(response);
  }, []);

  const { call: getTotalHouses } = useAsync(async (id: any) => {
    const response = await getHouseTotalsByPm(id);
    setTotalHouses(response);
  }, []);

  useEffect(() => {
    getHouses(pmId);
    getTotalHouses(pmId);
  }, [pmId]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('ADMINISTRATIVE.HOUSES')}>
        <div style={{ display: 'flex' }}>
          {statsTest.map((stat) => {
            return <StatsCommissions text={stat.name} numbers={stat.numbers} icon={stat.icon} />;
          })}
        </div>
      </FeatureRow>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <TextField
          label={t('ADMINISTRATIVE.SEARCH_BY_ID_OR_CONDOMINIUM')}
          variant="outlined"
          value={search}
          onChange={handleCodeChange}
          style={{ width: '35%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoSearchSharp size={24} />
              </InputAdornment>
            ),
          }}
        />
        <Buttons.Primary onClick={handleButtonClick}>Listagem de Condominios</Buttons.Primary>
      </div>
      <HousesListAdmTable houseList={houseListFilteredData} />
    </div>
  );
};

export default HousesListAdm;
