import React from 'react';
import { BaseProps } from './BaseProps';

const GolfNewSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.337" height="46" viewBox="0 0 41.337 46">
      <g id="golf-equipment_12951470" transform="translate(-5.666 -2.701)">
        <g id="Grupo_3326" data-name="Grupo 3326" transform="translate(13.08 25.135)">
          <g id="Grupo_3320" data-name="Grupo 3320">
            <path
              id="Caminho_7836"
              data-name="Caminho 7836"
              d="M37.8,56.19a.988.988,0,0,1-.57-.18,1,1,0,0,1-.25-1.39,9.385,9.385,0,0,0,1.67-5.38,9.5,9.5,0,0,0-19,0,9.392,9.392,0,0,0,1.48,5.09,1,1,0,1,1-1.68,1.08,11.5,11.5,0,1,1,19.18.35A1.015,1.015,0,0,1,37.8,56.19Z"
              transform="translate(-17.66 -37.74)"
              fill={color}
            />
          </g>
          <g id="Grupo_3321" data-name="Grupo 3321" transform="translate(3.194 18.044)">
            <path
              id="Caminho_7837"
              data-name="Caminho 7837"
              d="M29.16,60.73a11.446,11.446,0,0,1-8-3.23,1,1,0,1,1,1.39-1.43,9.513,9.513,0,0,0,12.88.31,1,1,0,0,1,1.32,1.5,11.5,11.5,0,0,1-7.59,2.85Z"
              transform="translate(-20.854 -55.784)"
              fill={color}
            />
          </g>
          <g id="Grupo_3322" data-name="Grupo 3322" transform="translate(8.12 4.21)">
            <path
              id="Caminho_7838"
              data-name="Caminho 7838"
              d="M27.5,43.95h-.72a1,1,0,1,1,0-2h.72a1,1,0,0,1,0,2Z"
              transform="translate(-25.78 -41.95)"
              fill={color}
            />
          </g>
          <g id="Grupo_3323" data-name="Grupo 3323" transform="translate(10.23 9.55)">
            <path
              id="Caminho_7839"
              data-name="Caminho 7839"
              d="M29.61,49.29h-.72a1,1,0,0,1,0-2h.72a1,1,0,0,1,0,2Z"
              transform="translate(-27.89 -47.29)"
              fill={color}
            />
          </g>
          <g id="Grupo_3324" data-name="Grupo 3324" transform="translate(4.3 8.31)">
            <path
              id="Caminho_7840"
              data-name="Caminho 7840"
              d="M23.68,48.05h-.72a1,1,0,0,1,0-2h.72a1,1,0,0,1,0,2Z"
              transform="translate(-21.96 -46.05)"
              fill={color}
            />
          </g>
          <g id="Grupo_3325" data-name="Grupo 3325" transform="translate(6.41 13.69)">
            <path
              id="Caminho_7841"
              data-name="Caminho 7841"
              d="M25.79,53.43h-.72a1,1,0,0,1,0-2h.72a1,1,0,1,1,0,2Z"
              transform="translate(-24.07 -51.43)"
              fill={color}
            />
          </g>
        </g>
        <g id="Grupo_3327" data-name="Grupo 3327" transform="translate(5.666 2.702)">
          <path
            id="Caminho_7842"
            data-name="Caminho 7842"
            d="M15.482,39.935c-2.719,0-7.29-.56-8.63-4.131-1.446-3.862-1.566-6.634-.377-8.472a6.47,6.47,0,0,1,5.2-2.869l15.815-2.032A3.031,3.031,0,0,0,30,20.75L38.782,3.119a.747.747,0,0,1,1.009-.336.735.735,0,0,1,.339,1L31.356,21.415a4.51,4.51,0,0,1-3.72,2.5L11.8,25.95A5.013,5.013,0,0,0,7.74,28.139c-.919,1.419-.746,3.75.52,7.142,1.047,2.794,5.068,3.183,7.365,3.16a.73.73,0,0,1,.761.74.757.757,0,0,1-.746.755h-.158Z"
            transform="translate(-5.666 -2.702)"
            fill={color}
          />
        </g>
        <g id="Grupo_3328" data-name="Grupo 3328" transform="translate(27.154 2.701)">
          <path
            id="Caminho_7843"
            data-name="Caminho 7843"
            d="M39.48,48.35a1,1,0,0,1-.37-1.93c.12-.05.24-.1.34-.15,4.88-2.33,6.21-9.21,5.67-11.51C43.94,29.67,55.15,5.96,56.43,3.27a1,1,0,0,1,1.8.86C53.51,14.04,46.3,30.99,47.07,34.31c.6,2.61-.55,10.79-6.75,13.76-.13.06-.3.14-.48.21a1.048,1.048,0,0,1-.36.07Z"
            transform="translate(-38.481 -2.701)"
            fill={color}
          />
        </g>
        <g id="Grupo_3329" data-name="Grupo 3329" transform="translate(6.698 40.24)">
          <path
            id="Caminho_7844"
            data-name="Caminho 7844"
            d="M22.96,61.3a1.019,1.019,0,0,1-.87-.5,10.209,10.209,0,0,1-1.3-5.01,5.463,5.463,0,0,0-1.07,3.3,1,1,0,0,1-1.86.55,5.651,5.651,0,0,0-2.64-2.24,7.556,7.556,0,0,1,.93,2.82,1,1,0,1,1-1.99.17,5.04,5.04,0,0,0-2.44-3.75,1,1,0,0,1,.44-1.88,9.492,9.492,0,0,1,5.89,2.05,5.528,5.528,0,0,1,3.58-3.91.977.977,0,0,1,.99.21.993.993,0,0,1,.31.96,8.973,8.973,0,0,0,.89,5.74,1,1,0,0,1-.36,1.37,1.115,1.115,0,0,1-.5.12Z"
            transform="translate(-11.195 -52.845)"
            fill={color}
          />
        </g>
        <g id="Grupo_3330" data-name="Grupo 3330" transform="translate(29.783 37.755)">
          <path
            id="Caminho_7845"
            data-name="Caminho 7845"
            d="M35.28,61.3a1.034,1.034,0,0,1-.48-.12,1,1,0,0,1-.4-1.35c.07-.13,1.63-3.1-.15-5.36a1,1,0,0,1,1-1.6,7.879,7.879,0,0,1,4.61,3.09,9.02,9.02,0,0,1,2.75-1.94.988.988,0,0,1,1.02.09,1.023,1.023,0,0,1,.41.94,8.282,8.282,0,0,0,.44,3.44c1.06-1.48,2.57-4.19,1.59-6.78a1,1,0,0,1,1.55-1.14c.17.13,4.12,3.32,3.59,9.81a1,1,0,1,1-2-.16,10.541,10.541,0,0,0-.93-5.49A13.085,13.085,0,0,1,44.99,61a1,1,0,0,1-1.55-.14,8.975,8.975,0,0,1-1.41-4.19c-1.27.96-1.38,1.75-1.38,1.76a1.014,1.014,0,0,1-.96.99.98.98,0,0,1-1.04-.9,3.788,3.788,0,0,0-1.67-2.72,8.267,8.267,0,0,1-.83,4.97.973.973,0,0,1-.87.53Z"
            transform="translate(-34.039 -50.357)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default GolfNewSVG;
