import { HouseResume } from 'models/application/HouseResume.model';
import { IPrincipalHouseResponse } from 'models/contracts/response/PrincipalHouse.response';
import { axiosInstances } from 'services/api';
import { getHouseListFilters } from 'utils/helpers';
import { sortByFilterOrder } from 'utils/sorts/filter-order.sort';
import { sortGroupType } from 'utils/sorts/group-type.sort';

export const fetchFeaturedHouses = async (filters: string[]): Promise<{ title: string; data: HouseResume[] }[]> => {
  const { data } = await axiosInstances.base.instance.get('service/v1/house', {
    params: {
      ...getHouseListFilters(filters),
      nearDisney: true, // default
      townhomes: true, // default
      singleFamily: true,
      thematic: true, // default
      cinema: true, // default
      amazingPools: true, //default
      firstTimeOrlando: true, // default
      townhomesNoPool: true, // default
      townhomes3d10p: true, // default
      townhomes4d12p: true, // default
      townhomes5d14p: true, // default
      largeGroups12p18: true, // default
      largeGroups19p23: true, // default
      largeGroups24p: true, // default
      nearUniversal: true,
    },
  });

  let sections = Object.keys(data);
  sections = sortByFilterOrder(sections, filters);
  sections = sortGroupType(sections, 'TOWNHOMES');
  sections = sortGroupType(sections, 'LARGE_GROUPS');

  const result = sections.map((section) => {
    const houses: any = data[section];

    return {
      title: section,
      data: houses.map(
        (house: IPrincipalHouseResponse) =>
          new HouseResume({
            mainPicture: house.mainPicture,
            price: {
              real: house.startPriceReal,
              dollar: house.startPriceDollar,
            },
            name: house.houseName,
            id: house.houseId,
            bedroomAmount: house.roomAmount,
            bathroomAmount: house.bathroomAmount,
            maxOccupancy: house.maxOccupancy,
            amenities: house.amenities.map((amenitie) => ({
              name: amenitie,
            })),
          }),
      ) as HouseResume[],
    };
  });

  return result.filter((el: any) => el.data.length > 0);
};
