import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_SUCCESS_URL, FORGOT_PASSWORD_URL, LOGIN_URL, REGISTER_URL } from 'routes/routes/public.routes';

const usePublicNavigation = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate(LOGIN_URL);
  };

  const navigateToRegister = () => {
    navigate(REGISTER_URL);
  };

  const navigateToForgotPassword = () => {
    navigate(FORGOT_PASSWORD_URL);
  };

  const navigateToForgotPasswordSuccess = (email?: string) => {
    if (email) {
      navigate(FORGOT_PASSWORD_SUCCESS_URL, { state: { email } });
      return;
    }
    navigate(FORGOT_PASSWORD_SUCCESS_URL);
  };

  const navigateToHomePage = () => {
    navigate('/');
  };

  return {
    navigateToLogin,
    navigateToHomePage,
    navigateToRegister,
    navigateToForgotPassword,
    navigateToForgotPasswordSuccess,
  };
};

export default usePublicNavigation;
