export default {
  TITLE: 'Elija las mejores casas de vacaciones en Orlando',
  SUB_TITLE: 'Encuentre el área que se adapta a las necesidades y deseos de sus clientes',
  GO_WEASY_CHOICES: 'Elecciones de GoWeasy',
  SEARCH: {
    LABEL: {
      DESTINATION: 'Dónde',
      BUTTON: 'Buscar',
    },
    PLACEHOLDER: {
      CHECK_IN: 'Fecha de llegada',
      CHECK_OUT: 'Fecha de salida',
      EMAIL: 'Ingrese su dirección de correo electrónico',
    },
  },
  PARTNERS: {
    TITLE: 'Socios de GoWeasy',
  },
  EXPLORE: {
    NEWS: 'Noticias',
    ORLANDO: 'Explora Orlando',
    HANG_OUT: 'Paseos',
    NIGHT: 'Nocturnos',
    THE_BEST: 'Lo mejor',
    BEST_ATTRACTIONS: 'atracciones de cada parque',
    ORLANDO_ALT: 'Personas mirando Orlando de noche frente al agua',
    NIGHT_LIFE_ALT: 'Barco de lujo anclado por la noche en Orlando',
    BEST_ATTRACTIONS_ALT: 'Dos amigas en una atracción de parque de diversiones',
  },
  SHOWOFF_EXPERIENCES: {
    CARS: 'Autos Deportivos',
    CHEF: 'Chef en Casa',
    GOLF: 'Paquetes de Golf',
    BABY: 'Babysitter',
    ENTERTEINMENT: 'Entertainment',
    OUTDOOR_FUN: 'Outdoor Fun',
    PARKS: 'Parques Temáticos',
    ACCOMODATION: 'Alojamiento',
    THEME_PARK: 'Experiencias Temáticas',
    HOLLYWOOD: 'Estudios de Hollywood',
    SHOPPING: 'Compras',
    FISHING: 'Pesca',
    WATER_PARKS: 'Parques Acuáticos',
    HOUSES: 'Casas Temáticas',
    BUTTON: 'BREVE',
  },
  FILTERS: {
    TOWNHOMES: 'Townhomes',
    SINGLE_FAMILY: 'Single family',
    APARTMENT: 'Apartamento',
    CONDOMINIUM: 'Condo',
    VILLA: 'Villa',
    LUXURY_HOUSES: 'Casas de Lujo',
    MANOR: 'Mansión',
    PET_FRIENDLY: 'Amigable con Mascotas',
    FOR_SENIORS: 'Para Personas Mayores',
    INTEGRATED_OFFICE: 'Oficina Integrada',
    GOLF_COURSE: 'Campo de Golf',
    CINEMA: 'Cine',
    THEMATIC: 'Temáticas',
    FIRST_TIME_ORLANDO: 'Primera vez',
    LARGE_GROUPS: 'Grandes grupos',
    HOVER_TEXT: {
      TOWNHOMES: 'Diseño contemporáneo y funcional, perfecto para quienes buscan vecindarios cercanos',
      SINGLE_FAMILY: 'Casas Particulares con espacio exclusivo.',
      APARTMENT: 'Apartamento',
      CONDOMINIUM: 'Condo',
      VILLA: 'Villa',
      LUXURY_HOUSES: 'Casas de Lujo',
      MANOR: 'Mansión',
      PET_FRIENDLY: 'Amigable con Mascotas',
      FOR_SENIORS: 'Para Personas Mayores',
      INTEGRATED_OFFICE: 'Oficina Integrada',
      GOLF_COURSE: 'Campo de Golf',
      CINEMA: 'Cine',
      THEMATIC: 'Temáticas',
      NEAR_DISNEY: 'Casas próximas a Disney',
      NEAR_UNIVERSAL: 'Casas próximas a Universal',
      FIRST_TIME_ORLANDO: 'Recomendación de GoWeasy homes para tu primer viaje a Orlando!',
    },
  },
  SECTIONS: {
    TOWNHOMES: 'Townhomes',
    SINGLE_FAMILY: 'Single family',
    APARTMENT: 'Apartamento',
    CONDOMINIUM: 'Condo',
    VILLA: 'Villa',
    LUXURY_HOUSE: 'Casa de Lujo',
    MANOR: 'Mansión',
    PET_FRIENDLY: 'Acepta Mascotas',
    FOR_SENIORS: 'Para Personas Mayores',
    INTEGRATED_OFFICE: 'Oficina Integrada',
    LARGE_GROUPS: 'Para Grupos Grandes',
    GOLF_COURSE: 'Campo de Golf',
    CINEMA: 'Cine',
    NEAR_DISNEY: 'Cerca de Disney',
    NEAR_UNIVERSAL: 'Próximo à Universal',
    NEAR_INTERNATIONAL: 'Al lado de International Drive y del Centro de Convenciones',
    THEMATIC: 'Temático',
    AMAZING_POOLS: 'Piscinas con hidromasaje',
    GO_WEASY_CHOICE: 'Elección Fácil de GoWeasy',
    IDEAL_FOR_SENIORS: 'Ideal para Personas Mayores',
    FIRST_TIME_ORLANDO: 'Primera vez en Orlando',
    TOWNHOMES_POOL: 'Casas adosadas con piscina',
    TOWNHOMES_NO_POOL: 'Casas adosadas sin piscina',
    TOWNHOMES_3_10: 'Casas adosadas de 3 habitaciones hasta 10 personas',
    TOWNHOMES_4_12: 'Casas adosadas de 4 habitaciones hasta 12 personas',
    TOWNHOMES_5_14: 'Casas adosadas de 5 habitaciones hasta 14 personas',
    LARGE_GROUPS_12_18: 'Grupos grandes de 12 a 18 personas',
    LARGE_GROUPS_19_23: 'Grupos grandes de 19 a 23 personas',
    LARGE_GROUPS_24: 'Grupos grandes de 24+ personas',
  },
};
