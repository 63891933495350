import { useContext } from 'react';
import { fetchLogin } from 'services/Login/fetchLogin.service';
import { setPath } from 'services/Login/redirect.service';
import { clearToken, clearUser } from 'services/Login/user.service';
import UserContext from 'store/context/user.context';

const useLogin = () => {
  const [_, setUser] = useContext(UserContext);

  const login = async (email: string, password: string) => {
    const user = await fetchLogin(email, password);
    setUser(user);
  };

  const savePathForNextLogin = (pathToSave: string) => {
    setPath(pathToSave);
  };

  const logout = (pathToSave?: string) => {
    clearToken();
    clearUser();
    if (pathToSave) {
      savePathForNextLogin(pathToSave);
    }
  };

  return {
    fetchLogin: login,
    logout,
  };
};

export default useLogin;
