export default {
  PUBLIC: {
    LOGIN: {
      PASSWORD: 'Contraseña',
      BUTTON: 'Iniciar sesión',
      WELCOME: '¡Bienvenido!',
      FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
      REMEMBER_ME: 'Recuérdame',
      PLACEHOLDER: {
        EMAIL: 'Ingresa tu correo electrónico',
        PASSWORD: 'Ingresa tu contraseña',
      },
      ERROR_MESSAGE: 'Correo electrónico o contraseña inválidos',
      GO_TO_REGISTER: '¿No tienes una cuenta? ¡Haz clic aquí!',
      UNAUTHORIZED: 'Sesión expirada, serás redirigido al inicio de sesión',
    },
    RECOVER_PASSWORD: {
      TITLE: 'Recupera tu contraseña',
      SUBTITLE: 'Ingresa tu nueva contraseña a continuación',
      PASSWORD: 'Contraseña',
      SEND_PASSWORD: 'Recuperar contraseña',
      SUCCESS: '¡Contraseña cambiada con éxito!',
    },
    FORGOT_PASSWORD: {
      QUESTION: '¿Olvidaste tu contraseña?',
      ANSWER: 'Ingresa tu correo electrónico y te enviaremos instrucciones sobre cómo restablecer tu contraseña',
      SEND_PASSWORD: 'Enviar contraseña',
      SUCCESS_TITLE: 'Correo electrónico enviado',
      SUCCESS_TEXT:
        'Hemos enviado un correo electrónico a {{email}}. Si este correo electrónico está asociado con una cuenta de GoWeasy, puedes restablecer tu contraseña.',
      BACK_TO_LOGIN: '< Volver al inicio de sesión',
      PLACEHOLDER: {
        EMAIL: 'Ingresa tu correo electrónico',
      },
    },
    REGISTER: {
      STEPS: {
        AGENCY: 'Información de la Agencia',
        AGENT: 'Información del Agente',
        SENT: 'Envío del Registro',
        MANAGER_DATA: 'Datos del Gestor AG',
        AGENCY_DATA: 'Datos de la Agencia',
        OPERATOR_DATA: 'Datos de la Operadora',
      },
      TITLE: '¡Únete a nuestra red y descubre Orlando!',
      SUBTITLE: 'Regístrate ahora',
      NAME: 'Nombre y Apellido',
      CELLPHONE: 'Teléfono de contacto',
      NATIONAL_REGISTRATION: 'Documento de Identidad',
      PASSWORD: 'Contraseña',
      SUBMIT: 'Enviar registro',
      SUCCESS_MESSAGE: '¡Tu cuenta ha sido creada exitosamente!',
      AGENCY: 'Agencia',
      PLACEHOLDER: {
        AGENCY: 'Selecciona la agencia',
        NAME: 'Ingresa tu nombre y apellido',
        EMAIL: 'Ingresa tu correo electrónico',
        CELLPHONE: 'Ingresa tu número de teléfono celular',
        NATIONAL_REGISTRATION: 'Ingresa tu documento de identidad',
        PASSWORD: 'Ingresa tu contraseña',
      },
      FEEDBACK: {
        SUCCESS: {
          TITLE: '¡Gracias por registrarte en GoWeasy!',
          BUTTON: 'Entendido',
        },
      },
    },
  },
};
