import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

type BasicTableProps = {
  collumnsNames?: string[];
  children?: ReactNode;
  minWidth?: number;
  marginTop?: string;
};

const BasicTable: React.FC<BasicTableProps> = ({ collumnsNames, children, minWidth, marginTop }) => {
  return (
    <TableContainer style={{ marginTop: marginTop || '24px' }} className={styles.tableContainerSize}>
      <Table sx={{ minWidth: minWidth || 650 }} aria-label="simple table" stickyHeader>
        <TableHead style={{ backgroundColor: '#F5F5F5' }}>
          <TableRow>
            {collumnsNames?.map((name) => {
              return <TableCell className={styles.title}>{name}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
          <Divider variant="middle" component={'table'} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
