import i18next from 'i18next';
import { ICoordenates } from './interfaces';
import { ICondominium } from './interfaces/ICondominium.interface';
import { Languages } from 'languages/helpers.languages';

class Condominium implements ICondominium {
  name: string;
  image: string;
  subtitle: string;
  subtitlePort: string;
  subtitleSpanish: string;
  coordenate: ICoordenates;
  description: string;
  descriptionPort: string;
  descriptionSpanish: string;

  constructor(condominium: ICondominium) {
    this.name = condominium.name;
    this.image = condominium.image;
    this.coordenate = condominium.coordenate;
    this.subtitle = condominium.subtitle;
    this.subtitleSpanish = condominium.subtitleSpanish;
    this.subtitlePort = condominium.subtitlePort;
    this.description = condominium.description;
    this.descriptionPort = condominium.descriptionPort;
    this.descriptionSpanish = condominium.descriptionSpanish;
  }

  getSubtitle(): string {
    const currentLanguage = i18next.language;

    switch (currentLanguage) {
      case Languages.PT_BR:
        return this.subtitlePort;
      case Languages.ES:
        return this.subtitleSpanish;
      default:
        return this.subtitle;
    }
  }

  getDescription(): string {
    const currentLanguage = i18next.language;

    switch (currentLanguage) {
      case Languages.PT_BR:
        return this.descriptionPort;
      case Languages.ES:
        return this.descriptionSpanish;
      default:
        return this.description;
    }
  }
}

export default Condominium;
