import User from 'models/application/User.model';
import { createContext, useState } from 'react';
import { getUser } from 'services/Login/user.service';

const UserContext = createContext<any>(null);

export const UserProvider: React.FC<any> = ({ children }) => {
  const [user, setUser] = useState<User>(getUser());

  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};

export default UserContext;
