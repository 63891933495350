import { AwesomeButton } from 'react-awesome-button';
import styled from 'styled-components';

import 'react-awesome-button/dist/styles.css';

const Container = styled.div`
  height: calc(60vh - 80px);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px #00000034;
  opacity: 1;
  border-radius: 5px;
  padding: 40px 53px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;

const Span = styled.div`
  font-size: 14px;
  color: #737373;
  font-weight: 400;
`;

const Value = styled.div`
  font-size: 24px;
  letter-spacing: 0px;
  font-weight: 700;
  color: #000000;
`;

const CheckoutButton = styled(AwesomeButton)`
  --button-raise-level: 0px;
  --button-primary-color: #0089ff;
  --button-hover-pressure: 0;
  --transform-speed: 0s;
  --button-default-height: 40px;
  border-radius: 8px;
  font-weight: 400;
`;

const DivForm = styled.div`
  padding: 24px 0px;
  border-top: 1px #e0e0e0 solid;
  border-bottom: 1px #e0e0e0 solid;
`;

const Warning = styled.div`
  color: #007632;
  font-size: 12px;
  margin-left: 4px;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`;

const GuestsContainer = styled.div`
  margin-top: 24px;
`;

export { CheckoutButton, Container, DivForm, Span, Value, Warning, WarningContainer, GuestsContainer };
