import styles from './styles.module.scss';
import Agency from 'models/application/Agency/Agency.model';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAgencyEdit } from './validate';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';

type AgencyEditProps = {
  agency: Agency;
  onSubmit: (data: Agency) => void;
  loading?: boolean;
};

const AgencyEditForm: React.FC<AgencyEditProps> = ({ agency, onSubmit, loading }) => {
  const { t } = useTranslation();

  const form = useForm<Agency>({
    resolver: yupResolver(validateAgencyEdit),
    defaultValues: agency,
  });

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.inputs}>
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.ID')} name="id" disabled />
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.NAME')} name="name" />
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.CITY')} name="city" />
        </div>
        <div className={styles.inputs}>
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.EMAIL')} name="email" />
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.CADASTUR')} name="cadastur" />
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.COUNTRY')} name="country" />
        </div>
        <div className={styles.inputs}>
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.PRIMARY_CONTACT')} name="primaryContact" />
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.URL_SITE')} name="urlSite" />
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.ADDRESS')} name="address" />
        </div>
        <div className={styles.inputs}>
          <Inputs.Text form={form} required label={t('BACKOFFICE.AGENCY.DOCUMENT')} name="document" />
          <Inputs.Text
            form={form}
            required
            label={t('BACKOFFICE.AGENCY.PROFIT_PERCENTAGE')}
            name="profitPercentage"
            disabled
          />
        </div>
      </div>
      <div className={styles.hr}></div>
      <div className={styles.buttons}>
        {/* <Button className={styles.buttonConfirm}>{t('BACKOFFICE.INACTIVE_ACCOUNT')} </Button> */}
        <Buttons.Orange
          loading={loading}
          className={styles.buttonConfirm}
          variant="contained"
          onClick={form.handleSubmit(onSubmit)}
        >
          {t('BACKOFFICE.SAVE_CHANGES')}
        </Buttons.Orange>
      </div>
    </div>
  );
};

export default AgencyEditForm;
