import {
  FaBath,
  FaCouch,
  FaBell,
  FaCamera,
  FaShieldAlt,
  FaSwimmingPool,
  FaHotTub,
  FaSwimmer,
  FaSolarPanel,
  FaFire,
  FaArrowsAltV,
  FaDoorOpen,
  FaMotorcycle,
  FaHome,
  FaBuilding,
  FaWheelchair,
  FaPhone,
  FaChair,
  FaDesktop,
  FaSnowflake,
  FaGasPump,
  FaHockeyPuck,
  FaTableTennis,
  FaGolfBall,
  FaTv,
  FaUmbrellaBeach,
  FaWind,
  FaNetworkWired,
} from 'react-icons/fa';

import {
  MdLocalLaundryService,
  MdOutlineBathtub,
  MdOutlineCrib,
  MdOutlineLocalLaundryService,
  MdOutlineMicrowave,
  MdOutlinePool,
  MdOutlinePrivacyTip,
  MdPeopleOutline,
  MdPets,
  MdSlowMotionVideo,
} from 'react-icons/md';
import { GiTennisBall, GiBoatFishing, GiBarbecue } from 'react-icons/gi';
import { BiDisc, BiSolidCarGarage, BiSolidWasher, BiSpa } from 'react-icons/bi';
import { BsDisc } from 'react-icons/bs';
import { LuBed, LuBedDouble, LuDumbbell, LuHotel, LuParkingCircle, LuWind } from 'react-icons/lu';
import { TbAirConditioning, TbToolsKitchen2 } from 'react-icons/tb';
import { CgGames, CgSmartHomeWashMachine } from 'react-icons/cg';
import { AiOutlineFire, AiOutlineWifi } from 'react-icons/ai';
import { LiaBabyCarriageSolid, LiaHotTubSolid, LiaToiletSolid } from 'react-icons/lia';
import { IoWaterOutline } from 'react-icons/io5';
import { RiHotelBedLine, RiHotelLine } from 'react-icons/ri';

const ICON_SIZE = 20;

export const houseSpacesIcons: any = {
  ROOMS: <LuBedDouble size={ICON_SIZE} />,
  BEDS: <LuBed size={ICON_SIZE} />,
  BATHROOM: <MdOutlineBathtub size={ICON_SIZE} />,
  LAVATORY: <IoWaterOutline size={ICON_SIZE} />,
  GARAGE: <BiSolidCarGarage size={ICON_SIZE} />,
  SOFABED: <FaCouch size={ICON_SIZE} />,
  MAXOCCUPANCY: <MdPeopleOutline size={ICON_SIZE} />,
  WIFI: <AiOutlineWifi size={ICON_SIZE} />,
  ALARM: <FaBell size={ICON_SIZE} />,
  CAMERA: <FaCamera size={ICON_SIZE} />,
  SAFE: <FaShieldAlt size={ICON_SIZE} />,
  SAFETY_FENCE_POOL: <FaSwimmingPool size={ICON_SIZE} />,
  POOL_ACCESS_ALARM: <FaBell size={ICON_SIZE} />,
  POOL: <MdOutlinePool size={ICON_SIZE} />,
  PRIVATE_POOL: <FaSwimmingPool size={ICON_SIZE} />,
  SPA: <BiSpa size={ICON_SIZE} />,
  OUT_TUB: <FaHotTub size={ICON_SIZE} />,
  IND_TUB: <FaBath size={ICON_SIZE} />,
  SOLAR_HEAT: <FaSolarPanel size={ICON_SIZE} />,
  POOL_HEATABLE: <AiOutlineFire size={ICON_SIZE} />,
  FACING_POOL: <FaArrowsAltV size={ICON_SIZE} />,
  POOL_ACCESS: <FaDoorOpen size={ICON_SIZE} />,
  IND_JACUZZI: <FaHotTub size={ICON_SIZE} />,
  BBQ: <GiBarbecue size={ICON_SIZE} />,
  GRELL: <FaSwimmer size={ICON_SIZE} />,
  HAIR_DRYER: <LuWind size={ICON_SIZE} />,
  DISHWASHER: <CgSmartHomeWashMachine size={ICON_SIZE} />,
  MINI_KITCHEN: <TbToolsKitchen2 size={ICON_SIZE} />,
  FULL_KITCHEN: <TbToolsKitchen2 size={ICON_SIZE} />,
  RESORT_AMEN: <LuHotel size={ICON_SIZE} />,
  ELEVATOR: <FaBuilding size={ICON_SIZE} />,
  WHEL_CHAIR_ACC: <FaWheelchair size={ICON_SIZE} />,
  FREE_CALLS: <FaPhone size={ICON_SIZE} />,
  PAV_PARK: <LuParkingCircle size={ICON_SIZE} />,
  ROCK_CHAIRS: <FaChair size={ICON_SIZE} />,
  MICROWAVE: <MdOutlineMicrowave size={ICON_SIZE} />,
  LAUNDRY_IN: <MdOutlineLocalLaundryService size={ICON_SIZE} />,
  LAUNDRY_ON: <MdLocalLaundryService size={ICON_SIZE} />,
  UNIT_AMEN: <FaHome size={ICON_SIZE} />,
  DOCK: <FaBuilding size={ICON_SIZE} />,
  MOTOR_CYDE: <FaMotorcycle size={ICON_SIZE} />,
  WIRED_INTERNET: <FaNetworkWired size={ICON_SIZE} />,
  INTERNET_PC: <FaDesktop size={ICON_SIZE} />,
  STROLLER: <LiaBabyCarriageSolid size={ICON_SIZE} />,
  CRIB: <MdOutlineCrib size={ICON_SIZE} />,
  PACKNPLAY: <FaCouch size={ICON_SIZE} />,
  HIGH_CHAIR: <FaChair size={ICON_SIZE} />,
  ELETRIC_FIREPLACE: <FaFire size={ICON_SIZE} />,
  WOOD_BURNING: <FaFire size={ICON_SIZE} />,
  UNDER_FLOOR_HEAT: <FaSnowflake size={ICON_SIZE} />,
  GAS_FIREPLACE: <FaGasPump size={ICON_SIZE} />,
  AIRCONDITIONING: <TbAirConditioning size={ICON_SIZE} />,
  GAMES_ROOM: <CgGames size={ICON_SIZE} />,
  COMMUNAL_GYM: <LuDumbbell size={ICON_SIZE} />,
  AIR_HOCKEY: <FaHockeyPuck size={ICON_SIZE} />,
  FOOSBALL: <FaTableTennis size={ICON_SIZE} />,
  POOL_TABLE: <FaTableTennis size={ICON_SIZE} />,
  VIDEO_GAMES: <CgGames size={ICON_SIZE} />,
  TABLE_TENNIS: <FaTableTennis size={ICON_SIZE} />,
  GOLF_INCL: <FaGolfBall size={ICON_SIZE} />,
  BIG_SCR_TV: <FaTv size={ICON_SIZE} />,
  TV_BEDROOMS: <FaTv size={ICON_SIZE} />,
  CDPLAYER: <BsDisc size={ICON_SIZE} />,
  VCR: <MdSlowMotionVideo size={ICON_SIZE} />,
  DVD_BLUERAY: <BiDisc size={ICON_SIZE} />,
  FISHING: <GiBoatFishing size={ICON_SIZE} />,
  CLUB_HOUSE: <RiHotelLine size={ICON_SIZE} />,
  TENNIS_COURT: <GiTennisBall size={ICON_SIZE} />,
  WASHER: <BiSolidWasher size={ICON_SIZE} />,
  WASHER_DRYER: <CgSmartHomeWashMachine size={ICON_SIZE} />,
  PETS: <MdPets size={ICON_SIZE} />,
  GAS_FREE: <FaGasPump size={ICON_SIZE} />,
  PRIVACY_FENCE: <MdOutlinePrivacyTip size={ICON_SIZE} />,
  BEACH_ON: <FaUmbrellaBeach size={ICON_SIZE} />,
  DRYER: <FaWind size={ICON_SIZE} />,
  HEATING: <AiOutlineFire size={ICON_SIZE} />,
  PRIVATE_ENTRANCE: <FaDoorOpen size={ICON_SIZE} />,
  BEDDING_ESSENTIALS: <RiHotelBedLine size={ICON_SIZE} />,
  TOILETRIES: <LiaToiletSolid size={ICON_SIZE} />,
  HIDRO_MASSAGE: <LiaHotTubSolid size={ICON_SIZE} />,
};
