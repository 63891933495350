import Operator from 'models/application/Operator/Operator.model';
import { useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import Inputs from 'components/molecules/inputs';
import { Buttons } from 'components/molecules/buttons';
import { MdOutlineChevronRight } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateOperator } from 'pages/Register/forms/EN/OperatorForm/validateOperator';
import getAddressByCep from 'services/CEP/getAddressByCep';
import { useRef, useState } from 'react';

const OperatorRegisterForm: React.FC<any> = ({ handleNext, operatorValue }) => {
  const form = useForm<Operator>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: operatorValue,
    resolver: yupResolver(validateOperator),
  });

  const beforeSubmit = (data: Operator) => {
    handleNext(new Operator(data));
  };

  const inputRef = useRef<any>(null);

  const [isDirty, setIsDirty] = useState<boolean>();

  const getCep = async (c: string) => {
    const response = await getAddressByCep(c);
    if (response.logradouro) {
      form.setValue('address', response.logradouro + ', ' + response.bairro, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('city', response.localidade, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('country', 'Brasil', {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setIsDirty(true);
    } else {
      form.setValue('address', '');
      form.setValue('city', '');
      form.setValue('country', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <Inputs.Text form={form} required label="Nome da Operadora:" name="name" />
          <Inputs.Text
            form={form}
            required
            label="Endereço:"
            name="address"
            inputRef={inputRef}
            inputLabelProps={{
              shrink: isDirty, // Disable label animation
            }}
          />
        </div>
        <div className={styles.inputContainer}>
          <Inputs.Mask
            whiteVersion={false}
            id="cnpj"
            form={form}
            required
            mask="99.999.999/9999-99"
            label="CNPJ:"
            name="cnpj"
          />
          <Inputs.Text
            form={form}
            required
            label="País:"
            name="country"
            inputLabelProps={{
              shrink: isDirty, // Disable label animation
            }}
          />
        </div>
        <div className={styles.inputContainer}>
          <Inputs.Mask
            inputLabelProps={{
              shrink: true, // Disable label animation
            }}
            whiteVersion={false}
            form={form}
            mask="99999-999"
            label="CEP"
            name="cep"
            id="cep"
            onBlur={handleBlur}
          />
          <Inputs.Text
            form={form}
            required
            label="Cidade:"
            name="city"
            inputLabelProps={{
              shrink: isDirty, // Disable label animation
            }}
          />
        </div>
      </div>
      <div className={styles.hr}></div>
      <div className={styles.buttons}>
        <Buttons.Secondary
          className={styles.buttonConfirm}
          endIcon={<MdOutlineChevronRight size={24} />}
          variant="contained"
          onClick={form.handleSubmit(beforeSubmit)}
        >
          Prosseguir
        </Buttons.Secondary>
      </div>
    </div>
  );
};

export default OperatorRegisterForm;
