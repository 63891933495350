import i18n from 'i18next';
import * as Yup from 'yup';

import { parseToCurrency } from './format';

Yup.setLocale({
  object: {
    noUnknown: (value) => i18n.t('YUP.OBJECT.FIELD_HAVE_UNKNOWN_PROPERTIES') + value,
  },
  mixed: {
    default: i18n.t('YUP.MIXED.ISNT_VALID'),
    required: i18n.t('YUP.MIXED.REQUIRED_FIELD'),
    notType: i18n.t('YUP.MIXED.INVALID_TYPE'),
    oneOf: i18n.t('YUP.MIXED.HAS_TO_BE_ONE_OF_THE_VALUES'),
    notOneOf: i18n.t('YUP.MIXED.CANT_BE_ONE_OF_THE_VALUES'),
    defined: i18n.t('YUP.MIXED.VALUE_HAS_TO_BE_DEFINED'),
  },
  number: {
    positive: i18n.t('YUP.NUMBER.HAS_TO_BE_A_POSITIVE_NUMBER'),
    negative: i18n.t('YUP.NUMBER.HAS_TO_BE_A_NEGATIVE_NUMBER'),
    integer: i18n.t('YUP.NUMBER.HAS_TO_BE_A_INTEGER_NUMBER'),
    min: (value: any) => i18n.t('YUP.NUMBER.MUST_BE_LESS_THAN') + value,
    max: (value: any) => i18n.t('YUP.NUMBER.MUST_BE_BIGGER_THAN') + value,
    lessThan: (value: any) => i18n.t('YUP.NUMBER.HAS_TO_BE_LESS_THAN') + value,
    moreThan: (value: any) => i18n.t('YUP.NUMBER.HAS_TO_BE_MORE_THAN') + value,
  },
  string: {
    email: i18n.t('YUP.STRING.MUST_BE_A_VALID_EMAIL'),
    url: i18n.t('YUP.STRING.MUST_BE_A_VALID_URL'),
    uuid: i18n.t('YUP.STRING.MUST_BE_A_UUID'),
    trim: i18n.t('YUP.STRING.MUST_BE_A_TRIM'),
    lowercase: i18n.t('YUP.STRING.MUST_BE_A_LOWERCASE_TEXT'),
    uppercase: i18n.t('YUP.STRING.MUST_BE_A_UPPERCASE_TEXT'),
    length: (value: any) =>
      value.length > 1
        ? i18n.t('YUP.STRING.MUST_HAVE_EXACLY_CARACTER_PLURAL', `${value.length}`)
        : i18n.t('YUP.STRING.MUST_HAVE_EXACLY_CARACTER', `${value.length}`),
    min: (value: any) =>
      Number(value.min) > 1
        ? i18n.t('YUP.STRING.MUST_HAVE_AT_LEAST_CARACTER_PLURAL', `${value.min}`)
        : i18n.t('YUP.STRING.MUST_HAVE_AT_LEAST_CARACTER', `${value.min}`),
    max: (value: any) =>
      Number(value.max) > 1
        ? i18n.t('YUP.STRING.MUST_HAVE_AT_MAXIMUM_CARACTER_PLURAL', `${value.max}`)
        : i18n.t('YUP.STRING.MUST_HAVE_AT_MAXIMUM_CARACTER', `${value.max}`),
    matches: (value: any) => i18n.t('YUP.STRING.MUST_MATCHES_TO_THIS_VALUE', `${value}`),
  },
  date: {
    min: (value: any) => i18n.t('YUP.DATE.DATE_MUST_BE_LESS_THAN', `${value}`),
    max: (value: any) => i18n.t('YUP.DATE.DATE_MUST_BE_MORE_THAN', `${value}`),
  },
  boolean: {
    isValue: (value: any) => i18n.t('YUP.BOOLEAN.VALUE_HAS_TO_BE', `${value}`),
  },
  array: {
    min: (value: any) =>
      value.min > 1
        ? i18n.t('YUP.ARRAY.MUST_CONTAIN_AT_LEAST_PLURAL', `${value.min}`)
        : i18n.t('YUP.ARRAY.MUST_CONTAIN_AT_LEAST', `${value.min}`),
    max: (value: any) =>
      value.max > 1
        ? i18n.t('YUP.ARRAY.MUST_CONTAIN_AT_MAX_PLURAL', `${value.max}`)
        : i18n.t('YUP.ARRAY.MUST_CONTAIN_AT_MAX', `${value.max}`),
    length: (value: any) =>
      value.length > 1
        ? i18n.t('YUP.ARRAY.MUST_CONTAIN_PLURAL', `${value.length}`)
        : i18n.t('YUP.ARRAY.MUST_CONTAIN', `${value.length}`),
  },
});

const functionCantBeSequentialNumbers = (num: string): boolean => {
  const result = true;
  for (let i = 0; i < num.length; i++) {
    const helper = num[i];
    if (Number(helper) === Number(num[i - 1]) + 1) {
      return false;
    }
  }
  return result;
};

const functionCantBeRepeatedNumbers = (num: number): boolean => {
  const digits = [];
  let digit;
  let numHelper = num;

  while (numHelper) {
    digit = numHelper % 10;
    if (numHelper % 10 === 0) {
      return false;
    }
    if (digits[digit]) {
      return false;
    }
    digits[digit] = true;
    numHelper = Math.floor(numHelper / 10);
  }

  return true;
};

const functionCantBeTheSameAs = (sameValue: string, value: string): boolean => {
  for (let i = 0; i < sameValue.length; i++) {
    if (sameValue[i] === value) {
      return false;
    }
  }
  return true;
};

const functionCantBeZero = (value: number): boolean => {
  if (value === 0) {
    return false;
  }
  return true;
};

Yup.addMethod(Yup.string, 'cantBeRepeatedNumbers', function cantBeRepeatedNumbers(errorMessage: string) {
  return this.test('test-cant-be-repeated-numbers', errorMessage, function (value: any) {
    const { path, createError } = this;

    const validation = (_value?: string) => functionCantBeRepeatedNumbers(Number(_value));

    return validation(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(Yup.string, 'cantBeSequentialNumbers', function cantBeSequentialNumbers(errorMessage: string) {
  return this.test('test-cant-be-sequential-numbers', errorMessage, function (value: any) {
    const { path, createError } = this;

    const validation = (_value?: string) => functionCantBeSequentialNumbers(String(_value));

    return validation(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(Yup.string, 'cantBeTheSameAs', function cantBeTheSameAs(sameValue: string, errorMessage: string) {
  return this.test('test-cant-be-the-same-as', errorMessage, function (value: any) {
    const { path, createError } = this;

    const validation = (_sameValue: string, _value: string) => functionCantBeTheSameAs(_sameValue, _value);

    return (
      validation(sameValue, String(value)) ||
      createError({
        path,
        message: errorMessage || i18n.t('YUP.CUSTOM.CANT_BE_THE_SAME_AS_THIS_VALUE', `${sameValue}`),
      })
    );
  });
});

Yup.addMethod(Yup.number, 'minMoney', function minMoney(min: number, errorMessage: string) {
  return this.test('test-min-money', errorMessage, function (value: any) {
    const { path, createError } = this;

    const validation = (_min: number, _value: number) => _value >= _min;

    return (
      validation(min, Number(value)) ||
      createError({
        path,
        message: errorMessage || i18n.t('YUP.CUSTOM.MUST_BE_LESS_OR_EQUAL_THAN') + parseToCurrency(min),
      })
    );
  });
});

Yup.addMethod(Yup.number, 'maxMoney', function maxMoney(max: number, errorMessage: string) {
  return this.test('test-max-money', errorMessage, function (value: any) {
    const { path, createError } = this;

    const validation = (_max: number, _value: number) => _value <= _max;

    return (
      validation(max, Number(value)) ||
      createError({
        path,
        message: errorMessage || i18n.t('YUP.CUSTOM.MUST_BE_MORE_OR_EQUAL_THAN') + parseToCurrency(max),
      })
    );
  });
});

Yup.addMethod(Yup.number, 'notZeroMoney', function notZeroMoney(errorMessage: string) {
  return this.test('test-cant-be-zero', errorMessage, function (value: any) {
    const { path, createError } = this;

    const validation = (_value: number) => functionCantBeZero(_value);

    return (
      validation(Number(value)) ||
      createError({
        path,
        message: errorMessage || i18n.t('YUP.CUSTOM.CANT_BE_ZERO'),
      })
    );
  });
});

export default Yup;
