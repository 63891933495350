export default {
  FAQ: {
    TITLE: `We're here to help you. Your Quick Answers Guide`,
    QUESTION_1: 'Is it easy to talk with GoWeasy when I need it most?',
    ANSWER_1:
      'Very easy. GoWeasy is an American company from Orlando and the founders are experts in tourism, so we understand your needs and we will work together to give you all the necessary support.',
    QUESTION_2: 'Does GoWeasy will be available for my client during stay?',
    ANSWER_2: `Yes, we have 24-hour service in Orlando for you and your clients during the stay.`,
    QUESTION_3: `What are GoWeasy's Customer Service hours for tourism professionals?`,
    ANSWER_3:
      'Monday to Friday from 9am to 5pm and Saturdays from 9am to 1pm. <br/><br/> For any emergency, we have 24-hour service.',
    QUESTION_4: 'How do I contact GoWeasy?',
    ANSWER_4:
      'During business hours, via email hello@goweasy.com <br/><br/> And our customer service number is (888) 885-6889',
    QUESTION_5: 'How does GoWeasy ensure the quality of homes?',
    ANSWER_5:
      'All houses that come to the Platform undergo quality control.<br/><br/> GoWeasy, in addition to being a vacation rental platform, is also a production company of content and for this we need to be constantly inspecting the houses next to our partners, so that they meet our standard property listing standard.',
  },
};
