import { HouseResume } from 'models/application/HouseResume.model';
import { ISectionHouseResume } from 'models/application/interfaces/ISectionHouseResume.interface';
import { HouseSearchResponse } from 'models/contracts/response/HouseSearch.response';
import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import { getHouseListFilters } from 'utils/helpers';
import { sortGroupType } from 'utils/sorts/group-type.sort';
import { sortByFilterOrder } from 'utils/sorts/filter-order.sort';

const getAvailableHousesByDate = async (
  checkIn: string,
  checkOut: string,
  filters: string[],
  cancelToken: any,
  guests?: number,
  pets?: number,
  seniors?: number,
): Promise<ISectionHouseResume[]> => {
  const { data }: IApi<HouseSearchResponse[]> = await axiosInstances.base.instance.post(
    'service/v1/house/availability',
    {
      ...getHouseListFilters(filters),
      ...getDefaultFilters(filters),
      checkin: checkIn,
      checkout: checkOut,
      guests: guests ?? 0,
      pet: pets ?? 0,
      senior: seniors ?? 0,
    },
    {
      cancelToken: cancelToken,
    },
  );

  let sections = Object.keys(data);

  if (sections?.length >= 0) {
    sections = sortByFilterOrder(sections, filters);
    sections = sortGroupType(sections, 'LARGE_GROUPS');
    sections = sortGroupType(sections, 'TOWNHOMES');
  }

  const result = sections.map((section) => {
    const houses: any = data[section as any];

    return {
      title: section,
      data: houses.map(
        (house: HouseSearchResponse) =>
          new HouseResume({
            id: house.houseId,
            name: house.houseName,
            mainPicture: house.mainPicture,
            price: {
              real: house.startPriceReal,
              dollar: house.startPriceDollar,
            },
            maxOccupancy: house.maxOccupancy,
            bedroomAmount: house.roomAmount,
            bathroomAmount: house.bathroomAmount,
            amenities: house.amenities.map((amenitie) => ({
              name: amenitie,
            })),
            coordinates: {
              lat: house.latitude,
              long: house.longitude,
            },
          }),
      ) as HouseResume[],
    };
  });

  return result;
};

export default getAvailableHousesByDate;

const getDefaultFilters = (filters: string[]) => {
  if (filters.length === 0) {
    return {
      nearDisney: true,
      thematic: true,
      amazingPools: true,
      townhomes: true,
      singleFamily: true,
      // apartment: true,
      codominium: true,
      villa: true,
      luxuryHouse: true,
      manor: true,
      petFriendly: true,
      forSeniors: true,
      integratedOffice: true,
      largeGroups: true,
      golfCourse: true,
      cinema: true,
      goWeasyChoise: true,
      idealForSeniors: true,
      firstTimeOrlando: true,
    };
  }

  return {};
};
