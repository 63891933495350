import { Buttons } from 'components/molecules/buttons';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Bell, CreditCard } from 'assets';
import TranslationButton from 'components/TranslationButton';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { useEffect, useState } from 'react';
import useAsync from 'hooks/useAsync.hook';
import { verifyPayment } from 'services/Pagtour/verifyPayment.service';
import LoadingSpinner from 'components/atoms/loading';

const ConfirmCheckout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [searchParams] = useSearchParams();

  const navigateToDetails = () => {
    if (paymentSuccess) {
      const reservationFromStorage = localStorage.getItem('reservation') ?? '{}';
      const newReservation = JSON.parse(reservationFromStorage);
      return navigate(`/infos/reservation/details/${newReservation?.reservationId}`);
    }
    return navigate('/');
  };

  const { loading, call: callConfirmPayment } = useAsync(async () => {
    const transactionId = searchParams.get('transactionid') ?? '';
    const orderId = searchParams.get('order-id') ?? '';
    const linkId = searchParams.get('link-id') ?? '';

    try {
      const data = await verifyPayment(transactionId, orderId, linkId);

      if (data?.status === 'SUCESSO' || data?.codigo === '20') {
        setPaymentSuccess(true);
      } else {
        setPaymentSuccess(false);
      }
    } catch (err) {
      setPaymentSuccess(false);
    }
  }, []);

  const checkPaymentStatus = async () => {
    await callConfirmPayment();
  };

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          <div className={styles.header}>
            <Logo onClick={() => navigate('/')} className={styles.logo} />
            <div className={styles.icon}>
              <TranslationButton />
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.card}>
              <div className={styles.cardContent}>
                <img src={paymentSuccess ? Bell : CreditCard} alt="" />
                <h4>{paymentSuccess ? t('SUCCESS_CHECKOUT.TITLE') : t('FAIL_CHECKOUT.TITLE')}</h4>
                {paymentSuccess ? (
                  <div className={styles.text}>{t('SUCCESS_CHECKOUT.MESSAGE')}</div>
                ) : (
                  <>
                    <div className={styles.text}>{t('FAIL_CHECKOUT.MESSAGE_1')}</div>
                    <div className={styles.text}>{t('FAIL_CHECKOUT.MESSAGE_2')}</div>
                  </>
                )}
                <Buttons.Default
                  className={styles.button}
                  backgroundColor="#0049A1"
                  variant="contained"
                  onClick={navigateToDetails}
                >
                  {paymentSuccess ? t('SUCCESS_CHECKOUT.BUTTON') : t('FAIL_CHECKOUT.BUTTON')}
                </Buttons.Default>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmCheckout;
