import HouseList from 'components/HouseList';
import { HouseResume } from 'models/application/HouseResume.model';
import { LiaMapSolid } from 'react-icons/lia';

import styles from './styles.module.scss';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Booking from 'models/application/Booking.model';
import { useTranslation } from 'react-i18next';
import SearchMap from 'components/Maps/SearchMap/Location.component';
import useFilters from 'hooks/useFilters';
import { setOrigim } from 'utils/helpers';

export const DISNEY_LOCATION = {
  lat: 28.41783,
  long: -81.57533,
};
type SectionListProps = {
  housesFound: HouseResume[];
  booking: Booking;
  title: string;
};

const SectionList: React.FC<SectionListProps> = ({ housesFound, title }) => {
  const [showLocation, setShowLocation] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getFiltersAsQueryString } = useFilters();
  const location = useLocation();

  const handleClickMarker = (id: number | string) => {
    setOrigim(`${location.pathname}${location.search}`);
    navigate({
      pathname: `/house-details/${id}`,
      search: `?${getFiltersAsQueryString()}`,
    });
  };

  const handleClick = () => {
    setShowLocation(!showLocation);
  };

  return (
    <div>
      <h2 className={styles.title}>{t(`SECTIONS.${title}`)}</h2>
      <div className={styles.showMap} onClick={handleClick}>
        <div>Ver no mapa</div>
        <LiaMapSolid />
      </div>
      {showLocation ? (
        <SearchMap
          center={{
            lat: housesFound[0].coordinates?.lat ?? 0,
            long: housesFound[0].coordinates?.long ?? 0,
          }}
          houses={housesFound}
          zoom={12}
          className={styles.location}
          onClickMarker={handleClickMarker}
        />
      ) : null}

      <HouseList houses={housesFound} />
    </div>
  );
};

export default SectionList;
