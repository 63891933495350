import { Buttons } from 'components/molecules/buttons';
import { MdOutlineChevronRight } from 'react-icons/md';

import styles from './styles.module.scss';

type ConfirmButtonProps = {
  form: any;
  onSubmit: any;
  children: any;
};

const ConfirmButton: React.FC<ConfirmButtonProps> = ({ form, onSubmit, children }) => {
  return (
    <Buttons.Secondary
      className={styles.buttonConfirm}
      endIcon={<MdOutlineChevronRight size={24} />}
      fullWidth
      variant="contained"
      onClick={form.handleSubmit(onSubmit)}
    >
      {children}
    </Buttons.Secondary>
  );
};

export default ConfirmButton;
