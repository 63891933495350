import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReadMoreButton, ReadMoreLabel, ReadMoreText } from './ReadMore.style';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import parse from 'html-react-parser';
import Markdown from 'react-markdown';

type TextToRender = 'HTML' | 'MARKDOWN' | 'NORMAL';

interface ReadMoreProps {
  text: string;
  limit: number;
  label: string;
  textType: TextToRender;
}

const ReadMore: React.FC<ReadMoreProps> = ({ text, limit, label, textType }) => {
  const [showAllText, setShowAllText] = useState(false);

  const { t } = useTranslation();

  const words = useMemo(() => {
    return text?.split(' ');
  }, [text]);

  const shouldShowMore = words?.length > limit;

  const handleClick = () => {
    setShowAllText((s) => !s);
  };

  const renderText = () => {
    const textToShow = showAllText ? words.join(' ') : words?.slice(0, limit).join(' ');
    const complement = !showAllText && shouldShowMore ? '...' : '';

    if (textType === 'HTML') {
      return `${parse(textToShow)}${complement}`;
    }

    if (textType === 'MARKDOWN') {
      return <Markdown>{`${textToShow}${complement}`}</Markdown>;
    }

    return `${textToShow}${complement}`;
  };

  return (
    <div>
      <ReadMoreLabel>{label}</ReadMoreLabel>
      <ReadMoreText>
        {renderText()}
        {!showAllText}
      </ReadMoreText>
      {shouldShowMore ? (
        <ReadMoreButton onClick={handleClick}>
          <div>{t(showAllText ? 'COMMON.SHOW_LESS' : 'COMMON.SHOW_MORE')}</div>
          {showAllText ? <BsFillCaretUpFill size={14} /> : <BsFillCaretDownFill size={14} />}
        </ReadMoreButton>
      ) : null}
    </div>
  );
};

export default ReadMore;
