import { Buttons } from 'components/molecules/buttons';
import { useTranslation } from 'react-i18next';
import usePublicNavigation from 'routes/navigation/public.navigation';
import styles from './styles.module.scss';
import { ReactComponent as Logo } from '../../../../assets/svg/logo-gw.svg';
import React from 'react';

type SuccessFeedbackProps = {
  text: string;
};

const SuccessFeedback: React.FC<SuccessFeedbackProps> = ({ text }) => {
  const { navigateToLogin } = usePublicNavigation();
  const { t } = useTranslation();

  const handleClick = () => {
    navigateToLogin();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{t('PUBLIC.REGISTER.FEEDBACK.SUCCESS.TITLE')}</div>
        <Logo />
        <div className={styles.text}>{text}</div>

        <Buttons.Secondary fullWidth variant="contained" onClick={handleClick}>
          {t('PUBLIC.REGISTER.FEEDBACK.SUCCESS.BUTTON')}
        </Buttons.Secondary>
      </div>
    </div>
  );
};
export default SuccessFeedback;
