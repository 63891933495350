import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import COLORS from 'constants/colors';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FIRST_PAGE = 1;

type WEPDFProps = {
  pdfUrl: string;
};

const WEPDF: React.FC<WEPDFProps> = ({ pdfUrl }) => {
  const [numberPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(FIRST_PAGE);

  const isOnFirstPage = pageNumber === FIRST_PAGE;
  const isOnLastPage = pageNumber === numberPages;

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  const nextPage = () => {
    setPageNumber((p) => p + 1);
  };

  const previousPage = () => {
    setPageNumber((p) => p - 1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.pdfPolicy}>
        <div className={styles.info}>
          <p>
            {pageNumber}/{numberPages}
          </p>
          <div className={styles.buttons}>
            <LuChevronLeft
              onClick={previousPage}
              size={24}
              color={COLORS.neutral.white}
              className={isOnFirstPage ? styles.disableButton : undefined}
            />
            <LuChevronRight
              onClick={nextPage}
              size={24}
              color={COLORS.neutral.white}
              className={isOnLastPage ? styles.disableButton : undefined}
            />
          </div>
        </div>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page scale={1.7} key={`page_${pageNumber}`} pageNumber={pageNumber} />
        </Document>
      </div>
    </div>
  );
};

export default WEPDF;
