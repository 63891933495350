import Booking from 'models/application/Booking.model';
import { House } from 'models/application/House.model';
import { createContext, useState } from 'react';

const BookingContext = createContext<any>(null);

export const BookingProvider: React.FC<any> = ({ children }) => {
  const [booking, setBooking] = useState<Booking>();
  const [houseBooking, setHouseBooking] = useState<House>();

  return (
    <BookingContext.Provider
      value={{
        booking,
        houseBooking,
        setBooking,
        setHouseBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingContext;
