import styles from './style.module.scss';

type FeatureRowProps = {
  title: string;
  noBorder?: boolean;
  children?: any;
  noJustify?: boolean;
};

const FeatureRow: React.FC<FeatureRowProps> = ({ title, noBorder, children, noJustify }) => {
  return (
    <div className={styles.container}>
      <div className={noJustify ? styles.halfContainer : styles.space}>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
      {!noBorder && <div className={styles.hr}></div>}
      {noBorder && <div className={styles.noBorder}></div>}
    </div>
  );
};

export default FeatureRow;
