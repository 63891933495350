import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
// TODO: Criar os tipos
const getGoalSettings = async (): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(`/service/payment/metas`);

  return data;
};

export default getGoalSettings;
