import { useState } from 'react';

import { IconButton } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import i18next from 'i18next';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { TbWorld } from 'react-icons/tb';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import US from 'country-flag-icons/react/3x2/US';
import BR from 'country-flag-icons/react/3x2/BR';
import ES from 'country-flag-icons/react/3x2/ES';
import WECircleFlag from 'components/atoms/WeCircleFlag';

const TranslationButton = (props: any) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const { t } = useTranslation();

  const handleClick = (event: any) => {
    setAnchorElement(event.currentTarget);
  };

  const changeTranslate = (language: string) => {
    handleClose();
    i18next.changeLanguage(language);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <div {...props}>
      <IconButton className={styles.containerIcon} onClick={handleClick}>
        <TbWorld className={styles.cursorPointer} />
        <MdOutlineArrowDropDown className={styles.dropDownIcon} />
      </IconButton>
      <Menu id="basic-menu" open={open} anchorEl={anchorElement} onClose={handleClose}>
        <MenuItem onClick={() => changeTranslate('pt-BR')}>
          <WECircleFlag>
            <BR title="" className={styles.flag} />
          </WECircleFlag>
          <b>{t('HOME.HEADER.PORTUGUESE')}</b>
        </MenuItem>
        <MenuItem onClick={() => changeTranslate('en')}>
          <WECircleFlag>
            <US title="United States" className={styles.flag} />
          </WECircleFlag>
          <b>{t('HOME.HEADER.ENGLISH')}</b>
        </MenuItem>
        <MenuItem onClick={() => changeTranslate('es')}>
          <WECircleFlag>
            <ES title="" className={styles.flag} />
          </WECircleFlag>
          <b>{t('HOME.HEADER.SPANISH')}</b>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TranslationButton;
