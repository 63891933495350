import styles from './styles.module.scss';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Zoom } from '@mui/material';
import React, { ReactNode } from 'react';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 140,
    backgroundColor: 'white',
    color: 'black',
    fontSize: 10,
    marginTop: '16px !important;',
  },
});

type WeTooltipProps = {
  children: ReactNode;
  hoverText: string;
};

const WeTooltip: React.FC<WeTooltipProps> = ({ children, hoverText }) => {
  return (
    <CustomWidthTooltip
      TransitionComponent={Zoom}
      title={
        <div className={styles.container}>
          <div className={styles.circle}></div>
          <div>{hoverText}</div>
        </div>
      }
      className={styles.tooltip}
    >
      <div>{children}</div>
    </CustomWidthTooltip>
  );
};

export default WeTooltip;
