import Booking from 'models/application/Booking.model';
import { IFilter } from 'models/application/interfaces/IFilter.interface';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useFilters = (): any => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const getHouseId = useCallback((): number | undefined => {
    const houseId = urlParams.houseId;
    return Number(houseId);
  }, []);

  const getTotalGuests = useCallback((): number => {
    const params = getFilterParameters();

    const adults = (params?.adults ?? 0) as number;
    const kids = (params?.kids ?? 0) as number;
    const pcd = (params?.pcd ?? 0) as number;
    const elderly = (params?.elderly ?? 0) as number;

    return adults + kids + pcd + elderly;
  }, []);

  const getFilterParameters = useCallback((): IFilter => {
    const checkIn = new URLSearchParams(location.search).get('checkIn') ?? null;
    const checkOut = new URLSearchParams(location.search).get('checkOut') ?? null;
    const adults = new URLSearchParams(location.search).get('adults') ?? 0;
    const kids = new URLSearchParams(location.search).get('kids') ?? 0;
    const babies = new URLSearchParams(location.search).get('babies') ?? 0;
    const pets = new URLSearchParams(location.search).get('pets') ?? 0;
    const elderly = new URLSearchParams(location.search).get('elderly') ?? 0;
    const pcd = new URLSearchParams(location.search).get('pcd') ?? 0;
    const total = new URLSearchParams(location.search).get('total') ?? 0;

    return {
      checkIn,
      checkOut,
      adults: Number(adults),
      kids: Number(kids),
      babies: Number(babies),
      pets: Number(pets),
      elderly: Number(elderly),
      pcd: Number(pcd),
      total: Number(total),
    };
  }, [location.search]);

  const getCurrentBookingParameters = useCallback((): Booking => {
    const params = getFilterParameters();

    return new Booking({
      checkIn: params.checkIn ?? '',
      checkOut: params.checkOut ?? '',
      adults: Number(params.adults),
      kids: Number(params.kids),
      babies: Number(params.babies),
      pets: Number(params.pets),
      pcd: Number(params.pcd),
      elderly: Number(params.elderly),
      houseId: getHouseId(),
    });
  }, [location.search]);

  const getFiltersAsQueryString = useCallback((): string => {
    const params = getFilterParameters();

    const queryParams = {
      checkIn: params.checkIn ?? null,
      checkOut: params.checkOut ?? null,
      adults: params.adults ?? 0,
      kids: params.kids ?? 0,
      babies: params.babies ?? 0,
      pets: params.pets ?? 0,
      elderly: params.elderly ?? 0,
      pcd: params.pcd ?? 0,
      total: params.total ?? 0,
    } as any;

    const queryString = new URLSearchParams(queryParams).toString();

    return queryString;
  }, []);

  const updateFilters = useCallback(
    (filters: IFilter) => {
      const currentFilters = getFilterParameters();
      const queryParams = {
        ...currentFilters,
        ...filters,
      } as any;

      const queryString = new URLSearchParams(queryParams).toString();

      navigate(
        {
          pathname: location.pathname,
          search: queryString,
        },
        { replace: true },
      );
    },
    [location.pathname],
  );

  return {
    getFilterParameters,
    getFiltersAsQueryString,
    getHouseId,
    updateFilters,
    getTotalGuests,
    getCurrentBookingParameters,
  };
};

export default useFilters;
