import styles from './styles.module.scss';

type Stats = {
  name: string;
  status?: string;
  reservationNumbers: string;
  icon: string;
};

const StatsSection: React.FC<Stats> = ({ name, status, reservationNumbers, icon }) => {
  return (
    <div className={styles.container}>
      <img src={icon} alt="icon" />
      <div className={styles.textContainer}>
        <label className={styles.number}>{reservationNumbers}</label>
        <div className={styles.namesContainer}>
          <label className={styles.textLabel}>{name}</label>
          <label className={styles.textLabel}>{status}</label>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
