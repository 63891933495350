export default {
  PUBLIC: {
    LOGIN: {
      PASSWORD: 'Senha',
      BUTTON: 'Entrar',
      WELCOME: 'Bem-vindo!',
      FORGOT_PASSWORD: 'Esqueceu a senha?',
      REMEMBER_ME: 'Lembre de mim',
      PLACEHOLDER: {
        EMAIL: 'Digite seu e-mail',
        PASSWORD: 'Digite sua senha',
      },
      ERROR_MESSAGE: 'E-mail ou senha inválidos',
      GO_TO_REGISTER: 'Não possui cadastro? Clique aqui!',
      UNAUTHORIZED: 'Sessão expirada, você será redirecionado para o login',
    },
    RECOVER_PASSWORD: {
      TITLE: 'Recupere sua senha',
      SUBTITLE: 'Digite sua nova senha abaixo',
      PASSWORD: 'Senha',
      SEND_PASSWORD: 'Recuperar senha',
      SUCCESS: 'Senha alterada com sucesso!',
    },
    FORGOT_PASSWORD: {
      QUESTION: 'Esqueceu sua senha?',
      ANSWER: 'Preencha seu e-mail e mandaremos instruções sobre sua senha',
      SEND_PASSWORD: 'Enviar senha',
      BACK_TO_LOGIN: '< Voltar para tela de login',
      SUCCESS_TITLE: 'E-mail enviado',
      SUCCESS_TEXT:
        'Enviamos um e-mail para {{email}}. Se este e-mail estiver associado a uma conta GoWeasy, você poderá redefinir sua senha.',
      PLACEHOLDER: {
        EMAIL: 'Digite seu e-mail',
      },
    },
    REGISTER: {
      STEPS: {
        AGENCY: 'Dados da agência',
        AGENT: 'Dados do gestor',
        SENT: 'Envio do cadastro',
        MANAGER_DATA: 'Dados Do Gestor AG',
        AGENCY_DATA: 'Dados da Agência',
        OPERATOR_DATA: 'Dados de Operadora',
      },
      TITLE: 'Junte-se à nossa rede e conheça Orlando!',
      SUBTITLE: 'Cadastre-se agora',
      NAME: 'Nome e Sobrenome',
      CELLPHONE: `Telefone de contato`,
      NATIONAL_REGISTRATION: 'CPF',
      PASSWORD: 'Senha',
      SUBMIT: 'Enviar cadastro',
      SUCCESS_MESSAGE: 'Sua conta foi criada com sucesso!',
      AGENCIE: 'Agência',
      PLACEHOLDER: {
        AGENCIE: 'Selecione a agência',
        NAME: 'Digite seu nome e sobrenome',
        EMAIL: 'Digite seu e-mail',
        CELLPHONE: 'Digite seu telefone celular',
        NATIONAL_REGISTRATION: 'Digite seu CPF',
        PASSWORD: 'Digite sua senha',
      },
      FEEDBACK: {
        SUCCESS: {
          TITLE: 'Obrigado por se cadastrar com a GoWeasy',
          BUTTON: 'Entendi',
        },
      },
    },
  },
};
