import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { FaEdit } from 'react-icons/fa';
import BasicTable from 'components/BasicTable';
import Button from '../Button';
import useAgent from 'hooks/useAgent';
import { useEffect, useMemo, useState } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

import styles from './styles.module.scss';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import ConfirmAccess from 'pages/BackOffice/Management/modal/ConfirmAccess';
import Agent from 'models/application/Agent/Agent.model';
import { useNavigate } from 'react-router-dom';
import { AGENT_URL } from 'routes/routes/backOffice.routes';
import { useTranslation } from 'react-i18next';

type AgentTableProps = {
  filter?: string;
  agencyId?: string;
  noEdit?: boolean;
};

const AgentTable: React.FC<AgentTableProps> = ({ filter, agencyId, noEdit }) => {
  const { t } = useTranslation();

  const [confirmAccessModal, setConfirmAccessModal] = useState(false);
  const [userSelected, setUserSelected] = useState<Agent>();

  const collumnsNames = [
    t('BACKOFFICE.TABLE.CODE'),
    t('BACKOFFICE.TABLE.NAME'),
    t('BACKOFFICE.TABLE.TYPE'),
    t('BACKOFFICE.TABLE.CONTACT'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_SALES'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_RESERVATIONS'),
    t('BACKOFFICE.TABLE.STATUS'),
    ' ',
    ' ',
  ];

  const navigate = useNavigate();
  const { agents, loadingGetAgents, loadAgentsByAgency, loadingAllowAgent, allowAgent } = useAgent();

  useEffect(() => {
    loadAgentsByAgency(agencyId);
  }, []);

  const filterList = (agentsToFilter: Agent[], searchTerm: string) => {
    return agentsToFilter.filter(
      (agent: Agent) =>
        agent.name.toLowerCase().includes(searchTerm.toLowerCase()) || agent?.id?.toString().includes(searchTerm),
    );
  };

  const agentsFiltered = useMemo(() => (filter ? filterList(agents, filter) : agents), [agents, filter]);

  const unlockAgent = (user: Agent) => {
    if (!user) return;
    setUserSelected(user);
    setConfirmAccessModal(true);
  };

  const handleEdit = (row: any) => {
    navigate(AGENT_URL, { state: { agent: row } });
  };

  const confirmAllowAccess = async () => {
    if (!userSelected) return;
    await allowAgent(userSelected.nationalRegistration);
    unselectUser();
  };

  const unselectUser = () => {
    setConfirmAccessModal(false);
    setUserSelected(undefined);
  };

  const getRole = (role?: string) => {
    if (role === 'ADMIN') {
      return <div className={styles.admin}>{t('BACKOFFICE.TABLE.ADMIN')}</div>;
    }

    if (role === 'USER') {
      return <div className={styles.user}>{t('BACKOFFICE.TABLE.USER')}</div>;
    }

    return null;
  };

  const getStatus = (ativo?: boolean) => {
    if (ativo) {
      return <div className={styles.active}>{t('BACKOFFICE.TABLE.ACTIVE')}</div>;
    }

    return <div className={styles.waiting}>{t('BACKOFFICE.TABLE.WAITING_ACCESS')}</div>;
  };

  return loadingGetAgents ? (
    <div className={styles.loading}>
      <MoonLoader color={PRIMARY_BLUE_COBALT} />
    </div>
  ) : (
    <BasicTable collumnsNames={collumnsNames}>
      {agentsFiltered.map((currentAgent) => (
        <TableRow key={currentAgent.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell className={styles.code}>#{currentAgent.id}</TableCell>
          <TableCell>{currentAgent.name}</TableCell>
          <TableCell>{getRole(currentAgent.role)}</TableCell>
          <TableCell>
            <div className={styles.contact}>
              <div>{currentAgent.cellphone}</div>
              <div className={styles.email}>{currentAgent.email}</div>
            </div>
          </TableCell>
          <TableCell>
            <span className={styles.monthSalesAgent}>{'$' + ' ' + currentAgent?.sales}</span>
          </TableCell>
          <TableCell>
            <span className={styles.monthReservationsAgent}>
              {currentAgent?.countReservations +
                ' ' +
                (Number(currentAgent?.countReservations) > 1
                  ? t('BACKOFFICE.TABLE.RESERVATION') + 'S'
                  : t('BACKOFFICE.TABLE.RESERVATION'))}
            </span>
          </TableCell>
          <TableCell>{getStatus(currentAgent?.ativo)}</TableCell>
          <TableCell>
            {!currentAgent.ativo && <Button onClick={() => unlockAgent(currentAgent)}> Liberar </Button>}
          </TableCell>
          <TableCell>
            {!noEdit && (
              <FaEdit className={styles.edit} onClick={() => handleEdit(currentAgent)} size={24} color="#0049A1" />
            )}
          </TableCell>
        </TableRow>
      ))}
      {userSelected && (
        <ConfirmAccess
          isOpen={confirmAccessModal}
          user={userSelected}
          onConfirm={confirmAllowAccess}
          onClose={unselectUser}
          loading={loadingAllowAgent}
        />
      )}
    </BasicTable>
  );
};

export default AgentTable;
