export default {
  DETAILS_HOUSE: {
    COPY_LINK: 'Link copiado para a área de transferência!',
    MORE_IMAGES: 'Mais Fotos',
    NOT_FOUND: {
      MESSAGE:
        'Parece que a casa que você está procurando não existe mais ou não está disponível para a data selecionada!',
      TRY_AGAIN: 'Tentar novamente',
      GO_BACK: 'Voltar para tela anterior',
    },
  },
};
