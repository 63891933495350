import { useMemo } from 'react';
import {
  DamageRuleContainer,
  Description,
  ItemContainer,
  Label,
  LabelContainer,
  PropertyRulesTitle,
  RulesContainer,
} from './PropertyRules.style';

import { PiClockClockwiseLight, PiClockCounterClockwiseFill } from 'react-icons/pi';
import { MdOutlineChildFriendly, MdOutlinePets } from 'react-icons/md';
import { LuCigaretteOff, LuPartyPopper } from 'react-icons/lu';
import { BsPersonVcard } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleOutline } from 'react-icons/io5';
import moment from 'moment';
import COLORS from 'constants/colors';

interface PropertyRulesProps {
  petsAllowed: boolean;
  checkIn: string;
  checkOut: string;
}

const PropertyRules: React.FC<PropertyRulesProps> = ({ petsAllowed, checkIn, checkOut }) => {
  const { t } = useTranslation();

  const formatTime = (time: string) => {
    if (time.length > 2) {
      return moment.duration(time).asHours();
    }
    return time;
  };

  const rules = useMemo(() => {
    return [
      {
        label: t('HOUSE_DETAILS.RULES.CHECK_IN'),
        icon: <PiClockClockwiseLight size={20} color={COLORS.primary.maya} />,
        description: `${t('HOUSE_DETAILS.RULES.DESCRIPTION.CHECK_IN')} ${formatTime(checkIn)}h`,
      },
      {
        label: t('HOUSE_DETAILS.RULES.CHECK_OUT'),
        icon: <PiClockCounterClockwiseFill size={20} color={COLORS.primary.maya} />,
        description: `${t('HOUSE_DETAILS.RULES.DESCRIPTION.CHECK_OUT')} ${formatTime(checkOut)}h`,
      },
      {
        label: t('HOUSE_DETAILS.RULES.MINIMUM_AGE_TO_RENT'),
        icon: <BsPersonVcard size={20} color={COLORS.primary.maya} />,
        description: t('HOUSE_DETAILS.RULES.DESCRIPTION.MINIMUM_AGE_TO_RENT'),
      },
      {
        label: t('HOUSE_DETAILS.RULES.PETS_ALLOWED'),
        icon: <MdOutlinePets size={20} color={COLORS.primary.maya} />,
        description: petsAllowed
          ? t('HOUSE_DETAILS.RULES.DESCRIPTION.PETS_ALLOWED')
          : t('HOUSE_DETAILS.RULES.DESCRIPTION.PETS_NOT_ALLOWED'),
      },
      {
        label: t('HOUSE_DETAILS.RULES.EVENTS'),
        icon: <LuPartyPopper size={20} color={COLORS.primary.maya} />,
        description: t('HOUSE_DETAILS.RULES.DESCRIPTION.EVENTS'),
      },
      {
        label: t('HOUSE_DETAILS.RULES.CHILDREN'),
        icon: <MdOutlineChildFriendly size={20} color={COLORS.primary.maya} />,
        description: t('HOUSE_DETAILS.RULES.DESCRIPTION.CHILDREN'),
      },
      {
        label: t('HOUSE_DETAILS.RULES.SMOKING_POLICY'),
        icon: <LuCigaretteOff size={20} color={COLORS.primary.maya} />,
        description: t('HOUSE_DETAILS.RULES.DESCRIPTION.SMOKING_POLICY'),
      },
    ];
  }, [petsAllowed, t, checkOut, checkIn]);

  return (
    <div>
      <PropertyRulesTitle>{t('HOUSE_DETAILS.RULES.TITLE')}</PropertyRulesTitle>
      <RulesContainer>
        {rules.map((el: any, index: number) => (
          <ItemContainer key={index}>
            <div>{el.icon}</div>
            <LabelContainer>
              <Label>{el.label}</Label>
              <Description>{el.description}</Description>
            </LabelContainer>
          </ItemContainer>
        ))}
      </RulesContainer>
      <DamageRuleContainer>
        <ItemContainer>
          <div>
            <IoCloseCircleOutline size={20} color={COLORS.primary.maya} />
          </div>
          <LabelContainer>
            <Label>{t('HOUSE_DETAILS.RULES.DAMAGES')}</Label>
            <Description>{t('HOUSE_DETAILS.RULES.DESCRIPTION.DAMAGES')}</Description>
          </LabelContainer>
        </ItemContainer>
      </DamageRuleContainer>
    </div>
  );
};

export default PropertyRules;
