import Operator from 'models/application/Operator/Operator.model';
import { useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import Inputs from 'components/molecules/inputs';
import { Buttons } from 'components/molecules/buttons';
import { MdOutlineChevronRight } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateOperatorES } from 'pages/Register/forms/ES/OperatorForm/validateOperatorES';

const OperatorRegisterFormES: React.FC<any> = ({ handleNext, operatorValue }) => {
  const form = useForm<Operator>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: operatorValue,
    resolver: yupResolver(validateOperatorES),
  });

  const beforeSubmit = (data: Operator) => {
    handleNext(new Operator(data));
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <Inputs.Text form={form} required label="Nombre de la Operadora:" name="name" />
          <Inputs.Text form={form} required label="Identificación Fiscal:" name="cnpj" />
        </div>
        <div className={styles.inputContainer}>
          <Inputs.Text form={form} label="Calle:" name="address" />
          <Inputs.Text form={form} required label="Ciudad:" name="city" />
        </div>
        <div className={styles.inputContainer}>
          <Inputs.Text form={form} required label="País:" name="country" />
        </div>
      </div>
      <div className={styles.hr}></div>
      <div className={styles.buttons}>
        <Buttons.Secondary
          className={styles.buttonConfirm}
          endIcon={<MdOutlineChevronRight size={24} />}
          variant="contained"
          onClick={form.handleSubmit(beforeSubmit)}
        >
          Siguiente
        </Buttons.Secondary>
      </div>
    </div>
  );
};

export default OperatorRegisterFormES;
