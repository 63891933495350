import { useTranslation } from 'react-i18next';
import { BigTitle, SubTitle, TextContainer, Text } from './HouseNotes.style';

const HouseNotes = ({}) => {
  const { t } = useTranslation();

  return (
    <div>
      <BigTitle>{t('HOUSE_DETAILS.NOTES.TITLE')}</BigTitle>
      <SubTitle>{t('HOUSE_DETAILS.NOTES.SUBTITLE')}</SubTitle>
      <TextContainer>
        <Text>{t('HOUSE_DETAILS.NOTES.EXTRA_PEOPLE')}</Text>
        <Text>{t('HOUSE_DETAILS.NOTES.OFFICIAL_ID')}</Text>
        <Text>{t('HOUSE_DETAILS.NOTES.SPECIAL_REQUESTS')}</Text>
        <Text>{t('HOUSE_DETAILS.NOTES.NO_GUARANTEE')}</Text>
      </TextContainer>
    </div>
  );
};

export default HouseNotes;
