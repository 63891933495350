import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BasicTable from 'components/BasicTable';
import useAgent from 'hooks/useAgent';
import { useEffect, useMemo, useState } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

import styles from './styles.module.scss';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import { useTranslation } from 'react-i18next';
import useAsync from 'hooks/useAsync.hook';
import getOperatorsList from 'services/operators/getOperatorsList';
import { showErrorMessage } from 'utils/Toast';
import Button from 'components/BackOffice/Button';
import { useNavigate } from 'react-router-dom';
import getAgency from 'services/Agency/getAgency.service';
import ConfirmAgentModal from 'pages/BackOffice/Administrative/ListAgency/Components/ListAgencyTable/ConfirmAgentModal';
import useListAgency from 'hooks/useListAgency.hook';

type AgentTableProps = {
  filter?: string;
  agencyId?: string;
};

const OperatorsTable: React.FC<AgentTableProps> = ({ filter, agencyId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [confirmAccessModal, setConfirmAccessModal] = useState(false);
  const [userSelected, setUserSelected] = useState<any>();
  const [operatorsList, setOperatorsList] = useState<any[]>([]);
  const [comissionSelected, setComissionSelected] = useState<any>('');

  const collumnsNames = [
    t('BACKOFFICE.TABLE.CODE'),
    t('BACKOFFICE.TABLE.TYPE'),
    t('BACKOFFICE.TABLE.NAME'),
    t('BACKOFFICE.TABLE.LINKED_AGENCIES'),
    t('BACKOFFICE.TABLE.LINKED_AGENTS'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_SALES'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_RESERVATIONS'),
    t('BACKOFFICE.TABLE.STATUS'),
    ' ',
  ];

  //const navigate = useNavigate();
  const { loadingGetAgents, loadAgentsByAgency } = useAgent();
  const { allowAgency } = useListAgency();

  const { call: getOperators } = useAsync(async () => {
    try {
      const response = await getOperatorsList();
      setOperatorsList(response);
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  const { loading: loadingAgency, call: getAgencyData } = useAsync(async (agId: any) => {
    try {
      const response = await getAgency(agId);
      setUserSelected(response);
      setConfirmAccessModal(true);
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  useEffect(() => {
    loadAgentsByAgency(agencyId);
    getOperators();
  }, []);

  const filterList = (OperatorToFilter: any[], searchTerm: string) => {
    return OperatorToFilter.filter(
      (operator: any) =>
        operator?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        operator?.id?.toString().includes(searchTerm),
    );
  };

  const operatorsListFiltered = useMemo(
    () => (filter ? filterList(operatorsList, filter) : operatorsList),
    [operatorsList, filter],
  );

  /*const unlockAgent = (user: Agent) => {
      if (!user) return;
      setUserSelected(user);
      setConfirmAccessModal(true);
    };*/

  /* const handleEdit = (row: any) => {
      navigate(AGENT_URL, { state: { agent: row } });
    };*/

  const goToAgency = (id: number) => {
    navigate(`/admin/agency-details/${id}`);
  };

  const goToOperatorDetails = (id: number) => {
    navigate(`/admin/operators-details/${id}`);
  };

  const confirmAllowAccess = async () => {
    if (!userSelected) return;
    await allowAgency(userSelected.id, comissionSelected);
    unselectUser();
  };

  const unselectUser = () => {
    setConfirmAccessModal(false);
    setUserSelected(undefined);
  };
  const handleSelect = (newSelectedValue: any) => {
    setComissionSelected(newSelectedValue);
  };

  const getType = (type?: string) => {
    if (type === 'OPERADORA') {
      return <div className={styles.user}>{t('BACKOFFICE.TABLE.OPERATOR')}</div>;
    }

    if (type === 'AGENCIA') {
      return <div className={styles.admin}>{t('BACKOFFICE.TABLE.AGENCY')}</div>;
    }

    return null;
  };

  const getStatus = (ativo?: boolean) => {
    if (ativo) {
      return <div className={styles.active}>{t('BACKOFFICE.TABLE.ACTIVE')}</div>;
    }

    return <div className={styles.inactive}>{'Inativo'}</div>;
  };

  const handleLinkedAgenciesNavigation = (id: any, type: string) => {
    if (type === 'OPERADORA') navigate(`/admin/linked-agencies/${id}`);
  };

  const handleLinkedAgentsNavigation = (id: any, type: string) => {
    if (type === 'AGENCIA') navigate(`/admin/linked-agents/${id}`);
  };

  const handleAccess = (id: any) => {
    getAgencyData(id);
  };

  //const handleLinkedAgentsNavigation =\ () => {
  //};

  // TODO: Ajustar loading
  return loadingGetAgents ? (
    <div className={styles.loading}>
      <MoonLoader color={PRIMARY_BLUE_COBALT} />
    </div>
  ) : (
    <BasicTable collumnsNames={collumnsNames}>
      {operatorsListFiltered.map((operator) => (
        <TableRow key={operator?.id + operator?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell className={styles.code}>#{operator?.id}</TableCell>
          <TableCell>{getType(operator?.type)}</TableCell>
          <TableCell>{operator?.name}</TableCell>
          <TableCell onClick={() => handleLinkedAgenciesNavigation(operator?.id, operator?.type)}>
            <div className={operator?.status ? styles.linkedAgency : styles.linkedAgencyInactive}>
              {operator?.agencyCount +
                ' ' +
                (Number(operator?.agencyCount) > 1 ? t('BACKOFFICE.TABLE.AGENCIES') : t('BACKOFFICE.TABLE.AGENCY'))}
            </div>
          </TableCell>
          <TableCell onClick={() => handleLinkedAgentsNavigation(operator?.id, operator?.type)}>
            <div className={operator?.status ? styles.linkedAgents : styles.linkedAgentsInactive}>
              {operator?.agentsCount +
                ' ' +
                (Number(operator?.agentsCount) > 1 ? t('BACKOFFICE.TABLE.AGENTS') : t('BACKOFFICE.TABLE.USER'))}
            </div>
          </TableCell>
          <TableCell>
            <div className={operator?.status ? styles.monthSales : styles.monthSalesInactive}>
              {'$' + operator?.valueMonth?.toFixed(3).slice(0, -1)}
            </div>
          </TableCell>
          <TableCell>
            <div className={operator?.status ? styles.monthReservations : styles.monthReservationsInactive}>
              {operator?.reservationMonth +
                ' ' +
                (Number(operator?.reservationMonth) > 1
                  ? t('BACKOFFICE.TABLE.RESERVATION') + 'S'
                  : t('BACKOFFICE.TABLE.RESERVATION'))}
            </div>
          </TableCell>
          {operator?.status && <TableCell>{getStatus(operator?.status)}</TableCell>}
          {!operator?.status && (
            <TableCell>
              {operator?.type === 'AGENCIA' ? (
                <Button onClick={() => handleAccess(operator?.id)}>{t('AGENT.CONFIRM.BUTTON')}</Button>
              ) : (
                getStatus(operator?.status)
              )}
            </TableCell>
          )}
          {/*<TableCell>
              {!currentAgent.ativo && <Button onClick={() => unlockAgent(currentAgent)}> Liberar </Button>}
            </TableCell>*/}
          <TableCell>
            {operator?.type === 'AGENCIA' && (
              <Button onClick={() => goToAgency(operator?.id)}> {t('BACKOFFICE.RESERVATION_DETAIL.DETAILS')} </Button>
            )}
            {operator?.type === 'OPERADORA' && Number(operator?.agencyCount) > 0 && (
              <Button onClick={() => goToOperatorDetails(operator?.id)}>
                {' '}
                {t('BACKOFFICE.RESERVATION_DETAIL.DETAILS')}{' '}
              </Button>
            )}
          </TableCell>
        </TableRow>
      ))}
      {userSelected && (
        <ConfirmAgentModal
          isOpen={confirmAccessModal}
          user={userSelected}
          onConfirm={confirmAllowAccess}
          onSelect={handleSelect}
          selectedValue={comissionSelected}
          onClose={unselectUser}
          loading={loadingAgency}
        />
      )}
    </BasicTable>
  );
};

export default OperatorsTable;
