import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { Container } from './style';
import styles from './styles.module.scss';

import 'react-calendar/dist/Calendar.css';
import '../../../styles/react-calendar.scss';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { TileArgs } from 'react-calendar/dist/cjs/shared/types';
import moment from 'moment';
import { useMemo, useState } from 'react';
import i18next from 'i18next';
import { NEUTRAL_BLACK, NEUTRAL_WHITE } from 'styles/colors';
import { Languages } from 'languages/helpers.languages';
import { useTranslation } from 'react-i18next';
import { Arrow_Down } from 'assets';

type WEDatePickerProps = {
  value: any;
  onChangeValue: (value: Date[] | null[]) => void;
  minDate?: Date;
  ratesCalendar?: any[];
  placeholder?: {
    checkIn: string;
    checkOut: string;
  } | null;
  darkMode?: boolean;
  cleanOff?: boolean;
};

const RangeDatePicker: React.FC<WEDatePickerProps> = ({
  minDate,
  onChangeValue,
  ratesCalendar,
  value: valueProps = new Date(),
  darkMode,
}) => {
  const [hasOpened, setHasOpened] = useState(false);
  const { t } = useTranslation();

  const disableDates = useMemo(() => {
    if (!ratesCalendar) return [];

    return Object.entries(ratesCalendar)
      .filter(([_, keyValue]) => keyValue === '0')
      .map(([key]) => key);
  }, [ratesCalendar]);

  const currentLanguage = i18next.language;
  const isBR = currentLanguage === 'pt-BR';

  const dateFormat = isBR ? 'dd/MM/y' : 'MM/dd/y';

  const useMemoColorMode = useMemo(() => {
    if (darkMode) {
      return {
        labelColor: NEUTRAL_WHITE,
        iconClass: styles.closeIconDark,
        classInput: styles.dateInputWhite,
        classPlaceholder: styles.placeholderTransparent,
      };
    }
    return {
      labelColor: NEUTRAL_BLACK,
      iconClass: styles.closeIconWhite,
      classInput: styles.dateInputDark,
      classPlaceholder: styles.placeholderWhite,
    };
  }, [darkMode]);

  const clearCheckout = () => {
    onChangeValue([valueProps[0], null]);
  };

  const handleChangeValue = (dateValue: any) => {
    if (!ratesCalendar) return onChangeValue(dateValue as any);

    const allDatesSelected = getDatesBetween(dateValue[0], dateValue[1]);
    const isInvalidDateRange = disableDates.some((element) => allDatesSelected.includes(element));

    if (isInvalidDateRange) {
      return clearCheckout();
    }

    onChangeValue(dateValue as any);
  };

  const handleDisableDates = ({ date }: TileArgs) => {
    const formatedDate = moment(date).format('YYYY-MM-DD');
    const isDisableDate = disableDates.find((disableDate) => disableDate === `${formatedDate}`);

    if (isDisableDate) return true;

    return false;
  };

  const getDatesBetween = (startDate: any, endDate: any) => {
    const dates = [];
    const currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.toDate());
      currentDate.add(1, 'days');
    }

    return dates.map((date) => moment(date).format('YYYY-MM-DD'));
  };

  const getLocale = () => {
    switch (i18next.language) {
      case Languages.ES:
        return 'es';
      case Languages.PT_BR:
        return 'pt-BR';
      default:
        return 'en-US';
    }
  };

  const getDateName = () => {
    if (!valueProps[0] || !valueProps[1]) return t('SEARCH_DATES');

    const firstDay = {
      month: new Date(valueProps[0]),
      day: new Date(valueProps[0])?.getDate(),
    };

    const secondDay = {
      month: new Date(valueProps[1]),
      day: new Date(valueProps[1])?.getDate(),
    };

    return ` Mostrar de  ${firstDay.day} ${firstDay.month.toLocaleString(getLocale(), { month: 'short' })} - ${
      secondDay.day
    } ${secondDay.month.toLocaleString(getLocale(), { month: 'short' })}`;
  };

  const handleOpen = () => {
    setHasOpened((o) => !o);
  };

  return (
    <Container>
      <DateRangePicker
        calendarIcon={null}
        className={useMemoColorMode.classInput}
        calendarClassName={styles.calendar}
        tileClassName={styles.tile}
        minDate={minDate}
        onChange={handleChangeValue}
        clearIcon={null}
        next2Label={null}
        prev2Label={null}
        value={valueProps}
        rangeDivider={<div className={styles.fakeDivider}>-</div>}
        tileDisabled={ratesCalendar && handleDisableDates}
        format={dateFormat}
        isOpen={hasOpened}
        locale={i18next.language}
      />
      <div onClick={handleOpen} className={`${styles.placeholderContainer} ${useMemoColorMode.classPlaceholder}`}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {getDateName()}
          <img src={Arrow_Down} width={24} height={24}></img>
        </div>
      </div>
    </Container>
  );
};

export default RangeDatePicker;
