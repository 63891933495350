import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Buttons } from 'components/molecules/buttons';
import COLORS from 'constants/colors';
import useCheckout from 'hooks/useCheckout.hook';
import useFilters from 'hooks/useFilters';
import { IReservation } from 'models/contracts/request/reservation.request';
import { useNavigate } from 'react-router-dom';

type ButtonStripeProps = {
  form: any;
  children: React.ReactNode;
  costTotal: number;
  userId: number;
  paymentIntentId: string;
  checkIn: string;
  checkOut: string;
  houseId: number;
};

const ButtonStripe: React.FC<ButtonStripeProps> = ({
  form,
  children,
  costTotal,
  userId,
  paymentIntentId,
  checkIn,
  checkOut,
  houseId,
}) => {
  const { getTotalGuests } = useFilters();
  const { registerReserve, sendingReserve } = useCheckout();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleSubmitForm = async (data: IReservation) => {
    try {
      const totalGuests = getTotalGuests();
      const resultData = await registerReserve({
        checkIn: checkIn,
        checkOut: checkOut,
        houseId: houseId,
        costTotal,
        employeeId: userId,
        guests: totalGuests,
        paymentIntentId,
        personal: {
          ...data.personal,
          address2: '',
        },
      });
      confirmPayment(resultData?.id ?? resultData.bookingReservationId);
    } catch (err) {
      console.warn(err);
    }
  };

  const confirmPayment = (reservationId: number) => {
    if (elements) {
      stripe
        ?.confirmPayment({
          elements,
          redirect: 'if_required',
        })
        .then(({ error, paymentIntent }) => {
          if (error) {
            navigate('/checkout/fail', { state: { reservationId: reservationId } });
          } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            navigate('/checkout/success', { state: { reservationId: reservationId, checkIn, checkOut } });
          }
        });
    }
  };

  return (
    <Buttons.Default
      backgroundColor={COLORS.primary.bleu}
      variant="contained"
      loading={sendingReserve}
      onClick={form.handleSubmit(handleSubmitForm)}
    >
      {children}
    </Buttons.Default>
  );
};

export default ButtonStripe;
