import Header from 'components/BackOffice/Header';
import useLogin from 'hooks/useLogin';
import { Navigate, Outlet, ScrollRestoration, useNavigate } from 'react-router-dom';
import { getUser, isLogged, isTokenValid } from 'services/Login/user.service';

import styles from './styles.module.scss';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const BackOfficeLayout = () => {
  const { t } = useTranslation();
  const { logout } = useLogin();
  const navigate = useNavigate();
  const user = useMemo(() => getUser(), []);

  const validateAdmin = () => {
    if (user.isAdmin() || user.isGoWeasy() || user.isOperator()) return true;

    toast.error(t('ADMINISTRATIVE.FORBIDDEN'));

    return false;
  };

  const isValidUser = isLogged() && isTokenValid() && validateAdmin();

  const handleLogout = () => {
    const path = `${window.location.pathname}${window.location.search}`;

    if (!isLogged()) {
      const savePath = !isLogged() ? path : undefined;
      logout(savePath);
      return <Navigate to="/sign/login" replace />;
    }

    return <Navigate to="/" replace />;
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {!isValidUser ? (
        handleLogout()
      ) : (
        <div style={{ display: 'inline-block', minWidth: '100%' }}>
          <ScrollRestoration />
          <Header />
          <div className={styles.grid}>
            <div onClick={goBack} className={styles.backButton}>
              <MdOutlineChevronLeft size={40} color="#252525" />
            </div>
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default BackOfficeLayout;
