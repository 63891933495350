import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateUserAndAgency } from './validateUserAndAgency';
import Inputs from 'components/molecules/inputs';
import AgencyAndUserForm from 'models/application/Agency/AgencyAndUser.model';
import { Grid } from '@mui/material';
import { Buttons } from 'components/molecules/buttons';
import getAddressByCep from 'services/CEP/getAddressByCep';
import { useRef, useState } from 'react';

const AgencyRegisterForm: React.FC<any> = ({ handleSubmitForm, agencyValues }) => {
  const form = useForm<AgencyAndUserForm>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agencyValues,
    resolver: yupResolver(validateUserAndAgency),
  });

  const inputRef = useRef<any>(null);

  const [isDirty, setIsDirty] = useState<boolean>();

  const beforeSubmit = (data: AgencyAndUserForm) => {
    handleSubmitForm(new AgencyAndUserForm(data));
  };

  const getCep = async (c: string) => {
    const response = await getAddressByCep(c);
    if (response.logradouro) {
      form.setValue('complement', response.logradouro + ', ' + response.bairro, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setIsDirty(true);
    } else {
      form.setValue('complement', '');
    }
  };
  const getCepAgency = async (c: string) => {
    const response = await getAddressByCep(c);
    if (response.logradouro) {
      form.setValue('agencyAddress', response.logradouro + ', ' + response.bairro, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('agencyCity', response.localidade, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('agencyCountry', 'Brasil', {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setIsDirty(true);
    } else {
      form.setValue('agencyAddress', '');
      form.setValue('agencyCity', '');
      form.setValue('agencyCountry', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };
  const handleAgencyBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g).join('');
    if (cepNumbers) getCepAgency(cepNumbers);
  };

  return (
    <div>
      <h3>Vincular Agência</h3>
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Nome da agência:" name="agencyName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Email da agência:" name="agencyEmail" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Telefone da agência" name="agencyCellphone" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="99.999.999/9999-99"
              required
              label="CNPJ:"
              name="agencyCnpj"
              id="cnpj"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="99999-999"
              label="CEP da agência:"
              name="agencyCep"
              id="cep"
              onBlur={handleAgencyBlur}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Cidade:"
              name="agencyCity"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="País:"
              name="agencyCountry"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              required
              label="Endereço:"
              name="agencyAddress"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} label="Inscrição estadual (opcional):" name="stateRegistration" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} label="CADASTUR (opcional):" name="cadastur" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} label="URL do site (opcional):" name="siteUrl" />
          </Grid>
        </Grid>
      </div>
      <div className={styles.hr} />
      <h3>Dados do Responsavel pela Agência</h3>
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Nome do responsável pela Agência:" name="agentName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="999.999.999-99"
              required
              label="CPF do responsável pela Agência:"
              name="agentCpf"
              id="cpf"
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Email do responsável pela Agência:" name="agentEmail" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="99999-999"
              required
              label="CEP:"
              name="cep"
              id="cep"
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Número:" name="number" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text
              form={form}
              label="Endereço:"
              name="complement"
              inputRef={inputRef}
              inputLabelProps={{
                shrink: isDirty, // Disable label animation
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.hr} />
      <h3>Senha de Acesso</h3>
      <Grid container spacing={4}>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text type="password" autoComplete="new-password" form={form} label="Senha:" name="password" />
        </Grid>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text
            type="password"
            autoComplete="new-password"
            form={form}
            label="Confirmar Senha:"
            name="confirmPassword"
          />
        </Grid>
      </Grid>
      <div className={styles.hr} />
      <div className={styles.buttons}>
        <Buttons.Secondary
          className={styles.buttonConfirm}
          variant="contained"
          onClick={form.handleSubmit(beforeSubmit)}
        >
          Vincular
        </Buttons.Secondary>
      </div>
    </div>
  );
};

export default AgencyRegisterForm;
