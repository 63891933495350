const FiltersEnum = {
  TOWNHOMES: 'TOWNHOMES',
  SINGLE_FAMILY: 'SINGLE_FAMILY',
  APARTMENT: 'APARTMENT',
  CONDOMINIUM: 'CONDOMINIUM',
  VILLA: 'VILLA',
  LUXURY_HOUSE: 'LUXURY_HOUSE',
  MANOR: 'MANOR',
  PET_FRIENDLY: 'PET_FRIENDLY',
  FOR_SENIORS: 'FOR_SENIORS',
  INTEGRATED_OFFICE: 'INTEGRATED_OFFICE',
  GOLF_COURSE: 'GOLF_COURSE',
  CINEMA: 'CINEMA',
  LARGE_GROUPS: 'LARGE_GROUPS',
  NEAR_DISNEY: 'NEAR_DISNEY',
  NEAR_UNIVERSAL: 'NEAR_UNIVERSAL',
  THEMATIC: 'THEMATIC',
  AMAZING_POOLS: 'AMAZING_POOLS',
  GO_WEASY_CHOICE: 'GO_WEASY_CHOICE',
  FIRST_TIME_ORLANDO: 'FIRST_TIME_ORLANDO',
  INTERNATIONAL_DRIVE: 'NEAR_INTERNATIONAL',
};

export default FiltersEnum;
