import { TextField as TextFieldMUI } from '@mui/material';
import { styled } from '@mui/material/styles';
import APP_COLORS from 'constants/color';
import COLORS from 'constants/colors';

export const TextFieldWhite = styled(TextFieldMUI)(({ theme, error }) => ({
  '& .MuiFormLabel-root': {
    top: '.2rem',
    fontFamily: 'Libre Franklin, sans-serif',
    color: '#fff',

    '&.Mui-focused': {
      color: '#FFFFFF !important', // Cor do label quando o input está focado
    },
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: 4,
    position: 'relative',
    color: '#fff',
    padding: '.75rem',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-input::placeholder': {
    // Cor do placeholder
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    // Cor do fundo do input
    backgroundColor: '#ffffff0f',
    '&.Mui-focused': {
      '& fieldset': {
        // Cor da borda do input após ser selecionado
        borderColor: error ? APP_COLORS.feedback.danger.default : COLORS.neutral.white,
      },
    },
    '&:hover': {
      '& fieldset': {
        // Cor da borda do input ao passar o mouse
        borderColor: error ? APP_COLORS.feedback.danger.default : COLORS.neutral.white,
      },
    },
    '& fieldset': {
      // Cor da borda do input sem estar selecionado
      borderColor: error ? APP_COLORS.feedback.danger.default : COLORS.neutral.white,
    },
  },
}));

export const TextField = styled(TextFieldMUI)(({ theme, error }) => ({
  '& .MuiFormLabel-root': {
    top: '.2rem',
    fontFamily: 'HelveticaNeue, sans-serif',
    '&:focus': {
      display: 'none',
    },
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: 4,
    position: 'relative',
    borderColor: error ? APP_COLORS.feedback.danger.default : '#E0E3E7',
    background: '#fff',
    padding: '.75rem',
    alignItems: 'center',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#0089FF',
      borderWidth: 0.5,
    },
  },
}));
