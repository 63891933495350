import { ReactNode, useState } from 'react';
import Carousel from 'react-multi-carousel';

import styles from './styles.module.scss';
import CustomArrows from 'components/ListHousesSection/components/CustomArrows/CustomArrows';

type WeCarouselProps = {
  children: ReactNode;
  sliderClass?: string;
  containerClass?: string;
  withNumbers?: boolean;
};

const WECarousel: React.FC<WeCarouselProps> = ({ children, withNumbers }) => {
  const [additionalTransfrom, setAdditionalTransform] = useState(0);

  return (
    <Carousel
      responsive={{
        veryLargeDesktop: {
          breakpoint: {
            max: 10000,
            min: 2200,
          },
          items: withNumbers ? 5 : 6,
          slidesToSlide: 1,
        },
        largeDesktop: {
          breakpoint: {
            max: 2200,
            min: 1680,
          },
          items: withNumbers ? 4 : 5,
          slidesToSlide: 1,
        },
        desktop: {
          breakpoint: {
            max: 1680,
            min: 1024,
          },
          items: withNumbers ? 3 : 4,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          slidesToSlide: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 2,
          slidesToSlide: 1,
        },
      }}
      beforeChange={(nextSlide) => {
        setAdditionalTransform(-26 * nextSlide);
      }}
      additionalTransfrom={additionalTransfrom}
      arrows
      customLeftArrow={<CustomArrows.Left />}
      customRightArrow={<CustomArrows.Right />}
      autoPlaySpeed={3000}
      centerMode={false}
      sliderClass={styles.list}
      containerClass={styles.container}
      itemClass={styles.item}
      draggable
      focusOnSelect={false}
      keyBoardControl
      minimumTouchDrag={80}
      partialVisible
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      slidesToSlide={4}
      swipeable
    >
      {children}
    </Carousel>
  );
};

export default WECarousel;
