import { FaRegCopy } from 'react-icons/fa6';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

const CopyLabel: React.FC<any> = ({ text }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success('Copied'))
      .catch(() => alert('Failed to copy text'));
  };

  const handleOpenLink = () => {
    window.open(text);
  };

  return (
    <div className={styles.container}>
      <span style={{ alignSelf: 'center' }}>Link de pagamento criado com sucesso!</span>
      <div className={styles.labelContainer}>
        <span className={styles.labelText} onClick={handleOpenLink}>
          {text}
        </span>
        <div className={styles.copyContainer} onClick={handleCopy}>
          <label style={{ fontSize: '16px', cursor: 'pointer' }}>Copiar</label>
          <FaRegCopy className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default CopyLabel;
