import { ISectionHouseResume } from 'models/application/interfaces/ISectionHouseResume.interface';
import { createContext, useState } from 'react';

const ChatbotSearchContext = createContext<any>(null);

export const ChatbotProvider: React.FC<any> = ({ children }) => {
  const [sections, setSections] = useState<ISectionHouseResume[]>([]);

  return <ChatbotSearchContext.Provider value={{ sections, setSections }}>{children}</ChatbotSearchContext.Provider>;
};

export default ChatbotSearchContext;
