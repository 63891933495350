import { isValidCNPJ } from 'utils/validator';
import Yup from 'utils/yup';

export const validateOperator = Yup.object({
  name: Yup.string().required(),
  cnpj: Yup.string()
    .required()
    .test('is-valid-cnpj', 'CNPJ inválido', (value) => isValidCNPJ(value)),
  address: Yup.string().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  // campos faltantes
  // cadastur
  // urlSite
  // email
  // primary contact
});
