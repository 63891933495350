import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateUserAndAgencyES } from './validateUserAndAgencyES';
import Inputs from 'components/molecules/inputs';
import { Grid } from '@mui/material';
import { Buttons } from 'components/molecules/buttons';
import AgencyAndUserES from 'models/application/Agency/AgencyAndUserES.model';

const AgencyRegisterFormES: React.FC<any> = ({ handleSubmitForm, agencyValues }) => {
  const form = useForm<AgencyAndUserES>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agencyValues,
    resolver: yupResolver(validateUserAndAgencyES),
  });

  const beforeSubmit = (data: AgencyAndUserES) => {
    handleSubmitForm(new AgencyAndUserES(data));
  };

  return (
    <div>
      <h3>Vincular agencia</h3>
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Agencia:" name="name" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="País:" name="country" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Ciudad" name="city" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Calle:" name="street" />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} label="Número:" name="number" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Razón Social:" name="corporateName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} label="Legajo:" name="dossier" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Teléfono" name="cellphone" />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="E-mail:" name="emailUser" />
          </Grid>
        </Grid>
      </div>
      <div className={styles.hr} />
      <h3>Datos del Responsable de la Agencia</h3>
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Nombre:" name="firstName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Apellido:" name="lastName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="E-mail:" name="email" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Teléfono:" name="phone" />
          </Grid>
        </Grid>
      </div>
      <div className={styles.hr} />
      <h3>Defina su contraseña</h3>
      <Grid container spacing={4}>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text type="password" form={form} label="Contraseña:" name="password" />
        </Grid>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text type="password" form={form} label="Confirmar Contraseña:" name="confirmPassword" />
        </Grid>
      </Grid>
      <div className={styles.hr} />
      <div className={styles.buttons}>
        <Buttons.Secondary
          className={styles.buttonConfirm}
          variant="contained"
          onClick={form.handleSubmit(beforeSubmit)}
        >
          Finalizar
        </Buttons.Secondary>
      </div>
    </div>
  );
};

export default AgencyRegisterFormES;
