class Agent {
  id?: number;
  associationId: string;
  name: string;
  nationalRegistration: string;
  email: string;
  cellphone: string;
  agencyCode: string;
  address: string;
  city: string;
  zipcode: string;
  country: string;
  state: string;
  role?: string;
  password?: string;
  ativo?: boolean;
  countReservations?: number;
  sales?: number;

  constructor(agent: Agent) {
    this.id = agent.id;
    this.agencyCode = agent.agencyCode;
    this.name = agent.name;
    this.email = agent.email;
    this.cellphone = agent.cellphone;
    this.nationalRegistration = agent.nationalRegistration;
    this.address = agent.address;
    this.associationId = agent.associationId;
    this.city = agent.city;
    this.zipcode = agent.zipcode;
    this.country = agent.country;
    this.state = agent.state;
    this.role = agent?.role;
    this.ativo = agent?.ativo;
    this.countReservations = agent?.countReservations;
    this.sales = agent?.sales;
  }
}

export default Agent;
