import Checkbox from '@mui/material/Checkbox';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import FormControlLabel from '@mui/material/FormControlLabel';

const CheckBox: React.FC<any> = ({ label, ...props }) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          {...props}
          inputProps={{ 'aria-label': label }}
          sx={{
            color: PRIMARY_BLUE_COBALT,
            '&.Mui-checked': {
              color: PRIMARY_BLUE_COBALT,
            },
          }}
        />
      }
    />
  );
};

export default CheckBox;
