const gregorian_es: any = {
  name: 'gregorian_es',
  months: [
    ['Enero', 'Ene'],
    ['Febrero', 'Feb'],
    ['Marzo', 'Mar'],
    ['Abril', 'Abr'],
    ['Mayo', 'May'],
    ['Junio', 'Jun'],
    ['Julio', 'Jul'],
    ['Agosto', 'Ago'],
    ['Septiembre', 'Sep'],
    ['Octubre', 'Oct'],
    ['Noviembre', 'Nov'],
    ['Diciembre', 'Dic'],
  ],
  weekDays: [
    ['Sábado', 'Sáb'],
    ['Domingo', 'Dom'],
    ['Lunes', 'Lun'],
    ['Martes', 'Mar'],
    ['Miércoles', 'Mié'],
    ['Jueves', 'Jue'],
    ['Viernes', 'Vie'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['AM', 'am'],
    ['PM', 'pm'],
  ],
};

export default gregorian_es;
