import { axiosInstances } from 'services/api';
import { OperatorRequest } from 'models/contracts/request/operator.request';

const createOperator = async (operator: OperatorRequest): Promise<any> => {
  const { data } = await axiosInstances.base.instance.post('/service/agencies/operadora', {
    ...operator,
  });

  return data?.id;
};

export default createOperator;
