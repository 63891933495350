import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  height: 60vh;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const MainPicture = styled.img`
  margin-right: 8px;
  width: 75%;
  border-radius: 8px 0px 0px 8px;
  transition: filter 0.3s;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0px;
    margin-right: 0px;
  }

  &:hover {
    filter: brightness(80%);
    cursor: pointer;
  }
`;

const SecondaryPictures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  height: 60vh;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SmallPicture = styled.img`
  height: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(80%);
    cursor: pointer;
  }
`;

const SecondPicture = styled(SmallPicture)`
  border-radius: 0px 8px 0px 0px;
`;

const LastPicture = styled.img`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 0px;
  width: 100%;
  filter: brightness(40%);
  transition: filter 0.3s;

  &:hover {
    filter: brightness(35%);
    cursor: pointer;
  }
`;

const HoverEffect = styled.div`
  &:hover {
    filter: brightness(80%);
    cursor: pointer;
  }
`;

const MorePhotos = styled.div`
  position: absolute;
  z-index: 1;
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 6px;
  padding: 8px 32px;

  &:hover {
    cursor: pointer;
  }
`;

const LastPictureContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32%;
`;

export {
  HoverEffect,
  LastPicture,
  LastPictureContainer,
  MainContainer,
  MainPicture,
  MorePhotos,
  SecondaryPictures,
  SecondPicture,
  SmallPicture,
};
