export default {
  DETAILS_HOUSE: {
    COPY_LINK: 'Link copied to clipboard!',
    MORE_IMAGES: 'More Photos',
    NOT_FOUND: {
      MESSAGE:
        'It seems that the house you are looking for either no longer exists or is not available for the selected date!',
      TRY_AGAIN: 'Try again',
      GO_BACK: 'Go back to the previous screen',
    },
  },
};
