import { Box, Divider, FormControl, FormLabel, Grid, MenuItem, TextField } from '@mui/material';
import { IoMdClose } from 'react-icons/io';
import FeatureRow from 'components/BackOffice/FeatureRow';
import useAsync from 'hooks/useAsync.hook';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import getHouseCategories from 'services/HouseListAdm/getHouseCategories';
import getHouseManageDetails from 'services/HouseListAdm/getHouseManageDetails';
import styles from './styles.module.scss';
import getCondominiumSelect from 'services/HouseListAdm/getCondominiumSelect';
import { Buttons } from 'components/molecules/buttons';
import updateHouseAdm from 'services/HouseListAdm/updateHousesAdm';
import { toast } from 'react-toastify';

const initialFormState = {
  id: 0,
  status: 0,
  name: '',
  condominium_id: 0,
  description: '',
  descriptionPT: '',
  descriptionES: '',
  houseCategories: [],
  pictures: [],
};

const CheckBoxWithImage: React.FC<any> = ({ name, url, onClose, onDrop }) => {
  return (
    <div onDrop={onDrop} onDragOver={(e) => e.preventDefault()}>
      {name && <label className={styles.categoryTitle}>{name}</label>}
      <br />
      <div className={styles.container}>
        {url && <img width="180" height="120" src={url} />}
        {!url && (
          <div style={{ width: '180px', height: '120px', display: 'flex', alignItems: 'center' }}>
            <label style={{ fontSize: '12px' }}>Arraste uma imagem da galeria</label>
          </div>
        )}
        <div className={styles.closeIcon}>
          <IoMdClose size={24} color="#FF7400" onClick={onClose} />
        </div>
        {/*<input type="checkbox" className={styles.checkbox} id="check1" />*/}
      </div>
    </div>
  );
};

const CheckBoxWithImageOrdered: React.FC<any> = ({
  url,
  id,
  status,
  onChange,
  onDragStart,
  onDragEnter,
  onDrop,
  onDragEnd,
}) => {
  return (
    <div
      draggable
      onDragStart={onDragStart}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()}
      onDragEnd={onDragEnd}
    >
      <br />
      <div className={styles.container}>
        {url && <img width="180" height="120" src={url} />}
        {!url && <div style={{ width: '180px', height: '120px' }} />}
        <input type="checkbox" className={styles.checkbox} id={id} onChange={onChange} checked={status === 1} />
      </div>
    </div>
  );
};

const HouseManagement = () => {
  const urlParams = useParams();
  const { t } = useTranslation();
  const houseId = urlParams.id;
  const [formData, setFormData] = useState(initialFormState);
  const [categories, setCategories] = useState<any[]>([]);
  const [condominium, setCondominium] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any>(0);
  const [categoriesCollection, setcategoriesCollection] = useState<any[]>([]);
  const [selectedCondominium, setSelectedCondominium] = useState<any>(0);
  const [initialCondominium, setInitialCondominium] = useState<any>(null);
  const [houseImages, setHouseImages] = useState<any>();
  const [imageOnDrag, setImageOnDrag] = useState<any>('');
  const dragHouse = useRef<number>(0);
  const draggedOverHouse = useRef<number>(0);
  const activeDrag = useRef<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e: any) => {
    // Temporario talvez
    const categorieId = e?.target?.value;
    const categorieNewValue = categories.find((c) => c.id === categorieId);
    const categoriesTemp = categoriesCollection;
    categoriesTemp?.push({ ...categorieNewValue, url: '' });
    setSelectedCategorie(e?.target?.value);
    setcategoriesCollection(categoriesTemp);
  };

  const handleCondominiumChange = (e: any) => {
    // Temporario talvez
    setSelectedCondominium(e?.target?.value);
  };

  const handleCheckboxChange = (id: any) => {
    // TODO: Tomar cuidado quando a ordem for nula
    setHouseImages((prevImages: any) =>
      prevImages?.map((image: any) => (image.ordem === id ? { ...image, status: image.status === 0 ? 1 : 0 } : image)),
    );
  };

  const handleClearCategorie = (indexToRemove: any) => {
    setcategoriesCollection((prev) => {
      return prev?.filter((_, index) => index !== indexToRemove);
    });
  };

  const { call: getHouseInformation } = useAsync(async (id: any) => {
    const response = await getHouseManageDetails(id);
    setFormData((prevState) => ({
      ...prevState,
      ...response,
    }));

    const orderedImages = response?.pictures?.sort((a: any, b: any) => a?.ordem - b?.ordem);
    // Alguns dados do backend estão vindo sem o atributo "ordem" preenchido
    const imagesWithNonNullOrder = orderedImages.map((item: any, index: any) => {
      if (!item.ordem) {
        return {
          ...item,
          ordem: index + 1,
        };
      } else {
        return item;
      }
    });
    setHouseImages(imagesWithNonNullOrder);
    setcategoriesCollection(response?.houseCategories);
    setInitialCondominium(response?.condominium);
    //setSelectedCondominium(initialCondominium?.id);
    // TODO: Colocar os estados dos selects ou usar o formData
  }, []);

  const { call: getCategories } = useAsync(async () => {
    const response = await getHouseCategories();
    setCategories(response);
  }, []);

  const { call: updateHouses } = useAsync(async (data: any) => {
    try {
      await updateHouseAdm(data);
      toast.success('Ação concluida com sucesso');
    } catch {
      toast.error('Verifique se todos os campos foram preenchidos ou tente mais tarde.');
    }
  }, []);

  const { call: getCondominium } = useAsync(async () => {
    const response = await getCondominiumSelect();
    setCondominium(response);
  }, []);

  const setInitialCondominiumSelect = () => {
    const initialCondValue = condominium.find((v: any) => v?.name === initialCondominium);
    setSelectedCondominium(initialCondValue?.id);
  };

  const handleSort = () => {
    if (activeDrag.current === true) {
      const houseClone = [...houseImages];
      const temp = houseClone[dragHouse.current];
      houseClone[dragHouse.current] = houseClone[draggedOverHouse.current];
      houseClone[draggedOverHouse.current] = temp;
      const orderedImages = houseClone.map((item: any, index: any) => {
        return {
          ...item,
          ordem: index + 1,
        };
      });
      setHouseImages(orderedImages);
    }
    activeDrag.current = false;
  };

  const handleDragStart = (i: any, url: string) => {
    activeDrag.current = true;
    dragHouse.current = i;
    setImageOnDrag(url);
  };

  const handleCategoryDrag = (categoryId: any, imageIndex: any) => {
    if (imageOnDrag.length > 1) {
      const newcategoriesCollection = categoriesCollection?.map((item: any, index: any) => {
        if (item.id == categoryId && imageIndex == index) {
          return {
            ...item,
            url: imageOnDrag,
          };
        } else {
          return item;
        }
      });
      setcategoriesCollection(newcategoriesCollection);
    }
    setImageOnDrag('');
    activeDrag.current = false;
  };

  const handleSubmit = (e: any) => {
    const buttonName = e?.nativeEvent?.submitter?.name;

    const getStatusValue = () => {
      if (buttonName === 'inactive') {
        return 4;
      } else if (buttonName === 'save') {
        return 3;
      } else if (buttonName === 'publish') {
        return 1;
      }
    };

    const values = {
      id: Number(houseId),
      status: getStatusValue(),
      name: formData?.name,
      condominium_id: selectedCondominium,
      description: formData?.description,
      descriptionPT: formData?.descriptionPT,
      descriptionES: formData?.descriptionES,
      houseCategories: categoriesCollection,
      pictures: houseImages,
    };
    e.preventDefault();
    updateHouses(values);
  };

  useEffect(() => {
    getCategories();
    getCondominium();
    if (houseId) {
      getHouseInformation(houseId);
    }
  }, [houseId]);

  useEffect(() => {
    if (initialCondominium) {
      setInitialCondominiumSelect();
    }
  }, [initialCondominium, condominium]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('ADMINISTRATIVE.HOUSES')} />
      <form onSubmit={handleSubmit} id="save-form" style={{ width: '100%' }}>
        <FormControl style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <FormLabel>{t('ADMINISTRATIVE.NAME_INPUT')}</FormLabel>
              <TextField
                name="name"
                variant="outlined"
                margin="normal"
                value={formData.name}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormLabel>Categoria</FormLabel>
              <TextField
                select
                name="categorie"
                id="simple-select"
                value={selectedCategorie}
                onChange={handleCategoryChange}
                fullWidth
                style={{ marginTop: '16px' }}
              >
                {categories?.map((c) => (
                  <MenuItem value={c?.id}>{c?.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormLabel>Condominio</FormLabel>
              <TextField
                select
                name="condominium"
                id="simple-select"
                value={selectedCondominium}
                onChange={handleCondominiumChange}
                fullWidth
                style={{ marginTop: '16px' }}
              >
                {condominium?.map((c) => (
                  <MenuItem value={c?.id}>{c?.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}></Grid>
            <Box sx={{ flexGrow: 1, marginLeft: 2 }}>
              <Grid
                container
                spacing={2}
                sx={{
                  columnGap: {
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                  rowGap: {
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 2,
                  },
                }}
              >
                {categoriesCollection?.map((categ: any, index) => (
                  <Grid item xs={12} sm={4} md={3} lg={2} xl={2} key={index}>
                    <CheckBoxWithImage
                      name={categ?.name}
                      url={categ?.url}
                      onClose={() => handleClearCategorie(index)}
                      onDrop={() => handleCategoryDrag(categ?.id, index)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Grid item xs={12}>
              <Divider color="#DEDEDE" />
            </Grid>
            <Grid item xs={12}>
              <label className={styles.titleSelection}>Fotos da Galeria</label>
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <label>Selecionar:</label>
                  <label className={styles.titleSelection}>Todos</label>
                  <label>|</label>
                  <label className={styles.titleSelection}>Nenhuma</label>
                </div>
                {/*<div>Nenhuma Foto Selecionada</div>*/}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{
                columnGap: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
                rowGap: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2,
                },
              }}
            >
              {houseImages?.map((pic: any, index: any) => (
                <Grid item xs={12} sm={4} md={3} lg={2} xl={2} key={pic?.ordem}>
                  <CheckBoxWithImageOrdered
                    url={pic?.url}
                    id={pic?.ordem}
                    status={pic?.status}
                    onChange={() => handleCheckboxChange(pic?.ordem)}
                    onDragStart={() => handleDragStart(index, pic?.url)}
                    onDragEnter={() => (draggedOverHouse.current = index)}
                    onDrop={handleSort}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_ENGLISH')} *</FormLabel>
              <TextField
                multiline
                rows={4}
                name="description"
                variant="outlined"
                margin="normal"
                value={formData.description}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_SPANISH')} *</FormLabel>
              <TextField
                multiline
                rows={4}
                name="descriptionES"
                variant="outlined"
                margin="normal"
                value={formData.descriptionES}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_PORTUGUESE')} *</FormLabel>
              <TextField
                multiline
                rows={4}
                name="descriptionPT"
                variant="outlined"
                margin="normal"
                value={formData.descriptionPT}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '20px',
                  marginBottom: '40px',
                }}
              >
                <Buttons.NeutralWhite type="submit" variant="contained" className={styles.buttonWhite} name="inactive">
                  Inativar
                </Buttons.NeutralWhite>
                <Buttons.Primary type="submit" variant="contained" name="publish" className={styles.buttonWhite}>
                  {t('ADMINISTRATIVE.PUBLISH')}
                </Buttons.Primary>
                <Buttons.NeutralWhite type="submit" variant="contained" className={styles.buttonWhite} name="save">
                  {t('ADMINISTRATIVE.SAVE')}
                </Buttons.NeutralWhite>
              </div>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
};

export default HouseManagement;
