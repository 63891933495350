import { yupResolver } from '@hookform/resolvers/yup';
import Inputs from 'components/molecules/inputs';
import { useForm } from 'react-hook-form';
import { validateLoginForm } from './schema';
import { Buttons } from 'components/molecules/buttons';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { showErrorMessage } from 'utils/Toast';
import useAsync from 'hooks/useAsync.hook';
import usePublicNavigation from 'routes/navigation/public.navigation';
import useLogin from 'hooks/useLogin';
import { clearPath, getPath } from 'services/Login/redirect.service';
import { useNavigate } from 'react-router-dom';

type LoginFormType = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const { fetchLogin } = useLogin();
  const { navigateToForgotPassword, navigateToHomePage, navigateToRegister } = usePublicNavigation();
  const { t } = useTranslation();

  const form = useForm<LoginFormType>({
    resolver: yupResolver(validateLoginForm),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  const { loading, call: login } = useAsync(async (data: LoginFormType) => {
    try {
      await fetchLogin(data.email, data.password);
      const pathToRedirect = getPath();

      if (pathToRedirect) {
        navigate(pathToRedirect);
        clearPath();
        return;
      }
      navigateToHomePage();
    } catch (err) {
      showErrorMessage(t('PUBLIC.LOGIN.ERROR_MESSAGE'));
    }
  });

  return (
    <div className={styles.content}>
      <div className={styles.welcome}>{t('PUBLIC.LOGIN.WELCOME')}</div>
      <Inputs.Text form={form} label="E-mail" placeholder={t('PUBLIC.LOGIN.PLACEHOLDER.EMAIL')} name="email" />
      <Inputs.Text
        form={form}
        label={t('PUBLIC.LOGIN.PASSWORD')}
        placeholder={t('PUBLIC.LOGIN.PLACEHOLDER.PASSWORD')}
        type="password"
        name="password"
      />
      <div className={styles.smallInputs}>
        {/* <Inputs.CheckBox label={t('PUBLIC.LOGIN.REMEMBER_ME')} /> */}
        <div></div>
        <div onClick={navigateToForgotPassword} className={styles.forgotPassword}>
          {t('PUBLIC.LOGIN.FORGOT_PASSWORD')}
        </div>
      </div>
      <Buttons.Secondary loading={loading} fullWidth variant="contained" onClick={form.handleSubmit(login)}>
        {t('PUBLIC.LOGIN.BUTTON')}
      </Buttons.Secondary>
      <Buttons.Text fullWidth variant="text" onClick={navigateToRegister}>
        {t('PUBLIC.LOGIN.GO_TO_REGISTER')}
      </Buttons.Text>
    </div>
  );
};

export default Login;
