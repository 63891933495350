import FeatureRow from 'components/BackOffice/FeatureRow';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Agent from 'models/application/Agent/Agent.model';
import Inputs from 'components/molecules/inputs';
import { Buttons } from 'components/molecules/buttons';
import { useTranslation } from 'react-i18next';
import useAgent from 'hooks/useAgent';
import SelectRole from 'components/BackOffice/SelectRole';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAgentEdit } from './validate';
import { validateAgentRegister } from './validateRegister';
import getAddressByCep from 'services/CEP/getAddressByCep';
import { useRef, useState } from 'react';

const AgentEdit = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { editAgent, createAgent, loadingCreate, loadingEditAgent } = useAgent();

  const form = useForm<Agent>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: location.state?.agent ?? {},
    resolver: location.state?.agent ? yupResolver(validateAgentEdit) : yupResolver(validateAgentRegister),
  });

  const onSubmit = (data: Agent) => {
    if (location.state?.agent) {
      return editAgent(data);
    }
    createAgent(data);
  };

  const inputRef = useRef<any>(null);

  const [isDirty, setIsDirty] = useState<boolean>();

  const getCep = async (c: string) => {
    try {
      const response = await getAddressByCep(c);
      if (response?.logradouro) {
        form.setValue('address', response.logradouro + ', ' + response.bairro, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
        form.setValue('city', response.localidade, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });

        form.setValue('state', response.uf, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
        form.setValue('country', 'Brasil', {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
        setIsDirty(true);
      }
    } catch {
      form.setValue('address', '');
      form.setValue('city', '');
      form.setValue('country', '');
      form.setValue('state', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };

  return (
    <div>
      <FeatureRow title={t('BACKOFFICE.AGENT.PERSONAL_DATA')}>
        <div></div>
      </FeatureRow>
      <div>
        <div className={styles.content}>
          <div className={styles.inputs}>
            <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.CODE')} name="id" disabled />
            <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.NAME')} name="name" />
          </div>
          <div className={styles.inputs}>
            <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.EMAIL')} name="email" />
            <SelectRole form={form} />
          </div>
          <div className={styles.inputs}>
            <Inputs.Text form={form} required label={t('BACKOFFICE.AGENT.CELLPHONE')} name="cellphone" />
            <Inputs.Text form={form} type="password" required label={t('BACKOFFICE.AGENT.PASSWORD')} name="password" />
          </div>
          <div className={styles.inputs}>
            <Inputs.Text
              form={form}
              required
              label={t('BACKOFFICE.AGENT.NATIONAL_REGISTRATION')}
              name="nationalRegistration"
            />
          </div>
        </div>
        <div className={styles.hr}></div>
        <div className={styles.content}>
          <div className={styles.inputs}>
            <Inputs.Text
              form={form}
              required
              label={t('BACKOFFICE.AGENT.ZIPCODE')}
              name="zipcode"
              onBlur={handleBlur}
              inputLabelProps={{
                shrink: isDirty, // Disable label animation
              }}
            />
            <Inputs.Text
              form={form}
              required
              label={t('BACKOFFICE.AGENT.ADDRESS')}
              name="address"
              inputRef={inputRef}
            />
          </div>
          <div className={styles.inputs}>
            <Inputs.Text
              form={form}
              required
              label={t('BACKOFFICE.AGENT.COUNTRY')}
              name="country"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </div>
          <div className={styles.inputs}>
            <Inputs.Text
              form={form}
              required
              label={t('BACKOFFICE.AGENT.STATE')}
              name="state"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </div>
          <div className={styles.inputs}>
            <Inputs.Text
              form={form}
              required
              label={t('BACKOFFICE.AGENT.CITY')}
              name="city"
              inputLabelProps={{
                shrink: isDirty,
              }}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <Buttons.Orange
            loading={loadingEditAgent || loadingCreate}
            className={styles.buttonConfirm}
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('BACKOFFICE.AGENT.SAVE')}
          </Buttons.Orange>
        </div>
      </div>
    </div>
  );
};

export default AgentEdit;
