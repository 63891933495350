export default {
  BACKOFFICE: {
    MANAGEMENT: 'Gestión',
    SEARCH: 'Buscar por nombre o código',
    RESERVATIONS: 'Reservas',
    COMMISSIONS: 'Comisiones',
    AVAILABILITY: 'Consulta de Disponibilidad',
    REPORTS: 'Informe',
    REGISTER: 'Registrar usuario',
    INACTIVE_ACCOUNT: 'Inactivar cuenta',
    SAVE_CHANGES: 'Guardar cambios',
    OPERATOR: 'Operadora',
    REGISTER_TITLE: '¿Qué desea registrar?',
    TABLE: {
      MORE_INFORMATION: 'MÁS INFORMACIÓN',
      LINKED_AGENCIES: 'AGENCIAS VINCULADAS',
      LINKED_AGENTS: 'AGENTES VINCULADOS',
      MONTH_TOTAL_SALES: 'TOTAL DE VENTAS MES',
      MONTH_TOTAL_RESERVATIONS: 'TOTAL DE RESERVAS MES',
      CODE: 'CÓDIGO',
      NAME: 'NOMBRE',
      TYPE: 'TIPO',
      CONTACT: 'CONTACTO',
      ADMIN: 'Gestor',
      USER: 'Agente',
      STATUS: 'STATUS',
      ACTIVE: 'ACTIVO',
      WAITING_ACCESS: 'SOLICITANDO ACCESO',
      OPERATOR: 'OPERADORA',
      RESERVATION: 'RESERVA',
      CLIENT_NAME: 'NOMBRE DEL CLIENTE',
      HOUSE: 'CASA',
      SOLD_BY: 'VENDIDO POR',
      AGENCIES: 'AGENCIAS',
      AGENTS: 'AGENTES',
      AGENCY: 'AGENCIA',
      VALUE: 'VALOR',
      DAILY_RATES: 'TARIFAS DIARIAS',
      CHANGE: 'CAMBIAR',
      PRE_BOOKING: 'PRE-RESERVA',
      CONFIRMED: 'CONFIRMADA',
      CANCELED: 'CANCELADA',
    },
    AGENT: {
      PERSONAL_DATA: 'Datos personales',
      CODE: 'Código',
      NAME: 'Nombre',
      EMAIL: 'Correo electrónico',
      ROLE: 'Rol',
      CELLPHONE: 'Teléfono móvil',
      NATIONAL_REGISTRATION: 'Documento',
      ZIPCODE: 'Código Postal',
      ADDRESS: 'Dirección',
      COUNTRY: 'País',
      STATE: 'Estado',
      CITY: 'Ciudad',
      SAVE: 'Guardar',
      PASSWORD: 'Contraseña',
      FEEDBACK: {
        EDIT_SUCCESS: 'Agente editado exitosamente',
        CREATE_SUCCESS: 'Agente creado exitosamente',
      },
    },
    AGENCY: {
      ID: 'ID',
      NAME: 'Nombre',
      EMAIL: 'Correo electrónico',
      CADASTUR: 'CADASTUR',
      PRIMARY_CONTACT: 'Contacto Principal',
      URL_SITE: 'Sitio URL',
      DOCUMENT: 'Documento',
      PROFIT_PERCENTAGE: 'Porcentaje de Ganancia',
    },
    FEEDBACK: {
      EDIT_SUCCESS: 'Agencia editada exitosamente',
    },
    RESERVATIONSCREEN: {
      COMPLETED: 'completadas',
      CONFIRMED: 'confirmadas',
      CANCELED: 'canceladas',
      SEARCH_BY_RESERVATION_ID: 'Buscar por código de reserva',
      RESERVATIONID: 'ID de Reserva',
      CUSTOMERNAME: 'Nombre del Cliente',
      PROPERTY: 'Propiedad',
      AGENT: 'Agente',
      AGENCY: 'Agencia',
      DOLLARAMOUNT: 'Valor en Dólares',
      REALAMOUNT: 'Valor en Reales',
      NIGHTS: 'Noches',
      STATUS: 'Estado',
      DOWNLOAD: 'Descargar Informes',
    },
    RESERVATION_DETAIL: {
      RESERVATION_SUMMARY: 'Resumen de la Reserva',
      SUMMARY: 'Resumen',
      VALUES: 'Valores',
      HISTORY: 'Historial',
      DATES: 'Fechas',
      GUESTS: 'Huéspedes',
      DAILY_RATE: 'Tarifa Diaria',
      NAME: 'Nombre',
      CONTACT: 'Contacto',
      ADULTS: 'Adultos',
      ADDRESS: 'Dirección',
      HOUSE_ID: 'ID de la Casa',
      CREATE_AT: 'Creado en',
      ACCOMMODATION_ADDRESS: 'Dirección de la Hospedaje',
      SHOW_ON_MAP: 'Mostrar en el mapa',
      DETAILS: 'Detalles',
      PAYMENT_INFORMATION: 'Información de Pago',
      AMOUNTS: 'Montos',
      CLEANING_FEE: 'Tarifa de Limpieza',
      GOWEASY_SERVICE_FEE: 'Tarifa de Servicio GoWeasy',
      TAXES: 'Impuestos',
      COMMISSION: 'Comisión',
      PAYMENT_INFORMATION_TEXT: `Si el Anfitrión cancela una reserva antes del check-in,
      el huésped recibirá un reembolso completo automáticamente.
      Si el Anfitrión cancela una reserva hasta 30 días antes del check-in,
      también ayudaremos al huésped a encontrar un lugar similar,
      dependiendo de la disponibilidad en el mismo rango de precios.`,
      PAYMENT_LINK: 'Link de Pago',
      TIME: 'HORARIO',
      RESERVATION: 'RESERVA',
      CLIENT_NAME: 'NOMBRE DEL CLIENTE',
      PROPERTY: 'PROPIEDAD',
      AGENCY: 'AGENCIA',
      CATEGORY: 'CATEGORÍA',
      RESPONSIBLE: 'RESPONSABLE',
      DESCRIPTION: 'DESCRIPCIÓN',
      PENDING: 'PENDIENTE',
      CONFIRMED: 'CONFIRMADA',
      CANCELED: 'CANCELADA',
    },
    COMMISSIONS_TABLE: {
      COMMISSION_QUERY: 'Consulta de comisiones',
      TOTAL_MONTHLY_COMMISSIONS: 'Total de comisiones del mes',
      SALES_CANCELED_IN_THE_MONTH: 'Ventas canceladas en el mes',
      SEARCH_BY_RESERVATION_CODE: 'Búsqueda por código de reserva',
      PENDING: 'PENDIENTE',
      CANCELED: 'CANCELADA',
      PAID: 'PAGADA',
      BOOKING_COMPLETED: 'Reserva Realizada',
      PAYMENT_PENDING: 'Pago Pendiente',
      BOOKING_CANCELED: 'Reserva Cancelada',
      PAYMENT_COMPLETED: 'Pagado',
      DATE: 'FECHA',
      RESERVATION: 'RESERVA',
      PROPERTY: 'PROPIEDAD',
      SOLD_BY: 'VENDIDO POR',
      AGENCY: 'AGENCIA',
      SALE_VALUE: 'VALOR DE VENTA',
      COMMISSION: 'COMISIÓN',
      STATUS: 'STATUS',
      PAYMENT_HAS_BEEN_COMPLETED: 'Pago Realizado',
      REPORTS: {
        DATE: 'Fecha',
        RESERVACODE: 'Código de Reserva',
        HOUSEID: 'ID de Casa',
        AGENTE: 'Agente',
        AGENCY: 'Agencia',
        VALUEREAL: 'Valor en Reales',
        VALUEDOLLAR: 'Valor en Dólares',
        COMISSAOREAL: 'Comisión en Reales',
        COMISSAODOLLAR: 'Comisión en Dólares',
        COMISSAOPORCENTAGEM: 'Porcentaje de Comisión',
        STATUS: 'Estado',
        AGENCY_PAYMENT: 'Pago a la agencia',
      },
    },
  },
};
