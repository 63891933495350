class AmericanAgent {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  password: string;
  confirmPassword?: string;

  constructor(agent: AmericanAgent) {
    this.firstName = agent.firstName;
    this.lastName = agent.lastName;
    this.email = agent.email;
    this.phone = agent.phone;
    this.position = agent.position;
    this.password = agent.password;
  }
}

export default AmericanAgent;
