/* eslint-disable simple-import-sort/imports */
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

type IUseQueryStringResponse<T> = [query: T, setQuery: (params: T) => void, resetQuery: () => void];

export const useQueryString = <T>(defaultValue?: T): IUseQueryStringResponse<T> => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(
    () =>
      // @ts-ignore
      qs.parse(search, {
        ignoreQueryPrefix: true,
      }) as T,
    [search],
  );

  const setQuery = useCallback(
    (data: T) => {
      const currentyQuery = Object.assign({}, query, data);
      navigate({ pathname, search: qs.stringify(currentyQuery) });
    },
    [search],
  );

  const resetQuery = useCallback(() => {
    navigate({ pathname, search: qs.stringify(defaultValue) });
  }, [search]);

  useEffect(() => {
    if (defaultValue) {
      setQuery(defaultValue);
    }
  }, []);

  return [query, setQuery, resetQuery];
};
