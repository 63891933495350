import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AccountCircleIcon } from 'assets';
import { Box, Dialog, DialogContent, FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';

import { clearToken, clearUser, getUser, isLogged } from 'services/Login/user.service';

import useBackOfficeNavigation from 'routes/navigation/backOffice.navigation';

import styles from './styles.module.scss';
import usePublicNavigation from 'routes/navigation/public.navigation';
import { Buttons } from 'components/molecules/buttons';
import { useNavigate } from 'react-router-dom';
import useAsync from 'hooks/useAsync.hook';
import getPmList from 'services/HouseListAdm/getPmList.service';

const UserOptions = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [pmList, setPmList] = useState<any[]>([]);
  const [selectedPm, setSelectedPm] = useState<number>(0);
  const { navigateToLogin } = usePublicNavigation();
  const { navigateToManagement, navigateToAgency } = useBackOfficeNavigation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const user = useMemo(() => getUser(), []);

  const { call: getPm } = useAsync(async () => {
    try {
      const response = await getPmList();
      setPmList(response);
    } catch (err: any) {
      throw new Error(err);
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    navigateToAgency(user.agency.id);
  };

  const handleManagementNavigation = () => {
    if (user.isOperator()) navigate(`/admin/linked-agencies/${user.operadoraId}`);
    if (user.isAdmin()) navigateToManagement();
  };

  const handleAdministrative = () => {
    //navigateToListAgency();
    navigate('/admin/operators-list');
  };

  const handleHouseConfigurationNavigation = () => {
    if (selectedPm > 0) {
      setIsDialogOpen(false);
      navigate(`/admin/house-list/${selectedPm}`);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePropertyModal = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSelect = (e: any) => {
    setSelectedPm(e?.target?.value);
  };

  const handleLogout = () => {
    clearToken();
    clearUser();
    handleClose();
    navigateToLogin();
  };

  const handlePagtour = () => {
    window.location.href = 'https://pagtur.com.br/Default';
  };

  const handleAcademy = () => {
    navigate(`/academy`);
  };

  useEffect(() => {
    getPm();
  }, []);

  return (
    <div className={styles.containerLogin}>
      {isLogged() && (user.isAdmin() || user.isOperator()) ? (
        <>
          <div
            className={styles.containerLogin}
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <img src={AccountCircleIcon} alt="Account Circle Icon" />
            <div>{user.name}</div>
          </div>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleAcademy}>Academy</MenuItem>
            <MenuItem onClick={handleManagementNavigation}>{t('MENU.MANAGEMENT')}</MenuItem>
            <MenuItem onClick={handleEdit}>{t('MENU.CHANGE_REGISTER')}</MenuItem>
            <MenuItem onClick={handleLogout}>{t('MENU.LOGOUT')}</MenuItem>
          </Menu>
        </>
      ) : null}
      {isLogged() && user.isGoWeasy() ? (
        <>
          <div
            className={styles.containerLogin}
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <img src={AccountCircleIcon} alt="Account Circle Icon" />
            <div>{user.name}</div>
          </div>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleAcademy}>Academy</MenuItem>
            <MenuItem onClick={handleAdministrative}>{t('MENU.ADMINISTRATIVE')}</MenuItem>
            <MenuItem onClick={handlePropertyModal}>{t('ADMINISTRATIVE.HOUSE_CONFIGURATION')}</MenuItem>
            <MenuItem onClick={handlePagtour}>Pagtur</MenuItem>
            <MenuItem onClick={handleLogout}>{t('MENU.LOGOUT')}</MenuItem>
          </Menu>
          <Dialog
            fullWidth
            open={isDialogOpen}
            onClose={handleCloseDialog}
            maxWidth={'sm'}
            sx={{
              backdropFilter: 'blur(2px)',
            }}
          >
            <DialogContent style={{ overflow: 'hidden' }}>
              <Box className={styles.dialogContainer}>
                <h2 className={styles.title}>Selecione uma Property Management:</h2>
              </Box>
              <Box className={styles.selectContainer}>
                <FormControl fullWidth>
                  <InputLabel>Property</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={selectedPm}
                    label="Categoria"
                    onChange={handleSelect}
                  >
                    {/*categories.map((c) => (
                      <MenuItem value={c.categoryValue}>{c.categoryName}</MenuItem>
                    ))*/}
                    {pmList.map((p) => (
                      <MenuItem value={p?.id} key={p?.id}>
                        {p?.pmName} - {p?.totalHouses} Imóveis
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <div className={styles.buttonContainer}>
                <Buttons.Primary onClick={handleHouseConfigurationNavigation} fullWidth>
                  Próximo
                </Buttons.Primary>
              </div>
            </DialogContent>
          </Dialog>
        </>
      ) : null}
    </div>
  );
};

export default UserOptions;
