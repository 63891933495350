class AgencyAndUserEN {
  //agency
  name: string;
  email: string;
  cellphone: string;
  association: string;
  associationCode: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  state: string;

  //user
  firstName: string;
  lastName: string;
  emailUser: string;
  phone: string;
  position: string;
  password: string;
  confirmPassword?: string;

  constructor(agencyAndUser: AgencyAndUserEN) {
    this.name = agencyAndUser.name;
    this.email = agencyAndUser.email;
    this.cellphone = agencyAndUser.cellphone;
    this.association = agencyAndUser.association;
    this.associationCode = agencyAndUser.associationCode;
    this.address = agencyAndUser.address;
    this.city = agencyAndUser.city;
    this.zipCode = agencyAndUser.zipCode;
    this.country = agencyAndUser.country;
    this.state = agencyAndUser.state;

    this.firstName = agencyAndUser.firstName;
    this.lastName = agencyAndUser.lastName;
    this.emailUser = agencyAndUser.emailUser;
    this.phone = agencyAndUser.phone;
    this.position = agencyAndUser.position;
    this.password = agencyAndUser.password;
  }
}

export default AgencyAndUserEN;
