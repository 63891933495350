import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import TextField, { TextFieldVariants } from '@mui/material/TextField';

import { IInputProps } from '../defaultSettings';

type TCredit = 'validate' | 'code' | 'credit';

export interface ITextProps<T extends FieldValues = FieldValues> extends IInputProps<T> {
  defaultValue?: string | number | readonly string[];
  type?: React.HTMLInputTypeAttribute;
  variant?: TextFieldVariants;
  disableMaskOnRequest?: boolean;
  mask: TCredit;
}

const CardNumber = <T extends FieldValues>(props: ITextProps<T>): React.ReactElement<T> => {
  const { name, form, defaultValue, variant, disableMaskOnRequest = true, mask, ...rest } = props;
  const { formState, register, watch, setValue } = form;
  const currentValue = watch(name) as string | null;

  const { error } = useMemo(() => {
    return form.getFieldState(name);
  }, [formState.errors]);

  const handleNumberCredit = () => {
    return currentValue?.charAt(0) === '3' ? '9999 999999 99999' : '9999 9999 9999 9999';
  };

  const inputMask: { [key in TCredit]: string | Array<string | RegExp> } = {
    code: '999',
    validate: '99/9999',
    credit: handleNumberCredit(),
  };

  const onChangeMask = (value: string) => {
    return setValue(name, value as any);
  };

  return (
    <div style={{ width: '100%' }}>
      <ReactInputMask
        mask={inputMask[mask]}
        {...register(name)}
        placeholder={mask}
        onChange={(event) => {
          register(name).onChange(event);
          if (disableMaskOnRequest) {
            onChangeMask(event.target.value.replace(/\D+/g, ''));
          }
        }}
      >
        {/*@ts-ignore */}
        {() => (
          <TextField
            defaultValue={defaultValue}
            required
            variant={variant}
            {...rest}
            style={{ width: '100%', margin: 0 }}
            sx={{
              label: { color: '#6F6F6F', borderColor: '#6F6F6F', fontWeight: 300, borderRadius: '6px' },
            }}
            InputProps={
              mask === 'credit'
                ? {
                    endAdornment: <div>Visa</div>,
                  }
                : {}
            }
            helperText={error?.message as string}
            margin="normal"
            type="text"
            name={register(name).name}
            ref={register(name).ref}
          />
        )}
      </ReactInputMask>
    </div>
  );
};

export default CardNumber;
