import {
  AgenciesActiveIcon,
  AgentsActiveIcon,
  DoneDeal,
  HouseGreen,
  OperatorsActiveIcon,
  ReservationCancelIcon,
} from 'assets';
import FeatureRow from 'components/BackOffice/FeatureRow';
import StatsSection from 'components/StatsSection';
import useAsync from 'hooks/useAsync.hook';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
import getHeaderDetails from 'services/operators/getHeaderDetails';

const HeaderInformations: React.FC<any> = ({ title }) => {
  // const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  const [infos, setInfos] = useState<any>({});
  const statsOperatorsScreen = [
    {
      name: 'Operadoras',
      status: 'Ativas',
      reservationNumbers: infos?.operadorasAtivas,
      icon: AgenciesActiveIcon,
    },
    {
      name: 'Agencias',
      status: 'Ativas',
      reservationNumbers: infos?.agenciasAtivas,
      icon: AgentsActiveIcon,
    },
    {
      name: 'Agentes',
      status: 'Ativos',
      reservationNumbers: infos?.agentesAtivas,
      icon: OperatorsActiveIcon,
    },
    {
      name: 'Reservas',
      status: 'Concluidas',
      reservationNumbers: infos?.reservarConcluidas,
      icon: HouseGreen,
    },
    {
      name: 'Reservas',
      status: 'Pendentes',
      reservationNumbers: infos?.reservasPendentes,
      icon: DoneDeal,
    },
    {
      name: 'Reservas',
      status: 'Canceladas',
      reservationNumbers: infos?.reservasCanceladas,
      icon: ReservationCancelIcon,
    },
  ];

  const stats = [
    {
      name: 'Reservas',
      status: 'Concluidas',
      reservationNumbers: infos?.reservarConcluidas,
      icon: HouseGreen,
    },
    {
      name: 'Reservas',
      status: 'Pendentes',
      reservationNumbers: infos?.reservasPendentes,
      icon: DoneDeal,
    },
    {
      name: 'Reservas',
      status: 'Canceladas',
      reservationNumbers: infos?.reservasCanceladas,
      icon: ReservationCancelIcon,
    },
  ];

  const { call: getInfos } = useAsync(async () => {
    try {
      const response = await getHeaderDetails();
      setInfos(response);
    } catch (err: any) {
      throw new Error(err);
    }
  }, []);

  useEffect(() => {
    getInfos();
  }, []);

  return (
    <FeatureRow title={title}>
      <div style={{ display: 'flex' }}>
        {pathname === '/admin/operators-list' &&
          statsOperatorsScreen.map((stat) => {
            return (
              <StatsSection
                name={stat.name}
                status={stat.status}
                reservationNumbers={stat.reservationNumbers}
                icon={stat.icon}
              />
            );
          })}
        {!(pathname === '/admin/operators-list') &&
          stats.map((stat) => {
            return (
              <StatsSection
                name={stat.name}
                status={stat.status}
                reservationNumbers={stat.reservationNumbers}
                icon={stat.icon}
              />
            );
          })}
      </div>
    </FeatureRow>
  );
};

export default HeaderInformations;
