import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import CondominiumResponse from 'models/contracts/response/Condominium.response';
import Condominium from 'models/application/Condominium.model';

const getCondominiums = async (checkIn: string, checkOut: string, city: string): Promise<Condominium[]> => {
  const { data }: IApi<CondominiumResponse[]> = await axiosInstances.base.instance.post(
    'service/v1/house/availability/city',
    {
      filters: {
        ...getDefaultFilters(),
        checkin: checkIn,
        checkout: checkOut,
      },
      city,
    },
  );

  return data.map(
    (condominium) =>
      new Condominium({
        name: condominium.name,
        image: condominium.urlImage,
        subtitle: condominium.subtitle,
        subtitlePort: condominium.subtitlePort,
        subtitleSpanish: condominium.subtitleSpanish,
        description: condominium.description,
        descriptionSpanish: condominium.descriptionSpanish,
        descriptionPort: condominium.descriptionPort,
        coordenate: {
          lat: condominium.latitude,
          long: condominium.longitude,
        },
      }),
  );
};

export default getCondominiums;

const getDefaultFilters = () => {
  return {
    nearDisney: true,
    thematic: true,
    amazingPools: true,
    townhomes: true,
    singleFamily: true,
    // apartment: true,
    codominium: true,
    villa: true,
    luxuryHouse: true,
    manor: true,
    petFriendly: true,
    forSeniors: true,
    integratedOffice: true,
    largeGroups: true,
    golfCourse: true,
    cinema: true,
    goWeasyChoise: true,
    idealForSeniors: true,
  };
};
