import FeatureRow from 'components/BackOffice/FeatureRow';
import { useParams } from 'react-router-dom';
import Agency from 'models/application/Agency/Agency.model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAgency from 'hooks/useAgency.hook';
import AgencyEditForm from './form';

const AgencyEdit = () => {
  const urlParams = useParams();
  const { t } = useTranslation();
  const { editAgency, getAgency, loadingEdit } = useAgency();
  const [agency, setAgency] = useState();

  const agencyId = urlParams.agencyId;

  const onSubmit = async (data: Agency) => {
    await editAgency(data);
    loadAgency();
  };

  const loadAgency = async () => {
    if (agencyId) {
      const newAgency = await getAgency(agencyId);
      setAgency(newAgency);
    }
  };

  useEffect(() => {
    loadAgency();
  }, []);

  return (
    <div>
      <FeatureRow title={t('BACKOFFICE.AGENCY.TITLE')}>
        <div></div>
      </FeatureRow>
      {agency ? <AgencyEditForm agency={agency} onSubmit={onSubmit} loading={loadingEdit} /> : null}
    </div>
  );
};

export default AgencyEdit;
