import styled from 'styled-components';

export const BigTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 32px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
`;

export const Text = styled.div`
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
`;
