import { isValidCNPJ, isValidCPF, isValidPhoneNumber } from 'utils/validator';
import Yup from 'utils/yup';

export const validateUserAndAgency = Yup.object({
  //user
  agentName: Yup.string().required(),
  agentCpf: Yup.string()
    .required()
    .test('is-valid-cnpj', 'CPF inválido', (value: any) => isValidCPF(value)),
  agentEmail: Yup.string().email().required(),
  cep: Yup.string().required(),
  number: Yup.number().required(),
  password: Yup.string().required(),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),

  // Agency
  agencyName: Yup.string().required(),
  agencyEmail: Yup.string().email().required(),
  agencyCnpj: Yup.string().test('is-valid-cnpj', 'CNPJ inválido', (value) => isValidCNPJ(value)),
  agencyCity: Yup.string().required(),
  agencyAddress: Yup.string().required(),
  agencyCountry: Yup.string().required(),
  agencyCellphone: Yup.number()
    .required()
    .test('is-valid-phone', 'Celular inválido', (value) => isValidPhoneNumber(value)),
});
