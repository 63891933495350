import LoadingButton from '@mui/lab/LoadingButton';
import { CALL_TO_ACTION, NEUTRAL_WHITE, PRIMARY_BLUE_COBALT, PRIMARY_BLUE_FRANCE } from 'styles/colors';
import Button from '@mui/material/Button';

export const Buttons = {
  Default: ({ children, loading, backgroundColor = PRIMARY_BLUE_COBALT, color = NEUTRAL_WHITE, ...props }: any) => (
    <LoadingButton
      style={{
        height: 48,
        background: backgroundColor,
        color: color,
        textTransform: 'initial',
        fontWeight: 600,
      }}
      loading={loading}
      {...props}
    >
      {children}
    </LoadingButton>
  ),
  Primary: ({ children, ...props }: any) => (
    <Button
      style={{
        height: 48,
        background: PRIMARY_BLUE_COBALT,
        color: NEUTRAL_WHITE,
        textTransform: 'initial',
        fontWeight: 600,
      }}
      {...props}
    >
      {children}
    </Button>
  ),
  Secondary: ({ children, ...props }: any) => (
    <Button
      style={{
        height: 48,
        background: PRIMARY_BLUE_FRANCE,
        color: NEUTRAL_WHITE,
        textTransform: 'initial',
        fontWeight: 600,
      }}
      {...props}
    >
      {children}
    </Button>
  ),
  NeutralWhite: ({ children, ...props }: any) => (
    <Button
      style={{
        height: 48,
        background: NEUTRAL_WHITE,
        color: PRIMARY_BLUE_COBALT,
        textTransform: 'initial',
        fontWeight: 600,
        width: '100%',
      }}
      {...props}
    >
      {children}
    </Button>
  ),
  Text: ({ children, ...props }: any) => (
    <Button
      style={{
        color: PRIMARY_BLUE_COBALT,
        textTransform: 'initial',
      }}
      {...props}
    >
      {children}
    </Button>
  ),
  Orange: ({ children, ...props }: any) => (
    <LoadingButton
      loadingPosition="end"
      style={{
        backgroundColor: CALL_TO_ACTION,
        textTransform: 'initial',
        color: NEUTRAL_WHITE,
        padding: '12px 48px',
      }}
      {...props}
    >
      {children}
    </LoadingButton>
  ),
};
