import FeatureRow from 'components/BackOffice/FeatureRow';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAgency from 'hooks/useAgency.hook';
import { Card } from '@mui/material';
import AgentTable from 'components/BackOffice/AgentTable';
import MoonLoader from 'react-spinners/MoonLoader';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';

const AgencyDetails = () => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const { getAgency, loadingEdit } = useAgency();

  const [agency, setAgency] = useState<any>();

  const agencyId = urlParams.id;

  const loadAgency = async () => {
    if (agencyId) {
      const newAgency = await getAgency(agencyId);
      setAgency(newAgency);
    }
  };

  useEffect(() => {
    loadAgency();
  }, []);

  return (
    <div className={styles.screen}>
      {loadingEdit ? (
        <div className={styles.loading}>
          <MoonLoader color={PRIMARY_BLUE_COBALT} />
        </div>
      ) : (
        <>
          <div className={styles.info}>
            <FeatureRow title={t('MENU.ADMINISTRATIVE')}>
              <div></div>
            </FeatureRow>
          </div>
          <div className={styles.content}>
            {agency && (
              <Card className={styles.card}>
                <div className={styles.left}>
                  <div>
                    <h5>ID</h5>
                    {agency?.id}
                  </div>
                  <div>
                    <h5>{t('ADMINISTRATIVE.MAIN.NAME')}</h5>
                    {agency?.name}
                  </div>
                  <div>
                    <h5>CNPJ/Number State</h5>
                    {agency?.cnpj || agency?.numberState}
                  </div>
                  <div>
                    <h5>{t('ADMINISTRATIVE.MAIN.CONTACT')}</h5>
                    {agency?.primaryContact}
                  </div>
                </div>
                <div className={styles.right}>
                  <div>
                    <h5>E-mail</h5>
                    {agency?.email ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                  </div>
                  <div>
                    <h5>{t('BACKOFFICE.AGENT.CITY')}</h5>
                    {agency?.city ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                  </div>
                  <div>
                    <h5>{t('BACKOFFICE.AGENT.COUNTRY')}</h5>
                    {agency?.country ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                  </div>
                  <div>
                    <h5>{t('BACKOFFICE.AGENT.ADDRESS')}</h5>
                    {agency?.address ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                  </div>
                </div>
                <div className={styles.right}>
                  <div>
                    <h5>{t('ADMINISTRATIVE.MAIN.PROFIT_PERCENTAGE')}</h5>
                    {agency?.profitPercentage}
                  </div>
                  <div>
                    <h5>{t('ADMINISTRATIVE.MAIN.ACTIVE')}</h5>
                    {agency?.active ? t('ADMINISTRATIVE.MAIN.YES') : t('ADMINISTRATIVE.MAIN.NO')}
                  </div>
                  <div>
                    <h5>Cadastur</h5>
                    {agency?.cadastur ?? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION')}
                  </div>
                  <div>
                    <h5>{t('ADMINISTRATIVE.MAIN.SITE')}</h5>
                    {agency?.urlSite?.length < 2 ? t('ADMINISTRATIVE.MAIN.NOT_INFORMATION') : agency?.urlSite}
                  </div>
                </div>
                <div className={styles.right}>
                  <div>
                    <h5>{t('ADMINISTRATIVE.MAIN.IDIOM')}</h5>
                    {agency?.language}
                  </div>
                </div>
              </Card>
            )}
          </div>
          <div className={styles.agents}>{t('ADMINISTRATIVE.MAIN.AGENTS')}</div>
          <AgentTable agencyId={agencyId} noEdit />
        </>
      )}
    </div>
  );
};

export default AgencyDetails;
