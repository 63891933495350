export const parseToCurrency = (value: number) => {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};
//Input: Mon Aug 28 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)
//Output: 28/08/2023
export const formatDate = (date: any) => {
  const data = new Date(date);

  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0'); // Lembre-se que os monthes são baseados em zero, então é necessário adicionar 1.
  const year = data.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

//Input: Mon Aug 28 2023 00:00:00 GMT-0300 (Horário Padrão de Brasília)
//Output: 28-08-2023
export const formatDateToBackend = (date: any) => {
  const data = new Date(date);

  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const year = data.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

// Input R$ 1,322
// Output 1322
export const formatValueToNumber = (valor: string) => {
  const valorFormatado = valor.replace(/[^0-9.]/g, '');
  const valorSemPonto = valorFormatado.replace('.', '');

  const valorInteiro = parseInt(valorSemPonto, 10);

  return valorInteiro;
};

export const formatLowerCaseAndUpperFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
};

//Input 2023-10-17
//Ouput 17/10/2023

export const formatDateToUser = (value: string | any) => {
  if (value === null) {
    return '';
  }
  const partesDaData = value.split('-');
  return partesDaData[2] + '/' + partesDaData[1] + '/' + partesDaData[0];
};

//Input 17-10-2029
//Ouput 2029-17-10
export const formatDateFilter = (value: string | any) => {
  if (value === null) {
    return '';
  }
  const dateParts = value.split('-');
  return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
};

export const formatMoney = (value: number): string => {
  return value?.toLocaleString('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
