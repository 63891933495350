export default {
  CLERMONT:
    'The city is one of the fastest-growing cities in Central Florida and is strategically located just west of Orlando and north of Disney World. <br/><br/> Known for picturesque hills and pristine lakes. The quality of life in and around Clermont makes it a prime destination for those who look for a familiar place . Local stores offer a variety of shopping and dining options, ample sports facilities, and waterfront activities.',
  KISSIMMEE:
    'Kissimme is the largest city in the Osceola County just minutes from Disney Parks, Universal Resorts and an amazing array of hotels, resorts, and vacation rentals in the Vacation Home Capital of the World®. <br/> <br/> There’s more than parks, winding rivers, and sparkling lakes alongside outdoors experiences . Kissimmee offers an amazing array of hotels, resorts, and vacation rentals in the Vacation Home Capital of the World®.',
  DAVENPORT:
    'Located minutes from the Disney Parks and Orlando, Davenport is a beautiful city that’s the perfect stay for vacation. Nestled in Central Florida just off of I-4 and Highway 27, the city has easy access to nearby entertainment, activities, dining, in Orlando area. Also both Gulf Coast and Atlantic Shore beaches',
  ORLANDO:
    'Orlando, Florida, is renowned for its world-famous amusement parks, including Walt Disney World, Universal Studios, and SeaWorld. Beyond these thrilling attractions, the city, with its always blue skies and friendly tourist atmosphere, offers a rich tapestry of experiences. Explore the enchanting Leu Gardens, enjoy fine dining and shopping in Winter Park, and immerse yourself in the vibrant nightlife of downtown’s Church Street District. Orlando also boasts excellent infrastructure and a rich cultural scene with museums, theaters, and scenic outdoor adventures.',
  OPEN_MAP: 'View on map',
  CONDOMINIUMS: 'Condominiums',
  TOOLTIP: {
    ORLANDO:
      'Orlando, Florida, is renowned for its world-famous amusement parks, including Walt Disney World, Universal Studios, and SeaWorld.',
    CLERMONT: 'Peaceful hills & lakes near Disney! Charming community with shopping, dining, sports & waterfront fun.',
    KISSIMMEE:
      'Minutes to Disney & Universal! The vacation rental capital near Orlando with parks, nature, and adventure!',
    DAVENPORT:
      "Located minutes from the Disney Parks and Orlando, a beautiful city that's the perfect stay for a vacation.",
  },
};
