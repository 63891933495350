import styles from './styles.module.scss';

type Stats = {
  text: string;
  numbers: string;
  icon: string;
};

const StatsCommissions: React.FC<Stats> = ({ text, numbers, icon }) => {
  // Não é usado só em comissões
  return (
    <div className={styles.container}>
      <img src={icon} alt="icon" />
      <div className={styles.textContainer}>
        <label className={styles.number}>{numbers}</label>
        <div className={styles.namesContainer}>
          <label className={styles.textLabel}>{text}</label>
        </div>
      </div>
    </div>
  );
};

export default StatsCommissions;
