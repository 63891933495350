import listAgentByAgency from 'services/Agent/listAgentByAgency.service';
import { showErrorMessage, showSuccessMessage } from 'utils/Toast';
import useAsync from './useAsync.hook';
import { useMemo, useState } from 'react';
import { getUser } from 'services/Login/user.service';
import Agent from 'models/application/Agent/Agent.model';
import allowAgentByDocument from 'services/Agent/allowAgentByDocument';
import updateAgent from 'services/Agent/updateAgent.service';
import { useTranslation } from 'react-i18next';
import createAgent from 'services/Agent/createAgent.service';
import useBackOfficeNavigation from 'routes/navigation/backOffice.navigation';

const useAgent = () => {
  const { t } = useTranslation();
  const { navigateToManagement } = useBackOfficeNavigation();

  const [agents, setAgents] = useState<Agent[]>([]);
  const user = useMemo(() => getUser(), []);

  const { loading: loadingGetAgents, call: loadAgentsByAgency } = useAsync(async (id?: number) => {
    try {
      const agencyId = id ?? user.agency.id;
      const agentList = await listAgentByAgency(agencyId);
      setAgents(agentList);
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  const { loading: loadingAllowAgent, call: allowAgent } = useAsync(async (document: string) => {
    try {
      await allowAgentByDocument(document);
      loadAgentsByAgency();
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  const { loading: loadingEditAgent, call: editAgent } = useAsync(async (agent: Agent) => {
    try {
      await updateAgent(agent);
      showSuccessMessage(t('BACKOFFICE.AGENT.FEEDBACK.EDIT_SUCCESS'));
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  const { loading: loadingCreate, call: create } = useAsync(async (agent: Agent) => {
    try {
      await createAgent({
        name: agent.name,
        email: agent.email,
        phone: agent.cellphone,
        address: agent.address,
        zipcode: agent.zipcode,
        state: agent.state,
        country: agent.country,
        cpf: agent.nationalRegistration,
        city: agent.city,
        password: agent.password,
        role: agent.role || 'USER',
        agencyCode: user.agency.id,
      });
      showSuccessMessage(t('BACKOFFICE.AGENT.FEEDBACK.CREATE_SUCCESS'));
      navigateToManagement();
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  return {
    loadAgentsByAgency,
    loadingGetAgents,
    loadingAllowAgent,
    loadingEditAgent,
    loadingCreate,
    editAgent,
    agents,
    createAgent: create,
    allowAgent,
  };
};

export default useAgent;
