export default {
  AGENT: {
    CONFIRM: {
      AGENCY: 'Agência',
      NAME: 'Nome',
      NATIONAL_REGISTRATION: 'CPF',
      EMAIL: 'Email',
      CONTACT: 'Contato',
      BUTTON: 'Liberar acesso',
      CANCEL: 'Cancelar',
      TITLE: 'Confirme os dados do agente',
    },
  },
  LANGUAGE: {
    CHOOSE: {
      TITLE: 'Escolha o idioma desejado para continuar o registro e a categoria:',
      CATEGORY_INPUT: {
        LABEL: 'Categoria',
        PLACEHOLDER: 'Selecione a categoria',
      },
      CATEGORY: {
        AGENT: 'Agente de Viagem',
        TRAVEL_AGENCY: 'Agência de Viagens',
        PROPERTY_MANAGER: 'Gestor de Imóveis',
        FREELANCER: 'Freelancer',
        GOWEASY: 'GoWeasy',
        TRAVEL_OPERATOR: 'Operador de Viagens',
        AGENCY_OPERATOR: 'Operadora de Agências',
      },
      CONFIRM: 'Confirmar',
    },
  },
};
