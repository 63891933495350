import WESelectGuests from 'components/WESelectGuests';
import styles from './styles.module.scss';
import { Label } from 'components/atoms/Label';
import { useTranslation } from 'react-i18next';
import WEDatePicker from 'components/WEDatePicker';
import { useEffect, useMemo, useState } from 'react';
import { Buttons } from 'components/molecules/buttons';
import { IoIosSearch } from 'react-icons/io';
import { formatDateToBackend } from 'utils/format';
import { IGuests } from 'models/application/interfaces/Guests.interface';
import Booking from 'models/application/Booking.model';
import moment from 'moment';

type SearchHouseProps = {
  onSearch: (booking: Booking) => void;
  initialPeriod?: any[];
  buttonColor?: string;
};

const SearchHouse: React.FC<SearchHouseProps> = ({ onSearch, buttonColor, initialPeriod = [] }) => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState<any[]>([]);
  const [guests, setGuests] = useState<IGuests>({
    adults: 0,
    kids: 0,
    babies: 0,
    pets: 0,
    pcd: 0,
    elderly: 0,
    total: 0,
  });

  useEffect(() => {
    setPeriod([
      initialPeriod[0] ? moment(initialPeriod[0]).utc().format() : null,
      initialPeriod[1] ? moment(initialPeriod[1]).utc().format() : null,
    ]);
  }, [initialPeriod]);

  const disableButton = period.length < 2 || !period[0];
  const hasInitialValue = initialPeriod.length > 0;
  const minDate = useMemo(() => {
    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + 10);

    return currentDate;
  }, []);

  const changeGuests = (guestValues: IGuests) => {
    setGuests(guestValues);
  };

  const handlePeriod = (dates: Date[] | null[]) => {
    setPeriod(dates);
  };

  const search = () => {
    if (disableButton) return;

    const searchValues = new Booking({
      checkIn: formatDateToBackend(period[0]),
      checkOut: formatDateToBackend(period[1]),
      kids: guests.kids,
      adults: guests.adults,
      pets: guests.pets,
      pcd: guests.pcd,
      elderly: guests.elderly,
      babies: guests.babies,
    });

    onSearch(searchValues);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.destination}>
        <Label>{t('SEARCH.LABEL.DESTINATION')}</Label>
        <div className={styles.subLabel}>Orlando - Flórida</div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.datePicker}>
        <WEDatePicker
          minDate={minDate}
          value={period}
          onChangeValue={handlePeriod}
          placeholder={
            !hasInitialValue
              ? {
                  checkIn: t('SEARCH.PLACEHOLDER.CHECK_IN'),
                  checkOut: t('SEARCH.PLACEHOLDER.CHECK_OUT'),
                }
              : null
          }
        />
      </div>
      <div className={styles.divider}></div>
      <div className={styles.guests}>
        <WESelectGuests petsAllowed={true} limitGuests={9999} onChange={changeGuests} />
      </div>
      <Buttons.Default
        backgroundColor={buttonColor || '#FF7400'}
        className={styles.button}
        startIcon={<IoIosSearch size={18} />}
        onClick={search}
        disabled={guests.total === 0 || period.length < 2 || !period[0]}
      >
        {t('SEARCH.LABEL.BUTTON')}
      </Buttons.Default>
    </div>
  );
};
export default SearchHouse;
