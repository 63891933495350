import React from 'react';
import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import AmericanAgency from 'models/application/Agency/AmericanAgency.model';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { validateAgency } from './validateAgency';
import { yupResolver } from '@hookform/resolvers/yup';

type AgencyFormENProps = {
  onSubmit: (data: AmericanAgency) => void;
  agency?: AmericanAgency;
};

const AgencyFormEN: React.FC<AgencyFormENProps> = ({ onSubmit, agency }) => {
  const form = useForm<AmericanAgency>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agency,
    resolver: yupResolver(validateAgency),
  });

  const beforeSubmit = (data: AmericanAgency) => {
    onSubmit(new AmericanAgency(data));
  };

  return (
    <div className={styles.containerForm}>
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="Agency Name:" name="name" />
          <div className={styles.gridTwoColumns}>
            <Inputs.Text form={form} required label="Association:" name="association" />
            <Inputs.Text form={form} required label="Association #:" name="associationCode" />
          </div>
          <Inputs.Text form={form} required label="Address:" name="address" />
          <Inputs.Text form={form} required label="City:" name="city" />
          <div className={styles.gridTwoColumns}>
            <Inputs.Text form={form} required label="Country:" name="country" />
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask={'99999-9999'}
              required
              label="Postal/ ZIP Code:"
              name="zipCode"
              id="zipCode"
            />
          </div>
          <Inputs.Text form={form} required label="State:" name="state" />
          <Inputs.Mask
            whiteVersion={false}
            form={form}
            mask="(999) 999-9999"
            required
            label="Phone:"
            name="cellphone"
            id="cellphone"
          />
          <Inputs.Text form={form} required label="Contact email:" name="email" />
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Continue
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AgencyFormEN;
