class Operator {
  name: string;
  cnpj: string;
  address: string;
  city: string;
  country: string;
  cep?: string;

  constructor(operator: Operator) {
    this.name = operator.name;
    this.cnpj = operator.cnpj;
    this.address = operator.address;
    this.city = operator.city;
    this.country = operator.country;
    this.cep = operator.cep;
  }
}

export default Operator;
