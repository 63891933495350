import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
// TODO: Criar os tipos
const getReportsTableList = async (startDate: any, endDate: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(
    `/service/payment/list/payment?startDate=${startDate}&endDate=${endDate}`,
  );

  return data;
};

export default getReportsTableList;
