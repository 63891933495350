export default {
  FAQ: {
    TITLE: 'Estamos aqui para te ajudar.  Seu Guia de respostas rápidas',
    QUESTION_1: 'É fácil ser atendido quando mais preciso?',
    ANSWER_1:
      'Muito fácil. A GoWeasy nasceu em Orlando mas somos brasileiros e os fundadores vieram do turismo, então entendemos suas necessidades e vamos trabalhar juntos para dar todo o apoio necessário.',
    QUESTION_2: 'A GoWeasy tem atendimento durante a estadia de meu cliente?',
    ANSWER_2: 'Sim, temos atendimento 24 hrs em Orlando para você e seus clientes durante a estadia.',
    QUESTION_3: 'Qual o horário do Customer Service da GoWeasy para o profissional do turismo?',
    ANSWER_3:
      'De Segunda a sexta das 9 às 19hrs e aos sábados das 9 às 14 hrs. <br/><br/> Para qualquer emergência, temos o atendimento 24 hrs.',
    QUESTION_4: 'Como entro em contato com a GoWeasy?',
    ANSWER_4:
      'No horário comercial, via email hello@goweasy.com <br/><br/> Temos também o WhatssApp: (21) 99774-0133 <br/><br/> E nossos telefones de atendimento no Brasil e nos Estados Unidos',
    QUESTION_5: 'Como a GoWeasy garante a qualidade das casas?',
    ANSWER_5:
      'Todas as casas que vem para a Plataforma passam por um controle de qualidade.<br/><br/> A GoWeasy além de ser uma Plataforma de aluguel de temporada, também é uma produtora de conteúdo e para isto precisamos estar constantemente vistoriando as casas junto a nossos parceiros, para que atendam ao nosso padrão standard de listing da propriedade.',
  },
};
