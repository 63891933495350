import FiltersEnum from './enums/filters.enum';

export const calculateAveragePerDay = (values: number[]) =>
  values.reduce((acc, current) => acc + current, 0) / values.length;

export const getHouseListFilters = (filters: string[]) => {
  return {
    singleFamily: filters.includes(FiltersEnum.SINGLE_FAMILY),
    townhomes: filters.includes(FiltersEnum.TOWNHOMES),
    apartment: filters.includes(FiltersEnum.APARTMENT),
    codominium: filters.includes(FiltersEnum.CONDOMINIUM),
    villa: filters.includes(FiltersEnum.VILLA),
    luxuryHouse: filters.includes(FiltersEnum.LUXURY_HOUSE),
    manor: filters.includes(FiltersEnum.MANOR),
    petFriendly: filters.includes(FiltersEnum.PET_FRIENDLY),
    forSeniors: filters.includes(FiltersEnum.FOR_SENIORS),
    integratedOffice: filters.includes(FiltersEnum.INTEGRATED_OFFICE),
    largeGroups: filters.includes(FiltersEnum.LARGE_GROUPS),
    golfCourse: filters.includes(FiltersEnum.GOLF_COURSE),
    cinema: filters.includes(FiltersEnum.CINEMA),
    nearDisney: filters.includes(FiltersEnum.NEAR_DISNEY),
    internationalDrive: filters.includes(FiltersEnum.INTERNATIONAL_DRIVE),
    nearUniversal: filters.includes(FiltersEnum.NEAR_UNIVERSAL),
    thematic: filters.includes(FiltersEnum.THEMATIC),
    amazingPools: filters.includes(FiltersEnum.AMAZING_POOLS),
    goWeasyChoise: filters.includes(FiltersEnum.GO_WEASY_CHOICE),
    idealForSeniors: filters.includes(FiltersEnum.FOR_SENIORS),
    firstTimeOrlando: filters.includes(FiltersEnum.FIRST_TIME_ORLANDO),
  };
};

export const setOrigim = (origin: string) => {
  localStorage.setItem('origin', origin);
};

export const getOrigin = () => {
  return localStorage.getItem('origin');
};

export const saveFilters = (filters: string[]) => {
  sessionStorage.setItem('filters', JSON.stringify(filters));
};

export const getFiltersFromStorage = () => {
  return JSON.parse(sessionStorage.getItem('filters') ?? '[]');
};
