import { CircleGreen, CircleRed } from 'assets';
import FeatureRow from 'components/BackOffice/FeatureRow';
import useAsync from 'hooks/useAsync.hook';
import i18n from 'languages';
import { Language } from 'pages/Reservations/components/ReservationTable';
import { useEffect, useMemo, useState } from 'react';
import { getUser } from 'services/Login/user.service';
import CommissionsList from 'services/Management/getComissions.service';
import StatsCommissions from './components/StatsCommissions';
import { InputAdornment, TextField } from '@mui/material';
import { IoSearchSharp } from 'react-icons/io5';
import CommissionsTable from './components/CommissionTable';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RangeDatePicker from 'components/BackOffice/RangeDatePicker';
import { useLocation } from 'react-router-dom';
import Button from 'components/BackOffice/Button';
import { CSVLink } from 'react-csv';
import styles from './styles.module.scss';

const Commissions = () => {
  const today = moment().format('YYYY-MM-DD HH:mm:ss');
  const location = useLocation();
  const user = useMemo(() => getUser(), []);
  const [period, setPeriod] = useState(['01-01-2024', '12-12-2024']);
  //const period = ['01-01-2024', '12-12-2024'];
  const startDate = moment(period?.[0]).format('YYYY-MM-DD');
  const endDate = moment(period?.[1]).format('YYYY-MM-DD');
  const { t } = useTranslation();
  const initialLanguage = i18n.language as Language;
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const [commissions, setCommissions] = useState<any>([]);
  const [code, setCode] = useState<any>('');
  const commissionsDataTable = commissions?.comissoesList?.filter((r: any) =>
    String(r.reservaCode).toLowerCase().includes(code),
  );
  const [csvData, setCsvData] = useState<any>([]);

  const headers = [
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.DATE'), key: 'date' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.RESERVACODE'), key: 'reservaCode' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.HOUSEID'), key: 'houseId' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.AGENTE'), key: 'agente' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.AGENCY'), key: 'agencia' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.VALUEREAL'), key: 'valueReal' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.VALUEDOLLAR'), key: 'valueDollar' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.COMISSAOREAL'), key: 'comissaoReal' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.COMISSAODOLLAR'), key: 'comissaoDollar' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.COMISSAOPORCENTAGEM'), key: 'comissaoPorcentagem' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.STATUS'), key: 'status' },
    { label: t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.AGENCY_PAYMENT'), key: 'pagamentoAgenciaStatus' },
  ];

  const getReportsData = (responseData: any) => {
    const newData = responseData?.comissoesList?.map((item: any) => ({
      date: moment(item.date).format('YYYY-MM-DD HH:mm:ss'),
      reservaCode: item.reservaCode,
      houseId: item.houseId,
      agente: item.agente,
      agency: item.agency,
      valueReal: item.valueReal,
      valueDollar: item.valueDollar,
      comissaoReal: item.comissaoReal,
      comissaoDollar: item.comissaoDollar,
      comissaoPorcentagem: item.comissaoPorcentagem,
      status: item.status,
      pagamentoAgenciaStatus: item.pagamentoAgenciaStatus,
    }));
    return newData;
  };

  const { call: getComission } = useAsync(async (sDate: any, eDate: any) => {
    //setCommissionsDataTable(commissionsList?.comissoesList);
    if (user.role === 'ADMIN') {
      const response = await CommissionsList(user.agency.id, sDate, eDate);
      setCommissions(response);
      const reports = getReportsData(response);
      setCsvData(reports);
    } else if (user.role === 'GOWEASY') {
      const response = await CommissionsList(location?.state?.agencyId, sDate, eDate);
      setCommissions(response);
      const reports = getReportsData(response);
      setCsvData(reports);
    }
  }, []);

  const commissionStatsNumbers = {
    totalDollar: commissions?.comissaoTotalDollar,
    totalReal: commissions?.comissaoTotalReal,
    canceled: commissions?.reservasCanceladas,
  };

  const statsCommissionsMonth = {
    text: t('BACKOFFICE.COMMISSIONS_TABLE.TOTAL_MONTHLY_COMMISSIONS'),
    totalReal: String('R$' + ' ' + commissionStatsNumbers.totalReal?.toFixed(2)),
    totalDollar: String('$' + ' ' + commissionStatsNumbers.totalDollar?.toFixed(2)),
    icon: CircleGreen,
  };

  const statusComissionsCanceled = {
    text: t('BACKOFFICE.COMMISSIONS_TABLE.SALES_CANCELED_IN_THE_MONTH'),
    numbersCanceled: commissionStatsNumbers.canceled,
    icon: CircleRed,
  };

  const handleCodeChange = (event: any) => {
    setCode(event.target.value);
  };

  const handleGetComission = () => {
    getComission(startDate, endDate);
  };

  useEffect(() => {
    getComission(startDate, endDate);
  }, [period]);

  useEffect(() => {
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  /*useEffect(() => {
    setCommissionsDataTable(
      commissionsDataTable?.filter((r: any) => String(r.reservaCode).toLowerCase().includes(code)),
    );
  }, [code]);*/

  const handlePeriod = (date: any) => {
    setPeriod(date);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('BACKOFFICE.COMMISSIONS_TABLE.COMMISSION_QUERY')}>
        <div style={{ display: 'flex' }}>
          {(currentLanguage === 'pt-BR' || currentLanguage === 'pt-PT') && (
            <StatsCommissions
              numbers={statsCommissionsMonth.totalReal}
              text={statsCommissionsMonth.text}
              icon={statsCommissionsMonth.icon}
            />
          )}
          {(currentLanguage === 'en' || currentLanguage === 'es') && (
            <StatsCommissions
              numbers={statsCommissionsMonth.totalDollar}
              text={statsCommissionsMonth.text}
              icon={statsCommissionsMonth.icon}
            />
          )}
          <StatsCommissions
            numbers={statusComissionsCanceled.numbersCanceled}
            text={statusComissionsCanceled.text}
            icon={statusComissionsCanceled.icon}
          />
        </div>
      </FeatureRow>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '97%', alignItems: 'center' }}>
        <TextField
          label={t('BACKOFFICE.COMMISSIONS_TABLE.SEARCH_BY_RESERVATION_CODE')}
          variant="outlined"
          value={code}
          onChange={handleCodeChange}
          style={{ width: '30%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoSearchSharp size={24} />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: 'flex', gap: '25px', flexDirection: 'row', alignItems: 'center' }}>
          <Button className={styles.buttonReports}>
            <CSVLink data={csvData} headers={headers} filename={`comissions - ${today}.csv`}>
              {t('BACKOFFICE.RESERVATIONSCREEN.DOWNLOAD')}
            </CSVLink>
          </Button>
          {<RangeDatePicker cleanOff minDate={new Date('01-01-2020')} value={period} onChangeValue={handlePeriod} />}
        </div>
      </div>
      <CommissionsTable data={commissionsDataTable} updateComission={handleGetComission} />
    </div>
  );
};

export default Commissions;
