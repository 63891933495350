import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';

// TODO: Criar os tipos
const reservationDetails = async (id: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(`/service/v1/house/reservation/${id}`);

  return data;
};

export default reservationDetails;
