import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BoxCalculate = styled.div`
  display: flex;
  align-items: center;
`;

export const DropDown = styled.div`
  width: 320px;
  overflow: auto;
  padding: 10px 0px;

  @media (max-width: 768px) {
    width: 60vw;
  }
`;

export const MenuBtn = styled.div``;

export const TotalGuests = styled.div`
  color: ${(props) => (props.color ? props.color : '#6f6f6f')};
  font-size: 16px;
`;

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  color: #1c1c1c;
  font-weight: 700;
`;

export const SubTitle = styled.div`
  color: #6f6f6f;
  font-size: 14px;
  margin-top: 8px;
`;

export const Hr = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #f2f2f2;
  margin: 20px 0px;
`;

export const Total = styled.div`
  color: #a8a8a8;
  font-size: 18px;
  margin: 0px 24px;
`;

export const IconAction = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
