import { axiosInstances } from 'services/api';

const getCondominiumSelect = async (): Promise<any> => {
  const { data } = await axiosInstances.base.instance.get(`/service/v1/condominiums`);
  const newData = data.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
    };
  });
  return newData;
};

export default getCondominiumSelect;
