import React, { useMemo } from 'react';

import styles from './styles.module.scss';
import { TbPointFilled } from 'react-icons/tb';
import { ImageNotAvailable } from 'assets';
import { useTranslation } from 'react-i18next';
import currency from 'models/application/Currency.model';

const MAX_SPACES_TO_SHOW = 2;

type HouseCardProps = {
  image: string;
  pricePerNight?: number;
  address: string;
  name: string;
  id: number;
  guests: number;
  rooms: number;
  spaces: string[];
  onClickItem: (id: number) => void;
  staticCard?: boolean;
};

const HouseCard: React.FC<HouseCardProps> = ({
  image,
  id,
  name,
  address,
  pricePerNight,
  rooms,
  guests,
  spaces,
  staticCard,
  onClickItem,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClickItem(id);
  };

  const imageUrl = image.length > 0 ? image : ImageNotAvailable;

  const styleConfig = useMemo(() => {
    return {
      container: !staticCard ? styles.containerScale : null,
      visibility: staticCard ? styles.alwaysVisible : styles.hiddenContainer,
      imageHeight: staticCard ? '162px' : '192px',
    };
  }, [staticCard]);

  const styleAddress = useMemo(() => {
    return {
      width: staticCard ? '13vw' : '17vw',
    };
  }, [staticCard]);

  return (
    <div className={`${styles.container} ${styleConfig.container}`} onClick={handleClick}>
      <div className={styles.imageContainer}>
        <div className={styles.layer}></div>
        <h6>{name}</h6>
        <img
          className={styles.imageBackground}
          height={styleConfig.imageHeight}
          src={imageUrl}
          alt="House background"
        />
      </div>
      <div className={styleConfig.visibility}>
        <div className={styles.infos}>
          <div style={styleAddress}>
            <div className={styles.address}>{address}</div>
          </div>
          <div className={styles.itens}>
            <div>{t('HOUSE_CARD.ROOMS')}</div>
            <div>{rooms}</div>
            <TbPointFilled size={5} color="red" />
            <div>{t('HOUSE_CARD.GUESTS')}</div>
            <div>{guests}</div>
            {spaces.slice(0, MAX_SPACES_TO_SHOW).map((space, index) => (
              <div key={index} className={styles.flex}>
                <TbPointFilled size={5} color="red" />
                <div className={`${styles.space} ${index === 1 ? styles.lastSpace : undefined}`}>{space}</div>
              </div>
            ))}
          </div>
          <div className={styles.priceContainer}>
            <div>{currency.getCurrencySign()}</div>
            <div>{pricePerNight ?? '--'}</div>
            <div className={styles.perNight}>{t('HOUSE_CARD.PER_NIGHT')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseCard;
