import { axiosInstances } from 'services/api';
import { AgentRequest } from 'models/contracts/request/agent.request';

const createAgent = async (agent: AgentRequest): Promise<void> => {
  await axiosInstances.base.instance.post('/service/users/create', {
    ...agent,
    role: agent.role,
  });
};

export default createAgent;
