import React from 'react';
import { BaseProps } from './BaseProps';

const NearDisneySVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51.191" height="46.795" viewBox="0 0 51.191 46.795">
      <path
        id="mickey-mouse_8545387"
        d="M40.357,2a10.112,10.112,0,0,1,2.025,20.021A18.049,18.049,0,1,1,9.088,35.969a18.69,18.69,0,0,1,0-13.947A10.115,10.115,0,1,1,21.236,11.673a15.588,15.588,0,0,1,4.5-.675,14.5,14.5,0,0,1,4.5.675A10.141,10.141,0,0,1,40.357,2Z"
        transform="translate(-0.139 -1.15)"
        fill="none"
        stroke={color}
        stroke-width="1.7"
      />
    </svg>
  );
};

export default NearDisneySVG;
