import React from 'react';
import styles from './styles.module.scss';

type ButtonProps = {
  children: any;
  onClick?: () => void;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({ children, onClick, className }) => {
  return (
    <div className={`${styles.button} ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default Button;
