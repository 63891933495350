import { axiosInstances } from 'services/api';

const getHouseManageDetails = async (id: any): Promise<any> => {
  const { data } = await axiosInstances.base.instance.get(`/service/v1/house/${id}`);

  return {
    name: data?.name,
    houseCategories: data?.houseCategories,
    pictures: data?.pictures,
    description: data?.description,
    descriptionPT: data?.descriptionPT,
    descriptionES: data?.descriptionES,
    condominium: data?.condominium,
  };
};

export default getHouseManageDetails;
