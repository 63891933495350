class ListAgencyAdm {
  active: boolean;
  cadastur: string;
  cnpj: string;
  id: number;
  name: string;
  primaryContact: string;
  profitPercentage: any;
  constructor(listAgency: ListAgencyAdm) {
    this.active = listAgency.active;
    this.cadastur = listAgency.cadastur;
    this.cnpj = listAgency.cnpj;
    this.id = listAgency.id;
    this.name = listAgency.name;
    this.primaryContact = listAgency.primaryContact;
    this.profitPercentage = listAgency.profitPercentage;
  }
}

export default ListAgencyAdm;
