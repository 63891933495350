import { isValidPhoneNumber } from 'utils/validator';
import Yup from 'utils/yup';

export const validateAgency = Yup.object({
  name: Yup.string().required(),
  association: Yup.string().required(),
  associationCode: Yup.string().required(),
  email: Yup.string().email().required(),
  city: Yup.string().required(),
  address: Yup.string().required(),
  country: Yup.string().required(),
  zipCode: Yup.string().required(),
  state: Yup.string().required(),
  cellphone: Yup.number()
    .required()
    .test('is-valid-phone', 'Celular inválido', (value) => isValidPhoneNumber(value)),
});
