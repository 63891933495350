import { yupResolver } from '@hookform/resolvers/yup';
import Inputs from 'components/molecules/inputs';
import { useForm } from 'react-hook-form';
import { Buttons } from 'components/molecules/buttons';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { showErrorMessage, showSuccessMessage } from 'utils/Toast';
import useAsync from 'hooks/useAsync.hook';
import Yup from 'utils/yup';
import { useParams } from 'react-router-dom';
import { resetPassword } from 'services/Login/resetPassword.service';
import usePublicNavigation from 'routes/navigation/public.navigation';

type RecoverPasswordFormType = {
  password: string;
};

const RecoverPassword = () => {
  const urlParams = useParams();
  const { t } = useTranslation();
  const { navigateToLogin } = usePublicNavigation();

  const token = urlParams.token ?? '';

  const form = useForm<RecoverPasswordFormType>({
    resolver: yupResolver(
      Yup.object({
        password: Yup.string().required(),
      }),
    ),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  const { loading, call: callRecoverPassword } = useAsync(async (data: RecoverPasswordFormType) => {
    try {
      await resetPassword(data.password, token);
      showSuccessMessage(t('PUBLIC.RECOVER_PASSWORD.SUCCESS'));
      navigateToLogin();
    } catch (err: any) {
      showErrorMessage(err.message);
    }
  });

  return (
    <div className={styles.content}>
      <div className={styles.question}>{t('PUBLIC.RECOVER_PASSWORD.TITLE')}</div>
      <div className={styles.answer}>{t('PUBLIC.RECOVER_PASSWORD.SUBTITLE')}</div>
      <Inputs.Text type="password" form={form} label={t('PUBLIC.RECOVER_PASSWORD.PASSWORD')} name="password" />
      <Buttons.Secondary
        loading={loading}
        fullWidth
        variant="contained"
        onClick={form.handleSubmit(callRecoverPassword)}
      >
        {t('PUBLIC.RECOVER_PASSWORD.SEND_PASSWORD')}
      </Buttons.Secondary>
    </div>
  );
};

export default RecoverPassword;
