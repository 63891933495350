import { SubmitCheckoutResponse } from 'models/contracts/response/SubmitCheckoutSuccess.response';
import { axiosInstances } from 'services/api';

type Status = 'APPROVED' | 'CANCELED';

export const updateStatusReservation = async (idReserve: string, status: Status) => {
  await axiosInstances.base.instance.patch<SubmitCheckoutResponse>(
    `service/payment/status/update/${idReserve}?status=${status}`,
  );
};
