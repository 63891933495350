import styles from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateUserAndAgencyEN } from './validateUserAndAgencyEN';
import Inputs from 'components/molecules/inputs';
import { Grid } from '@mui/material';
import { Buttons } from 'components/molecules/buttons';
import AgencyAndUserEN from 'models/application/Agency/AgencyAndUserEN.model';

const AgencyRegisterFormEN: React.FC<any> = ({ handleSubmitForm, agencyValues }) => {
  const form = useForm<AgencyAndUserEN>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agencyValues,
    resolver: yupResolver(validateUserAndAgencyEN),
  });

  const beforeSubmit = (data: AgencyAndUserEN) => {
    handleSubmitForm(new AgencyAndUserEN(data));
  };

  return (
    <div>
      <h3>Link Agency</h3>
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Agency Name:" name="name" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Association:" name="association" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Association #:" name="associationCode" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask={'99999-9999'}
              required
              label="Postal/ ZIP Code:"
              name="zipCode"
              id="zipCode"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Address:" name="address" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="City:" name="city" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Country:" name="country" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="State:" name="state" />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="(999) 999-9999"
              required
              label="Phone:"
              name="cellphone"
              id="cellphone"
            />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Contact email:" name="email" />
          </Grid>
        </Grid>
      </div>
      <div className={styles.hr} />
      <h3>Agency Responsible Person's Information</h3>
      <div className={styles.container}>
        <Grid container spacing={4}>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="First Name:" name="firstName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Last Name:" name="lastName" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Text form={form} required label="Email:" name="emailUser" />
          </Grid>
          <Grid item xl={3} md={3} xs={12}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="(999) 999-9999"
              required
              label="Contact phone:"
              name="phone"
              id="phone"
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.hr} />
      <h3>Choose your password</h3>
      <Grid container spacing={4}>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text type="password" autoComplete="new-password" form={form} label="Senha:" name="password" />
        </Grid>
        <Grid item xl={3} md={3} xs={12}>
          <Inputs.Text
            type="password"
            autoComplete="new-password"
            form={form}
            label="Confirmar Senha:"
            name="confirmPassword"
          />
        </Grid>
      </Grid>
      <div className={styles.hr} />
      <div className={styles.buttons}>
        <Buttons.Secondary
          className={styles.buttonConfirm}
          variant="contained"
          onClick={form.handleSubmit(beforeSubmit)}
        >
          Finish
        </Buttons.Secondary>
      </div>
    </div>
  );
};

export default AgencyRegisterFormEN;
