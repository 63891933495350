import React, { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { MenuItem, Select, FormControl, InputLabel, FormHelperText } from '@mui/material';

import { IInputProps } from '../defaultSettings';

export interface ISelectOption {
  label: string;
  value: string | number;
}

export interface ISelectProps<T extends FieldValues = FieldValues> extends IInputProps<T> {
  options: ISelectOption[];
  defaultValue?: string | number;
  className?: string;
  label: string;
}

const SelectForm = <T extends FieldValues>(props: ISelectProps<T>): React.ReactElement<T> => {
  const { name, form, defaultValue, options, label, ...rest } = props;
  const { formState, register } = form;

  const { error } = useMemo(() => {
    return form.getFieldState(name);
  }, [formState.errors]);

  return (
    <FormControl fullWidth variant="outlined" size="small" error={!!error?.message} className={props.className}>
      <InputLabel
        style={{
          marginTop: '3px',
        }}
      >
        {label}
      </InputLabel>
      <Select
        style={{
          height: '47px',
        }}
        {...rest}
        {...register(name)}
        defaultValue={defaultValue}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error?.message && <FormHelperText>{error?.message as string}</FormHelperText>}
    </FormControl>
  );
};

export default SelectForm;
