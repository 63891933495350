import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
// TODO: Criar os tipos
const getLinkedAgenciesList = async (id: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(`/service/agencies/operadora/${id}`);

  return data;
};

export default getLinkedAgenciesList;
