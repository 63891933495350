import { Navigate, Outlet } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useMemo } from 'react';
import { getUser } from 'services/Login/user.service';
import { useTranslation } from 'react-i18next';

const ManagementLayout = () => {
  const { t } = useTranslation();
  const user = useMemo(() => getUser(), []);

  const validateAdmin = () => {
    if (user.isGoWeasy()) return true;
    if (user.isOperator()) return true;
    toast.error(t('ADMINISTRATIVE.FORBIDDEN'));

    return false;
  };

  const isValidUser = validateAdmin();

  const handleLogout = () => {
    return <Navigate to="/" replace />;
  };

  return <>{!isValidUser ? handleLogout() : <Outlet />}</>;
};

export default ManagementLayout;
