import CardNumber from './CardNumber';
import Text from './Text';
import Mask from './Mask';
import CheckBox from './Checkbox';
import WESelect from './Select';
import SelectForm from './SelectForm';

const Inputs = {
  Text,
  CardNumber,
  Mask,
  CheckBox,
  Select: WESelect,
  SelectInput: SelectForm,
};
export default Inputs;
