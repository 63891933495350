import styles from './styles.module.scss';
import pdf from './privacy_policy.pdf';
import WEPDF from 'components/WEPdf';
import TitleBack from 'components/TitleBack';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleBack title={t('PRIVACY_POLICY.TITLE')} />
      <div className={styles.container}>
        <WEPDF pdfUrl={pdf} />
      </div>
    </>
  );
};

export default PrivacyPolicy;
