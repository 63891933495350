import Yup from 'utils/yup';

export const validateCheckoutForm = Yup.object({
  personal: Yup.object({
    documentNumber: Yup.string().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    phoneNumber: Yup.string().required(),
    address1: Yup.string().required(),
    zipcode: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
  }),
});
