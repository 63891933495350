export default {
  FAQ: {
    TITLE: 'Estamos aquí para ayudarte. Tu guía de respuestas rápidas',
    QUESTION_1: '¿Es fácil ser atendido cuando más lo necesito?',
    ANSWER_1:
      'Muy fácil. GoWeasy nació en Orlando y los fundadores vinieron del turismo, por lo que entendemos sus necesidades y trabajaremos juntos para brindarle todo el apoyo necesario.',
    QUESTION_2: '¿GoWeasy tiene atencion durante la estadía de mi cliente?',
    ANSWER_2: 'Sí, tenemos servicio las 24 horas en Orlando para usted y sus clientes durante su estadía.',
    QUESTION_3: '¿Cuál es el horario de atención al cliente de GoWeasy para los profesionales del turismo?',
    ANSWER_3:
      'De lunes a viernes de 9 a 19 horas y sábados de 9 a 14 horas. <br/><br/> Para cualquier emergencia, contamos con servicio las 24 horas.',
    QUESTION_4: '¿Cómo me pongo en contacto con GoWeasy?',
    ANSWER_4:
      'Durante el horario comercial, por correo electrónico hello@goweasy.com <br/><br/> Y nuestros números de atención al cliente en Estados Unidos  (888) 885-6889',
    QUESTION_5: '¿Cómo garantiza GoWeasy la calidad de las viviendas?',
    ANSWER_5:
      'Todas las casas que llegan a la Plataforma se someten a un control de calidad. <br/><br/> GoWeasy, además de ser una plataforma de alquiler vacacional, también es una productora  de contenido y para ello necesitamos estar constantemente inspeccionando las casas aledañas a nuestra socios, para que cumplan con nuestro estándar de listado de propiedades.',
  },
};
