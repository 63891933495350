import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';

// TODO: Criar os tipos
// TODO: COLOCAAR O PAYMENT ID
const reservationRecords = async (paymentId: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(
    `/service/v1/house/reservation/${paymentId}/history`,
  );

  return data;
};

export default reservationRecords;
