import { axiosInstances } from 'services/api';

// TODO: Criar tipo
const saveCondominiumById = async (id: any, formData: any): Promise<any> => {
  const { data } = await axiosInstances.base.instance.put(`/service/v1/condominiums/${id}`, {
    ...formData,
  });

  return data;
};

export default saveCondominiumById;
