import React from 'react';

import styles from './styles.module.scss';

type ShowoffExperiencesItemProps = {
  item: {
    name?: string;
    image?: string;
  };
};

const ShowoffExperiencesItem: React.FC<ShowoffExperiencesItemProps> = ({ item }) => {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={item.image} alt={item.name} />
      <p className={styles.name}>{item.name}</p>
    </div>
  );
};

export default ShowoffExperiencesItem;
