import { axiosInstances } from 'services/api';

const sendHistory = async (
  paymentId: string,
  responsable: string,
  description: string,
  categorie: string,
): Promise<number> => {
  const { data }: any = await axiosInstances.base.instance.post('/service/v1/house/history', {
    paymentId,
    responsable,
    description,
    categorie,
  });

  return data;
};

export default sendHistory;
