type DividerProps = {
  color?: string;
  height?: number | string;
  opacity?: number;
  className?: string;
};

const Divider: React.FC<DividerProps> = ({ color = '#000', height = '1px', opacity = 1, className }) => {
  return (
    <div
      className={className}
      style={{
        backgroundColor: color,
        height: height,
        opacity: opacity,
        width: '100%',
        marginTop: '2px',
        marginBottom: '2px',
      }}
    ></div>
  );
};

export default Divider;
