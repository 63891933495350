import { useForm } from 'react-hook-form';
import styles from '../../styles.module.scss';
import Inputs from 'components/molecules/inputs';
import BrazilianAgency from 'models/application/Agency/BrazilianAgency.model';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAgency } from './validateAgency';
import { useRef, useState } from 'react';
import getAddressByCep from 'services/CEP/getAddressByCep';

type AgencyFormBRProps = {
  onSubmit: (data: BrazilianAgency) => void;
  agency?: BrazilianAgency;
};

const AgencyFormBR: React.FC<AgencyFormBRProps> = ({ onSubmit, agency }) => {
  const form = useForm<BrazilianAgency>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agency,
    resolver: yupResolver(validateAgency),
  });

  const inputRef = useRef<any>(null);

  const [isDirty, setIsDirty] = useState<boolean>();

  const beforeSubmit = (data: BrazilianAgency) => {
    onSubmit(new BrazilianAgency(data));
  };

  const getCep = async (c: string) => {
    const response = await getAddressByCep(c);
    if (response.logradouro) {
      form.setValue('address', response.logradouro + ', ' + response.bairro, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('city', response.localidade, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue('country', 'Brasil', {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setIsDirty(true);
    } else {
      form.setValue('address', '');
      form.setValue('city', '');
      form.setValue('country', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };

  return (
    <div className={styles.containerForm}>
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="Nome da agência:" name="name" />
          <Inputs.Text form={form} required label="Email da agência:" name="email" />
          <Inputs.Text form={form} required label="Telefone da agência" name="cellphone" />
          <Inputs.Mask
            whiteVersion={false}
            form={form}
            mask="99.999.999/9999-99"
            required
            label="CNPJ:"
            name="cnpj"
            id="cnpj"
          />
          <div className={styles.gridTwoColumns}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="99999-999"
              label="CEP da agência:"
              name="cep"
              id="cep"
              onBlur={handleBlur}
            />
            <Inputs.Text
              form={form}
              required
              label="Endereço:"
              name="address"
              inputRef={inputRef}
              inputLabelProps={{
                shrink: isDirty, // Disable label animation
              }}
            />
          </div>
          <Inputs.Text
            form={form}
            required
            label="Cidade:"
            name="city"
            inputLabelProps={{
              shrink: isDirty,
            }}
          />
          <Inputs.Text
            form={form}
            required
            label="País:"
            name="country"
            inputLabelProps={{
              shrink: isDirty,
            }}
          />
          <Inputs.Text form={form} label="Inscrição estadual (opcional):" name="stateRegistration" />
          <Inputs.Text form={form} label="CADASTUR (opcional):" name="cadastur" />
          <Inputs.Text form={form} label="URL do site (opcional):" name="siteUrl" />
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Continue
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AgencyFormBR;
