export default {
  ADMINISTRATIVE: {
    FORBIDDEN: 'Você não tem permissão para acessar esta página.',
    SEARCH_BY_ID_OR_CONDOMINIUM: 'Busque por código de propiedad o condominio',
    HOUSES: 'Casas',
    HOUSE_CONFIGURATION: 'Configuración de casas',
    NAME: 'NOMBRE',
    CONDOMINIUM: 'CONDOMINIO',
    CONDOMINIUM_TABLE: 'Condominio',
    ADDRESS: 'DIRECCIÓN',
    CITY: 'CIUDAD',
    REVIEWED_HOUSES: 'Casas Revisadas',
    PUBLISHED_HOUSES: 'Casas Publicadas',
    PENDING_HOUSES: 'Casas Pendientes',
    INACTIVES_HOUSES: 'Casas Inactivas',
    TOTAL_HOUSES: 'Total de Casas',
    REVIEWED: 'Revisado',
    PUBLISHED: 'Publicado',
    PENDING: 'Pendiente',
    INACTIVES: 'Inactivo',
    PROPERTIES: 'Propiedades',
    SAVE_SUCCESS: '¡Guardado con éxito!',
    SAVE_FAIL: 'Algo salió mal.',
    TOTAL_CONDOMINIUMS: 'Total de Condominios',
    NAME_INPUT: 'Nombre',
    LATITUDE: 'Latitud',
    LONGITUDE: 'Longitud',
    ADD_VIDEO_OR_PHOTO: 'Añadir Video o Foto de la presentación del condominio',
    VIDEO_LIMIT: 'Video de hasta 20 segundos y 10MB',
    SELECT_FILE: 'Seleccionar Archivo',
    TITLE_IN_ENGLISH: 'Título en Inglés',
    TITLE_IN_SPANISH: 'Título en Español',
    TITLE_IN_PORTUGUÉS: 'Título en Portugués',
    DESCRIPTION_IN_ENGLISH: 'Descripción en Inglés',
    DESCRIPTION_IN_SPANISH: 'Descripción en Español',
    DESCRIPTION_IN_PORTUGUESE: 'Descripción en Portugués',
    ADD_NEW_CONDOMINIUM: 'Incluir nuevo condominio',
    SAVE: 'Guardar',
    SAVED: 'Guardado',
    SAVED_SUCCESSFULLY: '¡Guardado con éxito!',
    SAVE_ERROR: 'Algo salió mal al guardar.',
    SOMETHING_WENT_WRONG: 'Algo salió mal.',
    NEW_CONDOMINIUM: 'Nuevo Condominio',
    PUBLISH: 'Publicar',
    MAIN: {
      NAME: 'Nombre',
      CONTACT: 'Contacto',
      SITE: 'URL del Sitio',
      IDIOM: 'Idioma',
      PROFIT_PERCENTAGE: 'Porcentaje de Ganancia',
      ACTIVE: 'Activo',
      NOT_INFORMATION: 'No informado',
      AGENTS: 'Agentes',
      YES: 'Sí',
      NO: 'No',
    },
    REPORTS: {
      RESERVATIONS: 'RESERVA',
      PROPERTY: 'PROPIEDAD',
      PM: 'P.M',
      OPERATOR: 'OPERADOR',
      AGENCY: 'AGENCIA',
      AGENT: 'AGENTE',
      SALE: 'VENTA',
      DAILY: 'TARIFA POR NOCHE',
      CLEANING: 'LIMPIEZA',
      COMMISSION_LP: 'COMISIÓN LP',
      COMMISSION_AG: 'COMISIÓN AG',
      COMMISSION_GW: 'COMISIÓN GW',
      TAXES: 'IMPUESTOS',
      DISCOUNTS: 'DESCUENTOS',
      GW_FEE: 'TASA GW',
      TOTAL_GW: 'TOTAL GW',
      DATE: 'FECHA',
      DOLLAR_EXCHANGE_RATE: 'COTIZACIÓN DEL DÓLAR',
      PAGTOUR_FEE: 'TASA PAGTOUR',
      FINANCIAL_REPORT: 'Informe Financiero',
      SELECT_MONTH: 'Seleccione un mes',
      GOAL_SUCCESS: 'Meta creada con éxito',
      PERCENTAGE: 'Porcentaje',
      REGISTER: 'Registrar',
      GOAL_REGISTRATION: 'Registro de meta',
      REGISTER_AGENCY: 'Registrar agencia',
    },
    MONTHS: {
      JANUARY: 'ENERO',
      FEBRUARY: 'FEBRERO',
      MARCH: 'MARZO',
      APRIL: 'ABRIL',
      MAY: 'MAYO',
      JUNE: 'JUNIO',
      JULY: 'JULIO',
      AUGUST: 'AGOSTO',
      SEPTEMBER: 'SEPTIEMBRE',
      OCTOBER: 'OCTUBRE',
      NOVEMBER: 'NOVIEMBRE',
      DECEMBER: 'DICIEMBRE',
    },
    MONTHS_ACRONYMS: {
      JANUARY: 'ENE',
      FEBRUARY: 'FEB',
      MARCH: 'MAR',
      APRIL: 'ABR',
      MAY: 'MAY',
      JUNE: 'JUN',
      JULY: 'JUL',
      AUGUST: 'AGO',
      SEPTEMBER: 'SEP',
      OCTOBER: 'OCT',
      NOVEMBER: 'NOV',
      DECEMBER: 'DIC',
    },
    DASHBOARD: {
      TITLE_MONTH: 'Meta',
      SUBTITLE_MONTH: 'Mensual',
      TITLE_DAILY: 'Meta',
      SUBTITLE_DAILY: 'Diaria',
      TITLE_CANCEL: 'Reservas',
      SUBTITLE_CANCEL: 'Canceladas',
      TITLE_PROFIT: 'Rentabilidad',
      SUBTITLE_PROFIT: 'Alcanzada',
    },
  },
};
