import Box from '@mui/material/Box';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';
import { Dialog, MenuItem, TextField } from '@mui/material';
import Button from 'components/BackOffice/Button';
import { maskCNPJ } from 'utils/masks';

type ConfirmAccessProps = {
  user: any;
  onConfirm: () => void;
  onClose: () => void;
  onSelect: any;
  selectedValue: any;
  isOpen: boolean;
  loading: boolean;
};

const agentCategories = [
  {
    value: 'AGENTE_GRANDE',
    label: 'Agente de Viagem Grande - 15%',
  },
  {
    value: 'AGENTE_MEDIO',
    label: 'Agente de Viagem Medio - 12%',
  },
  {
    value: 'AGENTE_PEQUENO',
    label: 'Agente de Viagem Pequeno - 10%',
  },
  {
    value: 'OPERADORA_PEQUENO',
    label: 'Operadora de Viagem Pequena - 23%',
  },
];

const ConfirmAgentModal: React.FC<ConfirmAccessProps> = ({
  onConfirm,
  onClose,
  onSelect,
  selectedValue,
  isOpen,
  user,
  loading,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    onSelect(event.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box className={styles.content}>
        <div className={styles.title}>{t('AGENT.CONFIRM.TITLE')}</div>
        <div className={styles.data}>
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.AGENCY')}:</b>
            <div className={styles.info}>{user.id}</div>
          </div>
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.NAME')}:</b>
            <div className={styles.info}>{user.name}</div>
          </div>
          <div className={styles.row}>
            <b>CNPJ:</b>
            <div className={styles.info}>{user?.cnpj ? maskCNPJ(user?.cnpj) : user?.cnpj}</div>
          </div>
          <div className={styles.row}>
            <b>Cadastur:</b>
            <div className={styles.info}>{user?.cadastur}</div>
          </div>
          {/*<div className={styles.row}>
            <b>{t('AGENT.CONFIRM.EMAIL')}:</b>
            <div className={styles.info}>{user.email}</div>
          </div>*/}
          <div className={styles.row}>
            <b>{t('AGENT.CONFIRM.CONTACT')}:</b>
            <div className={styles.info}>{user.primaryContact}</div>
          </div>
        </div>
        <div>
          <TextField
            select
            name="agentCategorie"
            label="Tipo de Comissionamento"
            id="simple-select"
            value={selectedValue}
            onChange={handleChange}
            fullWidth
          >
            {agentCategories?.map((c) => (
              <MenuItem value={c?.value}>{c?.label}</MenuItem>
            ))}
          </TextField>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>{t('AGENT.CONFIRM.CANCEL')}</Button>
          <Buttons.Orange
            className={styles.orangeButton}
            fullWidth
            variant="contained"
            onClick={onConfirm}
            loading={loading}
          >
            {t('AGENT.CONFIRM.BUTTON')}
          </Buttons.Orange>
        </div>
      </Box>
    </Dialog>
  );
};

export default ConfirmAgentModal;
