import React, { useRef, useState } from 'react';
import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import BrazilianAgent from 'models/application/Agent/BrazilianAgent.model';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAgent } from './validateAgent';
import getAddressByCep from 'services/CEP/getAddressByCep';

type AgencyFormBRProps = {
  onSubmit: (data: BrazilianAgent) => void;
  agent?: BrazilianAgent;
  isOperator?: boolean;
};

const AgentFormBR: React.FC<AgencyFormBRProps> = ({ onSubmit, agent, isOperator }) => {
  const form = useForm<BrazilianAgent>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agent,
    resolver: yupResolver(validateAgent),
  });

  const inputRef = useRef<any>(null);

  const [isDirty, setIsDirty] = useState<boolean>();

  const beforeSubmit = (data: BrazilianAgent) => {
    onSubmit(new BrazilianAgent(data));
  };

  const getCep = async (c: string) => {
    const response = await getAddressByCep(c);
    if (response.logradouro) {
      form.setValue('complement', response.logradouro + ', ' + response.bairro, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      });
      setIsDirty(true);
    } else {
      form.setValue('complement', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };

  return (
    <div className={styles.containerForm}>
      {!isOperator && <div className={styles.subTitle}>Cadastre sua agência agora!</div>}
      {isOperator && (
        <div className={styles.subTitle}>
          <span style={{ fontWeight: 700 }}>Cadastre sua Operadora!</span>
          <br />
          <br />
          <label style={{ fontSize: '14px' }}>Para cadastrar sua operadora, é necessário vincular uma agência.</label>
        </div>
      )}
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="Nome do responsável pela Agência:" name="name" />
          <Inputs.Mask
            whiteVersion={false}
            form={form}
            mask="999.999.999-99"
            required
            label="CPF do responsável pela Agência:"
            name="cpf"
            id="cpf"
          />
          {/* <Inputs.Text form={form} required label="Cargo:" name="position" /> */}
          <Inputs.Text form={form} required label="Email do responsável pela Agência:" name="email" />
          <div className={styles.gridTwoColumns}>
            <Inputs.Mask
              whiteVersion={false}
              form={form}
              mask="99999-999"
              required
              label="CEP da agência:"
              name="cep"
              id="cep"
              onBlur={handleBlur}
            />
            <Inputs.Text form={form} required label="Número:" name="number" />
          </div>
          <Inputs.Text
            form={form}
            label="Endereço:"
            name="complement"
            inputRef={inputRef}
            inputLabelProps={{
              shrink: isDirty, // Disable label animation
            }}
          />
          <div className={styles.gridTwoColumns}>
            <Inputs.Text type="password" autoComplete="new-password" form={form} label="Senha:" name="password" />
            <Inputs.Text
              type="password"
              autoComplete="new-password"
              form={form}
              label="Confirmar Senha:"
              name="confirmPassword"
            />
          </div>
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Continuar
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AgentFormBR;
