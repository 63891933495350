import React from 'react';
import { BaseProps } from './BaseProps';

const ManorSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g
        id="Grupo_de_máscara_249"
        data-name="Grupo de máscara 249"
        transform="translate(-1224 -660)"
        clip-path="url(#clip-path)"
      >
        <g id="mansion_1676106" transform="translate(1224 660)">
          <path
            id="Caminho_7687"
            data-name="Caminho 7687"
            d="M45.233,16.867h-1.04l-1.26-2.773V3.833a.767.767,0,0,0-.767-.767H37.921L36.527,0H9.473L8.079,3.067H3.833a.767.767,0,0,0-.767.767v10.26l-1.26,2.773H.767A.767.767,0,0,0,0,17.633V20.7a.767.767,0,0,0,.767.767h.767V46H44.467V21.467h.767A.767.767,0,0,0,46,20.7V17.633A.767.767,0,0,0,45.233,16.867ZM41.4,4.6v6.12L38.618,4.6ZM4.6,4.6H7.382L4.6,10.72Zm9.2,39.867H3.067V42.933H13.8ZM7.667,38.333V41.4H6.133V38.333ZM6.133,36.8V35.267H7.667V36.8Zm4.6,1.533V41.4H9.2V38.333ZM9.2,36.8V35.267h1.533V36.8Zm4.6,4.6H12.267V33.733H4.6V41.4H3.067V21.467H13.8Zm0-21.467H1.533V18.4H13.8Zm0-3.067H3.491l.7-1.533H7.667V13.8H4.9L10.46,1.533H35.54L41.1,13.8H38.333v1.533h3.479l.7,1.533H32.2V15.333h2.3a.767.767,0,0,0,.767-.767V11.5a.767.767,0,0,0-.767-.767H32.408L23.383,5.471a.765.765,0,0,0-.773,0l-9.018,5.262H11.5a.767.767,0,0,0-.767.767v3.067a.767.767,0,0,0,.767.767h2.3Zm16.867,27.6H15.333V42.933H30.667ZM19.7,29.133,23,26.31l3.3,2.824Zm6.363,1.533v7.667h-2.3V30.667Zm-3.833,0v7.667h-2.3V30.667Zm5.367,9.2V41.4H18.4V39.867ZM30.667,41.4H29.133V38.333H27.6V30.667h.767a.767.767,0,0,0,.5-1.348l-5.367-4.6a.766.766,0,0,0-1,0l-5.367,4.6a.767.767,0,0,0,.5,1.348H18.4v7.667H16.867V41.4H15.333V15.022L23,10.84l7.667,4.182Zm.962-27.6L23,9.093,14.371,13.8h-2.1V12.267H13.8a.763.763,0,0,0,.383-.1L23,7.021l8.817,5.141a.763.763,0,0,0,.383.1h1.533V13.8Zm11.3,30.667H32.2V42.933H42.933ZM36.8,38.333V41.4H35.267V38.333ZM35.267,36.8V35.267H36.8V36.8Zm4.6,1.533V41.4H38.333V38.333ZM38.333,36.8V35.267h1.533V36.8Zm4.6,4.6H41.4V33.733H33.733V41.4H32.2V21.467H42.933Zm1.533-21.467H32.2V18.4H44.467Zm0,0"
            transform="translate(0)"
            fill={color}
          />
          <path
            id="Caminho_7688"
            data-name="Caminho 7688"
            d="M42.867,24H35.2v9.2h7.667Zm-4.6,7.667H36.733V28.6h1.533Zm0-4.6H36.733V25.533h1.533Zm3.067,4.6H39.8V28.6h1.533Zm0-4.6H39.8V25.533h1.533Zm0,0"
            transform="translate(-1.467 -1)"
            fill={color}
          />
          <path
            id="Caminho_7689"
            data-name="Caminho 7689"
            d="M12.467,24H4.8v9.2h7.667Zm-4.6,7.667H6.333V28.6H7.867Zm0-4.6H6.333V25.533H7.867Zm3.067,4.6H9.4V28.6h1.533Zm0-4.6H9.4V25.533h1.533Zm0,0"
            transform="translate(-0.2 -1)"
            fill={color}
          />
          <path
            id="Caminho_7690"
            data-name="Caminho 7690"
            d="M18.4,23.667H29.133V16H18.4Zm7.667-6.133H27.6v4.6H26.067Zm-3.067,0h1.533v4.6H23Zm-3.067,0h1.533v4.6H19.933Zm0,0"
            transform="translate(-0.767 -0.667)"
            fill={color}
          />
          <path
            id="Caminho_7691"
            data-name="Caminho 7691"
            d="M17.6,25.6h1.533v1.533H17.6Zm0,0"
            transform="translate(-0.733 -1.067)"
            fill={color}
          />
          <path
            id="Caminho_7692"
            data-name="Caminho 7692"
            d="M28.8,25.6h1.533v1.533H28.8Zm0,0"
            transform="translate(-1.2 -1.067)"
            fill={color}
          />
          <path
            id="Caminho_7693"
            data-name="Caminho 7693"
            d="M16.689,4.775l2.058.686L19.232,4l-2.3-.767a.766.766,0,0,0-.484,0L14.147,4l.484,1.457Zm0,0"
            transform="translate(-0.589 -0.133)"
            fill={color}
          />
          <path
            id="Caminho_7694"
            data-name="Caminho 7694"
            d="M13.947,9.461,14.432,8l-2.3-.767a.766.766,0,0,0-.484,0L9.347,8l.484,1.457,2.058-.686Zm0,0"
            transform="translate(-0.389 -0.3)"
            fill={color}
          />
          <path
            id="Caminho_7695"
            data-name="Caminho 7695"
            d="M28.689,4.775l2.058.686L31.232,4l-2.3-.767a.766.766,0,0,0-.484,0L26.147,4l.484,1.457Zm0,0"
            transform="translate(-1.089 -0.133)"
            fill={color}
          />
          <path
            id="Caminho_7696"
            data-name="Caminho 7696"
            d="M35.089,7.975l2.058.686L37.632,7.2l-2.3-.767a.766.766,0,0,0-.484,0l-2.3.767.484,1.457Zm0,0"
            transform="translate(-1.356 -0.267)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default ManorSVG;
