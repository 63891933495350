import { Buttons } from 'components/molecules/buttons';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import usePublicNavigation from 'routes/navigation/public.navigation';
import { useLocation } from 'react-router-dom';

const ForgotPasswordSuccess = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { navigateToLogin } = usePublicNavigation();

  const email = location.state?.email;

  return (
    <div className={styles.content}>
      <div className={styles.question}>{t('PUBLIC.FORGOT_PASSWORD.SUCCESS_TITLE')}</div>
      <div className={styles.answer}>{t('PUBLIC.FORGOT_PASSWORD.SUCCESS_TEXT', { email: email })}</div>
      <Buttons.Secondary fullWidth variant="contained" onClick={navigateToLogin}>
        {t('PUBLIC.FORGOT_PASSWORD.SEND_PASSWORD')}
      </Buttons.Secondary>
    </div>
  );
};

export default ForgotPasswordSuccess;
