import i18next from 'i18next';

class Currency {
  getCurrencySign() {
    const currentLanguage = i18next.language;

    return currentLanguage === 'pt-BR' ? 'R$' : '$';
  }
}

const currency = new Currency();

export default currency;
