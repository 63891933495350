class AgencyAndUserES {
  //agency
  name: string;
  country: string;
  city: string;
  street: string;
  number: string;
  email: string;
  cellphone: string;
  corporateName: string;
  dossier: string;

  //user
  firstName: string;
  lastName: string;
  emailUser: string;
  phone: string;
  position: string;
  password: string;
  confirmPassword?: string;

  constructor(agencyAndUser: AgencyAndUserES) {
    this.name = agencyAndUser.name;
    this.country = agencyAndUser.country;
    this.city = agencyAndUser.city;
    this.street = agencyAndUser.street;
    this.number = agencyAndUser.number;
    this.email = agencyAndUser.email;
    this.cellphone = agencyAndUser.cellphone;
    this.corporateName = agencyAndUser.corporateName;
    this.dossier = agencyAndUser.dossier;

    this.firstName = agencyAndUser.firstName;
    this.lastName = agencyAndUser.lastName;
    this.emailUser = agencyAndUser.emailUser;
    this.phone = agencyAndUser.phone;
    this.position = agencyAndUser.position;
    this.password = agencyAndUser.password;
  }
}

export default AgencyAndUserES;
