import React from 'react';
import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import LatinAmericanAgent from 'models/application/Agent/LatinAmericanAgent.model';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAgent } from './validateAgent';

type AgentFormESProps = {
  onSubmit: (data: LatinAmericanAgent) => void;
  agent?: LatinAmericanAgent;
  isOperator?: boolean;
};

const AgentFormES: React.FC<AgentFormESProps> = ({ onSubmit, agent, isOperator }) => {
  const form = useForm<LatinAmericanAgent>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agent,
    resolver: yupResolver(validateAgent),
  });

  const beforeSubmit = (data: LatinAmericanAgent) => {
    onSubmit(new LatinAmericanAgent(data));
  };

  return (
    <div className={styles.containerForm}>
      {!isOperator && <div className={styles.subTitle}>¡Registra tu Agencia Ahora!</div>}
      {isOperator && (
        <div className={styles.subTitle}>
          <span style={{ fontWeight: 700 }}>Registre su Operadora</span>
          <br />
          <br />
          <label style={{ fontSize: '14px' }}>Para registrar su operadora, es necesario vincular una agencia.</label>
        </div>
      )}
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="Nombre:" name="firstName" />
          <Inputs.Text form={form} required label="Apellido:" name="lastName" />
          <Inputs.Text form={form} required label="E-mail:" name="email" />
          <Inputs.Text form={form} required label="Teléfono:" name="phone" />
          <div className={styles.gridTwoColumns}>
            <Inputs.Text type="password" form={form} label="Contraseña:" name="password" />
            <Inputs.Text type="password" form={form} label="Confirmar Contraseña:" name="confirmPassword" />
          </div>
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Continuar
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AgentFormES;
