import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import LanguageChoiceModal from './modals/LanguageChoice';
import WeStepper from 'components/WeStepper';
import { useTranslation } from 'react-i18next';
import { Languages } from 'languages/helpers.languages';
import RegisterFormEN from './forms/EN';
import RegisterFormBR from './forms/BR';
import RegisterFormES from './forms/ES';

const categories = {
  TRAVEL_OPERATOR: 'TRAVEL_OPERATOR',
  TRAVEL_AGENCY: 'TRAVEL_AGENCY',
  AGENCY_OPERATOR: 'AGENCY_OPERATOR',
};

const RegisterForm = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentForm, setCurrentForm] = useState<string>();
  const [category, setCategory] = useState<string>();
  const isOperator = category === categories.AGENCY_OPERATOR ? true : false;

  const { t } = useTranslation();

  const travelSteps = [
    t('PUBLIC.REGISTER.STEPS.AGENT'),
    t('PUBLIC.REGISTER.STEPS.AGENCY'),
    t('PUBLIC.REGISTER.STEPS.SENT'),
  ];
  const agencyOperatorSteps = [
    t('PUBLIC.REGISTER.STEPS.MANAGER_DATA'),
    t('PUBLIC.REGISTER.STEPS.AGENCY_DATA'),
    t('PUBLIC.REGISTER.STEPS.OPERATOR_DATA'),
  ];

  const stepsToRender = (c: string | undefined): string[] => {
    if (c === 'TRAVEL_OPERATOR') return travelSteps;
    if (c === 'TRAVEL_AGENCY') return travelSteps;
    if (c === 'AGENCY_OPERATOR') return agencyOperatorSteps;
    return [''];
  };

  const handleConfirm = (categoryValue: string, languageSelected: string) => {
    setCurrentForm(languageSelected);
    setCategory(categoryValue);
    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(true);
  }, []);

  const handleClickStep = (step: number) => {
    setCurrentStep(step);
  };

  const handleNextStep = () => {
    setCurrentStep((s) => s + 1);
  };

  const renderCurrentForm = () => {
    switch (currentForm) {
      case Languages.EN_US:
        return <RegisterFormEN isOperator={isOperator} onNextStep={handleNextStep} currentStep={currentStep} />;
      case Languages.PT_BR:
        return <RegisterFormBR isOperator={isOperator} onNextStep={handleNextStep} currentStep={currentStep} />;
      case Languages.ES:
        return <RegisterFormES isOperator={isOperator} onNextStep={handleNextStep} currentStep={currentStep} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.container}>
        <WeStepper steps={stepsToRender(category)} currentStep={currentStep} onClickStep={handleClickStep} />
        {renderCurrentForm()}
      </div>
      <LanguageChoiceModal isOpen={modalOpen} onConfirm={handleConfirm} />
    </>
  );
};

export default RegisterForm;
