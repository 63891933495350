import { House } from 'models/application/House.model';
import { useEffect, useState } from 'react';
import { getHouseById } from 'services/HouseDetails/getHouseById.service';
import useAsync from 'hooks/useAsync.hook';

const useHouse = (houseId: number) => {
  const [house, setHouse] = useState<House>();

  useEffect(() => {
    getHouse(houseId);
  }, []);

  const { loading, call: getHouse } = useAsync(async (id: number) => {
    const newHouse = await getHouseById(id);
    setHouse(newHouse);
  }, []);

  return {
    house,
    loadingHouse: loading,
    getHouse,
  };
};

export default useHouse;
