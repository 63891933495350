export default {
  ADMINISTRATIVE: {
    FORBIDDEN: 'Você não tem permissão para acessar esta página.',
    SEARCH_BY_ID_OR_CONDOMINIUM: 'Busque pelo código do imóvel ou condomínio',
    HOUSES: 'Casas',
    HOUSE_CONFIGURATION: 'Configuração de Casas',
    NAME: 'NOME',
    CONDOMINIUM: 'CONDOMÍNIO',
    CONDOMINIUM_TABLE: 'Condomínio',
    ADDRESS: 'ENDEREÇO',
    CITY: 'CIDADE',
    REVIEWED_HOUSES: 'Casas Revisadas',
    PUBLISHED_HOUSES: 'Casas Publicadas',
    PENDING_HOUSES: 'Casas Pendentes',
    INACTIVES_HOUSES: 'Casas Inativas',
    TOTAL_HOUSES: 'Total de Casas',
    REVIEWED: 'Revisado',
    PUBLISHED: 'Publicado',
    PENDING: 'Pendente',
    INACTIVES: 'Inativo',
    PROPERTIES: 'Imóveis',
    SAVE_SUCCESS: 'Salvo com sucesso!',
    SAVE_FAIL: 'Algo deu errado.',
    TOTAL_CONDOMINIUMS: 'Total de Condomínios',
    NAME_INPUT: 'Nome',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    ADD_VIDEO_OR_PHOTO: 'Adicione Video ou Foto da apresentação do condominio',
    VIDEO_LIMIT: 'Vídeo de no máximo 20 segundos e 10MB',
    SELECT_FILE: 'Selecionar Arquivo',
    TITLE_IN_ENGLISH: 'Titulo em Inglês',
    TITLE_IN_SPANISH: 'Titulo em Espanhol',
    TITLE_IN_PORTUGUESE: 'Titulo em Português',
    DESCRIPTION_IN_ENGLISH: 'Descrição em Inglês',
    DESCRIPTION_IN_SPANISH: 'Descrição em Espanhol',
    DESCRIPTION_IN_PORTUGUESE: 'Descrição em Português',
    ADD_NEW_CONDOMINIUM: 'Incluir novo condominio',
    SAVE: 'Salvar',
    SAVED: 'Salvo',
    SAVED_SUCCESSFULLY: 'Salvo com sucesso!',
    SAVE_ERROR: 'Algo deu errado ao salvar.',
    SOMETHING_WENT_WRONG: 'Algo deu errado.',
    NEW_CONDOMINIUM: 'Novo Condomínio',
    PUBLISH: 'Publicar',
    MAIN: {
      NAME: 'Nome',
      CONTACT: 'Contato',
      SITE: 'URL do Site',
      IDIOM: 'Idioma',
      PROFIT_PERCENTAGE: 'Porcentagem de Lucro',
      ACTIVE: 'Ativo',
      NOT_INFORMATION: 'Não informado',
      AGENTS: 'Agentes',
      YES: 'Sim',
      NO: 'Não',
    },
    REPORTS: {
      RESERVATIONS: 'RESERVA',
      PROPERTY: 'IMÓVEL',
      PM: 'P.M',
      OPERATOR: 'OPERADOR',
      AGENCY: 'AGÊNCIA',
      AGENT: 'AGENTE',
      SALE: 'VENDA',
      DAILY: 'DIÁRIA',
      CLEANING: 'LIMPEZA',
      COMMISSION_LP: 'COMISSÃO LP',
      COMMISSION_AG: 'COMISSÃO AG',
      COMMISSION_GW: 'COMISSÃO GW',
      TAXES: 'IMPOSTOS',
      DISCOUNTS: 'DESCONTOS',
      GW_FEE: 'TAXA GW',
      TOTAL_GW: 'TOTAL GW',
      DATE: 'DATA',
      DOLLAR_EXCHANGE_RATE: 'COTAÇÃO DÓLAR',
      PAGTOUR_FEE: 'TAXA PAGTOUR',
      FINANCIAL_REPORT: 'Relatório Financeiro',
      SELECT_MONTH: 'Selecione um mês',
      GOAL_SUCCESS: 'Meta criada com sucesso!',
      PERCENTAGE: 'Porcentagem',
      REGISTER: 'Cadastrar',
      GOAL_REGISTRATION: 'Cadastro de Meta',
      REGISTER_AGENCY: 'Cadastrar Agência',
    },
    MONTHS: {
      JANUARY: 'JANEIRO',
      FEBRUARY: 'FEVEREIRO',
      MARCH: 'MARÇO',
      APRIL: 'ABRIL',
      MAY: 'MAIO',
      JUNE: 'JUNHO',
      JULY: 'JULHO',
      AUGUST: 'AGOSTO',
      SEPTEMBER: 'SETEMBRO',
      OCTOBER: 'OUTUBRO',
      NOVEMBER: 'NOVEMBRO',
      DECEMBER: 'DEZEMBRO',
    },
    MONTHS_ACRONYMS: {
      JANUARY: 'JAN',
      FEBRUARY: 'FEV',
      MARCH: 'MAR',
      APRIL: 'ABR',
      MAY: 'MAI',
      JUNE: 'JUN',
      JULY: 'JUL',
      AUGUST: 'AGO',
      SEPTEMBER: 'SET',
      OCTOBER: 'OUT',
      NOVEMBER: 'NOV',
      DECEMBER: 'DEZ',
    },
    DASHBOARD: {
      TITLE_MONTH: 'Meta',
      SUBTITLE_MONTH: 'Mensal',
      TITLE_DAILY: 'Meta',
      SUBTITLE_DAILY: 'Diária',
      TITLE_CANCEL: 'Reservas',
      SUBTITLE_CANCEL: 'Canceladas',
      TITLE_PROFIT: 'Rentabilidade',
      SUBTITLE_PROFIT: 'Atingida',
    },
  },
};
