import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { LocationContainer, LocationTitle } from './Location.style';
import L from 'leaflet';
import icon from 'assets/svg/pin-go-weasy.svg';
import React, { useMemo } from 'react';
import { ICoordinates } from 'models/application/interfaces/ICoordinates.interface';
import Sea from 'assets/icons/sea.png';
import Disney from 'assets/icons/disney.png';
import Walmart from 'assets/icons/walmart.png';
import PlanetEarth from 'assets/icons/planet-earth.png';
import Oscar from 'assets/icons/statue.png';
import WaterSlide from 'assets/icons/water-slide.png';
import ThemePark from 'assets/icons/theme-park.png';
import Livestock from 'assets/icons/livestock.png';
import Blizzard from 'assets/icons/blizzard.png';
import styles from './styles.module.scss';

const ATTRACTIONS = [
  {
    name: 'Disney',
    icon: Disney,
    coordinates: { lat: 28.41783, long: -81.57533 },
    size: new L.Point(60, 60),
  },
  {
    name: 'SeaWorld Orlando',
    icon: Sea,
    coordinates: { lat: 28.4065, long: -81.4575 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Walmart',
    icon: Walmart,
    coordinates: { lat: 28.5158857, long: -81.4706849 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Blizzard',
    icon: Blizzard,
    coordinates: { lat: 28.3522517, long: -81.5764737 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Hollywood studios',
    icon: Oscar,
    coordinates: { lat: 28.3580674, long: -81.5639684 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Animal kingdom',
    icon: Livestock,
    coordinates: { lat: 28.3548112, long: -81.6021759 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Epicot',
    icon: PlanetEarth,
    coordinates: { lat: 28.3764734, long: -81.5519783 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Universal Studios',
    icon: ThemePark,
    coordinates: { lat: 28.4793801, long: -81.4711171 },
    size: new L.Point(40, 40),
  },
  {
    name: 'Typhoon Lagoon',
    icon: WaterSlide,
    coordinates: { lat: 28.3663612, long: -81.5310256 },
    size: new L.Point(40, 40),
  },
];

const iconPerson = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconSize: new L.Point(45, 55),
});

interface PointMapProps {
  markers: {
    coordinates: ICoordinates;
    idLocation: number;
  }[];
  zoom?: number;
  className?: string;
  title?: string;
  center?: ICoordinates;
  mapHeight?: string;
  onClickMarker?: (id: number) => void;
}

const PointMap: React.FC<PointMapProps> = ({
  markers,
  zoom = 16,
  mapHeight = '300px',
  title,
  className,
  onClickMarker,
  center,
}) => {
  const handleClick = (id: number) => {
    if (!onClickMarker) return;

    onClickMarker(id);
  };

  const centerOnMap = useMemo(() => {
    if (center) return [center.lat, center.long];

    return [markers[0].coordinates.lat, markers[0].coordinates.long];
  }, []) as any;

  return (
    <LocationContainer>
      {title ? <LocationTitle>{title}</LocationTitle> : null}
      <MapContainer style={{ height: mapHeight, zIndex: 0 }} center={centerOnMap} zoom={zoom} className={className}>
        <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=18bd8354-7dd9-476b-a618-b171f294926e" />
        {markers.map((mark) => (
          <Marker
            key={mark.idLocation}
            icon={iconPerson}
            position={[mark.coordinates.lat, mark.coordinates.long]}
            eventHandlers={{
              click: () => handleClick(mark.idLocation),
            }}
          ></Marker>
        ))}
        {ATTRACTIONS.map((attraction) => (
          <div key={attraction.name}>
            <Marker
              icon={new L.Icon({ iconUrl: attraction.icon, iconRetinaUrl: attraction.icon, iconSize: attraction.size })}
              position={[attraction.coordinates.lat, attraction.coordinates.long]}
            ></Marker>
            <Marker
              icon={L.divIcon({
                html: attraction.name,
                className: styles.marker,
              })}
              position={[attraction.coordinates.lat, attraction.coordinates.long]}
            ></Marker>
          </div>
        ))}
      </MapContainer>
    </LocationContainer>
  );
};

export default PointMap;
