import { Trans, useTranslation } from 'react-i18next';

import Divider from 'components/atoms/Divider';

import workplace from '../../assets/images/who-we-are-space.png';
import styles from './styles.module.scss';
import TitleBack from 'components/TitleBack';

const WhoWeAre = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleBack title={t('WHO_WE_ARE.TITLE')} />
      <div className={styles.content}>
        <div className={styles.title}>{t('WHO_WE_ARE.SUBTITLE')}</div>
        <div className={styles.paragraph}>
          <Trans i18nKey="WHO_WE_ARE.PARAGRAPH" />
        </div>
        <img src={workplace} alt="Workplace" />
        <Divider color="#FFFFFF65" opacity={0.5} />
        <div className={styles.title}>{t('WHO_WE_ARE.PROPERTY.TITLE')}</div>
        <div className={styles.subtitle}>{t('WHO_WE_ARE.PROPERTY.SUBTITLE')}</div>
        <div className={styles.paragraph}>
          <Trans i18nKey="WHO_WE_ARE.PROPERTY.PARAGRAPH" />
        </div>
        <Divider color="#FFFFFF65" opacity={0.5} />
        <div className={styles.title}>{t('WHO_WE_ARE.AGENTS.TITLE')}</div>
        <div className={styles.subtitle}>{t('WHO_WE_ARE.AGENTS.SUBTITLE')}</div>
        <div className={styles.paragraph}>
          <Trans i18nKey="WHO_WE_ARE.AGENTS.PARAGRAPH" />
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
