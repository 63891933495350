import { yupResolver } from '@hookform/resolvers/yup';
import Inputs from 'components/molecules/inputs';
import { useForm } from 'react-hook-form';
import { Buttons } from 'components/molecules/buttons';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { showErrorMessage } from 'utils/Toast';
import useAsync from 'hooks/useAsync.hook';
import Yup from 'utils/yup';
import { recoverPassword } from 'services/Login/recoverPassword.service';
import usePublicNavigation from 'routes/navigation/public.navigation';

type RecoverPasswordFormType = {
  email: string;
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { navigateToLogin, navigateToForgotPasswordSuccess } = usePublicNavigation();

  const form = useForm<RecoverPasswordFormType>({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string().required().email(),
      }),
    ),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });

  const { loading, call: callRecoverPassword } = useAsync(async (data: RecoverPasswordFormType) => {
    try {
      await recoverPassword(data.email);
      navigateToForgotPasswordSuccess(data.email);
    } catch (err: any) {
      showErrorMessage(err.message);
    }
  });

  return (
    <div className={styles.content}>
      <div className={styles.question}>{t('PUBLIC.FORGOT_PASSWORD.QUESTION')}</div>
      <div className={styles.answer}>{t('PUBLIC.FORGOT_PASSWORD.ANSWER')}</div>
      <Inputs.Text
        form={form}
        label="E-mail"
        placeholder={t('PUBLIC.FORGOT_PASSWORD.PLACEHOLDER.EMAIL')}
        name="email"
      />
      <Buttons.Secondary
        loading={loading}
        fullWidth
        variant="contained"
        onClick={form.handleSubmit(callRecoverPassword)}
      >
        {t('PUBLIC.FORGOT_PASSWORD.SEND_PASSWORD')}
      </Buttons.Secondary>
      <Buttons.Text fullWidth variant="text" onClick={navigateToLogin}>
        {t('PUBLIC.FORGOT_PASSWORD.BACK_TO_LOGIN')}
      </Buttons.Text>
    </div>
  );
};

export default ForgotPassword;
