export const maskCPF = (cpf: string): string => {
  const cpfNumeros = cpf.replace(/\D/g, '');
  return cpfNumeros.length === 11 ? cpfNumeros.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : cpf;
};

export const maskCNPJ = (cnpj: string): string => {
  const cnpjNumeros = cnpj.replace(/\D/g, '');
  return cnpjNumeros.length === 14
    ? cnpjNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : cnpj;
};

export const maskRG = (rg: string): string => {
  const rgNumeros = rg.replace(/\D/g, '');
  return rgNumeros.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
};

export const maskPhoneBR = (telefone: string): string => {
  const telefoneNumeros = telefone?.replace(/\D/g, '');
  return telefoneNumeros?.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
};

export const maskPhoneEN = (telefone: string): string => {
  const telefoneNumeros = telefone?.replace(/\D/g, '');
  return telefoneNumeros?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const maskPhoneES = (telefone: string): string => {
  const telefoneNumeros = telefone?.replace(/\D/g, '');
  return telefoneNumeros?.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
};

export const maskBirthDate = (data: string): string => {
  const dataNumeros = data.replace(/\D/g, '');
  return dataNumeros.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
};

export const maskCEP = (cep: string): string => {
  const cepNumeros = cep.replace(/\D/g, '');
  return cepNumeros.replace(/(\d{5})(\d{3})/, '$1-$2');
};
