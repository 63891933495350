import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import React from 'react';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { yupResolver } from '@hookform/resolvers/yup';
import Operator from 'models/application/Operator/Operator.model';
import { validateOperatorES } from '../../ES/OperatorForm/validateOperatorES';

type OperatorFormENProps = {
  onSubmit: (data: Operator) => void;
  operator?: Operator;
};

const OperatorFormEN: React.FC<OperatorFormENProps> = ({ onSubmit, operator }) => {
  const form = useForm<Operator>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: operator,
    resolver: yupResolver(validateOperatorES),
  });

  const beforeSubmit = (data: Operator) => {
    onSubmit(new Operator(data));
  };

  return (
    <div className={styles.containerForm}>
      {/*<div className={styles.subTitle}>Register Your Agency Now!</div>*/}
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="Operator Name:" name="name" />
          <Inputs.Text form={form} required label="ASSOCIATION:" name="cnpj" />
          <Inputs.Text form={form} label="Address:" name="address" />
          <Inputs.Text form={form} required label="City:" name="city" />
          <Inputs.Text form={form} required label="Country:" name="country" />
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Send
        </ConfirmButton>
      </div>
    </div>
  );
};

export default OperatorFormEN;
