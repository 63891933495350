import styles from './styles.module.scss';

import tours from 'assets/images/train.png';
import friends from 'assets/images/amigas-se-divertindo.png';
import exploreOrlando from 'assets/images/passeios-noturnos.png';
import { useTranslation } from 'react-i18next';

const Banners = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <div className={styles.text}>{t('EXPLORE.ORLANDO')}</div>
        <img src={exploreOrlando} alt={t('EXPLORE.ORLANDO_ALT')} />
      </div>
      <div className={styles.secondContainer}>
        <div className={styles.imageSecondContainer}>
          <div className={styles.text}>
            {t('EXPLORE.THE_BEST')} <br /> {t('EXPLORE.BEST_ATTRACTIONS')}
          </div>
          <img src={friends} alt={t('EXPLORE.BEST_ATTRACTIONS_ALT')} />
        </div>
        <div className={styles.imageSecondContainer}>
          <div className={styles.text}>{t('EXPLORE.NEWS')}</div>
          <img src={tours} alt={t('EXPLORE.ORLANDO_ALT')} />
        </div>
      </div>
    </div>
  );
};

export default Banners;
