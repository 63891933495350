import { useState } from 'react';
import getCondominiums from 'services/condominiums/getCondominiums.service';
import useAsync from './useAsync.hook';
import Condominium from 'models/application/Condominium.model';

const useCondominiums = () => {
  const [condominiums, setCondominiums] = useState<Condominium[]>([]);

  const { loading, call: getCondominiumsByCity } = useAsync(async (checkIn: string, checkOut: string, city: string) => {
    const response = await getCondominiums(checkIn, checkOut, city);
    setCondominiums(response);
  });

  return {
    condominiums,
    loadingCondominiums: loading,
    getCondominiumsByCity,
  };
};

export default useCondominiums;
