import { MdOutlineChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

type TitleBackProps = {
  title?: string;
};

const TitleBack: React.FC<TitleBackProps> = ({ title }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.mainTitleContainer}>
      <div onClick={goBack} className={styles.backButton}>
        <MdOutlineChevronLeft size={40} color="#FFFFFF" />
      </div>
      {title ? <div className={styles.mainTitle}>{title}</div> : null}
    </div>
  );
};

export default TitleBack;
