import TranslationButton from 'components/TranslationButton';
import styles from './styles.module.scss';
import UserOptions from 'components/atoms/UserOptions';
import Logo from 'components/atoms/Logo';

const Header = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.containerDesktop}>
        <Logo />
        <div className={styles.options}>
          <div className={styles.buttons}>
            <TranslationButton />
            <UserOptions />
          </div>
        </div>
      </div>
      <div className={styles.containerNotDesktop}>
        <Logo />
        <div className={styles.icons}>
          <TranslationButton />
          <UserOptions />
        </div>
      </div>
    </div>
  );
};
export default Header;
