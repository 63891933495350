import Skeleton from '@mui/material/Skeleton';
import styles from './styles.module.scss';
import Stack from '@mui/material/Stack';

const ListHousesLoading = () => {
  return (
    <Stack spacing={1} className={styles.mainList}>
      <h1 className={styles.title}>
        <Skeleton sx={{ bgcolor: 'grey.500' }} variant="rectangular" width={265} height={39} />
      </h1>
      <div className={styles.loadingContainer}>
        <Skeleton sx={{ bgcolor: 'grey.500', borderRadius: '7px' }} variant="rectangular" width={440} height={192} />
        <Skeleton sx={{ bgcolor: 'grey.500', borderRadius: '7px' }} variant="rectangular" width={440} height={192} />
        <Skeleton sx={{ bgcolor: 'grey.500', borderRadius: '7px' }} variant="rectangular" width={440} height={192} />
        <Skeleton sx={{ bgcolor: 'grey.500', borderRadius: '7px' }} variant="rectangular" width={440} height={192} />
      </div>
    </Stack>
  );
};

export default ListHousesLoading;
