export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const isValidFullName = (value?: string): boolean => {
  if (typeof value !== 'string') return false;
  const names = value.trim().split(/\s+/);
  return names.length >= 2;
};

export const isValidPhoneNumber = (phone?: number): boolean => {
  if (!phone) return false;
  const phoneRegex = /^(?:\(?([1-9][0-9])\)?\s?)?(\d{4,5})-?(\d{4})$/;
  return phoneRegex.test(phone.toString());
};

export const isValidCPF = (cpfParam?: string): boolean => {
  if (!cpfParam) return false;

  let cpf = cpfParam.toString();

  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11) return false;
  if (/^(\d)\1{10}$/.test(cpf)) return false;

  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export const isValidCNPJ = (cnpjParam?: string): boolean => {
  if (!cnpjParam) return false;

  const cnpj = cnpjParam.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1+$/.test(cnpj)) return false;

  let length = cnpj.length - 2;
  let numbers = cnpj.substring(0, length);
  const digits = cnpj.substring(length);
  let sum = 0;
  let pos = length - 7;

  for (let i = length; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    sum += +numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== +digits.charAt(0)) return false;

  length += 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;

  for (let i = length; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    sum += +numbers.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== +digits.charAt(1)) return false;

  return true;
};
