import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import FeatureRow from 'components/BackOffice/FeatureRow';
import useAsync from 'hooks/useAsync.hook';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import reservationDetails from 'services/Management/getReservartionDetail.service';
import { maskPhoneBR, maskPhoneEN, maskPhoneES } from 'utils/masks';
import ReservationRecordsTable from './Components/ReservationRecordsTable';
import i18n from 'languages';
import moment from 'moment';
import 'moment/locale/es';
import { Language } from 'pages/Reservations/components/ReservationTable';
import styles from './styles.module.scss';
import { Buttons } from 'components/molecules/buttons';
import { showErrorMessage, showSuccessMessage } from 'utils/Toast';
import sendVoucher from 'services/Management/sendVoucher.service';
import PointMap from 'components/Maps/PointMap/Location.component';
import { useTranslation } from 'react-i18next';
import { IoMdAdd } from 'react-icons/io';
import sendHistory from 'services/Management/sendHistory.service';
import { toast } from 'react-toastify';

//TODO: REFATORAR A TELA

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const categories = [
  {
    categoryValue: 'PRE_RESERVA',
    categoryName: 'Pré-Reserva',
  },
  {
    categoryValue: 'CONFIRMACAO_RESERVA',
    categoryName: 'Confirmação Reserva',
  },
  {
    categoryValue: 'PAGAMENTO_APROVADO',
    categoryName: 'Pagamento Aprovado',
  },
  {
    categoryValue: 'ALTERACAO_RESERVA',
    categoryName: 'Alteração Reserva',
  },
  {
    categoryValue: 'CHECKIN',
    categoryName: 'Check-in',
  },
  {
    categoryValue: 'SERVICO_ADICIONAL',
    categoryName: 'Serviço Adicional',
  },
  {
    categoryValue: 'CANCELAMENTO',
    categoryName: 'Cancelamento',
  },
  {
    categoryValue: 'CRIACAO_PAGAMENTO',
    categoryName: 'Criação Pagamento',
  },
  {
    categoryValue: 'SUPORTE',
    categoryName: 'Suporte',
  },
];

const Status: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div>
      {status === 'PRÉ-RESERVA' && <label style={{ color: '#5FBDFF' }}>{t('BACKOFFICE.TABLE.PRE_BOOKING')}</label>}
      {status === 'CONFIRMADA' && <label style={{ color: '#046408' }}>{t('BACKOFFICE.TABLE.CONFIRMED')}</label>}
      {status === 'CANCELADA' && <label style={{ color: '#E00000' }}>{t('BACKOFFICE.TABLE.CANCELED')}</label>}
    </div>
  );
};

const LabelBlue: React.FC<any> = ({ text, children }) => {
  return (
    <div style={{ display: 'flex', marginTop: '10px' }}>
      <label style={{ color: '#0089f6' }}>{text}</label>
      <label style={{ marginLeft: '8px', color: '#252525', fontSize: '14px' }}>{children}</label>
    </div>
  );
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 8 }}>{children}</Box>}
    </div>
  );
};

const CustomTabTable = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ReservationDetails = () => {
  const initialLanguage = i18n.language as Language;
  const { t } = useTranslation();
  const { reserveId } = useParams<{ reserveId: string }>();
  const title = `${t('BACKOFFICE.RESERVATION_DETAIL.RESERVATION_SUMMARY')} - ${reserveId}`;
  const [reservationResume, setReservationResume] = useState<any>();
  const [tableKey, setTableKey] = useState<any>();
  const reservationPayment = reservationResume?.reservation?.payment;
  const paymentId = reservationResume?.reservation?.payment?.id;
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const loading = false;
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [category, setCategory] = useState<any>('');
  const [text, setText] = useState<any>('');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { call: getReservationResume } = useAsync(async () => {
    const response = await reservationDetails(location.state.idReservation);
    setReservationResume(response);
    // setReservationData(reservationList.reservations);
  }, []);

  const { call: postHistory } = useAsync(async (payId: any, description: any, categ: any) => {
    const { name } = JSON.parse(sessionStorage.getItem('user') || '{}');
    try {
      const response = (await sendHistory(payId, name, description, categ)) as any;
      setTableKey(response.id);
      toast.success('Enviado com sucesso');
      setIsDialogOpen(false);
    } catch (error) {
      console.warn(error);
      toast.warn('Algo deu errado');
    }
  }, []);

  const handleVoucher = async () => {
    try {
      await sendVoucher(reservationPayment?.id);
      showSuccessMessage('Voucher enviado com sucesso');
    } catch {
      showErrorMessage('Erro ao enviar o voucher');
    }
  };

  const formatDate = (dateString: string, language: Language): string => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      moment.locale('pt-br');
      const date = moment(dateString, 'YYYY-MM-DD');
      const formattedDate = date.format('dddd, DD [de] MMMM, YYYY');

      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    } else if (language === 'es') {
      moment.locale('es');
      const date = moment(dateString, 'YYYY-MM-DD');
      const formattedDate = date.format('dddd, D [de] MMMM [de] YYYY');

      return formattedDate;
    } else {
      moment.locale('en');
      const date = moment(dateString, 'YYYY-MM-DD');
      const formattedDate = date.format('dddd, MMMM DD, YYYY');

      return formattedDate;
    }
  };

  const formatPhoneNumber = (phone: string, language: Language) => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      return maskPhoneBR(phone);
    } else if (language === 'es') {
      return maskPhoneES(phone);
    } else if (language === 'en') {
      return maskPhoneEN(phone);
    }
  };

  const convertCurrency = (dollar: any, real: any, language: Language) => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      return `R$ ${real}`;
    } else {
      return `$ ${dollar}`;
    }
  };

  const calculateValuePerDay = (language: Language) => {
    const dollar = Number(reservationPayment?.totalRentWithoutTax) + Number(reservationPayment?.payTaxValue);
    const real =
      Number(reservationPayment?.totalRentWithoutTaxConverted) + Number(reservationPayment?.payTaxValueConverted);
    const days = Number(location.state.days);
    if (language === 'pt-BR' || language === 'pt-PT') {
      return `R$ ${(real / days).toFixed(3).slice(0, -1)} x ${days}`;
    } else {
      return `$ ${(dollar / days).toFixed(3).slice(0, -1)} x ${days}`;
    }
  };

  const calculateTotal = (language: Language) => {
    const totalDolar = reservationPayment?.partialRent;
    /*
      Number(reservationPayment?.payTaxValue) +
      Number(reservationPayment?.totalRentWithoutTax) +
      Number(reservationPayment?.totalRentServices) +
      Number(reservationPayment?.totalService) +
      Number(reservationPayment?.totalTax) +
      Number(reservationPayment?.agencyTaxValue) +
      Number(reservationPayment?.totalDiscount);
      */

    const totalReal = reservationPayment?.partialRentConverted;
    /*
      Number(reservationPayment?.payTaxValueConverted) +
      Number(reservationPayment?.totalRentWithoutTaxConverted) +
      Number(reservationPayment?.totalRentServicesConverted) +
      Number(reservationPayment?.totalServiceConverted) +
      Number(reservationPayment?.totalTaxConverted) +
      Number(reservationPayment?.agencyTaxValueConverted) +
      Number(reservationPayment?.totalDiscountConverted);
      */

    if (language === 'pt-BR' || language === 'pt-PT') {
      return `R$ ${totalReal}`;
    } else {
      return `$ ${totalDolar}`;
    }
  };

  const handleModal = () => {
    getReservationResume();
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleSelect = (event: any) => {
    setCategory(event?.target?.value);
  };

  const handleText = (event: any) => {
    setText(event?.target?.value);
  };

  const handleHistory = () => {
    postHistory(paymentId, text, category);
  };

  const handleOpenLink = () => {
    window.open(reservationResume?.reservation?.paymentLink);
  };

  useEffect(() => {
    getReservationResume();

    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  return (
    <div>
      <FeatureRow title={title} noBorder={true} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={t('BACKOFFICE.RESERVATION_DETAIL.SUMMARY')} {...a11yProps(0)} />
          <Tab label={t('BACKOFFICE.RESERVATION_DETAIL.VALUES')} {...a11yProps(1)} />
          <Tab label={t('BACKOFFICE.RESERVATION_DETAIL.HISTORY')} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={2} item={true}>
          <Grid item xs={12}>
            {t('BACKOFFICE.RESERVATION_DETAIL.RESERVATION_SUMMARY')}
          </Grid>
        </Grid>
        <Grid xs={12} container spacing={2} style={{ marginTop: '30px' }} item={true}>
          <Grid item height={'100%'} md={6} style={{ marginBottom: '84px' }}>
            <label style={{ fontWeight: 600 }}>{t('BACKOFFICE.RESERVATION_DETAIL.DATES')}</label>
            <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
              <LabelBlue text={'Check-in:'}>
                {formatDate(reservationResume?.reservation?.checkin, currentLanguage)}
              </LabelBlue>
              <LabelBlue text={'Check-Out:'}>
                {formatDate(reservationResume?.reservation?.checkout, currentLanguage)}
              </LabelBlue>
            </div>
            <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
              <LabelBlue text={'Status:'}>
                <Status status={location.state.status} />
              </LabelBlue>
              <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.DAILY_RATE')}:`}> {location.state.days}</LabelBlue>
            </div>
            <Divider style={{ marginTop: '40px', width: '80%' }} color={'#707070'} />
          </Grid>
          <Grid item md={6}>
            <label style={{ fontWeight: 600 }}>{t('BACKOFFICE.RESERVATION_DETAIL.GUESTS')}</label>
            <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
              <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.NAME')}:`}>{location.state.nameClient}</LabelBlue>
              <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.CONTACT')}:`}>
                {' '}
                {formatPhoneNumber(reservationResume?.reservation?.phoneNumber, currentLanguage)}
              </LabelBlue>
              <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.ADULTS')}:`}>
                {reservationResume?.house?.maxOccupancy}
              </LabelBlue>
            </div>
            <Divider style={{ marginTop: '85px', width: '80%' }} color={'#707070'} />
          </Grid>
          <Grid item md={6} style={{ marginBottom: '84px' }}>
            <label style={{ fontWeight: 600 }}>{t('BACKOFFICE.RESERVATION_DETAIL.ACCOMMODATION_ADDRESS')}</label>
            <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '85%' }}>
                <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.ADDRESS')}:`}>
                  {reservationResume?.house?.adress}
                </LabelBlue>
                <label
                  onClick={() => setShowMap(!showMap)}
                  style={{ textDecoration: 'underline black', fontSize: '14px', cursor: 'pointer', marginTop: '4px' }}
                >
                  {t('BACKOFFICE.RESERVATION_DETAIL.SHOW_ON_MAP')}
                </label>
              </div>
              <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.HOUSE_ID')}:`}>
                {reservationResume?.reservation?.id}
              </LabelBlue>
            </div>
            <Divider style={{ marginTop: '40px', width: '80%' }} color={'#707070'} />
            {showMap && (
              <PointMap
                markers={[
                  {
                    idLocation: 0,
                    coordinates: {
                      lat: reservationResume?.house?.latitude,
                      long: reservationResume?.house?.longitude,
                    },
                  },
                ]}
                title={'DETALHES'}
              />
            )}
          </Grid>
          <Grid item md={6}>
            <label style={{ fontWeight: 600 }}>{t('BACKOFFICE.RESERVATION_DETAIL.DETAILS')}</label>
            <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
              <LabelBlue text={`${t('BACKOFFICE.RESERVATION_DETAIL.CREATE_AT')}:`}>
                {reservationResume?.reservation?.createdAt}
              </LabelBlue>
            </div>
            <Divider style={{ marginTop: '68px' }} color={'#707070'} />
          </Grid>
        </Grid>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '275px' }}>
            {location.state.status === 'CONFIRMADA' && (
              <Buttons.NeutralWhite loading={loading} variant="contained" onClick={handleVoucher}>
                {'Voucher'}
              </Buttons.NeutralWhite>
            )}
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={6} style={{ marginTop: '30px' }}>
          <Grid item xs={6} style={{}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '42px' }}>
              <label style={{ marginBottom: '30px', fontWeight: 600, fontSize: 24 }}>
                {t('BACKOFFICE.RESERVATION_DETAIL.PAYMENT_INFORMATION')}
              </label>
              <label style={{ fontWeight: 600 }}>{t('BACKOFFICE.RESERVATION_DETAIL.AMOUNTS')}</label>
              <div className={styles.paymentValuesContainer}>
                <label>
                  {convertCurrency(
                    Number(reservationPayment?.totalRentWithoutTax) + Number(reservationPayment?.payTaxValue),
                    Number(reservationPayment?.totalRentWithoutTaxConverted) +
                      Number(reservationPayment?.payTaxValueConverted),
                    currentLanguage,
                  )}
                </label>
                <label>{calculateValuePerDay(currentLanguage)}</label>
              </div>
              <div className={styles.paymentValuesContainer}>
                <label>{t('BACKOFFICE.RESERVATION_DETAIL.CLEANING_FEE')}</label>
                <label>
                  {convertCurrency(
                    reservationPayment?.totalRentServices,
                    reservationPayment?.totalRentServicesConverted,
                    currentLanguage,
                  )}
                </label>
              </div>
              <div className={styles.paymentValuesContainer}>
                <label>{t('BACKOFFICE.RESERVATION_DETAIL.GOWEASY_SERVICE_FEE')}</label>
                <label>
                  {convertCurrency(
                    reservationPayment?.totalService,
                    reservationPayment?.totalServiceConverted,
                    currentLanguage,
                  )}
                </label>
              </div>
              <div className={styles.paymentValuesContainer}>
                <label>{t('BACKOFFICE.RESERVATION_DETAIL.TAXES')}</label>
                {convertCurrency(reservationPayment?.totalTax, reservationPayment?.totalTaxConverted, currentLanguage)}
              </div>
              <div className={styles.paymentValuesContainer}>
                <label>{t('BACKOFFICE.RESERVATION_DETAIL.COMMISSION')}</label>
                <label>
                  {convertCurrency(
                    reservationPayment?.agencyTaxValue,
                    reservationPayment?.agencyTaxValueConverted,
                    currentLanguage,
                  )}
                </label>
              </div>
              {reservationPayment?.totalDiscount && (
                <div className={styles.paymentValuesContainer}>
                  <label>{t('CARD.LONG_STAY_DISCOUNT')}</label>
                  <label>
                    {convertCurrency(
                      reservationPayment?.totalDiscount,
                      reservationPayment?.totalDiscountConverted,
                      currentLanguage,
                    )}
                  </label>
                </div>
              )}
              <div className={styles.paymentValuesContainer}>
                <label>Total</label>
                <label>{calculateTotal(currentLanguage)}</label>
              </div>
              <Divider style={{ marginTop: '32px' }} color="#707070" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '42px' }}>
                <label style={{ marginBottom: '30px', fontWeight: 600, fontSize: 24 }}>
                  {t('BACKOFFICE.RESERVATION_DETAIL.REFUND_POLICY')}
                </label>
                <label style={{ marginBottom: '32px' }}>
                  {t('BACKOFFICE.RESERVATION_DETAIL.PAYMENT_INFORMATION_TEXT')}
                </label>
                <Divider color="#707070" />
              </div>
            </div>
          </Grid>
          {reservationResume?.reservation?.paymentLink && (
            <Grid item xs={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '42px' }}>
                  <label style={{ marginBottom: '30px', fontWeight: 600, fontSize: 24 }}>
                    {t('BACKOFFICE.RESERVATION_DETAIL.PAYMENT_LINK')}
                  </label>
                  <span className={styles.paymentLinkText} style={{ marginBottom: '32px' }} onClick={handleOpenLink}>
                    {reservationResume?.reservation?.paymentLink}
                  </span>
                  <Divider color="#707070" />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
      <CustomTabTable value={value} index={2}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'right', marginTop: '24px' }}>
          <Buttons.Primary variant="contained" onClick={handleModal}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <label style={{ marginRight: '8px' }}>Adicionar</label>
              <IoMdAdd />
            </div>
          </Buttons.Primary>
        </div>
        <ReservationRecordsTable key={tableKey} paymentId={reservationPayment?.paymentId} />
        <Dialog fullWidth open={isDialogOpen} onClose={handleClose} maxWidth={'sm'}>
          <DialogContent style={{ overflow: 'hidden' }}>
            <Box className={styles.dialogContainer}>
              <h2 className={styles.title}>Customer Service</h2>
              <Box className={styles.selecContainer}>
                <FormControl fullWidth>
                  <InputLabel>Categoria</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={category}
                    label="Categoria"
                    onChange={handleSelect}
                  >
                    {categories.map((c) => (
                      <MenuItem value={c.categoryValue}>{c.categoryName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TextField fullWidth multiline rows={8} placeholder="Descrição" onChange={handleText}></TextField>
              <div className={styles.buttonContainer}>
                <Buttons.NeutralWhite onClick={handleClose} className={styles.buttonWhite} fullWidth>
                  Cancelar
                </Buttons.NeutralWhite>
                <Buttons.Primary onClick={handleHistory} fullWidth>
                  Enviar
                </Buttons.Primary>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      </CustomTabTable>
    </div>
  );
};

export default ReservationDetails;
