import { Helmet } from 'react-helmet';

const SEO = () => {
  const title = 'GoWeasy';
  const description =
    'Discover the perfect stay with our accommodation booking app. Experience hassle-free travel planning and explore a wide range of short-term rentals, vacation homes, and unique holiday accommodations worldwide. Whether youre seeking a cozy homestay, a luxurious villa, or a serene cabin in the woods, our platform connects you with countless options to make your travel dreams a reality. Unleash the joy of wanderlust and book your next unforgettable stay with us today!';
  const keywords =
    'Travel, Travel accommodation platform, Vacation, Vacation home bookings, Accommodation rentals, Short-term lodging, Holiday rentals, Vacation property listings';
  const author = '---, ----, ----';
  const image = 'https://www.___.com/logo.png';
  const url = 'https://www.___.com';

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="image" content={image} />
      <meta property="og:image" content={image} />
      <meta property="twitter:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="@kitsunesoftware" />
      <meta name="twitter:creator" content="@yourtwitterhandle" />
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "${title}",
          "url": "${url}",
          "logo": "${image}",
          "description": "${description}"
        }
      `}</script>
    </Helmet>
  );
};

export default SEO;
