import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
// TODO: Criar os tipos
const getReservationAndReports = async (id: any, startDate: any, endDate: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(
    `/service/v1/house/reservation/agencie/${id}?startDate=${startDate}&endDate=${endDate}`,
  );

  return data;
};

export default getReservationAndReports;
