import FeatureRow from 'components/BackOffice/FeatureRow';
import { useEffect, useMemo, useState } from 'react';
import OperatorRegisterForm from './OperatorRegisterForm';
import AgencyRegisterForm from './AgencyRegisterForm';
import { useLocation, useNavigate } from 'react-router-dom';
import Operator from 'models/application/Operator/Operator.model';
import createAgent from 'services/Agent/createAgent.service';
import AgencyAndUserForm from 'models/application/Agency/AgencyAndUser.model';
import createOperator from 'services/operators/createOperator';
import createAgency from 'services/Agency/createAgency.service';
import { showErrorMessage } from 'utils/Toast';
import { toast } from 'react-toastify';
import i18n from 'languages';
import { Language } from '../ReportsList/ReportsTable';
import OperatorRegisterFormEN from './OperatorRegisterFormEN';
import AgencyRegisterFormEN from './AgencyRegisterFormEN';
import AgencyAndUserEN from 'models/application/Agency/AgencyAndUserEN.model';
import AgencyRegisterFormES from './AgencyRegisterFormES';
import AgencyAndUserES from 'models/application/Agency/AgencyAndUserES.model';
import OperatorRegisterFormES from './OperatorRegisterFormES';
import { getUser } from 'services/Login/user.service';

const RegisterOperatorAndAgency = () => {
  const initialLanguage = i18n.language as Language;
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);

  const location = useLocation();
  const navigate = useNavigate();
  const formName = location?.state?.form;
  const user = useMemo(() => getUser(), []);
  const [operatorFormValue, setOperatorFormValue] = useState<Operator>();
  const [agencyAnduserValues, setAgencyAndUserValues] = useState<AgencyAndUserForm>();
  const [agencyAnduserENValues, setAgencyENAndUserValues] = useState<AgencyAndUserEN>();
  const [agencyAnduserESValues, setAgencyESAndUserValues] = useState<AgencyAndUserES>();

  const [step, setStep] = useState(formName || 'Operator');
  const title = step === 'Agency' ? 'Cadastrar Agencia' : 'Cadastrar Operadora'; // TODO: traduzir

  const goToAgencyStep = (formValue: Operator) => {
    setOperatorFormValue(formValue);
    setStep('Agency');
  };

  const submitForm = (agencyAndUserInfo: AgencyAndUserForm) => {
    setAgencyAndUserValues(agencyAndUserInfo);
    onSubmit(operatorFormValue, agencyAndUserInfo);
  };

  const submitFormEN = (agencyAndUserInfo: AgencyAndUserEN) => {
    setAgencyENAndUserValues(agencyAndUserInfo);
    onSubmitEN(operatorFormValue, agencyAndUserInfo);
  };
  const submitFormES = (agencyAndUserInfo: AgencyAndUserES) => {
    setAgencyESAndUserValues(agencyAndUserInfo);
    onSubmitES(operatorFormValue, agencyAndUserInfo);
  };

  const onSubmit = async (operatorData: Operator | undefined, agencyAndUserData: AgencyAndUserForm) => {
    let operatorId = location?.state?.operatorId || null;
    try {
      if (formName === 'Operator') {
        operatorId = await createOperator(operatorData as Operator);
      }

      const agencyId = await createAgency({
        name: agencyAndUserData.agencyName,
        document: agencyAndUserData.agencyCnpj,
        primaryContact: String(agencyAndUserData.agencyCellphone),
        numberState: agencyAndUserData.stateRegistration,
        cadastur: agencyAndUserData.cadastur,
        urlSite: agencyAndUserData.siteUrl,
        email: agencyAndUserData.agencyEmail,
        language: 'PORTUGUESE',
        country: agencyAndUserData.agencyCountry,
        address: agencyAndUserData.agencyAddress,
        city: agencyAndUserData.agencyCity,
        operadoraId: operatorId,
      });

      await createAgent({
        name: agencyAndUserData.agentName,
        cpf: agencyAndUserData.agentCpf,
        email: agencyAndUserData.agentEmail,
        phone: agencyAndUserData.phone,
        agencyCode: agencyId,
        address: `${agencyAndUserData.complement} ${agencyAndUserData.number}`,
        zipcode: String(agencyAndUserData.cep),
        country: 'Brasil',
        password: agencyAndUserData.password,
        role: formName === 'Operator' ? 'OPERATOR' : 'ADMIN',
      });
      toast.success('Cadastro realizado com sucesso!');
      if (user.isOperator()) {
        navigate(-1);
      } else {
        navigate('/admin/operators-list');
      }
    } catch (err: any) {
      showErrorMessage(err?.response?.data);
    }
  };

  const onSubmitEN = async (operatorData: Operator | undefined, agencyAndUserData: AgencyAndUserEN) => {
    let operatorId = location?.state?.operatorId || null;
    try {
      if (formName === 'Operator') {
        operatorId = await createOperator(operatorData as Operator);
      }
      const agencyId = await createAgency({
        name: agencyAndUserData.name,
        document: agencyAndUserData.association,
        primaryContact: agencyAndUserData.cellphone,
        numberState: agencyAndUserData.associationCode,
        email: agencyAndUserData.email,
        language: 'ENGLISH',
        country: agencyAndUserData.country,
        address: agencyAndUserData.address,
        city: agencyAndUserData.city,
        operadoraId: operatorId,
      });

      await createAgent({
        name: `${agencyAndUserData.firstName} ${agencyAndUserData.lastName}`,
        email: agencyAndUserData.emailUser,
        phone: agencyAndUserData.phone,
        agencyCode: agencyId,
        address: agencyAndUserData.address,
        zipcode: agencyAndUserData.zipCode,
        association: agencyAndUserData.association,
        associationId: agencyAndUserData.associationCode,
        state: agencyAndUserData.state,
        country: agencyAndUserData.country,
        city: agencyAndUserData.city,
        password: agencyAndUserData.password,
        role: formName === 'Operator' ? 'OPERATOR' : 'ADMIN',
      });
      toast.success('Registration successfully completed!');
      if (user.isOperator()) {
        navigate(-1);
      } else {
        navigate('/admin/operators-list');
      }
    } catch (err: any) {
      showErrorMessage(err?.response?.data);
    }
  };

  const onSubmitES = async (operatorData: Operator | undefined, agencyAndUserData: AgencyAndUserES) => {
    let operatorId = location?.state?.operatorId || null;
    try {
      if (formName === 'Operator') {
        operatorId = await createOperator(operatorData as Operator);
      }
      const agencyId = await createAgency({
        name: agencyAndUserData.name,
        primaryContact: agencyAndUserData.cellphone,
        email: agencyAndUserData.email,
        language: 'SPANISH',
        document: agencyAndUserData.corporateName,
        country: agencyAndUserData.country,
        address: agencyAndUserData.street,
        city: agencyAndUserData.city,
        operadoraId: operatorId,
      });

      await createAgent({
        name: `${agencyAndUserData.firstName} ${agencyAndUserData.lastName}`,
        email: agencyAndUserData.emailUser,
        phone: agencyAndUserData.phone,
        agencyCode: agencyId,
        address: agencyAndUserData.street,
        country: agencyAndUserData.country,
        password: agencyAndUserData.password,
        role: formName === 'Operator' ? 'OPERATOR' : 'ADMIN',
      });

      toast.success('¡Registro realizado con éxito!');
      if (user.isOperator()) {
        navigate(-1);
      } else {
        navigate('/admin/operators-list');
      }
    } catch (err: any) {
      showErrorMessage(err?.response?.data);
    }
  };

  const renderForms = (l: Language) => {
    if (step === 'Operator') {
      if (l === 'en') {
        return <OperatorRegisterFormEN handleNext={goToAgencyStep} operatorValue={operatorFormValue} />;
      } else if (l === 'pt-BR' || l === 'pt-PT') {
        return <OperatorRegisterForm handleNext={goToAgencyStep} operatorValue={operatorFormValue} />;
      } else if (l === 'es') {
        return <OperatorRegisterFormES handleNext={goToAgencyStep} operatorValue={operatorFormValue} />;
      }
    }
    if (step === 'Agency') {
      if (l === 'en') {
        return <AgencyRegisterFormEN handleSubmitForm={submitFormEN} agencyValues={agencyAnduserENValues} />;
      } else if (l === 'pt-BR' || l === 'pt-PT') {
        return <AgencyRegisterForm handleSubmitForm={submitForm} agencyValues={agencyAnduserValues} />;
      } else if (l === 'es') {
        return <AgencyRegisterFormES handleSubmitForm={submitFormES} agencyValues={agencyAnduserESValues} />;
      }
    }
  };

  useEffect(() => {
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  return (
    <div>
      <FeatureRow title={title}>
        <div></div>
      </FeatureRow>
      {renderForms(currentLanguage)}
    </div>
  );
};

export default RegisterOperatorAndAgency;
