import React, { useMemo } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import { TextFieldVariants } from '@mui/material/TextField';

import { IInputProps } from '../defaultSettings';
import { TextField, TextFieldWhite } from './styles';

type TMask = string | Array<string | RegExp>;

export interface ITextProps<T extends FieldValues = FieldValues> extends IInputProps<T> {
  defaultValue?: string | number | readonly string[];
  type?: React.HTMLInputTypeAttribute;
  variant?: TextFieldVariants;
  disableMaskOnRequest?: boolean;
  mask: TMask;
  id: any;
  whiteVersion?: boolean;
  onBlur?: any;
  inputRef?: any;
  InputProps?: any;
}

const Mask = <T extends FieldValues>(props: ITextProps<T>): React.ReactElement<T> => {
  const {
    name,
    form,
    defaultValue,
    disableMaskOnRequest = true,
    mask,
    placeholder,
    id,
    whiteVersion = true,
    onBlur,
    inputRef,
    InputProps,
    ...rest
  } = props;
  const { formState, setValue } = form;

  const { error } = useMemo(() => {
    return form.getFieldState(name);
  }, [formState.errors]);

  const onChangeMask = (value: string) => {
    return setValue(name, value as any);
  };

  return (
    <div style={{ width: '100%' }}>
      <Controller
        name={name}
        defaultValue={defaultValue as any}
        control={form.control}
        render={({ field }) => (
          <ReactInputMask
            mask={mask}
            placeholder={placeholder}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
              if (disableMaskOnRequest) {
                onChangeMask(event.target.value.replace(/\D+/g, ''));
              }
            }}
            onBlur={onBlur}
            inputRef={inputRef}
          >
            {/*@ts-ignore */}
            {() => {
              return whiteVersion ? (
                <TextFieldWhite
                  {...rest}
                  id={id}
                  fullWidth
                  placeholder={placeholder}
                  variant="outlined"
                  error={!!error?.message}
                  helperText={error?.message as string}
                  size="small"
                  autoComplete="new-password"
                  inputRef={inputRef}
                />
              ) : (
                <TextField
                  {...rest}
                  id={id}
                  fullWidth
                  placeholder={placeholder}
                  variant="outlined"
                  error={!!error?.message}
                  helperText={error?.message as string}
                  size="small"
                  autoComplete="new-password"
                  onBlur={onBlur}
                  inputRef={inputRef}
                  inputProps={InputProps}
                />
              );
            }}
          </ReactInputMask>
        )}
      />
    </div>
  );
};

export default Mask;
