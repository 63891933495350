import React, { useMemo } from 'react';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

import { TextField } from './styles';

export interface IInputProps<T extends FieldValues = FieldValues> {
  form: UseFormReturn<T, FieldValues>;
  name: FieldPath<T>;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export interface ITextProps<T extends FieldValues = FieldValues> extends IInputProps<T> {
  defaultValue?: string | number | readonly string[];
  type?: React.HTMLInputTypeAttribute;
  className?: string;
  autoComplete?: string;
  id?: any;
  inputLabelProps?: any;
}

const InputText = <T extends FieldValues>(props: ITextProps<T>): React.ReactElement<T> => {
  const { name, form, defaultValue, id, ...rest } = props;
  const { formState, register } = form;

  const { error } = useMemo(() => {
    return form.getFieldState(name);
  }, [formState.errors]);

  return (
    <TextField
      {...rest}
      {...register(name)}
      defaultValue={defaultValue}
      fullWidth
      variant="outlined"
      error={!!error?.message}
      helperText={error?.message as string}
      size="small"
      className={props.className}
      id={id}
      InputLabelProps={props.inputLabelProps}
    />
  );
};

export default InputText;
