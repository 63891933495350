export default {
  RESERVATION: {
    YOUR_RESERVE: 'Sua Reserva',
    INFO: 'Informações da reserva',
    DATES: 'Datas',
    GUESTS: 'Hóspedes',
    ADDRESS: 'Endereço da hospedagem',
    CODE: 'Código de confirmação',
    RULES: 'Regras de cancelamento',
    RULES_TEXT:
      'Caso cancele até o dia 27 de setembro, será concedido um reembolso parcial. Após essa data, a reserva torna-se não reembolsável.',
    NEED_INFO: 'O que você precisa saber',
    PRINT: 'Imprimir reserva',
    NEED_INFO_1: 'Pessoas extras podem incorrer em taxas adicionais que variam dependendo da política da propriedade.',
    NEED_INFO_2:
      'Documento de identificação oficial com foto e cartão de crédito, cartão de débito ou depósito em dinheiro podem ser exigidos no momento do check-in para despesas extras.',
    NEED_INFO_3:
      'Solicitações especiais estão sujeitas à disponibilidade no momento do check-in e podem incorrer em taxas adicionais. Essas solicitações não estão garantidas. Festas ou eventos de grupos no local são estritamente proibidos Os recursos de segurança disponíveis na propriedade incluem extintor de incêndio e detector de fumaça.',
  },
};
