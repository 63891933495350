export default {
  ADMINISTRATIVE: {
    FORBIDDEN: "You don't have permission to access this page.",
    SEARCH_BY_ID_OR_CONDOMINIUM: 'Search by property ID or condominium',
    HOUSES: 'Houses',
    HOUSE_CONFIGURATION: 'House Configuration',
    NAME: 'NAME',
    CONDOMINIUM_TABLE: 'Condominium',
    CONDOMINIUM: 'CONDOMINIUM',
    PROPERTIES: 'Properties',
    ADDRESS: 'ADDRESS',
    CITY: 'CITY',
    REVIEWED_HOUSES: 'Reviewed Houses',
    PUBLISHED_HOUSES: 'Published Houses',
    PENDING_HOUSES: 'Pending Houses',
    INACTIVES_HOUSES: 'Inactive Houses',
    TOTAL_HOUSES: 'Total Houses',
    REVIEWED: 'Reviewed',
    PUBLISHED: 'Published',
    PENDING: 'Pending',
    INACTIVES: 'Inactive',
    SAVE_SUCCESS: 'Successfully saved!',
    SAVE_FAIL: 'Something went wrong.',
    TOTAL_CONDOMINIUMS: 'Total Condominiums',
    NAME_INPUT: 'Name',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    ADD_VIDEO_OR_PHOTO: 'Add Video or Photo of the condominium presentation',
    VIDEO_LIMIT: 'Video up to 20 seconds and 10MB',
    SELECT_FILE: 'Select File',
    TITLE_IN_ENGLISH: 'Title in English',
    TITLE_IN_SPANISH: 'Title in Spanish',
    TITLE_IN_PORTUGUESE: 'Title in Portuguese',
    DESCRIPTION_IN_ENGLISH: 'Description in English',
    DESCRIPTION_IN_SPANISH: 'Description in Spanish',
    DESCRIPTION_IN_PORTUGUESE: 'Description in Portuguese',
    ADD_NEW_CONDOMINIUM: 'Add new condominium',
    SAVE: 'Save',
    SAVED: 'Saved',
    SAVED_SUCCESSFULLY: 'Successfully saved!',
    SAVE_ERROR: 'Something went wrong while saving.',
    SOMETHING_WENT_WRONG: 'Something went wrong.',
    NEW_CONDOMINIUM: 'New Condominium',
    PUBLISH: 'Publish',
    MAIN: {
      NAME: 'Name',
      CONTACT: 'Contact',
      SITE: 'URL Site',
      IDIOM: 'Language',
      PROFIT_PERCENTAGE: 'Profit Percentage',
      ACTIVE: 'Active',
      NOT_INFORMATION: 'Not informed',
      AGENTS: 'Agents',
      YES: 'Yes',
      NO: 'No',
    },
    REPORTS: {
      RESERVATIONS: 'RESERVATION',
      PROPERTY: 'PROPERTY',
      PM: 'P.M',
      OPERATOR: 'OPERATOR',
      AGENCY: 'AGENCY',
      AGENT: 'AGENT',
      SALE: 'SALE',
      DAILY: 'NIGHTLY RATE',
      CLEANING: 'CLEANING',
      COMMISSION_LP: 'LP COMMISSION',
      COMMISSION_AG: 'AG COMMISSION',
      COMMISSION_GW: 'GW COMMISSION',
      TAXES: 'TAXES',
      DISCOUNTS: 'DISCOUNTS',
      GW_FEE: 'GW FEE',
      TOTAL_GW: 'TOTAL GW',
      DATE: 'DATE',
      DOLLAR_EXCHANGE_RATE: 'DOLLAR EXCHANGE RATE',
      PAGTOUR_FEE: 'PAGTOUR FEE',
      FINANCIAL_REPORT: 'Financial Report',
      SELECT_MONTH: 'Select a month',
      GOAL_SUCCESS: 'Goal successfully created',
      PERCENTAGE: 'Percentage',
      REGISTER: 'Register',
      GOAL_REGISTRATION: 'Goal Registration',
      REGISTER_AGENCY: 'Register Agency',
    },
    MONTHS: {
      JANUARY: 'JANUARY',
      FEBRUARY: 'FEBRUARY',
      MARCH: 'MARCH',
      APRIL: 'APRIL',
      MAY: 'MAY',
      JUNE: 'JUNE',
      JULY: 'JULY',
      AUGUST: 'AUGUST',
      SEPTEMBER: 'SEPTEMBER',
      OCTOBER: 'OCTOBER',
      NOVEMBER: 'NOVEMBER',
      DECEMBER: 'DECEMBER',
    },
    MONTHS_ACRONYMS: {
      JANUARY: 'JAN',
      FEBRUARY: 'FEB',
      MARCH: 'MAR',
      APRIL: 'APR',
      MAY: 'MAY',
      JUNE: 'JUN',
      JULY: 'JUL',
      AUGUST: 'AUG',
      SEPTEMBER: 'SEP',
      OCTOBER: 'OCT',
      NOVEMBER: 'NOV',
      DECEMBER: 'DEC',
    },
    DASHBOARD: {
      TITLE_MONTH: 'Monthly',
      SUBTITLE_MONTH: 'Goal',
      TITLE_DAILY: 'Daily',
      SUBTITLE_DAILY: 'Goal',
      TITLE_CANCEL: 'Canceled',
      SUBTITLE_CANCEL: 'Reservations',
      TITLE_PROFIT: 'Profitability',
      SUBTITLE_PROFIT: 'Achieved',
    },
  },
};
