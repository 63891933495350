import { axiosInstances } from 'services/api';

// TODO: Criar tipo
const updateHouseAdm = async (formData: any): Promise<any> => {
  const { data } = await axiosInstances.base.instance.post(`/service/v1/house/update`, formData);

  return data;
};

export default updateHouseAdm;
