/* eslint-disable max-len */

export default {
  WHO_WE_ARE: {
    TITLE: 'Quiénes Somos',
    SUBTITLE: '¡Bienvenidos a GoWeasy, tu plataforma exclusiva para alquileres de casas de vacaciones!',
    PARAGRAPH: `
      GoWeasy nació para facilitar la búsqueda de la mejor experiencia de alojamiento en una casa de vacaciones. La idea surgió basada en la experiencia personal que los fundadores adquirieron a lo largo de casi 30 años trabajando en el mercado turístico. Como propietarios de una gran red de agencias de viajes y una empresa de Gestión de Propiedades, conocemos los desafíos que enfrenta un cliente al buscar la casa perfecta y, aún más, la atención recibida durante su estadía.
      <br/> <br/>
      Con tantas opciones que ofrece el mercado, no siempre es fácil alcanzar estos objetivos y conectar la casa perfecta con el cliente.
      <br/> <br/>
      Aquí nos enfocamos en simplificar la experiencia de reserva de casas de vacaciones para agentes de viajes.
      <br/> <br/>
      Nuestra plataforma ha sido diseñada en cada detalle para conectar perfectamente tu empresa con una selección premium de casas de vacaciones. Al elegir GoWeasy, tendrás acceso a un sistema poderoso que simplifica tu búsqueda, ahorrando tiempo valioso, garantizando reservas eficientes y experiencias únicas para tus clientes.
      <br/> <br/>
      Nuestro objetivo es proporcionar una asociación confiable que transforme desafíos en soluciones. Con un equipo dedicado y calificado, ofrecemos todo el apoyo necesario para que tu empresa se destaque en la atención al cliente, maximizando tus ventas.
      <br/> <br/>
      Únete a nosotros en GoWeasy y adéntrate en este fascinante mercado de ofrecer una experiencia real en una casa estadounidense.
    `,
    PROPERTY: {
      TITLE: 'Gestores de Propiedades',
      SUBTITLE: 'Tu Viaje con GoWeasy: Asociación para el Éxito',
      PARAGRAPH: `<strong>Descubrimiento:</strong> GoWeasy ofrece una herramienta innovadora para conectar tus propiedades con un nuevo mercado de miles de agencias de viajes (OTAS, Travel Techs, Influencers Digitales) en varios países, brindando la oportunidad de aumentar tus tasas de ocupación y rentabilidad. GoWeasy es la solución que estabas buscando para complementar tu estrategia de distribución.
      <br/> <br/>
      <strong>Asociación Inmediata:</strong> Envía tu información y asegura una rápida aprobación como socio de GoWeasy. ¡Te unirás a una comunidad comprometida con el éxito mutuo y el crecimiento de los negocios!
      <br/> <br/>
      <strong>Listado Libre de Compromisos:</strong> ¿Lo mejor de todo? No hay costos ni obligaciones de exclusividad al enumerar tus propiedades en GoWeasy. Mantén el control mientras amplías tu presencia en un mercado diversificado.
      <br/> <br/>
      <strong>Maximiza tu Rentabilidad:</strong> Con nuestra plataforma de distribución eficiente, tus propiedades obtienen la atención que merecen y son vistas por los principales canales de distribución de la industria. ¡Aumenta tus tasas de ocupación y observa cómo se dispara tu rentabilidad!
      <br/> <br/>
      <strong>Soporte de Alto Nivel:</strong> ¡Estamos aquí para ti! Nuestro equipo experimentado ofrece orientación, apoyo continuo y valiosos conocimientos para impulsar tus resultados.
      <br/> <br/>
      <strong>Conéctate para Avanzar:</strong> ¿Interesado en nuestra propuesta? Ponte en contacto ahora mismo para iniciar una asociación que revolucionará la forma en que gestionas tus propiedades. ¡Es hora de impulsar tu negocio! Únete a GoWeasy y experimenta una nueva era de oportunidades para tus propiedades de alquiler vacacional.`,
    },
    AGENTS: {
      TITLE: 'Agentes de Turismo',
      SUBTITLE: 'Tu Viaje con GoWeasy: Desbloqueando un Mundo de Oportunidades',
      PARAGRAPH: `<strong>Explora Nuevas Posibilidades:</strong> Como OTA o agente de viajes, constantemente buscas diferenciarte para satisfacer las demandas de los clientes. En GoWeasy, descubrirás un nuevo mundo de posibilidades, especialmente creado para introducirte en este mercado de alquiler vacacional.
      <br/> <br/>
      <strong>Plataforma Flexible de Comisiones:</strong> Además, te ofrecemos la exclusiva posibilidad de gestionar tus comisiones directamente desde nuestra plataforma. Simplifica el proceso de control financiero al agregar tus comisiones de manera práctica y transparente, todo en un solo lugar.
      <br/> <br/>
      <strong>Plataforma Excepcional:</strong> Al convertirte en socio de GoWeasy, tendrás acceso a una plataforma excepcional, diseñada específicamente para satisfacer las necesidades de las OTA y agentes de viajes. Una selección exclusiva de propiedades, elegidas cuidadosamente para garantizar calidad y diversidad, estará a tu disposición.
      <br/> <br/>
      <strong>Inventario Seleccionado a Mano:</strong> Ofrecemos un inventario cuidadosamente seleccionado para cumplir con los más altos estándares de calidad. Con propiedades seleccionadas minuciosamente, podrás presentar a tus clientes una variedad única de opciones de alquiler vacacional.
      <br/> <br/>
      <strong>Soporte 24 Horas:</strong> Nuestro equipo dedicado ofrece soporte en cualquier momento, ayudándote a satisfacer las necesidades de tus clientes en el momento en que más lo necesiten.
      <br/> <br/>
      <strong>Únete a Nosotros:</strong> ¿Interesado en lo que ofrecemos? Ponte en contacto ahora mismo para explorar una asociación única. Estamos listos para ofrecer lo mejor en estancia y experiencia en una casa estadounidense.`,
    },
  },
};
