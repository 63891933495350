import styled from 'styled-components';

export const ReadMoreLabel = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
`;

export const ReadMoreText = styled.div`
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  margin-top: 16px;
`;

export const ReadMoreButton = styled.div`
  font-size: 14px;
  color: #5fbdff;
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0px 8px;

  &:hover {
    cursor: pointer;
  }
`;
