import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
// TODO: Criar os tipos
const AvailabilityList = async (startDate?: string, endDate?: string, id?: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(
    `/service/v1/house/available-values?startDate=${startDate}&endDate=${endDate}&houseId=${id}`,
  );

  return data;
};

export default AvailabilityList;
