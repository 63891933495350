import i18next from 'i18next';
import { IBooking } from './interfaces/IBooking.interface';
import { IBookingPrice } from './interfaces/IBookingPrice.interface';
import { Languages } from 'languages/helpers.languages';
import { formatMoney } from 'utils/format';

class Booking {
  checkIn: string | null;
  checkOut: string | null;
  kids: number;
  adults: number;
  pets: number;
  babies: number;
  pcd: number;
  elderly: number;
  houseId?: number;
  price?: IBookingPrice;
  bookingDates?: any[];

  constructor(booking: IBooking) {
    this.checkIn = booking.checkIn === 'null' ? null : booking.checkIn;
    this.checkOut = booking.checkOut === 'null' ? null : booking.checkOut;
    this.kids = booking.kids ?? 0;
    this.adults = booking.adults ?? 0;
    this.pets = booking.pets ?? 0;
    this.babies = booking.babies ?? 0;
    this.pcd = booking.pcd ?? 0;
    this.elderly = booking.elderly ?? 0;
    this.houseId = booking.houseId;
  }

  isValidBooking = () => {
    const currentLanguage = i18next.language;

    const total = currentLanguage === Languages.PT_BR ? this.price?.real : this.price?.dollar;
    if (!total || total === 0) return false;

    return true;
  };

  setPrice = (price: IBookingPrice) => {
    this.price = price;
  };

  setBookingDates = (dates: any[]) => {
    this.bookingDates = dates;
  };

  setHouseId = (id: number) => {
    this.houseId = id;
  };

  setPeriod = (checkIn: string, checkOut: string) => {
    this.checkIn = checkIn;
    this.checkOut = checkOut;
  };

  setGuests = (adults: number, kids: number, pcd: number, elderly: number) => {
    this.adults = adults;
    this.kids = kids;
    this.pcd = pcd;
    this.elderly = elderly;
  };

  getTotalPrice = (): string => {
    const currentLanguage = i18next.language;

    if (!this.price) return formatMoney(0);

    return formatMoney(currentLanguage === Languages.PT_BR ? this.price.real : this.price.dollar);
  };

  getTotalGuests = (): number => {
    return this.kids + this.adults + this.pcd;
  };

  getParams = () => {
    return {
      checkIn: this.checkIn,
      checkOut: this.checkOut,
      kids: this.kids,
      adults: this.adults,
      pets: this.pets,
      babies: this.babies,
      pcd: this.pcd,
      elderly: this.elderly,
    };
  };
}

export default Booking;
