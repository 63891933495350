import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useCurrentPage = () => {
  const location = useLocation();

  const isInHome = useMemo(() => location.pathname === '/', [location]);

  return {
    isInHome,
  };
};

export default useCurrentPage;
