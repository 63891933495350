import { loadStripe } from '@stripe/stripe-js';
import { APP_KEYS } from 'constants/api/keys';
import i18next from 'i18next';

export default class StripeConfig {
  paymentIntentId: string;
  clientSecretId: string;

  constructor(intentId: string, clientSecret: string) {
    this.paymentIntentId = intentId;
    this.clientSecretId = clientSecret;
  }

  getAppearanceConfig() {
    return {
      theme: 'stripe',
      variables: {
        focusBoxShadow: '',
        borderRadius: '5px',
        fontSizeSm: '16px',
      },
      labels: 'above',
      rules: {
        '.Label': {
          color: 'white',
        },
        '.CheckboxLabel': {
          color: 'white',
        },
      },
    };
  }

  getOptions(): any {
    return {
      appearance: this.getAppearanceConfig(),
      clientSecret: this.clientSecretId ?? '',
      locale: i18next.language,
      loader: 'never',
    };
  }

  getStripePromise() {
    return loadStripe(APP_KEYS.STRIPE_PUBLIC_KEY || '');
  }
}
