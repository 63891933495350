import { TableCell, TableRow } from '@mui/material';
import BasicTable from 'components/BasicTable';
import useAsync from 'hooks/useAsync.hook';
import { useEffect, useMemo, useState } from 'react';
import getLinkedAgenciesList from 'services/operators/getLinkedAgenciesList';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'components/BackOffice/Button';
import getAgency from 'services/Agency/getAgency.service';
import { showErrorMessage } from 'utils/Toast';
import ConfirmAgentModal from 'pages/BackOffice/Administrative/ListAgency/Components/ListAgencyTable/ConfirmAgentModal';
import useListAgency from 'hooks/useListAgency.hook';
import { getUser } from 'services/Login/user.service';

const LinkedAgenciesTable: React.FC<any> = ({ id, filter }) => {
  const [agencies, setAgencies] = useState([]);
  const user = useMemo(() => getUser(), []);
  const { t } = useTranslation();
  const [confirmAccessModal, setConfirmAccessModal] = useState(false);
  const [userSelected, setUserSelected] = useState<any>();
  const [comissionSelected, setComissionSelected] = useState<any>('');
  const { allowAgency } = useListAgency();
  const navigate = useNavigate();

  const collumnsNames = [
    t('BACKOFFICE.TABLE.CODE'),
    t('BACKOFFICE.TABLE.TYPE'),
    t('BACKOFFICE.TABLE.AGENCY'),
    t('BACKOFFICE.TABLE.LINKED_AGENTS'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_SALES'),
    t('BACKOFFICE.TABLE.MONTH_TOTAL_RESERVATIONS'),
    t('BACKOFFICE.TABLE.STATUS'),
    ' ',
    ' ',
  ];

  const { call: getAgenciesList } = useAsync(async (agencyId: string) => {
    const response = await getLinkedAgenciesList(agencyId);
    setAgencies(response);
  });

  const filterList = (AgencyToFilter: any[], searchTerm: string) => {
    return AgencyToFilter.filter((agency: any) => agency?.id?.toString().includes(searchTerm));
  };

  const agencyListFiltered = useMemo(() => (filter ? filterList(agencies, filter) : agencies), [agencies, filter]);

  const getType = (type?: string) => {
    if (type === 'OPERATOR') {
      return <div className={styles.user}>{t('BACKOFFICE.TABLE.OPERATOR')}</div>;
    }

    if (type === 'AGENCIE') {
      return <div className={styles.admin}>{t('BACKOFFICE.TABLE.AGENCY')}</div>;
    }

    return null;
  };

  const getStatus = (ativo?: boolean) => {
    if (ativo) {
      return <div className={styles.active}>{t('BACKOFFICE.TABLE.ACTIVE')}</div>;
    }
    return <div className={styles.inactive}>{'Inativo'}</div>;
  };

  const handleAgentsNavigation = (idAgency: any) => {
    navigate(`/admin/linked-agents/${idAgency}`);
  };

  const { loading: loadingAgency, call: getAgencyData } = useAsync(async (agId: any) => {
    try {
      const response = await getAgency(agId);
      setUserSelected(response);
      setConfirmAccessModal(true);
    } catch (err: any) {
      showErrorMessage(err?.response.data);
      throw new Error(err);
    }
  }, []);

  const confirmAllowAccess = async () => {
    if (!userSelected) return;
    await allowAgency(userSelected.id, comissionSelected);
    unselectUser();
    getAgenciesList(id);
  };

  const unselectUser = () => {
    setConfirmAccessModal(false);
    setUserSelected(undefined);
  };
  const handleSelect = (newSelectedValue: any) => {
    setComissionSelected(newSelectedValue);
  };

  const handleAccess = (idAgenc: any) => {
    getAgencyData(idAgenc);
  };

  const goToAgency = (AgId: number) => {
    navigate(`/admin/agency-details/${AgId}`);
  };

  useEffect(() => {
    getAgenciesList(id);
  }, []);

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {agencyListFiltered.map((agency) => (
        <TableRow key={agency?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell className={styles.code}>#{agency?.id}</TableCell>
          <TableCell>{getType(agency?.type)}</TableCell>
          <TableCell>{agency?.agency}</TableCell>
          <TableCell onClick={() => handleAgentsNavigation(agency?.id)}>
            <div className={agency?.status ? styles.linkedAgency : styles.linkedAgencyInactive}>
              {agency?.agentsQuantity +
                ' ' +
                (Number(agency?.agentsQuantity) > 1 ? t('BACKOFFICE.TABLE.AGENTS') : t('BACKOFFICE.TABLE.USER'))}
            </div>
          </TableCell>
          <TableCell>
            <div className={agency?.status ? styles.monthSales : styles.monthSalesInactive}>
              {'$' + agency?.totalSalesMonth}
            </div>
          </TableCell>
          <TableCell>
            <div className={agency?.status ? styles.monthReservations : styles.monthReservationsInactive}>
              {agency?.totalReservationMonth +
                ' ' +
                (Number(agency?.totalReservationMonth) > 1
                  ? t('BACKOFFICE.TABLE.RESERVATION') + 'S'
                  : t('BACKOFFICE.TABLE.RESERVATION'))}
            </div>
          </TableCell>
          {<TableCell>{getStatus(agency?.status)}</TableCell>}
          <TableCell>
            {!agency?.status && user?.isGoWeasy() && (
              <Button onClick={() => handleAccess(agency?.id)}>{t('AGENT.CONFIRM.BUTTON')}</Button>
            )}
          </TableCell>
          <TableCell>
            <Button onClick={() => goToAgency(agency?.id)}> {t('BACKOFFICE.RESERVATION_DETAIL.DETAILS')} </Button>
          </TableCell>
          {/*<TableCell>{<FaEdit className={styles.edit} size={24} color="#0049A1" />}</TableCell>*/}
        </TableRow>
      ))}
      {userSelected && (
        <ConfirmAgentModal
          isOpen={confirmAccessModal}
          user={userSelected}
          onConfirm={confirmAllowAccess}
          onSelect={handleSelect}
          selectedValue={comissionSelected}
          onClose={unselectUser}
          loading={loadingAgency}
        />
      )}
    </BasicTable>
  );
};

export default LinkedAgenciesTable;
