import React from 'react';
import styles from './styles.module.scss';
import i18next from 'i18next';
import { Languages } from 'languages/helpers.languages';

interface DayProps {
  day?: number;
  value?: string;
  isAvailable?: boolean;
}

const Day: React.FC<DayProps> = ({ day, value, isAvailable = false }) => {
  const dayClass = isAvailable && day ? styles.available : styles.unavailable;

  const currentLanguage = i18next.language;

  const currency = currentLanguage === Languages.PT_BR ? 'R$' : '$';

  return (
    <div className={`${dayClass} ${styles.date}`}>
      {day && (
        <>
          <span>{day}</span>
          {value ? (
            <span className={styles.value}>
              {currency} {value}
            </span>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Day;
