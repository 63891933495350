import Condominium from 'models/application/Condominium.model';
import styles from './style.module.scss';
import { ImageNotAvailable } from 'assets';
import { useMemo } from 'react';

type CardCondominiumProps = {
  condominium: Condominium;
  onClick: (condominium: Condominium) => void;
  staticCard?: boolean;
};

const CardCondominium: React.FC<CardCondominiumProps> = ({ condominium, onClick, staticCard }) => {
  const handleClick = () => {
    onClick(condominium);
  };

  const styleConfig = useMemo(() => {
    return {
      container: !staticCard ? styles.containerScale : null,
      visibility: staticCard ? styles.alwaysVisible : styles.hiddenContainer,
      imageHeight: staticCard ? '162px' : '192px',
    };
  }, [staticCard]);

  return (
    <div className={`${styles.container} ${styleConfig.container}`} onClick={handleClick}>
      <div className={styles.imageContainer}>
        <div className={styles.layer}></div>
        <h6>{condominium?.name?.toLowerCase()}</h6>
        <img
          className={styles.imageBackground}
          height={styleConfig.imageHeight}
          src={condominium?.image ? condominium.image : ImageNotAvailable}
          alt="House background"
        />
      </div>
      {condominium.getSubtitle() && (
        <div className={styleConfig.visibility}>
          <div className={styles.infos}>
            <div className={styles.itens}>{condominium.getSubtitle() ?? ''}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardCondominium;
