import usePublicNavigation from 'routes/navigation/public.navigation';
import { ReactComponent as WELogo } from '../../../assets/svg/logo.svg';

import styles from './styles.module.scss';
import { isLogged } from 'services/Login/user.service';

const Logo = () => {
  const { navigateToHomePage } = usePublicNavigation();

  const onClickLogo = () => {
    if (!isLogged()) return;
    navigateToHomePage();
  };

  return <WELogo onClick={onClickLogo} className={styles.logo} />;
};

export default Logo;
