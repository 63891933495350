import WESelectGuests from 'components/WESelectGuests';
import styles from './styles.module.scss';
import { Label } from 'components/atoms/Label';
import { useTranslation } from 'react-i18next';
import WEDatePicker from 'components/WEDatePicker';
import { useState } from 'react';
import { formatDateToBackend } from 'utils/format';
import { IGuests } from 'models/application/interfaces/Guests.interface';
import Booking from 'models/application/Booking.model';
import moment from 'moment';
import { Buttons } from 'components/molecules/buttons';
import { IoIosSearch } from 'react-icons/io';

type SearchHouseMiniProps = {
  onSearch: (booking: Booking) => void;
  initialPeriod?: any[];
};

const SearchHouseMini: React.FC<SearchHouseMiniProps> = ({ onSearch, initialPeriod = [] }) => {
  const { t } = useTranslation();

  const [period, setPeriod] = useState<any[]>([
    initialPeriod[0] ? moment(initialPeriod[0]).utc().format() : null,
    initialPeriod[1] ? moment(initialPeriod[1]).utc().format() : null,
  ]);
  const [guests, setGuests] = useState<IGuests>({
    adults: 0,
    kids: 0,
    babies: 0,
    pets: 0,
    elderly: 0,
    pcd: 0,
    total: 0,
  });

  const disableButton = period.length < 2 || !period[0];

  const changeGuests = (guestValues: IGuests) => {
    setGuests(guestValues);
  };

  const handlePeriod = (dates: Date[] | null[]) => {
    setPeriod(dates);
  };

  const search = () => {
    if (disableButton) return;

    const searchValues = new Booking({
      checkIn: formatDateToBackend(period[0]),
      checkOut: formatDateToBackend(period[1]),
      kids: guests.kids,
      adults: guests.adults,
      pets: guests.pets,
      pcd: guests.pcd,
      elderly: guests.elderly,
      babies: guests.babies,
    });

    onSearch(searchValues);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.destination}>
        <Label color={'white'}>{t('SEARCH.LABEL.DESTINATION')}</Label>
        <div className={styles.subLabel}>Orlando - Flórida</div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.datePicker}>
        <WEDatePicker cleanOff darkMode minDate={new Date()} value={period} onChangeValue={handlePeriod} />
      </div>
      <div className={styles.divider}></div>
      <div className={styles.guests}>
        <WESelectGuests colorLabel={'white'} petsAllowed={true} limitGuests={9999} onChange={changeGuests} />
      </div>
      <Buttons.Default
        backgroundColor={'transparent'}
        className={styles.button}
        startIcon={<IoIosSearch size={24} />}
        onClick={search}
        disabled={guests.total === 0 || period.length < 2 || !period[0]}
      ></Buttons.Default>
    </div>
  );
};
export default SearchHouseMini;
