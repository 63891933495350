import { useRef, useState } from 'react';
import useAsync from './useAsync.hook';
import getAvailableHousesByDate from 'services/ListHouses/getAvailableHousesByDate.service';
import { ISectionHouseResume } from 'models/application/interfaces/ISectionHouseResume.interface';
import axios from 'axios';

const useSearchHouse = () => {
  const [sectionsHouses, setSectionHouses] = useState<ISectionHouseResume[]>([]);
  const cancelTokenSourceRef = useRef<any>(null);
  const [isLoading, setIsloading] = useState<boolean>(true);

  const { loading: loadingSearchHouse, call: searchHouse } = useAsync(
    async (checkIn: string, checkOut: string, filters: string[], guests?: number, pets?: number, seniors?: number) => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef?.current?.cancel('Operation canceled due to new request.');
      }

      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      try {
        const searchResult = await getAvailableHousesByDate(
          formatDate(checkIn),
          formatDate(checkOut),
          filters,
          cancelTokenSource.token,
          guests,
          pets,
          seniors,
        );
        setSectionHouses(searchResult);
        setIsloading(false);
      } catch {
        setIsloading(true);
      }
    },
  );

  const formatDate = (date: string) => {
    return date.split('-').reverse().join('-');
  };

  return {
    sectionsHouses,
    loadingSearchHouse,
    searchHouse,
    isLoading,
  };
};

export default useSearchHouse;
