import { DoneDeal, HouseGreen, ReservationCancelIcon } from 'assets';
import FeatureRow from 'components/BackOffice/FeatureRow';
import StatsSection from 'components/StatsSection';
import useAsync from 'hooks/useAsync.hook';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReservationList from 'services/Management/getReservation.service';
//import { useTranslation } from 'react-i18next';

const HeaderReservationAgents: React.FC<any> = ({ title }) => {
  // const { t } = useTranslation();
  const params = useParams();

  const [infos, setInfos] = useState<any>({});
  const reservationStatsNumbers = {
    confirmed: infos?.reservationSuccess,
    pending: infos?.reservationPending,
    cancel: infos?.reservationCancel,
  };

  const stats = [
    {
      name: 'Reservas',
      status: 'Concluidas',
      reservationNumbers: reservationStatsNumbers.confirmed,
      icon: HouseGreen,
    },
    {
      name: 'Reservas',
      status: 'Pendentes',
      reservationNumbers: reservationStatsNumbers.pending,
      icon: DoneDeal,
    },
    {
      name: 'Reservas',
      status: 'Canceladas',
      reservationNumbers: reservationStatsNumbers.cancel,
      icon: ReservationCancelIcon,
    },
  ];

  const { call: getInfos } = useAsync(async () => {
    try {
      const reservationList = await ReservationList(Number(params?.id));
      setInfos(reservationList);
    } catch (err: any) {
      throw new Error(err);
    }
  }, []);

  useEffect(() => {
    getInfos();
  }, []);

  return (
    <FeatureRow title={title}>
      <div style={{ display: 'flex' }}>
        {stats.map((stat) => {
          return (
            <StatsSection
              name={stat.name}
              status={stat.status}
              reservationNumbers={stat.reservationNumbers}
              icon={stat.icon}
            />
          );
        })}
      </div>
    </FeatureRow>
  );
};

export default HeaderReservationAgents;
