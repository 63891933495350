import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '../../assets/svg/wordmark-white.svg';
import styles from './styles.module.scss';
// import facebook from 'assets/logos/facebook.png';
import instagram from 'assets/logos/Instagram.png';
import linkedin from 'assets/logos/linkedin.png';
// import youtube from 'assets/logos/youtube.png';
import US from 'country-flag-icons/react/3x2/US';
import BR from 'country-flag-icons/react/3x2/BR';
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className={styles.container}>
      <div className={styles.links}>
        <Logo width={172} />
        <div className={styles.infoGroup}>
          <div className={styles.group}>
            <div className={styles.link} onClick={() => navigateTo('/infos/who-we-are')}>
              {t('HOME.FOOTER.WHO_WE_ARE')}
            </div>
            <div className={styles.link} onClick={() => navigateTo('/infos/terms-of-service')}>
              {t('HOME.FOOTER.TERMS_AND_CONDITIONS')}
            </div>
            <div className={styles.link} onClick={() => navigateTo('/infos/privacy-policy')}>
              {t('HOME.FOOTER.PRIVACY_POLICIES')}
            </div>
            <div className={styles.link} onClick={() => navigateTo('/infos/cancellation')}>
              {t('CANCELLATION_POLICY.TITLE')}
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.title}>{t('HOME.FOOTER.CONTACT')}</div>
            <div>hello@goweasy.com</div>
            <div className={styles.flexRow}>
              <div className={styles.circle}>
                <div>
                  <US title="United States" className={styles.flag} />
                </div>
              </div>
              <div>+1 (888) 885-6889</div>
            </div>
            <div className={styles.flexRow}>
              <div className={styles.circle}>
                <div>
                  <BR title="" className={styles.flag} />
                </div>
              </div>
              <div>0800-200-0133</div>
            </div>
            <div>
              20 North Orange Avenue, Suite 1100 Orlando <br /> FL 32801, United States
            </div>
          </div>
          <div className={styles.group}>
            <div className={styles.title}>Social</div>
            <div className={styles.socialContainer}>
              {/* <img src={facebook} alt="facebook" width="48px" height="48px" /> */}
              <a href="https://www.instagram.com/goweasy/" target="_blank">
                <img src={instagram} alt="instagram" width="48px" height="48px" />
              </a>
              <a href="https://www.linkedin.com/company/goweasy/mycompany/" target="_blank">
                <img src={linkedin} alt="linkedin" width="40px" height="40px" />
              </a>
              {/* <img src={youtube} alt="youtube" width="40px" height="40px" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
