import { InputAdornment, TextField } from '@mui/material';
import Calendar, { DateValue } from 'components/BackOffice/Calendar';
import FeatureRow from 'components/BackOffice/FeatureRow';
import RangeDatePicker from 'components/BackOffice/RangeDatePicker';
import useAsync from 'hooks/useAsync.hook';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from 'react-icons/io5';
import AvailabilityList from 'services/Management/getCheckAvailability';

const CheckAvailability: React.FC<any> = () => {
  const { t } = useTranslation();

  /*const data: Record<string, number> = {
    '2024-06-30': 3329.0,
    '2024-06-12': 2869.0,
    '2024-06-11': 2869.0,
    '2024-06-10': 2869.0,
    '2024-06-16': 339.0,
    '2024-06-15': 339.0,
    '2024-06-14': 339.0,
    '2024-06-13': 339.0,
    '2024-06-09': 339.0,
    '2024-06-08': 339.0,
    '2024-06-07': 339.0,
    '2024-06-29': 3329.0,
    '2024-06-06': 339.0,
    '2024-06-01': 2909.0,
    '2024-06-23': 339.0,
    '2024-06-22': 339.0,
    '2024-06-21': 339.0,
    '2024-06-20': 2869.0,
    '2024-06-05': 2869.0,
    '2024-06-27': 3309.0,
    '2024-06-04': 2869.0,
    '2024-06-26': 2869.0,
    '2024-06-03': 2869.0,
    '2024-06-25': 2869.0,
    '2024-06-02': 2869.0,
    '2024-06-24': 339.0,
    '2024-07-01': 339.0,
  };*/

  const [availableDates, setavailableDates] = useState<any>();
  const [period, setPeriod] = useState<any>([]);
  const formatedStartDate = moment(period?.[0]).format('YYYY-MM-DD');
  const formatedEndDate = moment(period?.[1]).format('YYYY-MM-DD');
  const result: DateValue[] = [];
  const [screenDate, setScreenDate] = useState<any>();
  // TODO: adicionar um debounce
  const [code, setCode] = useState<any>('');

  // Função para formatar a data como string "YYYY-MM-DD"
  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const { call: getAvailability } = useAsync(async (startDate: any, endDate: any, id: any) => {
    const availabilityList = await AvailabilityList(startDate, endDate, id);
    setavailableDates(availabilityList);
  }, []);

  const handleCodeChange = (event: any) => {
    const value = event.target.value?.replace(/\D/g, '');
    setCode(value);
  };
  const handlePeriod = (date: any) => {
    setPeriod(date);
  };

  const handleScreenDate = (periodDate: any) => {
    // Como o Javascript passa os objetos por referencia, criar um loop desse modo "d.setDate(d.getDate() + 1))"
    // vai modificar as variveis originais, causando diversos bugs/problemas
    // TODO: Pensar em um jeito melhor de criar um objet sem ser por referencia
    // Sugestões: https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
    const cloneStartDate = JSON.parse(JSON.stringify(periodDate?.[0]));
    const cloneEndDate = JSON.parse(JSON.stringify(periodDate?.[1]));
    const newCloneStartDate = new Date(cloneStartDate);
    const newCloneEndDate = new Date(cloneEndDate);
    for (let d = newCloneStartDate; d <= newCloneEndDate; d.setDate(d.getDate() + 1)) {
      const dateString = formatDate(d);
      const value = availableDates[dateString] || 0; // Valor padrão para dias ausentes
      result.push({ date: dateString, value });
    }
    setScreenDate(result);
  };

  useEffect(() => {
    if (period.length > 1) {
      getAvailability(formatedStartDate, formatedEndDate, code);
    } else {
      setavailableDates(null);
    }
  }, [period, code]);

  useEffect(() => {
    if (availableDates && period.length > 1) {
      handleScreenDate(period);
    }
  }, [availableDates, period]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('BACKOFFICE.AVAILABILITY')}></FeatureRow>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '97%', alignItems: 'center' }}>
        {
          <TextField
            label={t('BACKOFFICE.COMMISSIONS_TABLE.SEARCH_BY_RESERVATION_CODE')}
            variant="outlined"
            value={code}
            onChange={handleCodeChange}
            style={{ width: '30%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IoSearchSharp size={24} />
                </InputAdornment>
              ),
            }}
          />
        }
        <div>
          <RangeDatePicker cleanOff minDate={new Date()} value={period} onChangeValue={handlePeriod} />
        </div>
      </div>
      <Calendar availableDates={screenDate} selectedDate={period} />
    </div>
  );
};

export default CheckAvailability;
