import React, { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Inputs from 'components/molecules/inputs';
import { useQueryString } from 'hooks/useQueryString';

import { PaymentElement } from '@stripe/react-stripe-js';
import moment from 'moment';
import { CheckoutPageParams } from '..';
import DatePicker from './components/DateRangePicker';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import styles from './styles.module.scss';
import { MdOutlineChevronLeft } from 'react-icons/md';
import COLORS from 'constants/colors';
import InputText from './components/InputText';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateCheckoutForm } from '../schema';
import { IReservation } from 'models/contracts/request/reservation.request';
import UserContext from 'store/context/user.context';
import WESelectGuests from 'components/WESelectGuests';
import { House } from 'models/application/House.model';
import useFilters from 'hooks/useFilters';
import { IGuests } from 'models/application/interfaces/Guests.interface';
import ButtonStripe from './components/ButtonStripe';
import ButtonPagtur from './components/ButtonPagtur';
import CopyLabel from './components/CopyLabel';
import { MenuItem, OutlinedInput, Select } from '@mui/material';
import { Installment } from './interfaces/Installment';
import getAddressByCep from 'services/CEP/getAddressByCep';

interface ICheckoutFormProps {
  checkIn: string;
  checkOut: string;
  costTotal: number;
  paymentIntent: string;
  goBackURL: string;
  house: House;
  isUsingStripe: boolean;
  installments: Installment[];
}

const CheckoutForm: React.FC<ICheckoutFormProps> = ({
  checkIn,
  checkOut,
  costTotal,
  goBackURL,
  paymentIntent: paymentIntentConfig,
  house,
  isUsingStripe,
  installments,
}) => {
  const { t } = useTranslation();
  const form = useForm<IReservation>({
    resolver: yupResolver(validateCheckoutForm),
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  });
  const [user] = useContext(UserContext);
  const [selectedStallment, setSelectedStallment] = useState<number>(1);
  const { updateFilters } = useFilters();
  const navigate = useNavigate();
  const [qsData] = useQueryString<Partial<CheckoutPageParams>>();
  const currentLanguage = i18next.language.toLowerCase();
  const isBRL = currentLanguage === 'pt-br';
  const formatDate = `DD [${isBRL ? 'de' : 'at'}] MMMM`;
  const [paymentLink, setPaymentLink] = useState('');
  const [isDirty, setIsDirty] = useState<boolean>();
  moment.locale(currentLanguage);

  const handleGuests = (guests: IGuests) => {
    updateFilters({
      ...guests,
    });
  };

  const checkinFormmated = useMemo(() => moment(qsData.checkIn ?? '').format(formatDate), [qsData.checkIn]);
  const checkoutFormmated = useMemo(() => moment(qsData.checkOut ?? '').format(formatDate), [qsData.checkOut]);

  const handlePaymentLink = (link: string) => {
    setPaymentLink(link);
  };

  const handleSelect = (event: any) => {
    setSelectedStallment(event.target.value as number);
  };

  const getCep = async (c: string) => {
    // TODO: refatorar para uma função unica caso for continuar desse modo
    try {
      const response = await getAddressByCep(c);
      if (response?.logradouro) {
        form.setValue('personal.address1', response.logradouro + ', ' + response.bairro, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
        form.setValue('personal.city', response.localidade, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });

        form.setValue('personal.state', response.uf, {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
        form.setValue('personal.country', 'Brasil', {
          shouldTouch: true,
          shouldDirty: true,
          shouldValidate: true,
        });
        setIsDirty(true);
      }
    } catch {
      form.setValue('personal.address1', '');
      form.setValue('personal.city', '');
      form.setValue('personal.country', '');
      form.setValue('personal.state', '');
    }
  };

  const handleBlur = (e: any) => {
    const cepOnBlur = e?.target?.value;
    const cepNumbers = cepOnBlur?.match(/\d+/g)?.join('');
    if (cepNumbers) getCep(cepNumbers);
  };

  return (
    <div className={styles.containerForm}>
      <div className={styles.confirmationContainer}>
        <div className={styles.goBack}>
          <MdOutlineChevronLeft size={32} color="#FFFFFF" onClick={() => navigate(goBackURL, { replace: true })} />
        </div>
        <h3>{t('CHECKOUT.CONFIRM_BOOKING')}</h3>
      </div>
      <div className={styles.subTitle}>{t('CHECKOUT.YOUR_TRAVEL')}</div>
      <div className={styles.flex}>
        <div className={styles.confirmationReservationContainer}>
          <div>
            <div className={styles.miniTitle}>{t('CHECKOUT.FORM.DATES')}</div>
            <div className={styles.information}>
              {checkinFormmated} <span>-</span> {checkoutFormmated}
            </div>
          </div>
          <DatePicker />
        </div>
        <div className={styles.divider} />
        <div className={styles.confirmationReservationContainer}>
          <div>
            <div className={styles.miniTitle}>{t('CHECKOUT.FORM.GUESTS')}</div>
            <div className={styles.information}>{t('CHECKOUT.FORM.GUESTS').toLocaleLowerCase()}</div>
          </div>
          <WESelectGuests
            colorLabel={COLORS.neutral.white}
            petsAllowed={house?.isPetFriendly}
            limitGuests={house?.maxOccupancy}
            onChange={handleGuests}
          />
        </div>
        <div className={styles.divider} />
      </div>
      {isUsingStripe ? (
        <>
          <div className={styles.subTitle}>{t('CHECKOUT.FORM.PAY_WITH')}</div>
          <div>
            <PaymentElement id="payment-element" options={{ layout: 'tabs' }} />
          </div>
          <div className={styles.divider} />
        </>
      ) : null}
      <div className={styles.form}>
        <div className={styles.subTitle}>{t('CHECKOUT.FORM.YOUR_DATA')}</div>
        <div className={styles.row}>
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.NAME')}
            placeholder={t('CHECKOUT.FORM.NAME_PLACEHOLDER')}
            name="personal.firstName"
            id="name"
          />
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.SURNAME')}
            placeholder={t('CHECKOUT.FORM.SURNAME_PLACEHOLDER')}
            name="personal.lastName"
            id="surname"
          />
        </div>
        <div className={styles.row}>
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.DOCUMENT')}
            placeholder={t('CHECKOUT.FORM.DOCUMENT_PLACEHOLDER')}
            type="number"
            name="personal.documentNumber"
            id="number"
          />
          <InputText form={form} required label="Email" placeholder="email@address.com" name="personal.email" />
        </div>
        <Inputs.Mask
          form={form}
          required
          mask="(99) 99999-9999"
          label={t('CHECKOUT.FORM.PHONE_NUMBER')}
          placeholder={t('CHECKOUT.FORM.PHONE_NUMBER_PLACEHOLDER')}
          name="personal.phoneNumber"
          id="phone"
        />
        <div className={styles.row}>
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.ADDRESS')}
            placeholder={t('CHECKOUT.FORM.ADDRESS_PLACEHOLDER')}
            name="personal.address1"
            id="address"
            inputLabelProps={{
              shrink: isDirty, // Disable label animation
            }}
          />
        </div>
        <div className={styles.row}>
          <Inputs.Mask
            form={form}
            required
            label={t('CHECKOUT.FORM.POSTAL_CODE_PLACEHOLDER')}
            placeholder="99.999-999"
            mask="99.999-999"
            name="personal.zipcode"
            id="zipcode"
            onBlur={handleBlur}
            inputLabelProps={{
              shrink: isDirty, // Disable label animation
            }}
          />
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.COUNTRY')}
            placeholder={t('CHECKOUT.FORM.COUNTRY_PLACEHOLDER')}
            name="personal.country"
            id="country"
            inputLabelProps={{
              shrink: isDirty,
            }}
          />
        </div>
        <div className={styles.row}>
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.CITY')}
            placeholder={t('CHECKOUT.FORM.CITY_PLACEHOLDER')}
            name="personal.city"
            id="city"
            inputLabelProps={{
              shrink: isDirty,
            }}
          />
          <InputText
            form={form}
            required
            label={t('CHECKOUT.FORM.STATE')}
            placeholder={t('CHECKOUT.FORM.STATE_PLACEHOLDER')}
            name="personal.state"
            id="state"
            inputLabelProps={{
              shrink: isDirty,
            }}
          />
        </div>
        {installments.length > 1 && !isUsingStripe && (
          <div className={styles.row}>
            <Select
              className={styles.select}
              id="select-stallment"
              value={selectedStallment}
              onChange={handleSelect}
              input={<OutlinedInput placeholder={t('CHECKOUT.FORM.INSTALLMENT_PLACEHOLDER')} />}
            >
              {installments.map((installment) => (
                <MenuItem className={styles.items} value={installment?.parcela} key={installment?.parcela}>
                  <div>
                    {installment?.parcela} {t('CHECKOUT.FORM.INSTALLMENT')}
                    {installment?.parcela > 1 ? 's' : ''}
                    {t('CHECKOUT.FORM.OF')}
                    {installment?.valorParcel.toFixed(2)} - (R$ {installment?.valorTotal.toFixed(2)})
                  </div>
                  <div>
                    {installment.parcela === 1 && (
                      <div className={styles.noTaxes}>{t('CHECKOUT.FORM.INTEREST_FREE')}</div>
                    )}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <div className={styles.divider} />
      </div>
      <div className={styles.form}>
        <div className={styles.subTitle}>{t('CHECKOUT.INFORMATIONS.NEED_KNOW')}</div>
        <div className={styles.description}>{t('CHECKOUT.INFORMATIONS.NEED_KNOW_DESCRIPTION.0')}</div>
        <div className={styles.description}>{t('CHECKOUT.INFORMATIONS.NEED_KNOW_DESCRIPTION.1')}</div>
        <div className={styles.description}>{t('CHECKOUT.INFORMATIONS.NEED_KNOW_DESCRIPTION.2')}</div>
        <div className={styles.description}>{t('CHECKOUT.INFORMATIONS.NEED_KNOW_DESCRIPTION.3')}</div>
        <div className={styles.divider} />
        <div className={styles.description}>{t('CHECKOUT.INFORMATIONS.PAYMENT_TERMS.0')}</div>
        <div className={styles.description}>{t('CHECKOUT.INFORMATIONS.PAYMENT_TERMS.1')}</div>
      </div>
      {isUsingStripe ? (
        <ButtonStripe
          form={form}
          costTotal={costTotal}
          userId={user?.id}
          paymentIntentId={paymentIntentConfig}
          checkIn={checkIn}
          checkOut={checkOut}
          houseId={house?.id}
        >
          {t('CHECKOUT.ACTIONS.SUBMIT')}
        </ButtonStripe>
      ) : (
        <ButtonPagtur
          form={form}
          costTotal={costTotal}
          userId={user?.id}
          paymentIntentId={paymentIntentConfig}
          checkIn={checkIn}
          checkOut={checkOut}
          houseId={house?.id}
          handleLink={handlePaymentLink}
          isDisable={paymentLink?.length > 1 ? true : false}
        >
          {t('CHECKOUT.ACTIONS.EXTERNAL_LINK')}{' '}
        </ButtonPagtur>
      )}
      {paymentLink?.length > 1 && <CopyLabel text={paymentLink} />}
    </div>
  );
};

export default CheckoutForm;
