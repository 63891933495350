import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BasicTable from 'components/BasicTable';
import Button from '../../../../../../components/BackOffice/Button';
import { useEffect, useMemo, useState } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

import styles from './styles.module.scss';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import { useTranslation } from 'react-i18next';
import useListAgency from 'hooks/useListAgency.hook';
import ListAgencyAdm from 'models/application/ListAgency/ListAgency.model';
import ConfirmAgentModal from './ConfirmAgentModal';
import { useNavigate } from 'react-router-dom';

type AgentTableProps = {
  filter?: string;
};

const AgentListTable: React.FC<AgentTableProps> = ({ filter }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [confirmAccessModal, setConfirmAccessModal] = useState(false);
  const [userSelected, setUserSelected] = useState<ListAgencyAdm>();
  const [comissionSelected, setComissionSelected] = useState<any>('');

  const collumnsNames = [
    t('BACKOFFICE.TABLE.CODE'),
    t('BACKOFFICE.TABLE.NAME'),
    //t('BACKOFFICE.TABLE.TYPE'),
    t('BACKOFFICE.TABLE.CONTACT'),
    t('BACKOFFICE.TABLE.STATUS'),
    ' ',
    ' ',
  ];

  const { loadingAllowAgency, allowAgency, loadingGetAgencyList, getAgencyList, agency } = useListAgency();

  /*const { call: callGetAgency } = useAsync(async () => {
    try {
      const agentList = await getAgencyAdministrative();
      console.log('AGENTE', agentList);
    } catch (err: any) {
      throw new Error(err);
    }
  }, []);*/

  useEffect(() => {
    getAgencyList();
    //callGetAgency();
  }, []);

  const filterList = (agentsToFilter: ListAgencyAdm[], searchTerm: string) => {
    return agentsToFilter?.filter(
      (agencyList: ListAgencyAdm) =>
        agencyList?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agencyList?.id?.toString().includes(searchTerm),
    );
  };

  const agencyFiltered = useMemo(() => (filter ? filterList(agency, filter) : agency), [agency, filter]);

  const unlockAgent = (user: ListAgencyAdm) => {
    if (!user) return;
    setUserSelected(user);
    setConfirmAccessModal(true);
  };

  const confirmAllowAccess = async () => {
    if (!userSelected) return;
    await allowAgency(userSelected.id, comissionSelected);
    unselectUser();
  };

  const unselectUser = () => {
    setConfirmAccessModal(false);
    setUserSelected(undefined);
  };

  const handleSelect = (newSelectedValue: any) => {
    setComissionSelected(newSelectedValue);
  };

  const getStatus = (ativo?: boolean) => {
    if (ativo) {
      return <div className={styles.active}>{t('BACKOFFICE.TABLE.ACTIVE')}</div>;
    }

    return <div className={styles.waiting}>{t('BACKOFFICE.TABLE.WAITING_ACCESS')}</div>;
  };

  const goToAgency = (id: number) => {
    navigate(`/admin/agency-details/${id}`);
  };

  return loadingGetAgencyList ? (
    <div className={styles.loading}>
      <MoonLoader color={PRIMARY_BLUE_COBALT} />
    </div>
  ) : (
    <BasicTable collumnsNames={collumnsNames}>
      {agencyFiltered.map((currentAgency) => (
        <TableRow key={currentAgency.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell style={{ color: '#0049a1', fontSize: '14px' }}>#{currentAgency.id}</TableCell>
          <TableCell>{currentAgency.name}</TableCell>
          <TableCell>
            <div style={{ fontSize: '14px' }}>
              <div>{currentAgency.primaryContact}</div>
              {/*<div className={styles.email}>{currentAgent.email}</div>*/}
            </div>
          </TableCell>
          <TableCell>{getStatus(currentAgency?.active)}</TableCell>
          <TableCell>
            {!currentAgency?.active && <Button onClick={() => unlockAgent(currentAgency)}> Liberar </Button>}
          </TableCell>
          <TableCell>
            <Button onClick={() => goToAgency(currentAgency?.id)}> Detalhes </Button>
          </TableCell>
        </TableRow>
      ))}
      {userSelected && (
        <ConfirmAgentModal
          isOpen={confirmAccessModal}
          user={userSelected}
          onConfirm={confirmAllowAccess}
          onSelect={handleSelect}
          selectedValue={comissionSelected}
          onClose={unselectUser}
          loading={loadingAllowAgency}
        />
      )}
    </BasicTable>
  );
};

export default AgentListTable;
