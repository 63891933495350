import React from 'react';

import FAQItem from './FAQItem';

import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

interface FAQProps {
  title: string;
}

interface ObjectData {
  id: number;
  title: string;
  description: any;
}

const FAQ: React.FC<FAQProps> = ({ title }) => {
  const { t } = useTranslation();

  const data: ObjectData[] = [
    {
      id: 1,
      title: t('FAQ.QUESTION_1'),
      description: <Trans i18nKey="FAQ.ANSWER_1" />,
    },
    {
      id: 2,
      title: t('FAQ.QUESTION_2'),
      description: <Trans i18nKey="FAQ.ANSWER_2" />,
    },
    {
      id: 3,
      title: t('FAQ.QUESTION_3'),
      description: <Trans i18nKey="FAQ.ANSWER_3" />,
    },
    {
      id: 4,
      title: t('FAQ.QUESTION_4'),
      description: <Trans i18nKey="FAQ.ANSWER_4" />,
    },
    {
      id: 5,
      title: t('FAQ.QUESTION_5'),
      description: <Trans i18nKey="FAQ.ANSWER_5" />,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.beforeTitle}>EASY PEASY!</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.questionContainer}>
        <div className={styles.faqItems}>
          {data.map((item: ObjectData) => (
            <FAQItem item={item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
