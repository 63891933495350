export default {
  DETAILS_HOUSE: {
    COPY_LINK: '¡Enlace copiado al portapapeles!',
    MORE_IMAGES: 'Más Imágenes',
    NOT_FOUND: {
      MESSAGE: '¡Parece que la casa que estás buscando ya no existe o no está disponible para la fecha seleccionada!',
      TRY_AGAIN: 'Intentar de nuevo',
      GO_BACK: 'Volver a la pantalla anterior',
    },
  },
};
