import { FormControl, FormLabel, Grid, TableCell, TableRow, TextField } from '@mui/material';
import FeatureRow from 'components/BackOffice/FeatureRow';
import BasicTable from 'components/BasicTable';
import { Buttons } from 'components/molecules/buttons';
import useAsync from 'hooks/useAsync.hook';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import styles from './styles.module.scss';
import getCondominiumById from 'services/CondominiumListAdm/getCondominiumById';
import getCondominiumList from 'services/CondominiumListAdm/getCondominiumList';
import saveCondominiumById from 'services/CondominiumListAdm/saveCondominium';
import { toast } from 'react-toastify';
import { INCLUDE_NEW_CONDOMINIUM } from 'routes/routes/backOffice.routes';
import { useNavigate } from 'react-router-dom';
import StatsCommissions from 'pages/BackOffice/Commission/components/StatsCommissions';
import getCondominiumTotal from 'services/CondominiumListAdm/getCondominiumTotal';
import { CircleOrange } from 'assets';

const CondominiumListAdm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [condominiunList, setCondominiumList] = useState<any[]>([]);
  const [condominiumTotal, setCondominiumTotal] = useState<any>(0);
  const [currentCondominiumId, setCurrentCondominiumId] = useState(null);
  // TODO: usar os hooks de form
  const initialFormState = {
    name: '',
    latitude: '',
    longitude: '',
    urlImage: '',
    description: '',
    subtitle: '',
    descriptionPort: '',
    descriptionSpanish: '',
    subtitlePort: '',
    subtitleSpanish: '',
  };
  const [formData, setFormData] = useState(initialFormState);

  const collumnsNames = [
    'ID',
    t('ADMINISTRATIVE.CONDOMINIUM_TABLE'),
    t('ADMINISTRATIVE.PROPERTIES'),
    t('ADMINISTRATIVE.REVIEWED'),
  ];

  const handleEdit = (id: any) => {
    setFormData(initialFormState);
    getCondominiumToEdit(id);
    setCurrentCondominiumId(id);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (currentCondominiumId) {
      saveCondominium(currentCondominiumId, formData);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInclude = () => {
    navigate(INCLUDE_NEW_CONDOMINIUM);
  };

  const { call: saveCondominium } = useAsync(async (id: any, data: any) => {
    try {
      await saveCondominiumById(id, data);
      toast.success(t('ADMINISTRATIVE.SAVE_SUCCESS'));
    } catch {
      toast.warn(t('ADMINISTRATIVE.SAVE_FAIL'));
    }
  }, []);

  const { call: getCondominiumToEdit } = useAsync(async (id: any) => {
    const response = await getCondominiumById(id);
    setFormData(response);
  }, []);

  const { call: getCondominium } = useAsync(async () => {
    const response = await getCondominiumList();
    setCondominiumList(response);
  }, []);

  const { call: getTotal } = useAsync(async () => {
    const response = await getCondominiumTotal();
    setCondominiumTotal(response);
  }, []);

  useEffect(() => {
    getCondominium();
    getTotal();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('ADMINISTRATIVE.CONDOMINIUM_TABLE')}>
        <div style={{ display: 'flex' }}>
          <StatsCommissions
            text={t('ADMINISTRATIVE.TOTAL_CONDOMINIUMS')}
            numbers={condominiumTotal}
            icon={CircleOrange}
          />
        </div>
      </FeatureRow>
      <Grid container spacing={4}>
        <Grid item xs={5}>
          <BasicTable collumnsNames={collumnsNames} minWidth={100} marginTop="0px">
            {condominiunList.map((condominium) => (
              <TableRow key={condominium?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ color: '#0049a1', fontSize: '14px' }}>#{condominium?.id}</TableCell>
                <TableCell>{condominium.name}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <FaEdit
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(condominium?.id)}
                    size={24}
                    color="#0049A1"
                  />
                </TableCell>
              </TableRow>
            ))}
          </BasicTable>
        </Grid>
        <Grid container item xs={7}>
          <form onSubmit={handleSubmit} id="save-form">
            <FormControl>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.NAME_INPUT')} *</FormLabel>
                  <TextField
                    name="name"
                    variant="outlined"
                    margin="normal"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.LATITUDE')} *</FormLabel>
                  <TextField
                    name="latitude"
                    variant="outlined"
                    margin="normal"
                    value={formData.latitude}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.LONGITUDE')} *</FormLabel>
                  <TextField
                    name="longitude"
                    variant="outlined"
                    margin="normal"
                    value={formData.longitude}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      border: '2px dashed #252525',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '20px',
                    }}
                  >
                    <div>
                      <h5>{t('ADMINISTRATIVE.ADD_VIDEO_OR_PHOTO')} *</h5>
                      <label style={{ fontSize: '12px', color: '#5E5873' }}>{t('ADMINISTRATIVE.VIDEO_LIMIT')}</label>
                    </div>
                    <Buttons.NeutralWhite variant="contained" className={styles.buttonUpload}>
                      {t('ADMINISTRATIVE.SELECT_FILE')}
                    </Buttons.NeutralWhite>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.TITLE_IN_ENGLISH')} *</FormLabel>
                  <TextField
                    name="subtitle"
                    variant="outlined"
                    margin="normal"
                    value={formData.subtitle}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.TITLE_IN_SPANISH')} *</FormLabel>
                  <TextField
                    name="subtitleSpanish"
                    variant="outlined"
                    margin="normal"
                    value={formData.subtitleSpanish}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.TITLE_IN_PORTUGUESE')} *</FormLabel>
                  <TextField
                    name="subtitlePort"
                    variant="outlined"
                    margin="normal"
                    value={formData.subtitlePort}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_ENGLISH')} *</FormLabel>
                  <TextField
                    multiline
                    rows={8}
                    name="description"
                    variant="outlined"
                    margin="normal"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_SPANISH')} *</FormLabel>
                  <TextField
                    multiline
                    rows={8}
                    name="descriptionSpanish"
                    variant="outlined"
                    margin="normal"
                    value={formData.descriptionSpanish}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel className={styles.textContainer}>
                    {t('ADMINISTRATIVE.DESCRIPTION_IN_PORTUGUESE')} *
                  </FormLabel>
                  <TextField
                    multiline
                    rows={8}
                    name="descriptionPort"
                    variant="outlined"
                    margin="normal"
                    value={formData.descriptionPort}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '80px' }}
                >
                  <Buttons.NeutralWhite variant="contained" className={styles.buttonWhite} onClick={handleInclude}>
                    {t('ADMINISTRATIVE.ADD_NEW_CONDOMINIUM')}
                  </Buttons.NeutralWhite>
                  <Buttons.NeutralWhite type="submit" variant="contained" className={styles.buttonWhite}>
                    {t('ADMINISTRATIVE.SAVE')}
                  </Buttons.NeutralWhite>
                </div>
              </Grid>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default CondominiumListAdm;
