import gregorian_en from 'react-date-object/locales/gregorian_en';
import gregorian_pt_br from 'react-date-object/locales/gregorian_pt_br';

import styles from './styles.module.scss';
import { Container, LabelContainer, Checkin } from './style';

import 'react-multi-date-picker/styles/layouts/mobile.css';

import DatePicker, { DateObject } from 'react-multi-date-picker';

import 'react-calendar/dist/Calendar.css';
import '../../styles/react-calendar.scss';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { Label } from 'components/atoms/Label';
import { NEUTRAL_BLACK, NEUTRAL_WHITE } from 'styles/colors';
import { Languages } from 'languages/helpers.languages';
import { useTranslation } from 'react-i18next';
import gregorian_es from 'utils/calendar/gregorianEs';

// TODO refatorar mais

type WEDatePickerProps = {
  value: any;
  onChangeValue: (value: Date[] | null[]) => void;
  minDate?: Date;
  ratesCalendar?: any[];
  placeholder?: {
    checkIn: string;
    checkOut: string;
  } | null;
  darkMode?: boolean;
  cleanOff?: boolean;
  daysLabel?: {
    date: string;
    label: string;
  }[];
};

const WEDatePickerModalRange: React.FC<WEDatePickerProps> = ({
  minDate,
  onChangeValue,
  ratesCalendar,
  value: valueProps,
  darkMode,
  daysLabel,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<DateObject[]>([]);
  const windowWidth = window.innerWidth;

  /*const disableDates = useMemo(() => {
    if (!ratesCalendar) return [];

    return Object.entries(ratesCalendar)
      .filter(([_, keyValue]) => keyValue === '0')
      .map(([key]) => key);
  }, [ratesCalendar]);*/

  // const currentLanguage = i18next.language;
  // const isBR = currentLanguage === 'pt-BR';

  // const dateFormat = isBR ? 'dd/MM/y' : 'MM/dd/y';

  const useMemoColorMode = useMemo(() => {
    if (darkMode) {
      return {
        labelColor: NEUTRAL_WHITE,
        iconClass: styles.closeIconDark,
        classInput: styles.dateInputWhite,
        classPlaceholder: styles.placeholderTransparent,
      };
    }
    return {
      labelColor: NEUTRAL_BLACK,
      iconClass: styles.closeIconWhite,
      classInput: styles.dateInputDark,
      classPlaceholder: styles.placeholderWhite,
    };
  }, [darkMode]);

  const handleChangeValue = (date: DateObject[]) => {
    setValues(date);
    const arrayOfDates = date?.join(',\n');
    const dateArray: Date[] = arrayOfDates.split(',').map((d) => new Date(d.trim()));
    /*if (!ratesCalendar) return onChangeValue(date as any);

    const allDatesSelected = getDatesBetween(date[0], date[1]);
    const isInvalidDateRange = disableDates.some((element) => allDatesSelected.includes(element));

    if (isInvalidDateRange) {
      return clearCheckout();
    }

    onChangeValue(date as any);*/
    onChangeValue(dateArray as any);
  };

  /*const getDatesBetween = (startDate: any, endDate: any) => {
    const dates = [];
    const currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate.toDate());
      currentDate.add(1, 'days');
    }

    return dates.map((date) => moment(date).format('YYYY-MM-DD'));
  };*/

  const getLocale = () => {
    switch (i18next.language) {
      case Languages.ES:
        return 'es';
      case Languages.PT_BR:
        return 'pt-BR';
      default:
        return 'en-US';
    }
  };

  const getCalendarLocale = () => {
    switch (i18next.language) {
      case Languages.ES:
        return gregorian_es;
      case Languages.PT_BR:
        return gregorian_pt_br;
      default:
        return gregorian_en;
    }
  };

  const getDateName = (selectedDate: any) => {
    if (typeof selectedDate === 'string') {
      const dateArray = selectedDate?.split('-');
      const firstDay = {
        month: new Date(dateArray[0]),
        day: new Date(dateArray[0])?.getDate(),
      };

      const secondDay = {
        month: new Date(dateArray[1]),
        day: new Date(dateArray[1])?.getDate(),
      };

      const isSameMonth = firstDay.month.getMonth() === secondDay.month.getMonth();

      if (isSameMonth) {
        return `${firstDay.day} - ${secondDay.day} ${firstDay.month?.toLocaleString(getLocale(), { month: 'long' })}`;
      }

      return `${firstDay.day} ${firstDay.month.toLocaleString(getLocale(), { month: 'short' })} - ${
        secondDay.day
      } ${secondDay.month.toLocaleString(getLocale(), { month: 'short' })}`;
    } else {
      return selectedDate;
    }
  };

  const getCalendarSize = () => {
    if (windowWidth > 600) return 2;
    return 1;
  };

  useEffect(() => {
    if (valueProps[0] !== null) {
      setValues([new DateObject(valueProps[0]), new DateObject(valueProps[1])]);
    }
  }, [valueProps]);

  return (
    <Container>
      <LabelContainer>
        <Checkin>
          <Label color={useMemoColorMode.labelColor}>{t('WHEN')}</Label>
        </Checkin>
      </LabelContainer>
      {
        <DatePicker
          locale={getCalendarLocale()}
          onChange={handleChangeValue}
          value={values}
          minDate={minDate?.toUTCString()}
          mapDays={({ date }) => {
            const matchingDate = daysLabel?.find((schedule: any) => {
              return moment(schedule?.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
            });
            if (!matchingDate?.label && ratesCalendar)
              return {
                disabled: true,
                style: { color: '#ccc' },
              };
            if (matchingDate?.label != 'R$ 0' || matchingDate?.label != ('$0' as string)) {
              return {
                children: (
                  <div style={{ margin: -4 }}>
                    <div style={{ textAlign: 'center' }}>{date.format('D')}</div>

                    <div style={{ fontSize: '9px', whiteSpace: 'nowrap', color: '#046408' }}>{matchingDate?.label}</div>
                  </div>
                ),
              };
            }
          }}
          className={`${styles.customCalendar} rmdp-mobile`}
          range
          rangeHover
          dateSeparator="-"
          numberOfMonths={getCalendarSize()}
          disableYearPicker
          render={(v, openCalendar) => {
            return (
              <div onClick={openCalendar}>
                {!v && <div className={styles.datePlaceholder}>{t('SEARCH_DATES')}</div>}
                {v && <div className={styles.datePlaceholder}>{getDateName(v)}</div>}
              </div>
            );
          }}
        />
      }
    </Container>
  );
};

export default WEDatePickerModalRange;
