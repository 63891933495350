class BrazilianAgency {
  name: string;
  email: string;
  cellphone: string;
  cnpj: string;
  stateRegistration?: string;
  cadastur?: string;
  siteUrl?: string;
  country: string;
  address: string;
  city: string;
  cep?: string;

  constructor(agency: BrazilianAgency) {
    this.name = agency.name;
    this.email = agency.email;
    this.cellphone = agency.cellphone;
    this.cnpj = agency.cnpj;
    this.stateRegistration = agency.stateRegistration;
    this.cadastur = agency.cadastur;
    this.siteUrl = agency.siteUrl;
    this.country = agency.country;
    this.address = agency.address;
    this.city = agency.city;
    this.cep = agency.cep;
  }
}

export default BrazilianAgency;
