import { createRoot } from 'react-dom/client';

import Routes from 'routes';

import 'moment/locale/pt-br';
import 'react-chat-widget/lib/styles.css';
import 'react-multi-carousel/lib/styles.css';
import './styles/globals.scss';

const App = () => {
  return <Routes />;
};

const container = document.getElementById('root') || document.body;
const root = createRoot(container);
root.render(<App />);
