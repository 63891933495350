import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { FaEdit } from 'react-icons/fa';
import BasicTable from 'components/BasicTable';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type Language = 'pt-BR' | 'en' | 'es' | 'pt-PT';

const Status: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div>
      {status === 'Pendente' && <label className={styles.statusPending}>{t('ADMINISTRATIVE.PENDING')}</label>}
      {status === 'Revisada' && <label className={styles.statuReviewed}>{t('ADMINISTRATIVE.REVIEWED')}</label>}
      {status === 'Publicado' && <label className={styles.statusPublished}>{t('ADMINISTRATIVE.PUBLISHED')}</label>}
      {status === 'Inativo' && <label className={styles.statusInactive}>{t('ADMINISTRATIVE.INACTIVES')}</label>}
      {status === 'Salvo' && <label className={styles.statuReviewed}>{t('ADMINISTRATIVE.SAVED')}</label>}
    </div>
  );
};

const HousesListAdmTable: React.FC<any> = ({ houseList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const collumnsNames = [
    'ID',
    t('ADMINISTRATIVE.NAME'),
    'STATUS',
    t('ADMINISTRATIVE.CONDOMINIUM'),
    t('ADMINISTRATIVE.ADDRESS'),
    t('ADMINISTRATIVE.CITY'),
    '',
  ];

  const handleManagementNavigation = (id: any) => {
    navigate(`/admin/house-management/${id}`);
  };

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {houseList?.map((house: any) => (
        <TableRow key={house?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <label className={styles.reservationCode}>#{house?.id}</label>
          </TableCell>
          <TableCell>
            <label>{house?.name}</label>
          </TableCell>
          <TableCell>
            <Status status={house?.statusDescription} />
          </TableCell>
          <TableCell>
            <label>{house?.condominiumName}</label>
          </TableCell>
          <TableCell>{house?.address}</TableCell>
          <TableCell>{house?.city}</TableCell>
          <TableCell>
            <FaEdit
              size={24}
              color="#0049A1"
              onClick={() => handleManagementNavigation(house?.id)}
              style={{ cursor: 'pointer' }}
            />
          </TableCell>
        </TableRow>
      ))}
    </BasicTable>
  );
};

export default HousesListAdmTable;
