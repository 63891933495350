import { Step, StepLabel, Stepper } from '@mui/material';
import styles from './styles.module.scss';

import '../../styles/mui-stepper.scss';

type WeStepperProps = {
  steps: string[];
  currentStep: number;
  onClickStep: (step: number) => void;
};

const WeStepper: React.FC<WeStepperProps> = ({ steps, currentStep, onClickStep }) => {
  const clickStep = (index: number) => {
    if (index < currentStep) {
      onClickStep(index);
    }
  };

  return (
    <Stepper activeStep={currentStep} alternativeLabel className={styles.base}>
      {steps.map((label, index) => (
        <Step key={index} onClick={() => clickStep(index)}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default WeStepper;
