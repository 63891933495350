import styled from 'styled-components';

export const LocationContainer = styled.div`
  width: 100%;
  height: 550px;
`;

export const LocationTitle = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 16px;
`;
