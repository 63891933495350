import Yup from 'utils/yup';

export const validateOperatorES = Yup.object({
  name: Yup.string().required(),
  cnpj: Yup.string().required(),
  address: Yup.string().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  // campos faltantes
  // cadastur
  // urlSite
  // email
  // primary contact
});
