import i18next from 'i18next';
import { IReservation } from 'models/contracts/request/reservation.request';
import { SubmitCheckoutResponse } from 'models/contracts/response/SubmitCheckoutSuccess.response';
import { axiosInstances } from 'services/api';
import { showErrorMessage } from 'utils/Toast';

export const submitHouseCheckout = async (body: IReservation) => {
  try {
    const res = await axiosInstances.base.instance.post<SubmitCheckoutResponse>('service/v1/house/reservation', body);
    return res.data;
  } catch (err) {
    showErrorMessage(i18next.t('RESPONSE.RESERVE_ERROR'));
    throw new Error('Erro ao realizar a reserva. Tente novamente mais tarde.');
  }
};
