import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BasicTable from 'components/BasicTable';
import i18n from 'languages';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SlOptionsVertical } from 'react-icons/sl';
import { IconButton, Menu, MenuItem } from '@mui/material';
import sendApprovedPayment from 'services/Management/sendApprovedPayment';
import { toast } from 'react-toastify';

// TODO: retirar daqui
export type Language = 'pt-BR' | 'en' | 'es' | 'pt-PT';

const Status: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div>
      {status === 'PENDENTE' && (
        <label className={styles.statusCreated}>{t('BACKOFFICE.COMMISSIONS_TABLE.PENDING')}</label>
      )}
      {status === 'PAGA' && (
        <label className={styles.statuSucceeded}>{t('BACKOFFICE.COMMISSIONS_TABLE.BOOKING_COMPLETED')}</label>
      )}
      {status === 'CANCELADA' && (
        <label className={styles.statusFailed}>{t('BACKOFFICE.COMMISSIONS_TABLE.CANCELED')}</label>
      )}
    </div>
  );
};

const AgencyStatus: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div>
      {status === 'PAGAMENTO_PENDENTE' && (
        <label className={styles.agencyStatusCreated}>{t('BACKOFFICE.COMMISSIONS_TABLE.PAYMENT_PENDING')}</label>
      )}
      {status === 'PAGAMENTO_REALIZADO' && (
        <label className={styles.agencyStatuSucceeded}>{t('BACKOFFICE.COMMISSIONS_TABLE.PAYMENT_COMPLETED')}</label>
      )}
      {status === 'RESERVA_CANCELADA' && (
        <label className={styles.agencyStatusFailed}>{t('BACKOFFICE.COMMISSIONS_TABLE.BOOKING_CANCELED')}</label>
      )}
      {status === 'N/A' && <label>N/A</label>}
    </div>
  );
};

const CommissionsTable: React.FC<any> = ({ data, updateComission }) => {
  const initialLanguage = i18n.language as Language;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const { t } = useTranslation();
  const [reservationsValues, setReservationValues] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const collumnsNames = [
    t('BACKOFFICE.COMMISSIONS_TABLE.DATE'),
    t('BACKOFFICE.COMMISSIONS_TABLE.RESERVATION'),
    t('BACKOFFICE.COMMISSIONS_TABLE.PROPERTY'),
    t('BACKOFFICE.COMMISSIONS_TABLE.SOLD_BY'),
    t('BACKOFFICE.COMMISSIONS_TABLE.AGENCY'),
    t('BACKOFFICE.COMMISSIONS_TABLE.SALE_VALUE'),
    t('BACKOFFICE.COMMISSIONS_TABLE.COMMISSION'),
    t('BACKOFFICE.COMMISSIONS_TABLE.STATUS'),
    t('BACKOFFICE.COMMISSIONS_TABLE.REPORTS.AGENCY_PAYMENT').toUpperCase(),
    ' ',
  ];

  const convertStatus: any = (status: any) => {
    switch (status) {
      case 'PENDING':
        return 'PENDENTE';
      case 'APPROVED':
        return 'PAGA';
      case 'CANCELED':
        return 'CANCELADA';
      default:
        return '';
    }
  };

  const formatDate = (dateString: string, language: Language): string => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      moment.locale('pt-br');
      return moment(dateString).format('DD MMM YYYY HH:mm');
    } else {
      moment.locale('en');
      return moment(dateString).format('MMM DD, YYYY HH:mm');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePayment = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleOptionClick = async () => {
    try {
      await sendApprovedPayment(selectedRowId);
      updateComission();
    } catch {
      toast.error('Payment Error');
    }
    handleClose();
  };

  useEffect(() => {
    // TODO: tentar melhorar isso com um hook talvez
    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  useEffect(() => {
    /**
     *   'DATA',
  'RESERVA',
  'IMÓVEL',
  'VENDIDO POR',
  'AGÊNCIA',
  'VALOR DA VENDA',
  'COMISSÃO',
  'PORCENTAGEM',
  'DESCONTOS',
  'STATUS'
     */
    const rows = data?.map((value: any) => {
      return {
        date: String(value?.date),
        reservaCode: String(value?.reservaCode),
        houseId: String(value?.houseId),
        agente: value?.agente,
        agency: value?.agency,
        valueReal: String(value?.valueReal),
        valueDollar: String(value?.valueDollar),
        commissionReal: String(value?.comissaoReal),
        comissionDollar: String(value?.comissaoDollar),
        // porcentagem
        // descontos
        status: convertStatus(value?.status),
        pagamentoAgenciaStatus: value?.pagamentoAgenciaStatus,
      };
    });
    setReservationValues(rows);
  }, [data, currentLanguage]);

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {reservationsValues?.map((row: any) => (
        <TableRow key={row?.reservaCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <div className={styles.date}>{formatDate(row?.date, currentLanguage)}</div>
          </TableCell>
          <TableCell>
            <div className={styles.reservationCode}>#{row?.reservaCode}</div>
          </TableCell>
          <TableCell>
            <label className={styles.house}>#{row?.houseId}</label>
          </TableCell>
          <TableCell>{row?.agente}</TableCell>
          <TableCell>{row?.agency}</TableCell>
          <TableCell>
            <label>
              {currentLanguage === 'pt-BR' || currentLanguage === 'pt-PT'
                ? 'R$' + ' ' + row?.valueReal
                : '$' + ' ' + row?.valueDollar}
            </label>
          </TableCell>
          <TableCell>
            <label className={styles.currency}>
              {currentLanguage === 'pt-BR' || currentLanguage === 'pt-PT'
                ? 'R$' + ' ' + row?.commissionReal
                : '$' + ' ' + row?.comissionDollar}
            </label>
          </TableCell>
          <TableCell>
            <Status status={row?.status} />
          </TableCell>
          <TableCell>
            <AgencyStatus status={row?.pagamentoAgenciaStatus} />
          </TableCell>
          <TableCell>
            {row?.pagamentoAgenciaStatus === 'PAGAMENTO_PENDENTE' && (
              <div>
                <IconButton
                  aria-controls={`simple-menu-${row.reservaCode}`}
                  aria-haspopup="true"
                  onClick={(event) => handlePayment(event, row.reservaCode)}
                >
                  <SlOptionsVertical size={20} color={'#0049A1'} />
                </IconButton>
                <Menu
                  id={`simple-menu-${row.reservaCoded}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) && selectedRowId === row.reservaCode}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleOptionClick()}>
                    {t('BACKOFFICE.COMMISSIONS_TABLE.PAYMENT_HAS_BEEN_COMPLETED')}
                  </MenuItem>
                </Menu>
              </div>
            )}
          </TableCell>
        </TableRow>
      ))}
    </BasicTable>
  );
};

export default CommissionsTable;
