import Agency from 'models/application/Agency/Agency.model';
import { axiosInstances } from 'services/api';

const getAgency = async (agencyId: string): Promise<Agency> => {
  const { data } = await axiosInstances.base.instance.get(`/service/agencies/${agencyId}`);

  return {
    id: data.id,
    name: data.name,
    cnpj: data?.cnpj,
    email: data.email,
    cadastur: data.cadastur,
    cellphone: data.primaryContact,
    primaryContact: data.primaryContact,
    urlSite: data.urlSite,
    document: data.document ?? data.cnpj,
    profitPercentage: data.profitPercentage,
    language: data.language,
    numberState: data.numberState,
    active: data.active,
    address: data.address,
    city: data.city,
    country: data.country,
    operadora: data.operadora,
  };
};

export default getAgency;
