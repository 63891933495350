import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import { IHouseResponse } from 'models/contracts/response/House.response';
import { House } from 'models/application/House.model';

export const getHouseById = async (id: number): Promise<House> => {
  const { data }: IApi<IHouseResponse> = await axiosInstances.base.instance.get(`service/v1/house/${id}`);

  return new House({
    id,
    name: data.name,
    description: data.description,
    descriptionPT: data.descriptionPT,
    descriptionES: data.descriptionES,
    coordenates: {
      lat: data.latitude,
      long: data.longitude,
    },
    scheduleRules: {
      checkIn: data.checkInTime,
      checkOut: data.checkOutTime,
    },
    pictures: data.pictures,
    amenities: data.amenities,
    maxOccupancy: data.maxOccupancy ?? 0,
    bedroomAmount: data.roomAmount,
    bathroomAmount: data.bathroomAmount,
    minimunChildAge: data.minimunChildAge,
    isPetFriendly: data.animalFriendly,
    poolProtectedToChildAndAnimal: data.poolProtectedToChildAndAnimal,
    poolRestritecHours: data.poolRestritecHours,
    childFriendly: data.childFriendly,
    poolCovered: data.poolCovered,
    heatedPool: data.heatedPool,
    scheduleForRent: data.houseSchedule,
    hasHydromassage: data.hasHydromassage,
  });
};
