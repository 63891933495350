import styles from '../styles.module.scss';
import { useState } from 'react';
import AgencyFormBR from './AgencyForm';
import AgentFormBR from './AgentForm';
import BrazilianAgency from 'models/application/Agency/BrazilianAgency.model';
import SuccessFeedback from 'pages/Register/components/SuccessFeedback';
import BrazilianAgent from 'models/application/Agent/BrazilianAgent.model';
import useAgency from 'hooks/useAgency.hook';
import MoonLoader from 'react-spinners/MoonLoader';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import OperatorFormBR from './OperatorForm';
import Operator from 'models/application/Operator/Operator.model';

type RegisterFormBRProps = {
  currentStep: number;
  onNextStep: () => void;
  isOperator?: boolean;
};

const RegisterFormBR: React.FC<RegisterFormBRProps> = ({ currentStep, onNextStep, isOperator }) => {
  const { createAgencyAndAgentBR, loadingSubmitAgencyWithAgent } = useAgency();
  const [operator, setOperador] = useState<Operator>();
  const [agent, setAgent] = useState<BrazilianAgent>();
  const [agency, setAgency] = useState<BrazilianAgency>();

  const handleSubmitOperatorForm = async (operatorValues: Operator) => {
    setOperador(operatorValues);
    try {
      await createAgencyAndAgentBR(agency, agent, operatorValues);
      onNextStep();
    } catch (error) {
      console.warn(error);
    }
  };

  const handleSubmitAgentForm = (values: BrazilianAgent) => {
    setAgent(values);
    onNextStep();
  };

  const handleSubmitAgencyWhenOperator = (americanAgency: BrazilianAgency) => {
    setAgency(americanAgency);
    onNextStep();
  };

  const handleSubmitAgencyForm = async (agencyBR: BrazilianAgency) => {
    setAgency(agencyBR);
    try {
      await createAgencyAndAgentBR(agencyBR, agent);
      onNextStep();
    } catch (error) {
      console.warn(error);
    }
  };

  const getCurrentStep = () => {
    if (loadingSubmitAgencyWithAgent) {
      return (
        <div className={styles.loading}>
          <MoonLoader color={PRIMARY_BLUE_COBALT} />
        </div>
      );
    }

    if (currentStep === 0) {
      return <AgentFormBR onSubmit={handleSubmitAgentForm} agent={agent} />;
    }

    if (currentStep === 1) {
      return <AgencyFormBR onSubmit={handleSubmitAgencyForm} agency={agency} />;
    }

    if (currentStep === 2) {
      return (
        <SuccessFeedback text="Agradecemos por escolher a GoWeasy. Em breve, nossa equipe entrará em contato para fornecer acesso exclusivo e informações sobre como você pode aproveitar ao máximo a nossa plataforma." />
      );
    }
  };

  const getCurrentStepOperator = () => {
    if (loadingSubmitAgencyWithAgent) {
      return (
        <div className={styles.loading}>
          <MoonLoader color={PRIMARY_BLUE_COBALT} />
        </div>
      );
    }

    if (currentStep === 0) {
      return <AgentFormBR isOperator={true} onSubmit={handleSubmitAgentForm} agent={agent} />;
    }

    if (currentStep === 1) {
      return <AgencyFormBR onSubmit={handleSubmitAgencyWhenOperator} agency={agency} />;
    }

    if (currentStep === 2) {
      return <OperatorFormBR onSubmit={handleSubmitOperatorForm} operator={operator} />;
    }
    if (currentStep === 3) {
      return (
        <SuccessFeedback text="We appreciate you choosing GoWeasy. Soon, our team will be in touch to provide exclusive access and information on how you can make the most out of our platform." />
      );
    }
  };

  return (
    <div className={styles.container}>
      {!isOperator && getCurrentStep()}
      {isOperator && getCurrentStepOperator()}
    </div>
  );
};

export default RegisterFormBR;
