import { axiosInstances } from 'services/api';
import { Cep } from './cepModel';

const getAddressByCep = async (cep: string): Promise<Cep> => {
  const { data } = await axiosInstances.cep.instance.get(`${cep}/json/`);
  return {
    logradouro: data?.logradouro,
    complemento: data?.complemento,
    unidade: data?.unidade,
    bairro: data?.bairro,
    localidade: data?.localidade,
    uf: data?.uf,
    estado: data?.estado,
  };
};

export default getAddressByCep;
