import { Buttons } from 'components/molecules/buttons';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreditCard } from 'assets';
import TranslationButton from 'components/TranslationButton';
import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { useEffect } from 'react';
import useCheckout from 'hooks/useCheckout.hook';

const FailCheckout = () => {
  const { cancelReservation } = useCheckout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    cancelReservation(location.state?.reservationId);
  }, [location.state?.reservationId]);

  return (
    <div>
      <div className={styles.header}>
        <Logo onClick={navigateToHome} className={styles.logo} />
        <div className={styles.icon}>
          <TranslationButton />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <img src={CreditCard} alt="" />
            <h4>{t('FAIL_CHECKOUT.TITLE')}</h4>
            <div className={styles.text}>{t('FAIL_CHECKOUT.MESSAGE_1')}</div>
            <div className={styles.text}>{t('FAIL_CHECKOUT.MESSAGE_2')}</div>
            <Buttons.Default
              className={styles.button}
              backgroundColor="#0049A1"
              variant="contained"
              onClick={navigateToHome}
            >
              {t('FAIL_CHECKOUT.BUTTON')}
            </Buttons.Default>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailCheckout;
