import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import AuthenticatedLayout from 'layouts/Authenticated/index.layout';
import CheckoutPage from 'pages/Checkout';
import Home from 'pages/Home';
import HouseDetailsPage from 'pages/HouseDetails/HouseDetails.page';
import Login from 'pages/Login';
import {
  FORGOT_PASSWORD_SUCCESS_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  RECOVER_PASSWORD_URL,
  REGISTER_URL,
  SIGN_BASE_URL,
} from './routes/public.routes';
import AccessLayout from 'layouts/Access/index.layout';
import ForgotPassword from 'pages/ForgotPassword';
import RegisterForm from 'pages/Register';
import SearchHouses from 'pages/SearchHouses';
import HomeLayout from 'layouts/Home';
import ConfirmedCheckout from 'pages/ConfirmedCheckout';
import Condos from 'pages/Condominium';
import HousesInCondominium from 'pages/HousesInCondominium';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfService from 'pages/TermsOfService';
import WhoWeAre from 'pages/WhoWeAre';
import InfosLayout from 'layouts/Infos';
import ProviderLayout from 'layouts/Provider';
import BackOfficeLayout from 'layouts/BackOfficeLayout';
import Management from 'pages/BackOffice/Management';
import {
  AGENCY_DETAILS,
  AGENCY_URL,
  AGENT_URL,
  BACKOFFICE_BASE_URL,
  CHECK_AVAILABILITY_URL,
  COMMISSION_URL,
  CONDOMINIUM_LIST_ADM,
  HOUSE_LIST_ADM,
  HOUSE_MANAGEMENT,
  INCLUDE_NEW_CONDOMINIUM,
  LINKED_AGENCIES,
  LINKED_AGENTS,
  LIST_AGENCY,
  LIST_OPERATORS,
  MANAGEMENT_URL,
  OPERATOR_DETAILS,
  REGISTER_OPERATORS_AND_AGENCIES,
  REPORTS_LIST,
  RESERVATIONS_DETAILS_URL,
  RESERVATIONS_URL,
} from './routes/backOffice.routes';
import AgentEdit from 'pages/BackOffice/Agent';
import Agency from 'pages/BackOffice/Agency';
import Reservations from 'pages/Reservations';
import ReservationDetails from 'pages/ReservationDetails';
import Commissions from 'pages/BackOffice/Commission';
import RecoverPassword from 'pages/RecoverPassword';
import ForgotPasswordSuccess from 'pages/ForgotPasswordSuccess';
import CheckAvailability from 'pages/BackOffice/CheckAvailability';
import ListAgency from 'pages/BackOffice/Administrative/ListAgency';
import HousesListAdm from 'pages/BackOffice/Administrative/HousesListAdm';
import CondominiumListAdm from 'pages/BackOffice/Administrative/CondominiumListAdm';
import IncludeNewCondominium from 'pages/BackOffice/Administrative/IncludeNewCondominium';
import ConfirmCheckout from 'pages/FinishCheckout';
import HouseManagement from 'pages/BackOffice/Administrative/HouseManagement';
import FailCheckout from 'pages/FailCheckout';
import SuccessCheckout from 'pages/SuccessCheckout';
import ReservationResume from 'pages/ReservationResume';
import AgencyDetails from 'pages/BackOffice/Administrative/AgencyDetails';
import ManagementLayout from 'layouts/Management';
import ListOperators from 'pages/BackOffice/Administrative/ListOperators';
import LinkedAgencies from 'pages/BackOffice/Administrative/ListOperators/LinkedAgencies';
import LinkedAgents from 'pages/BackOffice/Administrative/ListOperators/LinkedAgents';
import ReportsList from 'pages/BackOffice/Administrative/ReportsList';
import RegisterOperatorAndAgency from 'pages/BackOffice/Administrative/Register';
import OperatorDetails from 'pages/BackOffice/Administrative/ListOperators/OperatorsDetails';
import CancellationPolicy from 'pages/CancellationPolicy';
import AcademyPage from 'pages/Academy';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProviderLayout />,
    children: [
      {
        path: '/',
        element: <AuthenticatedLayout />,
        children: [
          {
            path: '/',
            element: <HomeLayout />,
            children: [
              {
                path: '/',
                element: <Home />,
              },
              {
                path: '/list-houses',
                element: <SearchHouses />,
              },
            ],
          },
          {
            path: '/house-details/:houseId',
            element: <HouseDetailsPage />,
          },
          {
            path: '/checkout/:houseId',
            element: <CheckoutPage />,
          },
          {
            path: '/academy',
            element: <AcademyPage />,
          },
        ],
      },
      {
        path: '/infos',
        element: <InfosLayout />,
        children: [
          {
            path: '/infos/who-we-are',
            element: <WhoWeAre />,
          },
          {
            path: '/infos/privacy-policy',
            element: <PrivacyPolicy />,
          },
          {
            path: '/infos/cancellation',
            element: <CancellationPolicy />,
          },
          {
            path: '/infos/terms-of-service',
            element: <TermsOfService />,
          },
          {
            path: '/infos/reservation/details/:reservationId',
            element: <ReservationResume />,
          },
        ],
      },
      {
        path: '/condominiums/:city',
        element: <Condos />,
      },
      {
        path: '/condominium',
        element: <HousesInCondominium />,
      },
      {
        path: '/checkout/confirm',
        element: <ConfirmCheckout />,
      },
      {
        path: '/checkout/success',
        element: <SuccessCheckout />,
      },
      {
        path: '/checkout/fail',
        element: <FailCheckout />,
      },
      {
        path: '/checkout/confirmed',
        element: <ConfirmedCheckout />,
      },
      {
        path: BACKOFFICE_BASE_URL,
        element: <BackOfficeLayout />,
        children: [
          {
            path: MANAGEMENT_URL,
            element: <Management />,
          },
          {
            path: AGENT_URL,
            element: <AgentEdit />,
          },
          {
            path: AGENCY_URL,
            element: <Agency />,
          },
          {
            path: RESERVATIONS_URL,
            element: <Reservations />,
          },
          {
            path: RESERVATIONS_DETAILS_URL,
            element: <ReservationDetails />,
          },
          {
            path: COMMISSION_URL,
            element: <Commissions />,
          },
          {
            path: CHECK_AVAILABILITY_URL,
            element: <CheckAvailability />,
          },
          {
            path: BACKOFFICE_BASE_URL,
            element: <ManagementLayout />,
            children: [
              {
                path: LIST_OPERATORS,
                element: <ListOperators />,
              },
              {
                path: OPERATOR_DETAILS,
                element: <OperatorDetails />,
              },
              {
                path: REGISTER_OPERATORS_AND_AGENCIES,
                element: <RegisterOperatorAndAgency />,
              },
              {
                path: REPORTS_LIST,
                element: <ReportsList />,
              },
              {
                path: LINKED_AGENCIES,
                element: <LinkedAgencies />,
              },
              {
                path: LINKED_AGENTS,
                element: <LinkedAgents />,
              },
              {
                path: LIST_AGENCY,
                element: <ListAgency />,
              },
              {
                path: AGENCY_DETAILS,
                element: <AgencyDetails />,
              },
              {
                path: HOUSE_LIST_ADM,
                element: <HousesListAdm />,
              },
              {
                path: CONDOMINIUM_LIST_ADM,
                element: <CondominiumListAdm />,
              },
              {
                path: INCLUDE_NEW_CONDOMINIUM,
                element: <IncludeNewCondominium />,
              },
              {
                path: HOUSE_MANAGEMENT,
                element: <HouseManagement />,
              },
            ],
          },
        ],
      },
      {
        path: SIGN_BASE_URL,
        element: <AccessLayout />,
        children: [
          {
            path: LOGIN_URL,
            element: <Login />,
          },
          {
            path: REGISTER_URL,
            element: <RegisterForm />,
          },
          {
            path: FORGOT_PASSWORD_URL,
            element: <ForgotPassword />,
          },
          {
            path: FORGOT_PASSWORD_SUCCESS_URL,
            element: <ForgotPasswordSuccess />,
          },
        ],
      },
      {
        path: '/user',
        element: <AccessLayout />,
        children: [
          {
            path: RECOVER_PASSWORD_URL,
            element: <RecoverPassword />,
          },
        ],
      },
    ],
  },
]);

const Routes = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default Routes;
