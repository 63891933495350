import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import enTranslation from './english-us';
import ptBrTranslation from './portuguese-br';
import esTranslation from './spanish-es';
import { Languages } from './helpers.languages';

i18n.use(initReactI18next).init({
  lng: Languages.PT_BR,
  fallbackLng: Languages.PT_BR,
  resources: {
    en: { translation: enTranslation },
    'pt-BR': { translation: ptBrTranslation },
    es: { translation: esTranslation },
  },
});

export default i18n;
