import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import { ILoginResonse } from 'models/contracts/response/Login.response';

export const URL = '/service/payment/verifyPagTur';

export const verifyPayment = async (transactionId: string, orderId: string, linkId: string): Promise<any> => {
  const { data }: IApi<ILoginResonse> = await axiosInstances.base.instance.post(URL, {
    transactionId,
    orderId,
    linkId,
  });

  return data;
};
