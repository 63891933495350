/* eslint-disable max-len */

export default {
  WHO_WE_ARE: {
    TITLE: 'About us',
    SUBTITLE: 'Welcome to GoWeasy, your exclusive platform for vacation home rentals!',
    PARAGRAPH: `
      GoWeasy was born to facilitate the search for the best lodging experience in a vacation home. The idea originated from the personal experience that the founders acquired over nearly 30 years working in the tourism market. As owners of a large network of travel agencies and a Property Management company, we understand the challenges that a client faces when seeking to make the right choice of the perfect home and even more so in the service received throughout the stay.
      <br/> <br/>
      With so many options that the market offers, it's not always easy to achieve these goals and connect the perfect home with the client.
      <br/> <br/>
      Here, we are focused on simplifying the vacation home booking experience for travel agents.
      <br/> <br/>
      Our platform has been meticulously designed to seamlessly connect your company with a premium selection of vacation homes. By choosing GoWeasy, you will have access to a powerful system that streamlines your search, saving precious time, ensuring efficient bookings, and providing unique experiences for your clients.
      <br/> <br/>
      Our goal is to provide a reliable partnership that turns challenges into solutions. With a dedicated and qualified team, we offer all the support needed for your company to stand out in customer service, maximizing your sales.
      <br/> <br/>
      Join us at GoWeasy and enter this fascinating market of offering a real experience in an American home.
       `,
    PROPERTY: {
      TITLE: 'Property Managers',
      SUBTITLE: 'Your Journey with GoWeasy: Partnership for Success',
      PARAGRAPH: `<strong>Discovery:</strong> GoWeasy offers an innovative tool to connect your properties to a new market of thousands of travel agencies (OTAs, Travel Techs, Digital Influencers) in multiple countries, bringing the opportunity to increase your occupancy rates and profitability. GoWeasy is the solution you've been looking for to complement your distribution strategy.
      <br/> <br/>
      <strong>Immediate Partnership:</strong> Submit your information and ensure quick approval as a GoWeasy partner. You'll be joining a community committed to mutual success and business growth!
      <br/> <br/>
      <strong>Listing Free of Commitments:</strong> Best of all? There are no costs or exclusivity obligations when listing your properties on GoWeasy. You maintain control while expanding your presence in a diversified market.
      <br/> <br/>
      <strong>Maximize Your Profitability:</strong> With our efficient distribution platform, your properties gain the attention they deserve and are seen by the largest distribution channels in the industry. Increase your occupancy rates and watch your profitability soar!
      <br/> <br/>
      <strong>High-Level Support:</strong> We're here for you! Our experienced team offers guidance, ongoing support, and valuable insights to drive your results.
      <br/> <br/>
      <strong>Connect to Advance:</strong> Interested in our proposition? Contact us now to start a partnership that will revolutionize how you manage your properties. It's time to leverage your business! Join GoWeasy and experience a new era of opportunities for your vacation rental properties.`,
    },
    AGENTS: {
      TITLE: 'Travel Agents',
      SUBTITLE: 'Your Journey with GoWeasy: Unlocking a World of Opportunities',
      PARAGRAPH: `<strong>Explore New Possibilities:</strong> As an OTA or travel agent, you're constantly seeking differentiators to meet client demands. At GoWeasy, you discover a new world of possibilities, specially crafted to place you in this vacation rental market.
      <br/> <br/>
      <strong>Flexible Commission Platform:</strong> Additionally, we offer you the exclusive ability to manage your commissions directly through our platform. Simplify the financial control process by adding your commissions conveniently and transparently, all in one place.
      <br/> <br/>
      <strong>Exceptional Platform:</strong> By becoming a GoWeasy partner, you gain access to an exceptional platform, specifically designed to meet the needs of OTAs and travel agents. A unique selection of properties, handpicked to ensure quality and diversity, will be at your disposal.
      <br/> <br/>
      <strong>Handpicked Inventory:</strong> We offer carefully selected inventory to meet the highest quality standards. With meticulously chosen properties, you can present your clients with a unique range of vacation rental options.
      <br/> <br/>
      <strong>24/7 Support:</strong> Our dedicated team offers support around the clock, assisting you in meeting your client's needs when they need it most.
      <br/> <br/>
      <strong>Join Us:</strong> Interested in what we offer? Contact us now to explore a unique partnership. We're ready to offer the best in stay and experience in an American home.`,
    },
  },
};
