import HouseCard from 'components/HouseCard';
import { HouseResume } from 'models/application/HouseResume.model';
import { useLocation, useNavigate } from 'react-router-dom';
import useFilters from 'hooks/useFilters';
import WECarousel from 'components/WECarousel';
import { setOrigim } from 'utils/helpers';

type HouseListProps = {
  houses: HouseResume[];
};

const HouseList: React.FC<HouseListProps> = ({ houses }) => {
  const navigate = useNavigate();
  const { getFiltersAsQueryString } = useFilters();
  const location = useLocation();

  const handleClick = (id: number) => {
    setOrigim(`${location.pathname}${location.search}`);
    navigate({
      pathname: `/house-details/${id}`,
      search: `?${getFiltersAsQueryString()}`,
    });
  };

  return (
    <WECarousel>
      {houses.map((house) => (
        <HouseCard
          image={house.mainPicture}
          pricePerNight={house.getPrice()}
          address={house.name}
          name={house.name}
          id={house.id}
          spaces={house.amenities.map((amenitie) => amenitie.name)}
          rooms={house.bedroomAmount}
          guests={house.maxOccupancy}
          key={house.id}
          onClickItem={handleClick}
        />
      ))}
    </WECarousel>
  );
};

export default HouseList;
