class BrazilianAgent {
  name: string;
  cpf: string;
  position?: string;
  email: string;
  phone: string;
  cep: string;
  number: string;
  complement: string;
  password: string;
  confirmPassword?: string;

  constructor(agent: BrazilianAgent) {
    this.name = agent.name;
    this.cpf = agent.cpf;
    this.position = agent.position;
    this.email = agent.email;
    this.phone = agent.phone;
    this.cep = agent.cep;
    this.number = agent.number;
    this.complement = agent.complement;
    this.password = agent.password;
  }
}

export default BrazilianAgent;
