import styled from 'styled-components';

export const PropertyRulesTitle = styled.div`
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
`;

export const RulesContainer = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
`;

export const LabelContainer = styled.div`
  margin-left: 8px;
`;

export const Label = styled.div`
  color: #ffffff;
  font-size: 14px;
`;

export const Description = styled.div`
  margin-top: 8px;
  color: #ffffff;
  font-size: 14px;
  opacity: 0.8;
`;

export const DamageRuleContainer = styled.div`
  margin-top: 48px;
`;
