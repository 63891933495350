import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import styles from './styles.module.scss';
import Divider from 'components/atoms/Divider';
import Checkout from 'models/application/Checkout.model';
import { getCurrency } from 'languages/helpers.languages';
import { House } from 'models/application/House.model';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/*import BookingContext from 'store/context/booking.context';
import { useContext, useEffect } from 'react';
import useHouse from 'hooks/useHouse';
import useFilters from 'hooks/useFilters';*/

type SummaryOfTheOrderProps = {
  checkOut?: Checkout;
  houseBooking: House;
};

const SummaryOfTheOrder: React.FC<SummaryOfTheOrderProps> = ({ checkOut, houseBooking }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //const { setHouseBooking, houseBooking } = useContext(BookingContext);

  //const { getHouseId } = useFilters();
  //const { house } = useHouse(getHouseId());

  const checkLargeDescription = houseBooking?.name.length >= 180;

  const currency = getCurrency();

  useEffect(() => {
    if (!houseBooking) {
      navigate(`${window.location.pathname.replace('/checkout/', '/house-details/')}${window.location.search}`);
    }
  }, [houseBooking]);

  if (!checkOut) return null;

  return (
    <div className={styles.container}>
      <div className={styles.flexColumn}>
        <img src={houseBooking?.pictures[0].url} alt={t('BACKEND.DESCRIPTION')} />
        <Tooltip title={checkLargeDescription ? houseBooking.name : null}>
          <div className={styles.houseName}>
            {houseBooking?.name.substring(0, 180) + (checkLargeDescription ? '...' : '')}
          </div>
        </Tooltip>
      </div>
      <Divider color="#DEDEDE" />
      <div className={styles.priceInfo}>{t('CHECKOUT.HOUSE_DETAILS.PRICE_INFO')}</div>
      <div className={styles.dataLine}>
        <div>{t('CARD.DAILYS')}</div>
        <div>
          {currency} {checkOut.getDailyTotal()}
        </div>
      </div>
      <div className={styles.dataLine}>
        <div>{t('CARD.SERVICE_FEE')}</div>
        <div>
          {currency} {checkOut.getServiceFee()}
        </div>
      </div>
      <div className={styles.dataLine}>
        <div>{t('CARD.TAXES')}</div>
        <div>
          {currency} {checkOut.getTaxes()}
        </div>
      </div>
      <div className={styles.dataLine}>
        <div>{t('CARD.PLATFORM_FEE')}</div>
        <div>
          {currency} {checkOut.getPlatformFee()}
        </div>
      </div>
      {checkOut.getDiscount() !== '0' && (
        <div className={styles.dataLine}>
          <div>{t('CARD.LONG_STAY_DISCOUNT')}</div>
          <div>
            {currency} {checkOut.getDiscount()}
          </div>
        </div>
      )}
      <Divider color="#DEDEDE" />
      <div className={styles.dataLine}>
        <div className={styles.total}>Total</div>
        <div className={styles.totalValue}>
          {currency} {checkOut.getTotalPrice()}
        </div>
      </div>
    </div>
  );
};

export default SummaryOfTheOrder;
