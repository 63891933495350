import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

const WESelect: React.FC<any> = ({ label, options, onChangeValue, value, placeholder, labelClass, ...props }) => {
  const handleChange = (event: any) => {
    onChangeValue(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel className={labelClass} id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          {...props}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default WESelect;
