import styled from 'styled-components';
import { Accordion } from '@mui/material';

export const Title = styled.div`
  font-size: 22px;
  padding: 12px 0px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: #6f6f6f;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  margin-top: 4px;
  padding: 8px 0px !important;
`;
