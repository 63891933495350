import TitleBack from 'components/TitleBack';
import styles from './styles.module.scss';
import pdf from './terms_of_use.pdf';
import WEPDF from 'components/WEPdf';
import { useTranslation } from 'react-i18next';

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleBack title={t('TERMS_OF_SERVICE.TITLE')} />
      <div className={styles.container}>
        <WEPDF pdfUrl={pdf} />
      </div>
    </>
  );
};

export default TermsOfService;
