import React, { useEffect, useState } from 'react';
import TranslationButton from 'components/TranslationButton';

import styles from './styles.module.scss';
import useCurrentPage from 'hooks/useCurrentPage';
import UserOptions from 'components/atoms/UserOptions';
import Logo from 'components/atoms/Logo';

type HeaderProps = {
  children?: any;
  backgroundTransparent?: boolean;
  backgroudBlue?: boolean;
};

const Header: React.FC<HeaderProps> = ({ children, backgroundTransparent, backgroudBlue }) => {
  const [isOnTop, setIsOnTop] = useState<boolean>(true);

  const { isInHome } = useCurrentPage();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && isOnTop) {
        return setIsOnTop(false);
      }
      if (window.scrollY === 0 && !isOnTop) {
        return setIsOnTop(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOnTop, isInHome]);

  const getStyle = () => {
    if ((isOnTop && isInHome) || (backgroundTransparent && isOnTop)) {
      return styles.bgTransparent;
    }

    if (!isOnTop) {
      return styles.bgNone;
    }

    if (backgroudBlue && isOnTop) {
      return styles.bgBlue;
    }

    return styles.bgColor;
  };

  return (
    <div className={`${styles.mainContainer} ${getStyle()}`}>
      <div className={styles.containerDesktop}>
        <Logo />
        <div>{children}</div>
        <div className={styles.options}>
          <div className={styles.buttons}>
            <TranslationButton />
            <UserOptions />
          </div>
        </div>
      </div>
      <div className={styles.containerNotDesktop}>
        <Logo />
        <div className={styles.icons}>
          <TranslationButton />
          <UserOptions />
        </div>
      </div>
    </div>
  );
};
export default Header;
