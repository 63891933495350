import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
// TODO: Criar os tipos
const CommissionsList = async (agencyId: number, startDate: any, endDate: any): Promise<any> => {
  const { data }: IApi<any> = await axiosInstances.base.instance.get(
    `/service/payment/list/agencie/${agencyId}?startDate=${startDate}&endDate=${endDate}`,
  );

  return data;
};

export default CommissionsList;
