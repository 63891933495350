import { Outlet, ScrollRestoration } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';

import styles from './styles.module.scss';

const InfosLayout = () => {
  return (
    <div className={styles.container}>
      <ScrollRestoration />
      <Header backgroundTransparent={true} />
      <div className={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default InfosLayout;
