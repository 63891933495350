import Inputs from 'components/molecules/inputs';
import React from 'react';
import { useTranslation } from 'react-i18next';

type SeletRoleProps = {
  form: any;
};

const SelectRole: React.FC<SeletRoleProps> = ({ form, ...props }) => {
  const { t } = useTranslation();

  return (
    <Inputs.SelectInput
      form={form}
      required
      label={t('BACKOFFICE.AGENT.ROLE')}
      name="role"
      defaultValue={form.watch('role')}
      options={[
        { value: 'ADMIN', label: t('BACKOFFICE.TABLE.ADMIN') },
        { value: 'USER', label: t('BACKOFFICE.TABLE.USER') },
      ]}
      {...props}
    />
  );
};

export default SelectRole;
