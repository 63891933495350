import React from 'react';
import { BaseProps } from './BaseProps';

const FirstTimeOrlandoSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.251" height="41.251" viewBox="0 0 41.251 41.251">
      <g id="Page-1" transform="translate(-3 0)">
        <g id="_017---First-Flag" data-name="017---First-Flag" transform="translate(3 0)">
          <path
            id="Shape"
            d="M6.99,17.163a2.03,2.03,0,0,0,2.735.021V20.6H7.662a1.375,1.375,0,0,0-1.375,1.375v2.063a1.375,1.375,0,0,0,1.375,1.375H16.6a1.375,1.375,0,0,0,1.375-1.375V21.975A1.375,1.375,0,0,0,16.6,20.6H14.537V11.662A2.063,2.063,0,0,0,12.475,9.6H10.983a2.052,2.052,0,0,0-1.46.605l-3.33,3.33a2.034,2.034,0,0,0,.049,2.925Zm.175-2.653,3.33-3.33a.688.688,0,0,1,.488-.2h1.492a.688.688,0,0,1,.688.688V20.6a1.375,1.375,0,0,0,1.375,1.375H16.6v2.063H7.662V21.975H9.725A1.375,1.375,0,0,0,11.1,20.6V15.341A.688.688,0,0,0,9.888,14.9L8.831,16.144a.642.642,0,0,1-.894.015l-.756-.7a.659.659,0,0,1-.017-.949Z"
            transform="translate(-0.787 -1.35)"
            fill={color}
          />
          <path
            id="Shape-2"
            data-name="Shape"
            d="M19.662,14.3H18.287a.688.688,0,1,0,0,1.375h1.375a2.063,2.063,0,1,0,0-4.125.688.688,0,0,1,0-1.375h1.375a.688.688,0,0,0,0-1.375H19.662a2.063,2.063,0,0,0,0,4.125.688.688,0,1,1,0,1.375Z"
            transform="translate(-2.475 -1.237)"
            fill={color}
          />
          <path
            id="Shape-3"
            data-name="Shape"
            d="M23.887,12.238v1.375a2.063,2.063,0,0,0,2.063,2.063h.688a.688.688,0,0,0,0-1.375H25.95a.688.688,0,0,1-.688-.688V12.238h.688a.688.688,0,1,0,0-1.375h-.688V9.487a.688.688,0,1,0-1.375,0v1.375a.688.688,0,0,0,0,1.375Z"
            transform="translate(-3.262 -1.237)"
            fill={color}
          />
          <path
            id="Shape-4"
            data-name="Shape"
            d="M1.375,27.374v12.5A1.375,1.375,0,0,0,2.75,41.251H4.813a1.375,1.375,0,0,0,1.375-1.375V27.5H13.75V33a2.043,2.043,0,0,0,.349,1.144.018.018,0,0,0,0,.012.721.721,0,0,0,.121.144,2.05,2.05,0,0,0,1.589.763H39.188a2.063,2.063,0,0,0,1.6-3.363l-6.412-7.891a.694.694,0,0,1,0-.866l6.415-7.894a2.063,2.063,0,0,0-1.6-3.361H27.5v-5.5a2.063,2.063,0,0,0-2.063-2.063H6.188V1.375A1.375,1.375,0,0,0,4.813,0H2.75A1.375,1.375,0,0,0,1.375,1.375V4.252A2.063,2.063,0,0,0,0,6.188v19.25a2.063,2.063,0,0,0,1.375,1.936Zm3.438,12.5H2.75V27.5H4.813ZM23.437,27.5l-8.312,5.2V27.5ZM39.188,13.063a.688.688,0,0,1,.531,1.12L33.3,22.078a2.078,2.078,0,0,0,0,2.6l6.414,7.891a.688.688,0,0,1-.53,1.121H16.146l10.3-6.45c.013-.008.02-.022.032-.031A2.056,2.056,0,0,0,27.5,25.438V13.063ZM2.75,1.375H4.813v2.75H2.75ZM1.375,6.188A.688.688,0,0,1,2.063,5.5H25.438a.688.688,0,0,1,.688.688v19.25a.688.688,0,0,1-.688.688H2.063a.688.688,0,0,1-.688-.688Z"
            transform="translate(0 0)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default FirstTimeOrlandoSVG;
