export default {
  CLERMONT:
    'A cidade é uma das que mais crescem rapidamente no Centro da Flórida e está estrategicamente localizada a oeste de Orlando e ao norte do Disney World. <br/><br/> Conhecida por suas colinas pitorescas e lagos intocados. A qualidade de vida em torno de Clermont a torna um destino principal para aqueles que procuram um lugar familiar. As lojas locais oferecem uma variedade de opções de compras e restaurantes, amplas instalações esportivas e atividades aquáticas.',
  KISSIMMEE:
    'Kissimmee é a maior cidade do Condado de Osceola, a poucos minutos dos Parques da Disney, Universal Resorts e uma incrível variedade de hotéis, resorts e aluguéis de férias na Capital Mundial de Casas de Férias®. <br/><br/> Há mais do que parques, rios sinuosos e lagos cintilantes, juntamente com experiências ao ar livre. Kissimmee oferece uma incrível variedade de hotéis, resorts e aluguéis de férias na Capital Mundial de Casas de Férias®.',
  DAVENPORT:
    'Localizada a minutos dos Parques da Disney e de Orlando, Davenport é uma cidade bonita que é a estadia perfeita para férias. Aninhada no Centro da Flórida, logo após a I-4 e a Highway 27, a cidade tem fácil acesso ao entretenimento, atividades e restaurantes próximos, na área de Orlando. Também às praias da Costa do Golfo e da Costa Atlântica.',
  ORLANDO:
    'Orlando, Flórida, é conhecida por seus famosos parques temáticos, como Walt Disney World, Universal Studios e SeaWorld. Além dessas atrações únicas, a cidade, com seus céus sempre azuis e atmosfera acolhedora, oferece uma infinidade de experiências. Explore os encantadores Jardins no Leu Gardens Park, desfrute da alta gastronomia e compras em Winter Park, e mergulhe na vibrante vida noturna do Church Street District no centro da cidade. Orlando também conta com uma excelente infraestrutura turística e uma rica cena cultural, com museus, teatros e muitas aventuras ao ar livre.',
  OPEN_MAP: 'Ver no mapa',
  CONDOMINIUMS: 'Condomínios',
  TOOLTIP: {
    ORLANDO:
      'Orlando, Flórida, é conhecida por seus famosos parques temáticos, como Walt Disney World, Universal Studios e SeaWorld.',
    CLERMONT:
      'Colinas e lagos tranquilos perto da Disney! Comunidade encantadora com lojas, restaurantes, esportes e diversão à beira-mar.',
    KISSIMMEE:
      'Minutos para Disney e Universal! A capital do aluguel por temporada perto de Orlando com parques, natureza e aventura!',
    DAVENPORT:
      'Localizada a poucos minutos dos Parques Disney e de Orlando, uma linda cidade que é a estadia perfeita para férias.',
  },
};
