import styled from 'styled-components';

export const DetailsContainer = styled.div`
  margin: 24px 8px 0px 8px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0px;
  font-weight: 500;
`;

export const HouseSpacesContainer = styled.div`
  display: flex;
  gap: 8px 32px;
  flex-wrap: wrap;
  margin-top: 24px;
`;

export const HouseSpaceItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HouseSpaceLabel = styled.div`
  color: #ffffff;
  font-size: 12px;
  text-transform: capitalize;
`;

export const ShowMoreContainer = styled.div`
  margin-top: 16px;
`;

export const ShowMoreButton = styled.div`
  color: #5fbdff;
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;
