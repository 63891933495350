class AgencyAndUserForm {
  // Agent details
  agentName: string;
  agentCpf: string;
  agentEmail: string;
  cep: number;
  complement: string;
  number: number;
  password: string;
  confirmPassword: string;
  phone: string;

  // Agency details
  agencyName: string;
  agencyEmail: string;
  agencyCnpj: string;
  agencyCity: string;
  agencyAddress: string;
  agencyCountry: string;
  agencyCellphone: number;
  stateRegistration: string;
  cadastur: string;
  siteUrl: string;
  agencyCep?: string;

  constructor(data: AgencyAndUserForm) {
    this.agentName = data?.agentName;
    this.agentCpf = data?.agentCpf;
    this.agentEmail = data?.agentEmail;
    this.cep = data?.cep;
    this.number = data?.number;
    this.complement = data?.complement;
    this.phone = data?.phone;
    this.password = data?.password;
    this.confirmPassword = data?.confirmPassword;
    this.agencyName = data?.agencyName;
    this.agencyEmail = data?.agencyEmail;
    this.agencyCnpj = data?.agencyCnpj;
    this.agencyCity = data?.agencyCity;
    this.agencyAddress = data?.agencyAddress;
    this.agencyCountry = data?.agencyCountry;
    this.agencyCellphone = data?.agencyCellphone;
    this.stateRegistration = data.stateRegistration;
    this.cadastur = data.cadastur;
    this.siteUrl = data.siteUrl;
    this.agencyCep = data.agencyCep;
  }
}

export default AgencyAndUserForm;
