import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import Agent from 'models/application/Agent/Agent.model';
import AgentResponse from 'models/contracts/response/Agent.response';

const listAgentByAgency = async (agencyId: number): Promise<Agent[]> => {
  const { data }: IApi<AgentResponse[]> = await axiosInstances.base.instance.get(
    `service/agencies/${agencyId}/employees`,
  );
  return data.map(
    (agent) =>
      new Agent({
        id: agent.id,
        name: agent.name,
        email: agent.email,
        cellphone: agent.phone,
        nationalRegistration: agent?.cpf,
        address: agent.address,
        associationId: agent.associationId,
        city: agent.city,
        zipcode: agent.zipcode,
        country: agent.country,
        state: agent.state,
        agencyCode: agent.agencie.cnpj,
        role: agent.role,
        ativo: agent.ativo,
        sales: agent.sales,
        countReservations: agent.countReservations,
      }),
  );
};

export default listAgentByAgency;
