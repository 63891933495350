import { IoIosArrowDown } from 'react-icons/io';

import { Description, Title, StyledAccordion } from './styles';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import Divider from 'components/atoms/Divider';

type FAQItemProps = {
  item: {
    title?: string;
    description?: string;
  };
};

const FAQItem: React.FC<FAQItemProps> = ({ item }) => {
  return (
    <div>
      <StyledAccordion>
        <AccordionSummary
          expandIcon={<IoIosArrowDown color="#000" size={24} />}
          aria-controls="faq-content"
          id="faq-header"
        >
          <Title>{item.title}</Title>
        </AccordionSummary>
        <AccordionDetails>
          <Description>{item.description}</Description>
        </AccordionDetails>
      </StyledAccordion>
      <Divider color="#cccccc" height="1px" opacity={0.2} />
    </div>
  );
};

export default FAQItem;
