export default {
  TITLE: `Find the region that best serves your clients' needs`,
  GO_WEASY_CHOICES: 'GoWeasy choices',
  SUB_TITLE: 'Find the area that fits your clients needs and desires',
  SEARCH: {
    LABEL: {
      DESTINATION: 'Where',
      BUTTON: 'Search',
    },
    PLACEHOLDER: {
      CHECK_IN: 'Arrival date',
      CHECK_OUT: 'Departure date',
      EMAIL: 'Enter your email address',
    },
  },
  SHOWOFF_EXPERIENCES: {
    CARS: 'Sports Cars',
    CHEF: 'Private chef',
    GOLF: 'Golf Packages',
    BABY: 'Babysitter',
    ENTERTEINMENT: 'Entertainment',
    OUTDOOR_FUN: 'Outdoor Fun',
    PARKS: 'Theme Parks',
    ACCOMODATION: 'Accommodation',
    THEME_PARK: 'Thematic Experiences',
    HOLLYWOOD: 'Hollywood Studios',
    SHOPPING: 'Shopping',
    FISHING: 'Fishing',
    WATER_PARKS: 'Water Parks',
    HOUSES: 'Themed Houses',
    BUTTON: 'SOON',
  },
  PARTNERS: {
    TITLE: 'GoWeasy Partners',
  },
  EXPLORE: {
    NEWS: 'News',
    ORLANDO: 'Explore Orlando',
    HANG_OUT: 'Night',
    NIGHT: 'Tours',
    THE_BEST: 'The Best',
    BEST_ATTRACTIONS: 'attractions of each park',
    ORLANDO_ALT: 'People looking at Orlando at night in front of the water',
    NIGHT_LIFE_ALT: 'Luxurious boat anchored at night in Orlando',
    BEST_ATTRACTIONS_ALT: 'Two friends on an amusement park ride',
  },
  FILTERS: {
    TOWNHOMES: 'Townhomes',
    SINGLE_FAMILY: 'Single Family',
    APARTMENT: 'Apartment',
    CONDOMINIUM: 'Condo',
    VILLA: 'Villa',
    LUXURY_HOUSES: 'Luxury Houses',
    MANOR: 'Mansion',
    PET_FRIENDLY: 'Pet Friendly',
    FOR_SENIORS: 'For Seniors',
    INTEGRATED_OFFICE: 'Integrated Office',
    GOLF_COURSE: 'Golf Course',
    CINEMA: 'Cinema',
    THEMATIC: 'Thematic',
    FIRST_TIME_ORLANDO: 'First time',
    LARGE_GROUPS: 'Large Groups',
    HOVER_TEXT: {
      TOWNHOMES: 'Houses semi-detached  with multiple floors  located in the condominiums ',
      SINGLE_FAMILY: 'Private Houses with exclusive space.',
      APARTMENT: 'Apartment',
      CONDOMINIUM: 'Private residences with shared services and amenities.',
      VILLA: 'Residences with an elegant design located within resort environments.',
      LUXURY_HOUSES: 'Exclusive properties with premium finishes and decoration in sophisticated areas.',
      MANOR: 'Grand houses with spacious layouts and luxurious details.',
      PET_FRIENDLY: 'Homes that welcome your pet, because they also deserve a vacation.',
      FOR_SENIORS: 'Ideal homes to maximize the comfort of elderly people.',
      INTEGRATED_OFFICE: 'To combine work and vacation we have houses with offices to accommodate your needs.',
      LARGE_GROUPS: 'To accomodate groups of families, friends, schools or even groups traveling for work.',
      GOLF_COURSE: 'Golf Course',
      CINEMA: 'Entertainment nights in a private cinema.',
      NEAR_DISNEY: 'Near Disney',
      NEAR_UNIVERSAL: 'Near Universal',
      THEMATIC: 'Houses decorated with themed spaces creating a unique experience.',
      AMAZING_POOLS: 'Pools with Hydromassage',
      GO_WEASY_CHOICE: 'Easy Choice',
      FIRST_TIME_ORLANDO: 'GoWeasy homes are recommended for your first trip to Orlando!',
    },
  },
  SECTIONS: {
    TOWNHOMES: 'Townhomes',
    SINGLE_FAMILY: 'Single family',
    APARTMENT: 'Apartment',
    CONDOMINIUM: 'Condo',
    VILLA: 'Villa',
    LUXURY_HOUSE: 'Luxury House',
    MANOR: 'Mansion',
    PET_FRIENDLY: 'Pet Friendly',
    FOR_SENIORS: 'For Seniors',
    INTEGRATED_OFFICE: 'Integrated Office',
    LARGE_GROUPS: 'For Large Groups',
    GOLF_COURSE: 'Golf Course',
    CINEMA: 'Cinema',
    NEAR_DISNEY: 'Near Disney',
    NEAR_UNIVERSAL: 'Near Universal',
    NEAR_INTERNATIONAL: 'Near International Drive and the Convention Center',
    THEMATIC: 'Thematic',
    AMAZING_POOLS: 'Pools with Hydromassage',
    GO_WEASY_CHOICE: 'Easy Choice',
    IDEAL_FOR_SENIORS: 'Ideal for Seniors',
    FIRST_TIME_ORLANDO: 'First Time in Orlando',
    TOWNHOMES_POOL: 'Townhomes with Pool',
    TOWNHOMES_NO_POOL: 'Townhomes without Pool',
    TOWNHOMES_3_10: 'Townhomes 3 Bedrooms Sleeps up to 10 people',
    TOWNHOMES_4_12: 'Townhomes 4 Bedrooms Sleeps up to 12 people',
    TOWNHOMES_5_14: 'Townhomes 5 Bedrooms Sleeps up to 14 people',
    LARGE_GROUPS_12_18: 'Large Groups 12 to 18',
    LARGE_GROUPS_19_23: 'Large Groups 19 to 23',
    LARGE_GROUPS_24: 'Large Groups 24+ people',
  },
};
