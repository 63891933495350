export default {
  TITLE: 'Escolha as melhores casas de férias em Orlando',
  SUB_TITLE: 'Encontre a região que melhor atende as necessidades de seus clientes',
  GO_WEASY_CHOICES: 'Escolhas GoWeasy',
  SEARCH: {
    LABEL: {
      DESTINATION: 'Onde',
      BUTTON: 'Buscar',
    },
    PLACEHOLDER: {
      CHECK_IN: 'Data de chegada',
      CHECK_OUT: 'Data de saída',
      EMAIL: 'Digite seu endereço de e-mail',
    },
  },
  PARTNERS: {
    TITLE: 'Parceiros GoWeasy',
  },
  EXPLORE: {
    NEWS: 'Novidades',
    ORLANDO: 'Explore Orlando',
    HANG_OUT: 'Passeios',
    NIGHT: 'Noturnos',
    THE_BEST: 'As melhores',
    BEST_ATTRACTIONS: 'atrações de cada parque',
    ORLANDO_ALT: 'Pessoas olhando Orlando a noite em frente a água',
    NIGHT_LIFE_ALT: 'Barco luxuoso ancorado a noite em Orlando',
    BEST_ATTRACTIONS_ALT: 'Duas amigas em um brinquedo de parque de diversões',
  },
  SHOWOFF_EXPERIENCES: {
    CARS: 'Carros esportivos',
    CHEF: 'Chefe em casa',
    GOLF: 'Pacotes de golfe',
    PARKS: 'Parques Temáticos',
    BABY: 'Babysitter',
    ENTERTEINMENT: 'Entertainment',
    OUTDOOR_FUN: 'Outdoor Fun',
    ACCOMODATION: 'Hospedagem',
    THEME_PARK: 'Experiências Temáticas',
    HOLLYWOOD: 'Estúdios de Hollywood',
    SHOPPING: 'Compras',
    FISHING: 'Pesca',
    WATER_PARKS: 'Parques Aquáticos',
    HOUSES: 'Casas Temáticas',
    BUTTON: 'EM BREVE',
  },
  FILTERS: {
    TOWNHOMES: 'Townhomes',
    SINGLE_FAMILY: 'Single family',
    APARTMENT: 'Apartamento',
    CONDOMINIUM: 'Condo',
    VILLA: 'Villa',
    LUXURY_HOUSES: 'Casas de Luxo',
    MANOR: 'Mansão',
    PET_FRIENDLY: 'Pet Friendly',
    FOR_SENIORS: 'Para Idosos',
    INTEGRATED_OFFICE: 'Escritório Integrado',
    GOLF_COURSE: 'Campo de Golfe',
    CINEMA: 'Cinema',
    THEMATIC: 'Temáticas',
    FIRST_TIME_ORLANDO: 'Primeira vez',
    LARGE_GROUPS: 'Grupos Grandes',
    HOVER_TEXT: {
      TOWNHOMES: 'Casas com múltiplos andares, normalmente geminadas localizadas dentro de condomínios.',
      SINGLE_FAMILY: 'Casas independentes com privacidade e espaço exclusivo.',
      APARTMENT: 'Apartamento',
      CONDOMINIUM: 'Residências privadas com serviços e comodidades compartilhadas.',
      VILLA: 'Residências com design elegante em ambientes resort.',
      LUXURY_HOUSES: 'Propriedades exclusivas com acabamentos e decoração premium e áreas mais sofisticadas',
      MANOR: 'Residências grandiosas com amplos espaços e luxuosos detalhes.',
      PET_FRIENDLY: 'Casas para seu pet, afinal ele também merece curtir férias ',
      FOR_SENIORS: 'Casas adaptadas para o conforto dos idosos',
      LARGE_GROUPS: 'Para acomodar  grupos de familias, amigos, escolas  ou mesmo grupos de trabalho e empresas ',
      INTEGRATED_OFFICE:
        'Para conjugar trabalho e férias nós temos casas com escritórios que vão atender sua necessidade.',
      GOLF_COURSE: 'Campo de Golfe',
      CINEMA: 'Estas casas oferecem noites de entretenimento com salas privativas de cinema.',
      THEMATIC:
        'Casas decoradas com espaços temáticos que transportam sua família para experiências únicas e memoráveis.',
      FIRST_TIME_ORLANDO: 'Indicação de casas GoWeasy para sua primeira viagem em Orlando!',
      NEAR_DISNEY: 'Casas próximas a Disney',
      NEAR_UNIVERSAL: 'Casas próximas a Universal',
    },
  },
  SECTIONS: {
    TOWNHOMES: 'Townhomes',
    SINGLE_FAMILY: 'Single family',
    APARTMENT: 'Apartamento',
    CONDOMINIUM: 'Condo',
    VILLA: 'Vila',
    LUXURY_HOUSE: 'Casa de Luxo',
    MANOR: 'Mansão',
    PET_FRIENDLY: 'Aceita Animais',
    FOR_SENIORS: 'Para Idosos',
    INTEGRATED_OFFICE: 'Escritório Integrado',
    LARGE_GROUPS: 'Para Grupos Grandes',
    GOLF_COURSE: 'Campo de Golfe',
    CINEMA: 'Cinema',
    NEAR_DISNEY: 'Próximo à Disney',
    NEAR_UNIVERSAL: 'Próximo à Universal',
    NEAR_INTERNATIONAL: 'Próximo à International Drive e ao Centro de Convenções',
    THEMATIC: 'Temático',
    AMAZING_POOLS: 'Piscinas com Hidromassagem',
    GO_WEASY_CHOICE: 'Escolha Fácil',
    IDEAL_FOR_SENIORS: 'Ideal para Idosos',
    FIRST_TIME_ORLANDO: 'Primeira vez em Orlando',
    TOWNHOMES_POOL: 'Townhomes com Piscina',
    TOWNHOMES_NO_POOL: 'Townhomes sem Piscina',
    TOWNHOMES_3_10: 'Townhomes 3 quartos acomoda até 10 pessoas',
    TOWNHOMES_4_12: 'Townhomes 4 quartos acomoda até 12 pessoas',
    TOWNHOMES_5_14: 'Townhomes 5 quartos acomoda até 14 pessoas',
    LARGE_GROUPS_12_18: 'Grandes Grupos de 12 até 18 pessoas',
    LARGE_GROUPS_19_23: 'Grandes Grupos de 19 até 23 pessoas',
    LARGE_GROUPS_24: 'Grandes Grupos 24+ pessoas',
  },
};
