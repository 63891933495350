import { FormControl, FormLabel, Grid, TextField } from '@mui/material';
import FeatureRow from 'components/BackOffice/FeatureRow';
import { Buttons } from 'components/molecules/buttons';
import useAsync from 'hooks/useAsync.hook';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import { CONDOMINIUM_LIST_ADM } from 'routes/routes/backOffice.routes';
import { useNavigate } from 'react-router-dom';
import newCondominium from 'services/CondominiumListAdm/newCondominium';

const IncludeNewCondominium = () => {
  // TODO: Criar o componente do formulario
  const { t } = useTranslation();
  const navigate = useNavigate();
  // TODO: usar os hooks de form
  const initialFormState = {
    name: '',
    latitude: '',
    longitude: '',
    urlImage: '',
    description: '',
    subtitle: '',
    descriptionPort: '',
    descriptionSpanish: '',
    subtitlePort: '',
    subtitleSpanish: '',
  };
  const [formData, setFormData] = useState(initialFormState);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    includeCondominium(formData);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBack = () => {
    navigate(CONDOMINIUM_LIST_ADM);
  };

  const { call: includeCondominium } = useAsync(async (data: any) => {
    try {
      await newCondominium(data);
      toast.success('Incluido com sucesso!');
      setFormData(initialFormState);
    } catch {
      toast.warn('Algo deu errado ao salvar.');
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FeatureRow title={t('ADMINISTRATIVE.NEW_CONDOMINIUM')} />
      <Grid container spacing={4}>
        <Grid container item xs={12}>
          <form onSubmit={handleSubmit} id="save-form">
            <FormControl>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.NAME_INPUT')} *</FormLabel>
                  <TextField
                    name="name"
                    variant="outlined"
                    margin="normal"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.LATITUDE')} *</FormLabel>
                  <TextField
                    name="latitude"
                    variant="outlined"
                    margin="normal"
                    value={formData.latitude}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.LONGITUDE')} *</FormLabel>
                  <TextField
                    name="longitude"
                    variant="outlined"
                    margin="normal"
                    value={formData.longitude}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      border: '2px dashed #252525',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '20px',
                    }}
                  >
                    <div>
                      <h5>{t('ADMINISTRATIVE.ADD_VIDEO_OR_PHOTO')} *</h5>
                      <label style={{ fontSize: '12px', color: '#5E5873' }}>{t('ADMINISTRATIVE.VIDEO_LIMIT')}</label>
                    </div>
                    <Buttons.NeutralWhite variant="contained" className={styles.buttonUpload}>
                      {t('ADMINISTRATIVE.SELECT_FILE')}
                    </Buttons.NeutralWhite>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.TITLE_IN_ENGLISH')} *</FormLabel>
                  <TextField
                    name="subtitle"
                    variant="outlined"
                    margin="normal"
                    value={formData.subtitle}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.TITLE_IN_SPANISH')} *</FormLabel>
                  <TextField
                    name="subtitleSpanish"
                    variant="outlined"
                    margin="normal"
                    value={formData.subtitleSpanish}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.TITLE_IN_PORTUGUESE')} *</FormLabel>
                  <TextField
                    name="subtitlePort"
                    variant="outlined"
                    margin="normal"
                    value={formData.subtitlePort}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_ENGLISH')} *</FormLabel>
                  <TextField
                    multiline
                    rows={8}
                    name="description"
                    variant="outlined"
                    margin="normal"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_SPANISH')} *</FormLabel>
                  <TextField
                    multiline
                    rows={8}
                    name="descriptionSpanish"
                    variant="outlined"
                    margin="normal"
                    value={formData.descriptionSpanish}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormLabel>{t('ADMINISTRATIVE.DESCRIPTION_IN_PORTUGUESE')} *</FormLabel>
                  <TextField
                    multiline
                    rows={8}
                    name="descriptionPort"
                    variant="outlined"
                    margin="normal"
                    value={formData.descriptionPort}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                    marginTop: '80px',
                    marginBottom: '40px',
                  }}
                >
                  <Buttons.Primary type="submit" variant="contained" className={styles.buttonWhite}>
                    {t('ADMINISTRATIVE.PUBLISH')}
                  </Buttons.Primary>
                  <Buttons.NeutralWhite onClick={handleBack} variant="contained" className={styles.buttonWhite}>
                    {t('ADMINISTRATIVE.SAVE')}
                  </Buttons.NeutralWhite>
                </div>
              </Grid>
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default IncludeNewCondominium;
