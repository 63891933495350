import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom';

import Header from 'components/Header';
import { BookingProvider } from 'store/context/booking.context';
import useLogin from 'hooks/useLogin';
import { isLogged, isTokenValid } from 'services/Login/user.service';

const AuthenticatedLayout = () => {
  const { logout } = useLogin();

  const urlPath = window.location.pathname;
  const hasHouseDetails = urlPath.includes('/house-details/');

  const isAuthenticated = (isLogged() && isTokenValid()) || hasHouseDetails;

  const handleLogout = () => {
    const pathToRedirectInLogin = `${window.location.pathname}${window.location.search}`;
    logout(pathToRedirectInLogin);
    return <Navigate to="/sign/login" replace />;
  };

  return (
    <>
      {!isAuthenticated ? (
        handleLogout()
      ) : (
        <>
          <ScrollRestoration />
          <Header />
          <BookingProvider>
            <Outlet />
          </BookingProvider>
        </>
      )}
    </>
  );
};

export default AuthenticatedLayout;
