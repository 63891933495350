export default {
  PUBLIC: {
    LOGIN: {
      PASSWORD: 'Password',
      BUTTON: 'Login',
      WELCOME: 'Welcome!',
      FORGOT_PASSWORD: 'Forgot password?',
      REMEMBER_ME: 'Remember me',
      PLACEHOLDER: {
        EMAIL: 'Type your e-mail',
        PASSWORD: 'Type your password',
      },
      ERROR_MESSAGE: 'Invalid e-mail or password',
      GO_TO_REGISTER: 'Not registered yet? Click here!',
      UNAUTHORIZED: 'Session expired, you will be redirected to login',
    },
    RECOVER_PASSWORD: {
      TITLE: 'Recover your password',
      SUBTITLE: 'Type your new password below',
      PASSWORD: 'Password',
      SEND_PASSWORD: 'Recover password',
      SUCCESS: 'Password changed successfully!',
    },
    FORGOT_PASSWORD: {
      QUESTION: 'Forgot your password?',
      ANSWER: 'Fill your e-mail and we will send you instructions about your password',
      SEND_PASSWORD: 'Send password',
      BACK_TO_LOGIN: '< Back to login screen',
      SUCCESS_TITLE: 'Email sent',
      SUCCESS_TEXT:
        'We sent an email to {{email}}. If this email is associated with a GoWeasy account, you can reset your password.',
      PLACEHOLDER: {
        EMAIL: 'Type your e-mail',
      },
    },
    REGISTER: {
      STEPS: {
        AGENCY: 'Agency Information',
        AGENT: 'Agent Information',
        SENT: 'Submission of Registration',
        MANAGER_DATA: 'AG Manager Data',
        AGENCY_DATA: 'Agency Data',
        OPERATOR_DATA: 'Operator Data',
      },
      TITLE: 'Join our network and get to know Orlando!',
      SUBTITLE: 'Register now',
      NAME: 'Name and Surname',
      CELLPHONE: `Contact's phone number`,
      NATIONAL_REGISTRATION: 'National Registration',
      PASSWORD: 'Password',
      SUBMIT: 'Send register',
      SUCCESS_MESSAGE: 'Your account has been created successfully!',
      AGENCIE: 'Agency',
      PLACEHOLDER: {
        AGENCIE: 'Select the agency',
        NAME: 'Type your name and surname',
        EMAIL: 'Type your e-mail',
        CELLPHONE: 'Type your cellphone number',
        NATIONAL_REGISTRATION: 'Type your National Registration',
        PASSWORD: 'Type your password',
      },
      FEEDBACK: {
        SUCCESS: {
          TITLE: 'Thank you for signing up with GoWeasy!',
          BUTTON: 'Got it',
        },
      },
    },
  },
};
