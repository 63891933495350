const APP_COLORS = {
  colorTones: {
    clearPurple: '#6300B9',
  },
  greyTones: {
    100: '#0049A1',
    90: '#0D0D0D',
    80: '#1C1C1C',
    70: '#6F6F6F',
    60: '#A8A8A8',
    50: '#CCCCCC',
    40: '#00000029',
    30: '#00000019',
    20: '#0000001A',
    10: '#D1D1D1',
  },
  whitened: {
    100: '#FFFFFF',
    90: '#F2F4FA',
    80: '#F5F6FA',
    70: '#F7F5F4',
    60: '#FCFCFC',
    50: '#F8F9FC',
    40: '#FAFBFF',
    30: '#F2F2F2',
  },
  feedback: {
    info: {
      default: '#2278D2',
      darkest: '#103C68',
      action: '#2194FF',
      active: '#A7D4FF',
      lightest: '#EAF5FF',
    },
    success: {
      default: '#1D7F51',
      darkest: '#0E4025',
      action: '#1CA061',
      active: '#A4D7BE',
      lightest: '#E3F0EC',
    },
    warning: {
      default: '#AC7A00',
      darkest: '#805706',
      action: '#FFAD0D',
      active: '#FFC859',
      lightest: '#FFECC5',
    },
    danger: {
      default: '#CC0000',
      darkest: '#800000',
      action: '#FF0000',
      active: '#FF4D4D',
      lightest: '#FBDADA',
    },
  },
};

export default APP_COLORS;
