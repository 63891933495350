import React from 'react';

import { CarImage, GolfImage, Chef, Baby, Parks, Enterteiment, OutsideFun } from 'assets';

import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';

import styles from './styles.module.scss';
import ShowoffExperiencesItem from './ShowoffExperiencesItem';

interface ObjectData {
  id: number;
  image: string;
  name: string;
}

interface ShowoffExperiencesProps {
  title: string;
  description: string;
}

const ShowoffExperiences: React.FC<ShowoffExperiencesProps> = ({ title, description }) => {
  const { t } = useTranslation();

  const dataExperiences = [
    {
      id: 1,
      image: CarImage,
      name: t('SHOWOFF_EXPERIENCES.CARS'),
    },
    {
      id: 2,
      image: Chef,
      name: t('SHOWOFF_EXPERIENCES.CHEF'),
    },
    {
      id: 3,
      image: GolfImage,
      name: t('SHOWOFF_EXPERIENCES.GOLF'),
    },
    {
      id: 4,
      image: Parks,
      name: t('SHOWOFF_EXPERIENCES.PARKS'),
    },
    {
      id: 5,
      image: Baby,
      name: t('SHOWOFF_EXPERIENCES.BABY'),
    },
    {
      id: 6,
      image: Enterteiment,
      name: t('SHOWOFF_EXPERIENCES.ENTERTEINMENT'),
    },
    {
      id: 7,
      image: OutsideFun,
      name: t('SHOWOFF_EXPERIENCES.OUTDOOR_FUN'),
    },
  ];

  return (
    <div className={styles.main}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{description}</p>
      <div className={styles.experiences}>
        <Carousel
          responsive={{
            desktop: {
              breakpoint: {
                max: 3001,
                min: 1023,
              },
              items: 6,
              partialVisibilityGutter: 0,
            },
            mobile: {
              breakpoint: {
                max: 768,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 0,
            },
          }}
          arrows
          sliderClass={styles.list}
          containerClass={styles.container}
          focusOnSelect={false}
          showDots={false}
          slidesToSlide={1}
          autoPlaySpeed={3000}
          autoPlay={true}
          pauseOnHover
          rewindWithAnimation
          rewind
          partialVisible={false}
        >
          {dataExperiences.map((item: ObjectData) => (
            <ShowoffExperiencesItem item={item} key={item.id} />
          ))}
        </Carousel>
      </div>
      <div className={styles.soon}>{t('SHOWOFF_EXPERIENCES.BUTTON')}</div>
    </div>
  );
};

export default ShowoffExperiences;
