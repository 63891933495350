import styles from '../../styles.module.scss';
import { useForm } from 'react-hook-form';
import Inputs from 'components/molecules/inputs';
import React from 'react';
import AmericanAgent from 'models/application/Agent/AmericanAgent.model';
import ConfirmButton from 'pages/Register/components/ConfirmButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAgent } from './validateAgent';

type AgentFormENProps = {
  onSubmit: (data: AmericanAgent) => void;
  agent?: AmericanAgent;
  isOperator?: boolean;
};

const AgentFormEN: React.FC<AgentFormENProps> = ({ onSubmit, agent, isOperator }) => {
  const form = useForm<AmericanAgent>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: agent,
    resolver: yupResolver(validateAgent),
  });

  const beforeSubmit = (data: AmericanAgent) => {
    onSubmit(new AmericanAgent(data));
  };

  return (
    <div className={styles.containerForm}>
      {!isOperator && <div className={styles.subTitle}>Register Your Agency Now!</div>}
      {isOperator && (
        <div className={styles.subTitle}>
          <span style={{ fontWeight: 700 }}>Register Your Operator!</span>
          <br />
          <br />
          <label style={{ fontSize: '14px' }}>To register your operator, it is necessary to link an agency.</label>
        </div>
      )}
      <div className={styles.form}>
        <div className={styles.grid}>
          <Inputs.Text form={form} required label="First Name:" name="firstName" />
          <Inputs.Text form={form} required label="Last Name:" name="lastName" />
          <Inputs.Text form={form} required label="Email:" name="email" />
          <Inputs.Mask
            whiteVersion={false}
            form={form}
            mask="(999) 999-9999"
            required
            label="Contact phone:"
            name="phone"
            id="phone"
          />
          <div className={styles.gridTwoColumns}>
            <Inputs.Text type="password" form={form} label="Password:" name="password" />
            <Inputs.Text type="password" form={form} label="Confirm Password:" name="confirmPassword" />
          </div>
        </div>
        <ConfirmButton form={form} onSubmit={beforeSubmit}>
          Continue
        </ConfirmButton>
      </div>
    </div>
  );
};

export default AgentFormEN;
