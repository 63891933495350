import Checkout from 'models/application/Checkout.model';
import StripeConfig from 'models/application/StripeConfig.model';
import { useContext, useState } from 'react';
import { getCheckoutValues } from 'services/HouseCheckout/getCheckoutValues.service';
import UserContext from 'store/context/user.context';
import useAsync from './useAsync.hook';
import { IReservation } from 'models/contracts/request/reservation.request';
import { submitHouseCheckout } from 'services/HouseCheckout/submitHouseCheckout.service';
import { updateStatusReservation } from 'services/HouseCheckout/updateStatusReservation.service';

const useCheckout = () => {
  const [stripeConfig, setStripeConfig] = useState<StripeConfig>();
  const [checkOut, setCheckout] = useState<Checkout>();
  const [paymentTypeEnum, setPaymentTypeEnum] = useState<string>();
  const [user] = useContext(UserContext);
  const [error, setError] = useState<boolean>(false);

  const { loading: loadingCheckoutConfig, call: getCheckoutData } = useAsync(
    async (houseId: number, checkinDate: string, checkoutDate: string, guests: number) => {
      try {
        const data = await getCheckoutValues(houseId, checkinDate, checkoutDate, user.id, guests);
        setStripeConfig(data.stripeConfig);
        setCheckout(data.checkOut);
        setPaymentTypeEnum(data.paymentTypeEnum);
        setError(false);
      } catch (errorMessage: any) {
        setError(true);
      }
    },
  );

  const { loading: updatingStatusCanceled, call: cancelReservation } = useAsync(async (reservationId: string) => {
    await updateStatusReservation(reservationId, 'CANCELED');
  });

  const { loading: updatingStatusSuccess, call: reservationSuccess } = useAsync(async (reservationId: string) => {
    await updateStatusReservation(reservationId, 'APPROVED');
  });

  const { loading: sendingReserve, call: registerReserve } = useAsync(async (reservation: IReservation) => {
    const result = await submitHouseCheckout(reservation);
    return result;
  });

  return {
    isUsingStripe: paymentTypeEnum === 'STRIPE',
    stripeConfig,
    houseCheckout: checkOut,
    loadingCheckout: loadingCheckoutConfig,
    sendingReserve,
    registerReserve,
    getCheckoutData,
    configError: error,
    updatingStatus: updatingStatusCanceled || updatingStatusSuccess,
    cancelReservation,
    reservationSuccess,
  };
};

export default useCheckout;
