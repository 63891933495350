import styles from './styles.module.scss';
import styled from 'styled-components';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Zoom } from '@mui/material';
import React from 'react';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 120,
    backgroundColor: 'white',
    color: 'black',
    fontSize: 10,
  },
});

type FilterItemProps = {
  srcIcon?: string;
  title: string;
  enumValue: string;
  alt: string;
  hoverText?: string;
  onSelected: (enumValue: string) => void;
  isSelected?: boolean;
  icon?: React.ReactNode;
};

const FilterItem: React.FC<FilterItemProps> = ({
  srcIcon,
  title,
  enumValue,
  alt,
  hoverText,
  isSelected,
  onSelected,
  icon,
}) => {
  const handleClick = () => {
    onSelected(enumValue);
  };

  const classes = `${styles.main} ${isSelected ? styles.selected : ''}`;

  return (
    <CustomWidthTooltip
      TransitionComponent={Zoom}
      title={
        <div className={styles.container}>
          <div className={styles.circle}></div>
          <div>{hoverText}</div>
        </div>
      }
      className={styles.tooltip}
    >
      <div className={classes} onClick={handleClick}>
        {srcIcon && <img className={styles.icon} src={srcIcon} alt={alt} width="42px" height="42px" />}
        {icon && icon}
        <div className={styles.title}>{title}</div>
      </div>
    </CustomWidthTooltip>
  );
};

export default FilterItem;
