import { TableCell, TableRow } from '@mui/material';
import BasicTable from 'components/BasicTable';
import styles from './styles.module.scss';
import i18n from 'languages';
import React, { useEffect, useState } from 'react';
import reservationRecords from 'services/Management/getReservationRecords.service';
import useAsync from 'hooks/useAsync.hook';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Language = 'pt-BR' | 'en' | 'es' | 'pt-PT';

const Status: React.FC<any> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div>
      {status === 'PENDENTE' && (
        <label className={styles.statusCreated}>{t('BACKOFFICE.RESERVATION_DETAIL.PENDING')}</label>
      )}
      {status === 'CONFIRMADA' && (
        <label className={styles.statuSucceeded}>{t('BACKOFFICE.RESERVATION_DETAIL.CONFIRMED')}</label>
      )}
      {status === 'CANCELADA' && (
        <label className={styles.statusFailed}>{t('BACKOFFICE.RESERVATION_DETAIL.CANCELED')}</label>
      )}
    </div>
  );
};

const ReservationRecordsTable: React.FC<any> = ({ paymentId }) => {
  const { t } = useTranslation();
  const initialLanguage = i18n.language as Language;
  const [reservationsTableValues, setReservationTableValues] = useState<any>([]);
  const [reservationRecordsValues, setReservationRecordsValues] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(initialLanguage);
  const collumnsNames = [
    t('BACKOFFICE.RESERVATION_DETAIL.TIME'),
    t('BACKOFFICE.RESERVATION_DETAIL.RESERVATION'),
    t('BACKOFFICE.RESERVATION_DETAIL.CLIENT_NAME'),
    t('BACKOFFICE.RESERVATION_DETAIL.PROPERTY'),
    t('BACKOFFICE.RESERVATION_DETAIL.AGENCY'),
    t('BACKOFFICE.RESERVATION_DETAIL.CATEGORY'),
    'Status',
    t('BACKOFFICE.RESERVATION_DETAIL.RESPONSIBLE'),
    t('BACKOFFICE.RESERVATION_DETAIL.DESCRIPTION'),
  ];

  const convertStatus: any = (status: any) => {
    switch (status) {
      case 'payment_intent.created':
        return 'PENDENTE';
      case 'payment_intent.succeeded':
        return 'CONFIRMADA';
      case 'payment_intent.payment_failed':
        return 'CANCELADA';
      default:
        return '';
    }
  };

  const { call: getReservationRecords } = useAsync(async () => {
    const response = await reservationRecords(paymentId);
    setReservationRecordsValues(response);
  }, []);

  const formatDate = (dateString: string, language: Language): string => {
    if (language === 'pt-BR' || language === 'pt-PT') {
      moment.locale('pt-br');
      return moment(dateString).format('DD MMM YYYY HH:mm');
    } else {
      moment.locale('en');
      return moment(dateString).format('MMM DD, YYYY HH:mm');
    }
  };

  useEffect(() => {
    getReservationRecords();

    i18n.on('languageChanged', (l) => {
      setCurrentLanguage(l as Language);
    });

    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  useEffect(() => {
    const rows = reservationRecordsValues?.map((value: any) => {
      return {
        dateCreated: moment(value?.dateCreated).format('DD MMM YYYY HH:mm'),
        reservation: value?.reservation,
        clientName: value?.clientName,
        houseId: String(value?.houseId),
        agency: value?.agency,
        category: value?.category,
        status: convertStatus(String(value?.status)),
        responsability: String(value?.responsability),
        description: value?.description,
      };
    });
    setReservationTableValues(rows);
  }, [reservationRecordsValues, currentLanguage]);

  return (
    <BasicTable collumnsNames={collumnsNames}>
      {reservationsTableValues?.map((row: any) => (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <div className={styles.date}>{formatDate(row?.dateCreated, currentLanguage)}</div>
          </TableCell>
          <TableCell>
            <label className={styles.reservationCode}>#{row?.reservation}</label>
          </TableCell>
          <TableCell>
            <label>{row?.clientName}</label>
          </TableCell>
          <TableCell>
            <label className={styles.house}>{row?.houseId}</label>
          </TableCell>
          <TableCell>{row?.agency}</TableCell>
          <TableCell>
            <label className={styles.date}>{row?.category?.replace(/_/g, ' ')}</label>
          </TableCell>
          <TableCell>
            <Status status={row?.status} />
          </TableCell>
          <TableCell>{row?.responsability}</TableCell>
          <TableCell className={styles.description}>{row?.description}</TableCell>
        </TableRow>
      ))}
    </BasicTable>
  );
};

export default ReservationRecordsTable;
