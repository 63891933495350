import { useRef } from 'react';
import styles from './styles.module.scss';

const HouseControlVideos = () => {
  const videoRefs = useRef<any>([]);

  const handlePlay = (index: number) => {
    const videoElement = videoRefs.current[index];

    if (videoElement) {
      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          /* Firefox */
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
          /* Chrome, Safari, and Opera */
          videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
          /* IE/Edge */
          videoElement.msRequestFullscreen();
        }
      }
    }
  };

  const videosToRender = [
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Bem Vindo a GoWeasy!</label> Nossa Plataforma é simples e completa e te ajudará a oferecer as melhores
          casas de férias ao seu cliente com todo suporte que precisa.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Condomínios. </label> São verdadeiros resorts com toda a infraestrutura de segurança, lazer e
          comodidades.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%201.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Escolha a região </label> e <label> escolha por condominio</label>, que melhor atende seu cliente. Na
          plataforma observe que clicando na cidade, o botão BUSCAR muda da cor laranja para azul. Abrira um descritivo
          da cidade e logo abaixo, os condomínios.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%202.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          Como <label>acessar o condomínio.</label>
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%204.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          Como <label> entrar na casa.</label>
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%205.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Townhouses</label> Com o melhor custo-benefício.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%203.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Single Families</label> Com todo o espaço e privacidade.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%2013.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Casas com Piscina</label> e serviço extra de aquecimento.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%2012.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>O conforto</label> e a experiência de se hospedar em uma casa americana.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%207.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          <label>Lavanderia.</label> Faltou roupas? Molhou o tênis das crianças? Não tem problema, temos Lavanderia.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%206.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          Dicas úteis para o <label> uso do Fogão.</label>
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%208.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          Por que as casas americanas têm portas e janelas fechadas? Dicas sobre o <label>ar condicionado.</label>
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%209.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          Dicas sobre como <label>tratar o lixo</label> em uma casa americana.
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%2010.mp4',
    },
    {
      description: (
        <p className={styles.videoDesc}>
          Como funciona <label> o Delivery em casa?</label>
        </p>
      ),
      videoUrl: 'https://www.goweasy.com/media/video%2011.mp4',
    },
  ];

  return (
    <>
      {videosToRender.map((video: { description: any; videoUrl: string }, index) => {
        return (
          <div className={styles.mediaContainer} key={index}>
            <div className={styles.videoColumn}>
              <video controls ref={(el) => (videoRefs.current[index] = el)} onPlay={() => handlePlay(index)}>
                <source src={video?.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className={styles.descriptionColumn}>{video.description}</div>
          </div>
        );
      })}
    </>
  );
};

export default HouseControlVideos;
