import Box from '@mui/material/Box';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components/molecules/buttons';
import { Dialog, Divider, MenuItem, TextField } from '@mui/material';
import Button from 'components/BackOffice/Button';

type ConfirmAccessProps = {
  data: any;
  onConfirm: () => void;
  onClose: () => void;
  onSelect: any;
  onChangeValue: any;
  selectedValue: any;
  goalValue: any;
  isOpen: boolean;
  loading?: boolean;
};

const GoalSettingModal: React.FC<ConfirmAccessProps> = ({
  onConfirm,
  onClose,
  onSelect,
  onChangeValue,
  selectedValue,
  goalValue,
  isOpen,
  data,
  loading,
}) => {
  const { t } = useTranslation();
  const agentCategories = [
    {
      value: 'JANEIRO',
      label: t('ADMINISTRATIVE.MONTHS.JANUARY'),
    },
    {
      value: 'FEVEREIRO',
      label: t('ADMINISTRATIVE.MONTHS.FEBRUARY'),
    },
    {
      value: 'MARÇO',
      label: t('ADMINISTRATIVE.MONTHS.MARCH'),
    },
    {
      value: 'ABRIL',
      label: t('ADMINISTRATIVE.MONTHS.APRIL'),
    },
    {
      value: 'MAIO',
      label: t('ADMINISTRATIVE.MONTHS.MAY'),
    },
    {
      value: 'JUNHO',
      label: t('ADMINISTRATIVE.MONTHS.JUNE'),
    },
    {
      value: 'JULHO',
      label: t('ADMINISTRATIVE.MONTHS.JULY'),
    },
    {
      value: 'AGOSTO',
      label: t('ADMINISTRATIVE.MONTHS.AUGUST'),
    },
    {
      value: 'SETEMBRO',
      label: t('ADMINISTRATIVE.MONTHS.SEPTEMBER'),
    },
    {
      value: 'OUTUBRO',
      label: t('ADMINISTRATIVE.MONTHS.OCTOBER'),
    },
    {
      value: 'NOVEMBRO',
      label: t('ADMINISTRATIVE.MONTHS.NOVEMBER'),
    },
    {
      value: 'DEZEMBRO',
      label: t('ADMINISTRATIVE.MONTHS.DECEMBER'),
    },
  ];

  const monthsAcronyms = [
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.JANUARY'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.FEBRUARY'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.MARCH'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.APRIL'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.MAY'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.JUNE'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.JULY'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.AUGUST'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.SEPTEMBER'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.OCTOBER'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.NOVEMBER'),
    t('ADMINISTRATIVE.MONTHS_ACRONYMS.DECEMBER'),
  ];

  const handleChange = (event: any) => {
    onSelect(event?.target?.value);
  };

  const handlChangeValue = (event: any) => {
    onChangeValue(event?.target?.value);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'sm'} fullWidth>
      <Box className={styles.content}>
        <div className={styles.title}>{t('ADMINISTRATIVE.REPORTS.GOAL_REGISTRATION')}</div>
        <div className={styles.data}>
          {monthsAcronyms.map((v, i) => {
            return (
              <div style={{ width: '100%' }}>
                <div className={styles.row}>
                  <label style={{ color: '#046408', fontWeight: '600' }}>{v}</label>
                  <div className={styles.info}>${data?.[i]?.metaValor}</div>
                </div>
                <Divider color={'#D8D6DE'} style={{ marginTop: '8px' }} />
              </div>
            );
          })}
        </div>
        <div className={styles.buttonRow}>
          <TextField
            select
            name="agentCategorie"
            label="Mês"
            id="simple-select"
            value={selectedValue}
            onChange={handleChange}
            fullWidth
          >
            {agentCategories?.map((c) => (
              <MenuItem value={c?.value}>{c?.label}</MenuItem>
            ))}
          </TextField>
          <TextField
            value={goalValue}
            onChange={handlChangeValue}
            placeholder="Valor da meta $"
            type="number"
            fullWidth
          ></TextField>
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClose}>{t('AGENT.CONFIRM.CANCEL')}</Button>
          <Buttons.Primary
            className={styles.orangeButton}
            fullWidth
            variant="contained"
            onClick={onConfirm}
            loading={loading}
          >
            {t('ADMINISTRATIVE.REPORTS.REGISTER')}
          </Buttons.Primary>
        </div>
      </Box>
    </Dialog>
  );
};

export default GoalSettingModal;
