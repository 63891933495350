import styles from '../styles.module.scss';
import { useState } from 'react';
import AgencyFormES from './AgencyForm';
import AgentFormES from './AgentForm';
import AmericanAgency from 'models/application/Agency/AmericanAgency.model';
import SuccessFeedback from 'pages/Register/components/SuccessFeedback';
import MoonLoader from 'react-spinners/MoonLoader';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';
import useAgency from 'hooks/useAgency.hook';
import AmericanAgent from 'models/application/Agent/AmericanAgent.model';
import OperatorFormEN from './OperatorForm';
import Operator from 'models/application/Operator/Operator.model';

type RegisterFormESProps = {
  currentStep: number;
  onNextStep: () => void;
  isOperator?: boolean;
};

const RegisterFormES: React.FC<RegisterFormESProps> = ({ currentStep, onNextStep, isOperator }) => {
  const { createAgencyAndAgentEN, loadingSubmitAgencyWithAgent } = useAgency();
  const [operator, setOperador] = useState<Operator>();
  const [agent, setAgent] = useState<AmericanAgent>();
  const [agency, setAgency] = useState<AmericanAgency>();

  const handleSubmitOperatorForm = async (operatorValues: Operator) => {
    setOperador(operatorValues);
    try {
      await createAgencyAndAgentEN(agency, agent, operatorValues);
      onNextStep();
    } catch (error) {
      console.warn(error);
    }
  };

  const handleSubmitAgentForm = (values: AmericanAgent) => {
    setAgent(values);
    onNextStep();
  };

  const handleSubmitAgencyWhenOperator = (americanAgency: AmericanAgency) => {
    setAgency(americanAgency);
    onNextStep();
  };

  const handleSubmitAgencyForm = async (americanAgency: AmericanAgency) => {
    setAgency(americanAgency);
    try {
      await createAgencyAndAgentEN(americanAgency, agent);
      onNextStep();
    } catch (error) {
      console.warn(error);
    }
  };

  const getCurrentStep = () => {
    if (loadingSubmitAgencyWithAgent) {
      return (
        <div className={styles.loading}>
          <MoonLoader color={PRIMARY_BLUE_COBALT} />
        </div>
      );
    }

    if (currentStep === 0) {
      return <AgentFormES onSubmit={handleSubmitAgentForm} agent={agent} />;
    }

    if (currentStep === 1) {
      return <AgencyFormES onSubmit={handleSubmitAgencyForm} agency={agency} />;
    }

    if (currentStep === 2) {
      return (
        <SuccessFeedback text="We appreciate you choosing GoWeasy. Soon, our team will be in touch to provide exclusive access and information on how you can make the most out of our platform." />
      );
    }
  };

  const getCurrentStepOperator = () => {
    if (loadingSubmitAgencyWithAgent) {
      return (
        <div className={styles.loading}>
          <MoonLoader color={PRIMARY_BLUE_COBALT} />
        </div>
      );
    }

    if (currentStep === 0) {
      return <AgentFormES onSubmit={handleSubmitAgentForm} agent={agent} isOperator={true} />;
    }

    if (currentStep === 1) {
      return <AgencyFormES onSubmit={handleSubmitAgencyWhenOperator} agency={agency} />;
    }

    if (currentStep === 2) {
      return <OperatorFormEN onSubmit={handleSubmitOperatorForm} operator={operator} />;
    }
    if (currentStep === 3) {
      return (
        <SuccessFeedback text="We appreciate you choosing GoWeasy. Soon, our team will be in touch to provide exclusive access and information on how you can make the most out of our platform." />
      );
    }
  };

  return (
    <div className={styles.container}>
      {!isOperator && getCurrentStep()}
      {isOperator && getCurrentStepOperator()}
    </div>
  );
};

export default RegisterFormES;
