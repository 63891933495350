import styled from 'styled-components';

export const MainPage = styled.div`
  display: grid;
  grid-template-columns: 10fr 4fr;
  grid-column-gap: 24px;
  padding: 24px 40px 80px 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    padding: 0px;
  }
`;

export const HouseDetailsHR = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.8;
  background-color: #dedede;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const DataContainer = styled.div`
  padding: 0px 4px;

  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;
