import ListAgencyAdm from 'models/application/ListAgency/ListAgency.model';
import { axiosInstances } from 'services/api';

const getAgencyAdministrative = async (): Promise<ListAgencyAdm[]> => {
  const { data } = await axiosInstances.base.instance.get(`/service/agencies`);

  return data.map(
    (agency: any) =>
      new ListAgencyAdm({
        active: agency.active,
        cadastur: agency.cadastur,
        cnpj: agency.cnpj,
        id: agency.id,
        name: agency.name,
        primaryContact: agency.primaryContact,
        profitPercentage: agency.profitPercentage,
      }),
  );
};

export default getAgencyAdministrative;
