import { isValidCPF, isValidFullName } from 'utils/validator';
import Yup from 'utils/yup';

export const validateAgentEdit = Yup.object({
  name: Yup.string()
    .required()
    .test('is-valid-fullname', 'Nome Inválido', (value) => isValidFullName(value)),
  nationalRegistration: Yup.string()
    .required()
    .test('is-valid-cpf', 'CPF Inválido', (value) => isValidCPF(value)),
  role: Yup.string().required(),
  email: Yup.string().email().required(),
  zipcode: Yup.number().required(),
  address: Yup.string().required(),
});
