import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import { PRIMARY_BLUE_COBALT } from 'styles/colors';

import styles from './styles.module.scss';

const LoadingSpinner: React.FC = () => {
  return (
    <div className={styles.container}>
      <MoonLoader color={PRIMARY_BLUE_COBALT} />
    </div>
  );
};

export default LoadingSpinner;
