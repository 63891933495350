import React, { useMemo, useState } from 'react';
import {
  DetailsContainer,
  Title,
  HouseSpacesContainer,
  HouseSpaceItem,
  HouseSpaceLabel,
  ShowMoreButton,
  ShowMoreContainer,
} from './Details.style';
import { useTranslation } from 'react-i18next';
import { houseSpacesIcons } from 'utils/houseSpacesIcons';
import { IHouseSpaces } from 'models/application/interfaces/IHouseSpaces.interface';
import styles from './styles.module.scss';
import { FaRegCopy } from 'react-icons/fa6';
import { showInfoMessage } from 'utils/Toast';

// TODO: Transformar amenities em houseSpaces
interface ListImagePreviewProps {
  title: string;
  amenities: IHouseSpaces[];
}

const HouseDetails: React.FC<ListImagePreviewProps> = ({ title, amenities }) => {
  const [showMoreSpaces, setShowMoreSpaces] = useState(false);

  const { t } = useTranslation();

  const houseSpaces = useMemo(() => {
    const translatedAmentities = [...amenities].map((amenitie) => {
      return {
        ...amenitie,
        label: `AMENITIES.${amenitie.label.toUpperCase()}`,
        icon: houseSpacesIcons[amenitie.label.toUpperCase()],
      };
    });

    return [...translatedAmentities];
  }, [amenities]);

  const lastIndexToBeShow = showMoreSpaces ? houseSpaces.length : 6;

  const handleShowMoreSpaces = () => {
    setShowMoreSpaces((show) => !show);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    showInfoMessage(t('DETAILS_HOUSE.COPY_LINK'));
  };

  return (
    <DetailsContainer>
      <div className={styles.title}>
        <Title>{title}</Title>
        <div onClick={handleCopyLink} className={styles.pointer}>
          <FaRegCopy />
        </div>
      </div>
      <HouseSpacesContainer>
        {houseSpaces.slice(0, lastIndexToBeShow).map((el, index) => (
          <HouseSpaceItem key={index}>
            <div>{el.icon}</div>
            <HouseSpaceLabel>{`${el?.quantity ?? ''} ${t(`${el.label}`)}`}</HouseSpaceLabel>
          </HouseSpaceItem>
        ))}
      </HouseSpacesContainer>
      {houseSpaces.length > lastIndexToBeShow || showMoreSpaces ? (
        <ShowMoreContainer>
          <ShowMoreButton onClick={handleShowMoreSpaces}>
            {showMoreSpaces
              ? t('HOUSE_DETAILS.CHECKOUT.SHOW_LESS')
              : t('HOUSE_DETAILS.CHECKOUT.SHOW_MORE', { quantity: houseSpaces.length })}
          </ShowMoreButton>
        </ShowMoreContainer>
      ) : null}
    </DetailsContainer>
  );
};

export default HouseDetails;
