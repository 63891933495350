import { jwtDecode } from 'jwt-decode';
import User from 'models/application/User.model';

export const isLogged = () => {
  return sessionStorage.getItem('token') ? true : false;
};

export const clearToken = () => {
  sessionStorage.removeItem('token');
  window.dispatchEvent(new Event('storage'));
};

export const getToken = () => {
  return sessionStorage.getItem('token');
};

export const saveToken = (token: string) => {
  sessionStorage.setItem('token', token);
  window.dispatchEvent(new Event('storage'));
};

export const saveUser = (user: User) => {
  sessionStorage.setItem('user', JSON.stringify(user));
};

export const getUser = () => {
  const user = JSON.parse(sessionStorage.getItem('user') || '{}');

  if (user.id === undefined)
    return new User(
      0,
      '',
      '',
      {
        id: 0,
        name: '',
      },
      '',
      0,
    );

  return new User(user.id, user.email, user.name, user.agency, user.role, user.operadoraId);
};

export const clearUser = () => {
  sessionStorage.removeItem('user');
};

export const isTokenValid = () => {
  const token = getToken();

  if (token) {
    const decodedToken = jwtDecode(token) as any;

    if (!decodedToken || decodedToken.exp * 1000 < Date.now()) {
      return false;
    }

    return true;
  }

  return false;
};

export const createTokenListener = (callback: () => void) => {
  window.addEventListener('storage', () => {
    callback();
  });
};

export const clearTokenListener = (callback: () => void) => {
  window.removeEventListener('storage', () => {
    callback();
  });
};
