export const sortByFilterOrder = (list: any[], filters: string[]): any[] => {
  return list.sort((a, b) => {
    const aIndex = filters.indexOf(a);
    const bIndex = filters.indexOf(b);

    // Se ambos estão em filters, compara as posições e deixa o último clicado em primeiro
    if (aIndex !== -1 && bIndex !== -1) {
      return bIndex - aIndex;
    }

    // Se apenas A está em filters, coloca A antes
    if (aIndex !== -1) {
      return -1;
    }

    // Se apenas B está em filters, coloca B antes
    if (bIndex !== -1) {
      return 1;
    }

    // Se nenhum está em filters, mantém a ordem padrão
    return 0;
  });
};
