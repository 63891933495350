import { InputAdornment, TextField } from '@mui/material';
import FeatureRow from 'components/BackOffice/FeatureRow';
import { useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from 'react-icons/io5';
import AgentListTable from 'pages/BackOffice/Administrative/ListAgency/Components/ListAgencyTable';

const ListAgency = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      <div className={styles.info}>
        <FeatureRow title={t('MENU.ADMINISTRATIVE')}>
          <div></div>
        </FeatureRow>
      </div>
      <div className={styles.content}>
        <div className={styles.actions}>
          <div className={styles.inputs}>
            <TextField
              label={t('BACKOFFICE.SEARCH')}
              variant="outlined"
              value={search}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IoSearchSharp size={24} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.table}>
        <AgentListTable filter={search} />
      </div>
    </div>
  );
};

export default ListAgency;
