import React from 'react';
import { BaseProps } from './BaseProps';

const ApartmentSVG: React.FC<BaseProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g
        id="Grupo_de_máscara_240"
        data-name="Grupo de máscara 240"
        transform="translate(-486 -660)"
        clip-path="url(#clip-path)"
      >
        <g id="apartment_10880424" transform="translate(486 660)">
          <g id="Grupo_3222" data-name="Grupo 3222">
            <path
              id="Caminho_7607"
              data-name="Caminho 7607"
              d="M38,48H30a1,1,0,0,1-1-1V37a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V47A1,1,0,0,1,38,48Zm-7-2h6V38H31Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3223" data-name="Grupo 3223">
            <path
              id="Caminho_7608"
              data-name="Caminho 7608"
              d="M18,48H10a1,1,0,0,1-1-1V37a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V47A1,1,0,0,1,18,48Zm-7-2h6V38H11Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3224" data-name="Grupo 3224">
            <path
              id="Caminho_7609"
              data-name="Caminho 7609"
              d="M32,34H28a1,1,0,0,1-1-1V29a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,32,34Zm-3-2h2V30H29Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3225" data-name="Grupo 3225">
            <path
              id="Caminho_7610"
              data-name="Caminho 7610"
              d="M40,34H36a1,1,0,0,1-1-1V29a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,40,34Zm-3-2h2V30H37Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3226" data-name="Grupo 3226">
            <path
              id="Caminho_7611"
              data-name="Caminho 7611"
              d="M12,34H8a1,1,0,0,1-1-1V29a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,12,34ZM9,32h2V30H9Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3227" data-name="Grupo 3227">
            <path
              id="Caminho_7612"
              data-name="Caminho 7612"
              d="M20,34H16a1,1,0,0,1-1-1V29a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,20,34Zm-3-2h2V30H17Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3228" data-name="Grupo 3228">
            <path
              id="Caminho_7613"
              data-name="Caminho 7613"
              d="M12,26H8a1,1,0,0,1-1-1V21a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,12,26ZM9,24h2V22H9Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3229" data-name="Grupo 3229">
            <path
              id="Caminho_7614"
              data-name="Caminho 7614"
              d="M20,26H16a1,1,0,0,1-1-1V21a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,20,26Zm-3-2h2V22H17Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3230" data-name="Grupo 3230">
            <path
              id="Caminho_7615"
              data-name="Caminho 7615"
              d="M32,26H28a1,1,0,0,1-1-1V21a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,32,26Zm-3-2h2V22H29Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3231" data-name="Grupo 3231">
            <path
              id="Caminho_7616"
              data-name="Caminho 7616"
              d="M40,26H36a1,1,0,0,1-1-1V21a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,40,26Zm-3-2h2V22H37Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3232" data-name="Grupo 3232">
            <path
              id="Caminho_7617"
              data-name="Caminho 7617"
              d="M32,18H28a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,32,18Zm-3-2h2V14H29Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3233" data-name="Grupo 3233">
            <path
              id="Caminho_7618"
              data-name="Caminho 7618"
              d="M40,18H36a1,1,0,0,1-1-1V13a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,40,18Zm-3-2h2V14H37Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3234" data-name="Grupo 3234">
            <path
              id="Caminho_7619"
              data-name="Caminho 7619"
              d="M44,48H24a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1H44a1,1,0,0,1,1,1V47A1,1,0,0,1,44,48ZM25,46H43V2H25Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3235" data-name="Grupo 3235">
            <path
              id="Caminho_7620"
              data-name="Caminho 7620"
              d="M24,48H4a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H24a1,1,0,0,1,1,1V47A1,1,0,0,1,24,48ZM5,46H23V13H5Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3236" data-name="Grupo 3236">
            <path
              id="Caminho_7621"
              data-name="Caminho 7621"
              d="M44,8H24a1,1,0,0,1,0-2H44a1,1,0,0,1,0,2Z"
              fill={color}
            />
          </g>
          <g id="Grupo_3237" data-name="Grupo 3237">
            <path
              id="Caminho_7622"
              data-name="Caminho 7622"
              d="M24,18H4a1,1,0,0,1,0-2H24a1,1,0,0,1,0,2Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ApartmentSVG;
