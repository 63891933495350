const COLORS = {
  primary: {
    bleu: '#0089F6',
    cobalt: '#0049A1',
    maya: '#5FBDFF',
  },
  secondary: {
    pumpkin: '#FF7400',
    brown: '#963D00',
    sandy: '#FCA15E',
  },
  neutral: {
    black: '#252525',
    white: '#FFFFFF',
  },
};

export default COLORS;
