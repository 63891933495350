import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
  }

  /* disabilita o input padrão do componente */
  .react-daterange-picker__wrapper {
    display: none;
  }
`;

export const Divider = styled.div`
  height: 20px;
  background-color: #cccccc;
  width: 0.5px;
  margin-right: 16px;
  margin-left: 16px;
`;

export const LabelContainer = styled.div`
  display: flex;
`;

export const Label = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
`;
