import { useNavigate } from 'react-router-dom';
import { AGENCY_URL, AGENT_URL, MANAGEMENT_URL, LIST_AGENCY } from 'routes/routes/backOffice.routes';

const useBackOfficeNavigation = () => {
  const navigate = useNavigate();

  const navigateToManagement = () => {
    navigate(MANAGEMENT_URL);
  };

  const navigateToAgent = () => {
    navigate(AGENT_URL);
  };

  const navigateToListAgency = () => {
    navigate(LIST_AGENCY);
  };

  const navigateToAgency = (agencyId: number) => {
    navigate(AGENCY_URL.replace(':agencyId', agencyId.toString()));
  };

  return {
    navigateToManagement,
    navigateToAgency,
    navigateToAgent,
    navigateToListAgency,
  };
};

export default useBackOfficeNavigation;
