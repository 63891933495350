import { HouseResume } from 'models/application/HouseResume.model';
import { useState } from 'react';
import { getPrincipalHouses } from 'services/Home/getPrincipalHouses.service';
import useAsync from 'hooks/useAsync.hook';

const useTopTenHouses = () => {
  const [topTenHouses, setTopTenHouses] = useState<HouseResume[]>([]);

  const { loading, call: getTopTenHouses } = useAsync(async () => {
    const principalHouses = await getPrincipalHouses();
    setTopTenHouses(principalHouses);
  }, []);

  return {
    topTenHouses,
    loadingHouses: loading,
    getTopTenHouses,
  };
};

export default useTopTenHouses;
