import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type CopyrightProps = {
  backgroundColor: string;
  color: string;
};

const Copyright: React.FC<CopyrightProps> = ({ backgroundColor, color }) => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.copyright}
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {t('HOME.FOOTER.MESSAGE_COPYRIGHT')}
    </div>
  );
};

export default Copyright;
