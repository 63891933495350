import { useMemo, useRef, useState } from 'react';

import Lightbox from 'yet-another-react-lightbox';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import {
  LastPicture,
  LastPictureContainer,
  MainContainer,
  MainPicture,
  MorePhotos,
  SecondaryPictures,
  SecondPicture,
  SmallPicture,
} from './ListImagePreview.style';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import { IPicture } from 'models/application/interfaces';
import { ImageNotAvailable } from 'assets';
import { useTranslation } from 'react-i18next';

type ListImagePreviewProps = {
  pictures: IPicture[];
};

const ListImagePreview: React.FC<ListImagePreviewProps> = ({ pictures }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  const zoomRef = useRef(null);

  const slides = useMemo(
    () =>
      pictures.map((picture) => ({
        src: picture.url,
      })),
    [pictures],
  );

  const handlePreviewOpen = () => {
    setIsOpen((open) => !open);
  };

  const handleClickImage = (index: number) => {
    setCurrentIndex(index);
    handlePreviewOpen();
  };

  return (
    <MainContainer>
      <MainPicture
        src={pictures[0]?.url ?? ImageNotAvailable}
        alt="Imagem principal em destaque"
        onClick={() => handleClickImage(0)}
        loading="lazy"
      />
      <SecondaryPictures>
        <SecondPicture
          src={pictures[1]?.url ?? ImageNotAvailable}
          alt="Segunda imagem menor"
          onClick={() => handleClickImage(1)}
          loading="lazy"
        />
        <SmallPicture
          src={pictures[2]?.url ?? ImageNotAvailable}
          alt="Terceira imagem menor"
          onClick={() => handleClickImage(2)}
          loading="lazy"
        />
        <LastPictureContainer onClick={() => handleClickImage(3)}>
          <MorePhotos>{t('DETAILS_HOUSE.MORE_IMAGES')}</MorePhotos>
          <LastPicture
            src={pictures[3]?.url ?? ImageNotAvailable}
            alt="Quarta imagem com um botão de ver mais na frente"
            loading="lazy"
          />
        </LastPictureContainer>
      </SecondaryPictures>
      <Lightbox
        open={isOpen}
        close={handlePreviewOpen}
        slides={slides}
        plugins={[Zoom, Counter]}
        zoom={{ ref: zoomRef }}
        index={currentIndex}
      />
    </MainContainer>
  );
};

export default ListImagePreview;
