import useAsync from 'hooks/useAsync.hook';
import createAgency from 'services/Agency/createAgency.service';
import createAgent from 'services/Agent/createAgent.service';
import { LanguageEnum } from 'utils/enums/languages.enum';
import BrazilianAgent from 'models/application/Agent/BrazilianAgent.model';
import BrazilianAgency from 'models/application/Agency/BrazilianAgency.model';
import AmericanAgency from 'models/application/Agency/AmericanAgency.model';
import AmericanAgent from 'models/application/Agent/AmericanAgent.model';
import LatinAmericanAgency from 'models/application/Agency/LatinAmericanAgency.model';
import LatinAmericanAgent from 'models/application/Agent/LatinAmericanAgent.model';
import { showErrorMessage, showSuccessMessage } from 'utils/Toast';
import Agency from 'models/application/Agency/Agency.model';
import editAgency from 'services/Agency/editAgency.service';
import getAgency from 'services/Agency/getAgency.service';
import { useTranslation } from 'react-i18next';
import Operator from 'models/application/Operator/Operator.model';
import createOperator from 'services/operators/createOperator';

const useAgency = () => {
  const { t } = useTranslation();

  const { loading: loadingBR, call: createAgencyAndAgentBR } = useAsync(
    async (agencyBR: BrazilianAgency, agentBR: BrazilianAgent, operator?: Operator) => {
      let operatorId = null;
      try {
        if (operator) {
          const id = await createOperator({
            name: operator.name,
            cnpj: operator.cnpj,
            address: operator.address,
            city: operator.city,
            country: operator.country,
          });
          operatorId = id;
        }

        const agencyId = await createAgency({
          name: agencyBR.name,
          document: agencyBR.cnpj,
          primaryContact: agencyBR.cellphone,
          numberState: agencyBR.stateRegistration,
          cadastur: agencyBR.cadastur,
          urlSite: agencyBR.siteUrl,
          email: agencyBR.email,
          language: LanguageEnum.PORTUGUESE,
          country: agencyBR.country,
          address: agencyBR.address,
          city: agencyBR.city,
          operadoraId: operatorId,
        });

        await createAgent({
          name: agentBR.name,
          cpf: agentBR.cpf,
          email: agentBR.email,
          phone: agentBR.phone,
          agencyCode: agencyId,
          address: `${agentBR.complement} ${agentBR.number}`,
          zipcode: agentBR.cep,
          country: 'Brasil',
          password: agentBR.password,
          role: operator ? 'OPERATOR' : 'ADMIN',
        });
      } catch (err: any) {
        showErrorMessage(err?.response?.data);
        throw new Error(err);
      }
    },
    [],
  );

  const { loading: loadingEN, call: createAgencyAndAgentEN } = useAsync(
    async (agencyEN: AmericanAgency, agentEN: AmericanAgent, operator?: Operator) => {
      let operatorId = null;
      try {
        if (operator) {
          const id = await createOperator({
            name: operator.name,
            cnpj: operator.cnpj,
            address: operator.address,
            city: operator.city,
            country: operator.country,
          });
          operatorId = id;
        }

        const agencyId = await createAgency({
          name: agencyEN.name,
          document: agencyEN.association,
          primaryContact: agencyEN.cellphone,
          numberState: agencyEN.associationCode,
          email: agencyEN.email,
          language: LanguageEnum.ENGLISH,
          country: agencyEN.country,
          address: agencyEN.address,
          city: agencyEN.city,
          operadoraId: operatorId,
        });

        await createAgent({
          name: `${agentEN.firstName} ${agentEN.lastName}`,
          email: agentEN.email,
          phone: agentEN.phone,
          agencyCode: agencyId,
          address: agencyEN.address,
          zipcode: agencyEN.zipCode,
          association: agencyEN.association,
          associationId: agencyEN.associationCode,
          state: agencyEN.state,
          country: agencyEN.country,
          city: agencyEN.city,
          password: agentEN.password,
          role: operator ? 'OPERATOR' : 'ADMIN',
        });
      } catch (err: any) {
        showErrorMessage(err?.response.data);
        throw new Error(err);
      }
    },
    [],
  );

  const { loading: loadingES, call: createAgencyAndAgentES } = useAsync(
    async (agencyES: LatinAmericanAgency, agentES: LatinAmericanAgent, operator?: Operator) => {
      let operatorId = null;
      try {
        if (operator) {
          const id = await createOperator({
            name: operator.name,
            cnpj: operator.cnpj,
            address: operator.address,
            city: operator.city,
            country: operator.country,
          });
          operatorId = id;
        }

        const agencyId = await createAgency({
          name: agencyES.name,
          primaryContact: agencyES.cellphone,
          email: agencyES.email,
          document: agencyES.corporateName,
          language: LanguageEnum.SPANISH,
          country: agencyES.country,
          address: agencyES.street,
          city: agencyES.city,
          operadoraId: operatorId,
        });

        await createAgent({
          name: `${agentES.firstName} ${agentES.lastName}`,
          email: agentES.email,
          phone: agentES.phone,
          agencyCode: agencyId,
          address: agencyES.street,
          country: agencyES.country,
          password: agentES.password,
          role: operator ? 'OPERATOR' : 'ADMIN',
        });
      } catch (err: any) {
        showErrorMessage(err?.response.data);
        throw new Error(err);
      }
    },
    [],
  );

  const { loading: loadingGet, call: get } = useAsync(async (agencyId: string) => {
    try {
      return await getAgency(agencyId);
    } catch (err: any) {
      showErrorMessage(err?.response.data);
    }
  });

  const { loading: loadingEdit, call: edit } = useAsync(async (agency: Agency) => {
    try {
      await editAgency(agency);
      showSuccessMessage(t('BACKOFFICE.AGENCY.FEEDBACK.EDIT_SUCCESS'));
    } catch (err: any) {
      showErrorMessage(err?.response.data);
    }
  });

  return {
    createAgencyAndAgentBR,
    createAgencyAndAgentEN,
    createAgencyAndAgentES,
    editAgency: edit,
    getAgency: get,
    loadingEdit,
    loadingGet,
    loadingSubmitAgencyWithAgent: loadingBR || loadingEN || loadingES,
  };
};

export default useAgency;
