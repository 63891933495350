import Booking from 'models/application/Booking.model';
import { useNavigate } from 'react-router-dom';

interface NavigateListHousesParams {
  checkIn: string | null;
  checkOut: string | null;
  adults: number | string;
  kids: number | string;
  pets: number | string;
  babies: number | string;
  pmId: number | string;
}
const useHouseListNavigation = () => {
  const navigate = useNavigate();

  const navigateToHouseList = (booking: Booking) => {
    const basePath = `/list-houses`;
    const queries = {
      ...booking.getParams(),
    } as any;

    const queryString = new URLSearchParams(queries).toString();

    const fullPath = `${basePath}?${queryString}`;

    navigate(fullPath, { replace: true });
  };

  const navigateToCondominium = (booking: Booking, state: any) => {
    const basePath = `/condominium`;
    const queries = {
      ...booking.getParams(),
    } as any;

    const queryString = new URLSearchParams(queries).toString();
    const fullPath = `${basePath}?${queryString}`;

    navigate(fullPath, {
      state: state,
    });
  };

  const navigateToCondominiums = (selectedCity: string, booking: Booking) => {
    const basePath = `/condominiums/${selectedCity}`;
    const queries = {
      ...booking.getParams(),
    } as any;

    const queryString = new URLSearchParams(queries).toString();
    const fullPath = `${basePath}?${queryString}`;

    navigate(fullPath);
  };

  const navigateToHome = (booking: Booking, condominium: string) => {
    const basePath = '/';
    const queries = {
      ...booking.getParams(),
    } as any;

    const queryString = new URLSearchParams(queries).toString();
    const fullPath = `${basePath}?condominium=${condominium}&${queryString}`;

    navigate(fullPath);
  };

  const getCurrentURLData = (): NavigateListHousesParams => {
    const checkIn = new URLSearchParams(location.search).get('checkIn') ?? null;
    const checkOut = new URLSearchParams(location.search).get('checkOut') ?? null;
    const adults = new URLSearchParams(location.search).get('adults') ?? 0;
    const kids = new URLSearchParams(location.search).get('kids') ?? 0;
    const babies = new URLSearchParams(location.search).get('babies') ?? 0;
    const pets = new URLSearchParams(location.search).get('pets') ?? 0;
    const pmId = new URLSearchParams(location.search).get('pmId') ?? 0;

    return {
      checkIn,
      checkOut,
      adults,
      kids,
      babies,
      pets,
      pmId,
    };
  };

  const getCondominium = (): string => {
    const condominium = new URLSearchParams(location.search).get('condominium') ?? '';

    return condominium;
  };

  return {
    navigateToHouseList,
    getCurrentURLData,
    navigateToCondominiums,
    navigateToCondominium,
    navigateToHome,
    getCondominium,
  };
};

export default useHouseListNavigation;
