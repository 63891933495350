class AmericanAgency {
  name: string;
  email: string;
  cellphone: string;
  association: string;
  associationCode: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  state: string;

  constructor(agency: AmericanAgency) {
    this.name = agency.name;
    this.email = agency.email;
    this.cellphone = agency.cellphone;
    this.association = agency.association;
    this.associationCode = agency.associationCode;
    this.address = agency.address;
    this.city = agency.city;
    this.zipCode = agency.zipCode;
    this.country = agency.country;
    this.state = agency.state;
  }
}

export default AmericanAgency;
