import { isValidCNPJ, isValidPhoneNumber } from 'utils/validator';
import Yup from 'utils/yup';

export const validateAgency = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  cnpj: Yup.string().test('is-valid-cnpj', 'CNPJ inválido', (value) => isValidCNPJ(value)),
  city: Yup.string().required(),
  address: Yup.string().required(),
  country: Yup.string().required(),
  cellphone: Yup.number()
    .required()
    .test('is-valid-phone', 'Celular inválido', (value) => isValidPhoneNumber(value)),
});
