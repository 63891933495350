import OperatorsTable from './Components/OperatorsTable';
import styles from './styles.module.scss';
import { Dialog, Grid, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { IoSearchSharp } from 'react-icons/io5';
import Button from 'components/BackOffice/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderInformations from './Components/HeaderInformations';
import ButtonReports from './Components/ButtonReports';
import { REGISTER_OPERATORS_AND_AGENCIES } from 'routes/routes/backOffice.routes';
import { Buttons } from 'components/molecules/buttons';

const ListOperators = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [search, setSearch] = useState('');

  const handleChange = (event: any) => {
    setSearch(event.target.value);
  };

  const handleAvailabilityNavigation = () => {
    navigate('/admin/check-availability');
  };

  const handleRegisterNavigation = () => {
    setOpenDialog(true);
  };

  const navigateOperator = () => {
    navigate(REGISTER_OPERATORS_AND_AGENCIES, {
      state: {
        form: 'Operator',
      },
    });
  };

  const navigateAgency = () => {
    navigate(REGISTER_OPERATORS_AND_AGENCIES, {
      state: {
        form: 'Agency',
      },
    });
  };

  return (
    <div>
      <div className={styles.info}>
        <HeaderInformations title={`${t('BACKOFFICE.MANAGEMENT')} GoWeasy`} />
      </div>
      <div className={styles.content}>
        <div className={styles.actions}>
          <Grid container spacing={2}>
            <Grid item lg={4}>
              <div className={styles.inputs}>
                <TextField
                  label={t('BACKOFFICE.SEARCH')}
                  variant="outlined"
                  value={search}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IoSearchSharp size={24} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item lg={8}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <ButtonReports />
                <Button onClick={() => handleAvailabilityNavigation()}>{t('BACKOFFICE.AVAILABILITY')} </Button>
                <Button onClick={() => handleRegisterNavigation()}>{t('ADMINISTRATIVE.REPORTS.REGISTER')}</Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.table}>
          <OperatorsTable filter={search} />
        </div>
        <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
          <div
            style={{
              alignItems: 'center',
              width: '300x',
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '40px',
              padding: '40px',
            }}
          >
            <h3>{t('BACKOFFICE.REGISTER_TITLE')}</h3>
            <Buttons.Default fullWidth onClick={navigateOperator}>
              {t('BACKOFFICE.OPERATOR')}
            </Buttons.Default>
            <Buttons.Default fullWidth onClick={navigateAgency}>
              {t('PUBLIC.REGISTER.AGENCIE')}
            </Buttons.Default>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ListOperators;
