import Booking from 'models/application/Booking.model';
import { IBookingPrice } from 'models/contracts/response/BookingPrice.response';
import { axiosInstances } from 'services/api';
import { IApi } from 'models/application/interfaces/Api.interface';
import { showErrorMessage } from 'utils/Toast';
import i18next from 'i18next';

export const getBookingPrice = async (booking: Booking, userId: number, signal: any): Promise<Booking> => {
  try {
    const { data }: IApi<IBookingPrice> = await axiosInstances.base.instance.post(
      'service/v1/house/price',
      {
        houseId: booking.houseId,
        userId,
        checkin: booking.checkIn,
        checkout: booking.checkOut,
        guests: booking.getTotalGuests(),
      },
      {
        signal: signal,
      },
    );

    const validDates = Object.keys(data.costReal?.perDay ?? data.costDollar?.perDay ?? {});

    booking.setBookingDates(validDates);

    booking.setPrice({
      dollar: data.costDollar.total,
      real: data.costReal.total,
    });

    return booking;
  } catch (error: any) {
    if (!error?.response) {
      throw new Error('generic_error');
    }

    if (error.response.data) {
      const conflictError = 'reservation period conflict';

      if (error.response.data.includes(conflictError)) {
        showErrorMessage(i18next.t('RESPONSE.PRICE_RESERVE_ERROR_CONFLICT'));
        throw new Error('Erro ao buscar os valores, a reserva para esta casa está em conflito com outra reserva.');
      }

      const days = error.response.data.match(/\d+/);
      if (days) {
        showErrorMessage(i18next.t('RESPONSE.PRICE_RESERVE_ERROR', { days: days[0] }));
        throw new Error(`Erro ao buscar os valores, a reserva para esta casa deve ter no mínimo ${days[0]} dias.`);
      }
    }

    showErrorMessage(i18next.t('RESPONSE.PRICE_RESERVE_ERROR_GENERIC'));
    throw new Error('Erro ao buscar preço da reserva. Tente novamente mais tarde.');
  }
};
